import React, { memo, Fragment } from 'react';
import BookReturnTransfer from './BookReturnTransfer.js';
import PopularDestinations from './PopularDestinations/index.js';
import HvaB2bPromoPartner from './HvaB2bPromoPartner/index.js';
import AirbnbHostInvitation from '../../../../components/common/AirbnbHostInvitations';
import HvaSRides from './HvaSRides';

const ExtraTripBlocks = ({
  transfers,
  currentTrip,
  showBookTransfer,
  showPopularDestinationsReturn,
  showPopularDestinations
}) => {
  return (
    <Fragment>
      {/* Partner registration promo HVA */}
      {(currentTrip.hvaB2BPromoTrAgentUrl || currentTrip.hvaB2BPromoBusinessUrl) && (
        <HvaB2bPromoPartner
          travelAgentUrl={currentTrip.hvaB2BPromoTrAgentUrl}
          businessTravelUrl={currentTrip.hvaB2BPromoBusinessUrl}
          localStorageKey={`hidden_hva_b2b_promo_partner_${currentTrip.id}`}
        />
      )}

      {/* Airbnb question HVA */}
      <AirbnbHostInvitation />

      {/* Srides promo HVA */}
      <HvaSRides
        cityName={currentTrip.city}
        // localStorageKey={`hidden_hva_srides_trip_details_${currentTrip.id}`}
      />

      {/* Return transfers */}
      {showBookTransfer && <BookReturnTransfer />}

      {/* Popular destination suggestions */}
      {showPopularDestinationsReturn && (
        <PopularDestinations
          destinationReturn
          transfers={transfers}
          link={currentTrip.popularDestinationsReturnDifferentRideUrl}
          popularDestinationsReturn={currentTrip.popularDestinationsReturn}
        />
      )}
      {showPopularDestinations && (
        <PopularDestinations
          link={currentTrip.popularDestinationsOutsideCityDifferentRideUrl}
          popularDestinationsOutsideCity={currentTrip.popularDestinationsOutsideCity}
          city={currentTrip.city}
        />
      )}
    </Fragment>
  );
};

export default memo(ExtraTripBlocks);
