import { isIosMobileApp, stringWithEllipsis } from '@/src/components/common/helpers';
import { StyledModal } from '@/src/components/styled/IonElements';
import debounce from '@/src/helpers/debounce';
import mixpanel from '@/src/services/mixpanel';
import { IonItem, IonList, IonPopover } from '@ionic/react';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { Channel, MessageInput, Thread, MessageList, Window, withChatContext } from 'stream-chat-react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import {
  MobileChannelContainer,
  MobileChannelHeader,
  MobileChannelTitle,
  MobileDriverName
} from './mobileChannel.styled';
import { getDriver, scrollToLastMessage } from '@/src/components/common/Chat/helpers';
import ellipsisIcon from '@/src/assets/icons/ellipsis_horizontal_icon.svg';
import { format, parseISO } from 'date-fns';
import PropTypes from 'prop-types';
import { TransferDetails } from '../MyChannelPreview/myChannelPreview.styled';

class MobileChannel extends React.Component {
  constructor(props) {
    super(props);

    this.activeChannelModalRef = React.createRef();
    this.popOverRef = React.createRef();
    this.onCloseDebounced = debounce((e) => this.onClose(), 500);

    this.state = {
      showPopover: false,
      popoverEvent: undefined
    };
  }

  onClose = (e) => {
    const { channel } = this.props;

    mixpanel.trackChatClose({
      Chat_Id: channel.id,
      Order_Name: channel.data.name,
      Order_Id: channel.data.service.id,
      Is_Chat_Active: !channel.data.frozen,
      Created_By: channel.data.created_by.member_type
    });
    mixpanel.trackChatlListView();

    this.props.setActiveChannel(null);
  };

  transferFromDatetimeTimeFormatted(datetime) {
    const date = parseISO(datetime);
    return format(date, 'd MMM');
  }

  componentDidUpdate(prevProps) {
    const { channel: oldChannel } = prevProps;
    const { channel } = this.props;

    if (!oldChannel && channel) {
      mixpanel.trackChatOpenDebounced.init({
        Chat_Id: channel.id,
        Order_Name: channel.data.name,
        Order_Id: channel.data.service.id,
        Is_Chat_Active: !channel.data.frozen,
        Created_By: channel.data.created_by.member_type
      });

      setTimeout(() => {
        scrollToLastMessage();
      }, 200);
    }
  }

  render() {
    const { channel, t } = this.props;

    if (!channel) {
      return null;
    }

    const driver = getDriver(channel);
    const driverFirstName = driver?.first_name || driver?.name; // change to first_name
    const driverName = `Driver ${driverFirstName}`;

    let transferFromTitle, transferToTitle, transferFromDateTime;

    const service = channel.data.service;
    const transferToken = service.token || service.service_token; //TODO: remove_service
    const transferType = service.type || service.service_type; //TODO: remove service

    const transfer = this.props.messagesStore.getTransferForChannelToken(transferToken);

    if (transfer && transferType === 'Transfer') {
      transferFromTitle = transfer.fromTitle;
      transferToTitle = transfer.toTitle;
      transferFromDateTime = this.transferFromDatetimeTimeFormatted(transfer.fromDatetime);
    } else if (transfer && transferType === 'TourTransfer') {
      transferFromTitle = transfer.fromTitle;
      transferToTitle = transfer.title;
      transferFromDateTime = this.transferFromDatetimeTimeFormatted(transfer.fromDatetime);
    }

    return (
      <StyledModal
        isOpen={!!channel}
        ref={this.activeChannelModalRef}
        onDidDismiss={(e) => this.onCloseDebounced.init(e)}
      >
        {/* <div style={{ height: '100%' }}> */}
        <MobileChannelHeader>
          <div className="back-arrow-icon" onClick={() => this.activeChannelModalRef.current?.dismiss()} />
          <MobileChannelTitle>
            <MobileDriverName>{driverName}</MobileDriverName>
            <TransferDetails>
              <span>{transferFromTitle ? `From ${transferFromTitle} ${String.fromCharCode(8594)} ` : ''}</span>
              <span>{transferToTitle ? `${stringWithEllipsis(transferToTitle, 20)} - ` : ''}</span>
              <span>{transferFromDateTime || ''}</span>
            </TransferDetails>
          </MobileChannelTitle>
          <div>
            <button
              style={{ width: '40px', height: '40px', background: 'transparent' }}
              onClick={(e) => {
                e.persist();
                this.setState({ showPopover: true, popoverEvent: e });
              }}
            >
              <img alt={'Menu'} src={ellipsisIcon} />
            </button>
            <IonPopover
              ref={this.popOverRef}
              cssClass="my-custom-class"
              event={this.state.popoverEvent}
              isOpen={this.state.showPopover}
              onDidDismiss={() => this.setState({ showPopover: false, popoverEvent: undefined })}
            >
              <IonList lines="none">
                <IonItem
                  button
                  onClick={(e) => {
                    this.popOverRef.current?.dismiss();
                    this.activeChannelModalRef.current?.dismiss();
                    this.props.onViewTransferClick(e, channel);
                  }}
                  detail={false}
                >
                  {t('messages.cta_to_transfer')}
                </IonItem>
                <IonItem
                  href={this.props.helpUrl}
                  target="_blank"
                  detail={false}
                  onClick={() => {
                    this.popOverRef.current?.dismiss();
                  }}
                >
                  {t('my_trip.help')}
                </IonItem>
              </IonList>
            </IonPopover>
          </div>
        </MobileChannelHeader>
        <MobileChannelContainer className={isIosMobileApp() ? 'from-ios-app' : ''}>
          <Channel acceptedFiles={['image/*']}>
            <Window>
              <MessageList messageActions={[]} />
              {channel && channel.data.frozen ? null : <MessageInput grow />}
            </Window>
            <Thread />
          </Channel>
        </MobileChannelContainer>

        {/* </div> */}
      </StyledModal>
    );
  }
}

MobileChannel.propTypes = {
  channel: PropTypes.object,
  setActiveChannel: PropTypes.func,
  messagesStore: PropTypes.object,
  onViewTransferClick: PropTypes.func,
  helpUrl: PropTypes.string,
  t: PropTypes.func
};

export default withTranslation()(withRouter(withChatContext(inject('messagesStore')(observer(MobileChannel)))));
