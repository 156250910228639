import React from 'react';

const Comment = ({ fill, styles }) => (
  <svg
    width="20px"
    height="20px"
    style={styles}
    viewBox="0 0 20 20"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xlink="http://www.w3.org/1999/xlink"
  >
    <title>Comment</title>
    <desc>Created with sketchtool.</desc>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="01.12.01.D.Transfer" transform="translate(-528.000000, -1478.000000)">
        <g id="Main_card" transform="translate(488.000000, 84.000000)">
          <g id="Details.Block" transform="translate(40.000000, 1211.000000)">
            <g id="Icon-/-Small-/-Comment" transform="translate(0.000000, 183.000000)">
              <g id="Comment.Icon.20">
                <rect id="Bounds" x="0" y="0" width="20" height="20" />
                <path
                  d="M4,2 L16,2 C17.6568542,2 19,3.34314575 19,5 L19,11.996582 C19,13.6534363 17.6568542,14.996582 16,14.996582 L5.6,14.996582 L1,19 L1,5 C1,3.34314575 2.34314575,2 4,2 Z M4.85156773,12.996582 L16,12.996582 C16.5522847,12.996582 17,12.5488668 17,11.996582 L17,5 C17,4.44771525 16.5522847,4 16,4 L4,4 C3.44771525,4 3,4.44771525 3,5 L3,14.6080167 L4.85156773,12.996582 Z"
                  id="Fill"
                  fill={fill}
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default Comment;
