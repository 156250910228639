import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { applyStyles } from '@/src/helpers/styling';
import { IonCard } from '@ionic/react';

const baseStyle = ({ styles }) => ({ ...applyStyles(styles) });

/**
 * @example
 * <Wrapper
 *  element="div"
 *  styles={{
 *    margin: '20px',
 *    md: { margin: '30px' },
 *    lg: { margin: '40px' }
 *  }}></Wrapper>
 *
 */
export const Wrapper = styled(({ element: Component = 'div', ...props }) => <Component {...props} />).withConfig({
  shouldForwardProp: (prop) => !['styles'].includes(prop)
})(baseStyle);

Wrapper.propTypes = {
  element: PropTypes.oneOf(['div', 'section', 'article', 'main', 'header', 'footer', 'aside', 'nav', IonCard]),
  styles: PropTypes.object
};
