import { makeAutoObservable } from 'mobx';
// import TravelerApi from "../API";
import { StreamChat } from 'stream-chat';

import travelerAppStore from './travelerAppStore';
import { App } from '@capacitor/app';
import mixpanel from '../services/mixpanel';
import { isMobileAppPlatform } from '../components/common/helpers';
import { logError } from '../helpers/log';

let appStateChangeListener = null;

class MessagesStore {
  chatClient = null;
  /*
    ChatUser:
      chat_member_id: "traveler_87038"
      member_type: "traveler"
      token: "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjoidHJhdmVsZXJfODcwMzgifQ.lpX1Lh98T5i2S9Q7hn2izBvshdXFJbrtmPXn3nhA4OM"
  */
  chatUser = {};
  showNewMessagesBadge = false;
  channels = [];
  reconnecting = false;
  transfersPerChannelToken = {};
  failedToFetchTransfers = new Set(); // We store transfer ids that we failed to fetch so we don't try to fetch them again when no needed
  activeChannels = [];
  archivedChannels = [];
  totalUnreadMessages = 0;
  messagesReady = false;

  constructor() {
    makeAutoObservable(this);
  }

  /* SETTERS */
  setshowNewMessagesBadge = (state) => {
    this.showNewMessagesBadge = state;
  };

  setActiveChannels = (channels) => {
    this.activeChannels.replace(channels);
  };

  setArchivedChannels = (channels) => {
    this.archivedChannels.replace(channels);
  };

  setTotalUnreadMessages = (number) => {
    this.totalUnreadMessages = number;
  };

  setMessagesReady = (value) => {
    this.messagesReady = value;
  };

  /* METHODS */
  activeChannelsFilters = () => ({ type: 'messaging', members: { $in: [this.chatUser?.chatMemberId] }, frozen: false });
  archivedChannelsFilters = () => ({ ...this.activeChannelsFilters(), frozen: true });

  connect = async (chatUser = {}) => {
    // If we do not have a connection or if we have with a different chat user
    // connect to stream
    if (this.chatClient === null) {
      const chatClient = new StreamChat(process.env.REACT_APP_STREAM_API_KEY, { timeout: 15000 });
      //Set the client server location to new edge API infrastructure
      chatClient.setBaseURL('https://chat.stream-io-api.com');
      this.chatClient = chatClient;
    }
    // set chat user
    if (
      !this.chatUser.chatMemberId ||
      (this.chatUser.chatMemberId && this.chatUser.chatMemberId !== chatUser.chatMemberId)
    ) {
      this.chatUser = chatUser;

      try {
        const connected = await this.chatClient.connectUser(
          {
            id: chatUser.chatMemberId
          },
          chatUser.token
        );

        // Register device for push notifications
        if (isMobileAppPlatform() && travelerAppStore.firebaseToken) {
          await this.registerDevice(travelerAppStore.firebaseToken);
        }

        if (connected && connected.me.total_unread_count > 0) {
          this.setshowNewMessagesBadge(true);
        }

        const activeChannels = (await this.chatClient.queryChannels(this.activeChannelsFilters())) || [];
        const archivedChannels = (await this.chatClient.queryChannels(this.archivedChannelsFilters())) || [];
        let totalUnreadMessages = 0;
        for (let channel of activeChannels) {
          const channelUnreadMessages = channel.countUnread();
          totalUnreadMessages += channelUnreadMessages;
        }

        this.setActiveChannels(activeChannels);
        this.setArchivedChannels(archivedChannels);
        this.setTotalUnreadMessages(totalUnreadMessages);

        this.setMessagesReady(true);

        // Listen for client events
        // You can find more about channel events here: https://getstream.io/chat/docs/react/event_object/
        this.clientEventsHandler = this.chatClient.on((event) => {
          if (event.total_unread_count < this.totalUnreadMessages) {
            mixpanel.trackMessageRead();
          }

          if (event.total_unread_count > this.totalUnreadMessages) {
            mixpanel.trackMessageReceived();
          }

          if (event.type === 'message.new' && event.user?.member_type === 'traveler') {
            mixpanel.trackMessageSent();
          }

          if (event.total_unread_count >= 0) {
            this.setTotalUnreadMessages(event.total_unread_count);
          }

          if (
            (event.total_unread_count !== undefined && event.total_unread_count > 0) ||
            (event.unread_channels !== undefined && event.unread_channels > 0)
          ) {
            // console.log(`unread messages count is now: ${event.total_unread_count}`);
            this.setshowNewMessagesBadge(true);
          }
        });

        setTimeout(() => {
          // Add listener to reconnect/disconnect user when App's state changes.
          // Needs a small timeout to make sure that that the listener is triggered at the correct time.
          if (isMobileAppPlatform()) {
            appStateChangeListener = App.addListener('appStateChange', async (state) => {
              // state.isActive contains the active state
              if (state.isActive) {
                if (this.chatClient?.wsConnection && !this.chatClient?.wsConnection?.isConnecting) {
                  console.log('Getstream user will connect');
                  await this.chatClient.wsConnection.connect();
                  await this.chatClient.recoverState();
                  console.log('Getstream user connected');
                }
              } else {
                // App goes to background
                if (this.chatClient?.wsConnection && this.chatClient.user !== undefined) {
                  console.log('Getstream user will disconnect');
                  await this.chatClient.wsConnection.disconnect();
                  console.log('Getstream user disconnected');
                }
              }
            });
          }
        }, 500);
      } catch (err) {
        logError('Chat connection error', err);
        return Promise.resolve(false);
      }
    }
    return Promise.resolve(true);
  };

  disconnect = async (firebaseToken) => {
    // if we have a connection disconnect and reset info
    if (this.chatClient) {
      try {
        // Stop listening for client events
        this.chatClient.off(this.clientEventsHandler);
        // Unregister device
        if (isMobileAppPlatform()) {
          await this.unregisterDevice(firebaseToken);
        }
        // Disconnect from getstream
        await this.chatClient.disconnectUser();
      } catch (err) {
        logError('Chat disconnection error', err);
      }
    }
    this.chatUser = {};

    if (appStateChangeListener) {
      appStateChangeListener.remove();
    }
  };

  fetchChannels = async () => {
    const filters = { type: 'messaging', members: { $in: [this.chatUser.chatMemberId] } };
    const sort = { last_message_at: -1 };
    const channels = await this.chatClient.queryChannels(filters, sort, { watch: true, state: true });
    this.channels.replace(channels);
    return channels;
  };

  reconnectUser = async () => {
    if (this.chatClient && this.chatUser.chatMemberId) {
      console.log('Getstream user connecting');
      try {
        const connected = await this.chatClient.connectUser(
          {
            id: this.chatUser.chatMemberId
          },
          this.chatUser.token
        );
        return connected;
      } catch (err) {
        logError('Error reconnecting user', err);
        return Promise.resolve(false);
      }
    } else {
      return Promise.resolve(true);
    }
  };

  registerDevice = async (firebaseToken) => {
    if (this.chatClient && this.chatUser.chatMemberId) {
      try {
        return await this.chatClient.addDevice(firebaseToken, 'firebase', this.chatUser.chatMemberId);
      } catch (err) {
        logError('Error registering device', err);
      }
    }
    return Promise.resolve(false);
  };

  unregisterDevice = async (firebaseToken) => {
    if (this.chatClient && this.chatUser.chatMemberId && firebaseToken) {
      try {
        return await this.chatClient.removeDevice(firebaseToken, this.chatUser.chatMemberId);
      } catch (err) {
        logError('Error unregistering device', err);
      }
    }

    return Promise.resolve(false);
  };

  getTransferForChannelToken = (channelToken) => {
    // return transfer from stores or fetch it
    return this.transfersPerChannelToken[channelToken];
  };

  saveTransferWithChannelToken = (transfer, channelToken) => {
    this.transfersPerChannelToken[channelToken] = transfer;
  };
}
const messagesStore = new MessagesStore();

export default messagesStore;
