const travelerAppConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_TRAVELER_APP_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_TRAVELER_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_TRAVELER_APP_DB_URL,
  projectId: process.env.REACT_APP_FIREBASE_TRAVELER_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_TRAVELER_APP_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_TRAVELER_APP_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_TRAVELER_APP_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_TRAVELER_APP_MEASUREMENT_ID
};

const driversAppConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_DRIVERS_APP_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_DRIVERS_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DRIVERS_APP_DB_URL,
  projectId: process.env.REACT_APP_FIREBASE_DRIVERS_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_DRIVERS_APP_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_DRIVERS_APP_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_DRIVERS_APP_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_DRIVERS_APP_MEASUREMENT_ID
};

export { travelerAppConfig, driversAppConfig };
