import React, { useEffect, useState } from 'react';
import { useLocation, withRouter } from 'react-router-dom';
import queryString from 'query-string';
import { withTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import TripsHeader from './TripsHeader';
import TripPreviews from './TripPreviews';
import { useStores } from '../../stores/useStores';
import SkeletonTripsPreview from '../../components/common/ScarySkeletons/SkeletonTripsPreview';
import SingleLayout from '../../components/layouts/SingleLayout';
import { useHistory } from 'react-router-dom';
import mixpanel from '../../services/mixpanel';
import PromoPopupMyTrips from './Trip/List/PromoPopupMyTrips';
import { get, set } from '../../services/storage';
import { isMobileAppPlatform, isTruthy } from '../../components/common/helpers';
import braze from '../../services/braze';
import DuplicateTravelersNotification from '@/src/components/common/DuplicateTravelers/DuplicateTravelersNotification';
import PropTypes from 'prop-types';
import Container from '@/src/components/layouts/containers/Container';
import { FlexContainer } from '@/src/components/styled/Wrappers';

const Trips = (props) => {
  const { t } = props;

  const [selectedTab, setSelectedTab] = useState();

  const {
    travelerAppStore: {
      isAuthorised,
      setPromoVisiblePopupMyTrips,
      setTripsTabVisited,
      tripsTabVisited,
      setShowExploreBadge,
      travelerInfo,
      isAllowedToMergeAccounts
    },
    tripsStore: { fetchTripPreviews, tripPreviewsLoading, tripPreviews },
    duplicateTravelersStore: { duplicateTravelersInfo }
  } = useStores();

  const location = useLocation();
  const history = useHistory();

  const renderContent = () => {
    if (!selectedTab || tripPreviewsLoading) {
      return <SkeletonTripsPreview />;
    }

    if (selectedTab === 'completed') {
      return <TripPreviews tabName="completed" />;
    } else {
      return <TripPreviews tabName="upcoming" />;
    }
  };

  const handleTabSelect = (newTab) => {
    const urlParams = queryString.parse(location.search);

    if (urlParams.tab_name !== newTab) {
      history.replace(`/trips?tab_name=${newTab}`);
    }
  };

  const doRefresh = async (event) => {
    if (isAuthorised) {
      try {
        await fetchTripPreviews();

        if (isMobileAppPlatform()) {
          braze.getContentCardsFromServer();
        }
        // eslint-disable-next-line no-unused-vars
      } catch (err) {}
    }

    event.detail.complete();
  };

  const getMixpanelEventData = () => {
    const trips = selectedTab === 'upcoming' ? tripPreviews.upcoming?.length || 0 : tripPreviews.completed?.length || 0;
    const hvaPlacements = {
      return_transfer:
        selectedTab === 'upcoming' && tripPreviews.upcoming?.length
          ? !!tripPreviews.upcoming[0].returnBannerInfo
          : false,
      review_transfer: isTruthy(tripPreviews.reviewTransferHva)
    };

    return {
      Trips_Count: trips,
      HVA_Placements: hvaPlacements,
      merge_accounts_visible: isAllowedToMergeAccounts || undefined
    };
  };

  const trackPageView = () => {
    if (selectedTab === 'upcoming') {
      mixpanel.trackUpcomingTripsView(getMixpanelEventData());
    } else if (selectedTab === 'completed') {
      mixpanel.trackCompletedTripsView(getMixpanelEventData());
    }
  };

  const initPromoPopup = async () => {
    // TODO: Maybe rename the variable to "tripsTabVisits"
    let times = Number(await get('loggedTimes')) || 0;
    times++;

    if (times <= 2 && !tripsTabVisited) {
      // Once this value reaches 2, we don't need to increase it anymore
      set('loggedTimes', JSON.stringify(times));
      setShowExploreBadge(true);

      if (!isMobileAppPlatform() && ((isAuthorised && !travelerInfo.mAppInstalled) || !isAuthorised)) {
        setPromoVisiblePopupMyTrips(true);
      }

      setTripsTabVisited(true);
    } else {
      setPromoVisiblePopupMyTrips(false);
    }
  };

  const renderNotification = () => {
    if (isAllowedToMergeAccounts) {
      return (
        <DuplicateTravelersNotification
          title={t('duplicate_travelers.action_required')}
          subtitle={t('duplicate_travelers.merge_x_accounts_detected', {
            count: duplicateTravelersInfo.duplicateAccounts?.length
          })}
        />
      );
    }

    return null;
  };

  useEffect(() => {
    if (isAuthorised) {
      if (selectedTab && tripPreviews.upcoming?.length >= 0 && tripPreviews.completed?.length >= 0) {
        trackPageView();
      }
    } else {
      if (selectedTab) {
        trackPageView();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTab, tripPreviews]);

  useEffect(() => {
    const urlParams = queryString.parse(location.search);

    // If there is a specific tab_name in the URL, set it as the selected tab
    if (urlParams.tab_name && (urlParams.tab_name === 'upcoming' || urlParams.tab_name === 'completed')) {
      setSelectedTab(urlParams.tab_name);
    } else {
      setSelectedTab('upcoming');
    }

    if (isAuthorised && (!tripPreviews.upcoming || !tripPreviews.completed)) {
      try {
        fetchTripPreviews();
        // eslint-disable-next-line no-unused-vars
      } catch (err) {}
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  useEffect(() => {
    initPromoPopup();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SingleLayout
      marginTop="0"
      onRefresh={doRefresh}
      header={<TripsHeader onSelectTab={(newTab) => handleTabSelect(newTab)} selectedTab={selectedTab} />}
    >
      <Container>
        <FlexContainer flexDirection="column" gap="20px">
          {renderNotification()}
          {renderContent()}
        </FlexContainer>
      </Container>

      <PromoPopupMyTrips />
    </SingleLayout>
  );
};

Trips.propTypes = {
  t: PropTypes.func.isRequired
};

export default withTranslation()(withRouter(observer(Trips)));
