import React from 'react';
import PropTypes from 'prop-types';
import LocationInfo from './LocationInfo';

const FromToLocation = (props) => {
  const {
    fromTitle,
    fromAddress,
    fromKind = '',
    toTitle = '',
    toAddress = '',
    toKind = '',
    fromDateTime = '',
    timeZone = '',
    showDate,
    marginBottom = '0px'
  } = props;

  return (
    <div style={{ marginBottom: marginBottom }}>
      <LocationInfo
        from
        title={fromTitle}
        address={fromAddress}
        kind={fromKind}
        dateTime={fromDateTime}
        timeZone={timeZone}
        showDate={showDate}
        showLine={!!(toTitle || toAddress)}
      />

      {(toTitle || toAddress) && <LocationInfo title={toTitle} address={toAddress} kind={toKind} />}
    </div>
  );
};

FromToLocation.propTypes = {
  fromTitle: PropTypes.string.isRequired,
  fromAddress: PropTypes.string.isRequired,
  fromKind: PropTypes.string,
  fromDateTime: PropTypes.string,
  showDate: PropTypes.bool,

  toTitle: PropTypes.string,
  toAddress: PropTypes.string,
  toKind: PropTypes.string,

  timeZone: PropTypes.string,
  marginBottom: PropTypes.string
};

export default FromToLocation;
