// This import loads the firebase namespace along with all its type information.
import firebase from 'firebase/app';
// These imports load individual services into the firebase namespace.
import 'firebase/database';

import { travelerAppConfig, driversAppConfig } from '../config/firebase.config.js';

const travelerFirebaseApp = firebase.initializeApp(travelerAppConfig, 'travelersApp');

const driversFirebaseApp = firebase.initializeApp(driversAppConfig, 'driversApp');

const getTravelerFirebaseDb = async () => await travelerFirebaseApp.database();

const getDriversFirebaseDb = async () => await driversFirebaseApp.database();

export { travelerFirebaseApp, getTravelerFirebaseDb, driversFirebaseApp, getDriversFirebaseDb };
