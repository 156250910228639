import { useState } from 'react';

const useEmailValidation = () => {
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(null);

  const validateEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    setEmail(email);
    const valid = emailRegex.test(email);
    if (!valid) {
      setEmailError('Email is invalid. Correct errors');
    } else {
      setEmailError(null);
    }

    return valid;
  };

  return { email, emailError, setEmailError, validateEmail };
};

export default useEmailValidation;
