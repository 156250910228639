import React, { useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { StyledModal } from '../../styled/IonElements';
import styled from 'styled-components';
import Header from '../Modals/Header';
import MapBackground from '../../../assets/map-background.png';
import { useStores } from '../../../stores/useStores';
import defaultAvatar from '../../../assets/icons/avatar.svg';
import ChevronRight from '../../../assets/icons/chevron-right-grey.svg';
import theme from '../../../theme';
import Avatar from '../Avatar';
import { dateLocale } from '../helpers';
import i18n from '../../../services/i18n';
import { observer } from 'mobx-react';
import getUnicodeFlagIcon from 'country-flag-icons/unicode';
import mixpanel from '../../../services/mixpanel';

function AirbnbHostInvitationPreview(props) {
  const { t, isOpen, onClose } = props;
  const {
    travelerAppStore: {
      currentTrip: {
        airbnbHostInvitation: {
          travelerFullName,
          travelerPhotoUrl,
          travelerCountryCode,
          driverFullName,
          driverProfessionalPhotoUrl,
          vehicleBrand,
          vehicleModel,
          transferToDatetime,
          transferTimeZone
        }
      }
    }
  } = useStores();

  const handleClose = () => {
    onClose();
  };

  useEffect(() => {
    if (isOpen) {
      mixpanel.trackAirbnbInvitationPreviewed();
    }
  }, [isOpen]);

  return (
    <CustomStyledModal isOpen={isOpen} onClose={handleClose} onDidDismiss={handleClose}>
      <div className="padding-all-lg">
        <Header title={t('airbnb_host_invitations.trip_details_preview')} onClose={handleClose} />
      </div>
      <MapContainer>
        <PassengerContainer>
          <Avatar src={travelerPhotoUrl || defaultAvatar} size="42px" />
          <div style={{ flexGrow: '1' }}>
            <div className="title">{travelerFullName}</div>
            <div className="arriving-from">
              {t('airbnb_host_invitations.arriving_from')} {getUnicodeFlagIcon(travelerCountryCode)}
            </div>
          </div>
          <img src={ChevronRight} alt="chevron right" style={{ marginRight: '8px' }} />
        </PassengerContainer>
      </MapContainer>

      <EtaContainer>
        <div className="eta-circle">
          <div className="minutes">12</div>
          <div className="text">min</div>
        </div>

        <div className="description">
          {t('airbnb_host_invitations.estimated_arrival_time', {
            arrival_time: transferToDatetime && dateLocale(transferToDatetime, transferTimeZone, 'HH:mm', i18n.language)
          })}
        </div>

        {driverFullName && (
          <DriverContainer>
            <Avatar src={driverProfessionalPhotoUrl || defaultAvatar} />
            <div style={{ flexGrow: '1' }}>
              <div className="title-container">{t('trip_details.driver')}</div>

              <div className="name-container">{driverFullName}</div>

              <div className="vehicle-container">
                {vehicleBrand} {vehicleModel}
              </div>
            </div>

            <img src={ChevronRight} alt="chevron right" style={{ marginRight: '20px' }} />
          </DriverContainer>
        )}
      </EtaContainer>
    </CustomStyledModal>
  );
}

const CustomStyledModal = styled(StyledModal)`
  --height: auto;
  --max-height: 90%;
  --overflow: auto;
  --max-width: 450px;

  @media (max-width: 768px) {
    align-items: end;
    --border-radius: 4px 4px 0 0;
  }
`;

const MapContainer = styled.div`
  height: 680px;
  background-image: url(${MapBackground});
  background-size: cover;
  background-repeat: no-repeat;

  @media (max-width: 768px) {
    height: 520px;
  }
`;

const PassengerContainer = styled.div`
  background-color: #fff;
  margin: 8px;
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 8px;
  border-radius: 4px;

  .avatar {
    width: 40px;
    border-radius: 100%;
  }

  .title {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 2px;
  }

  .arriving-from {
    font-size: 10px;
    font-weight: 600;
    text-transform: uppercase;
    color: ${theme.colors.base.graphiteLighter};
  }
`;

const EtaContainer = styled.div`
  text-align: center;
  color: ${theme.colors.base.graphiteLighter};
  font-weight: 600;
  position: relative;

  .eta-circle {
    background-color: ${theme.colors.cta.primary};
    color: #fff;
    position: absolute;
    left: 50%;
    top: 0;
    transform: translate(-50%, -50%);
    min-width: 70px;
    min-height: 70px;
    border-radius: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 5px solid #fff;

    .minutes {
      font-size: 24px;
      color: #fff;
    }

    .text {
      color: ${theme.colors.cta.primary};
      filter: brightness(0.5);
      font-size: 12px;
      font-weight: 400;
      text-transform: uppercase;
    }
  }

  .description {
    margin: 35px 0 20px;
    color: ${theme.colors.base.graphiteLighter};
  }
`;

const DriverContainer = styled.div`
  background-color: ${theme.colors.background.grey};
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 8px;
  text-align: left;

  .title-container,
  .vehicle-container {
    text-transform: uppercase;
    color: ${theme.colors.base.graphiteLighter};
    font-size: 12px;
    margin-bottom: 2px;
  }

  .name-container {
    font-weight: 600;
    margin-bottom: 2px;
  }
`;

export default withTranslation()(observer(AirbnbHostInvitationPreview));
