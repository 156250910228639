import React from 'react';

const Edit = ({ styles, fill, opacity }) => (
  <svg
    style={styles}
    viewBox="0 0 20 20"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xlink="http://www.w3.org/1999/xlink"
  >
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" opacity={opacity}>
      <g id="05.11.01.D.Profile" transform="translate(-517.000000, -206.000000)">
        <g id="Main_card" transform="translate(300.000000, 84.000000)">
          <g id="Personal_data.Section" transform="translate(40.000000, 32.000000)">
            <g id="Icon" transform="translate(177.000000, 90.000000)">
              <g id="Edit.Icon">
                <rect id="Bounds" opacity="0.300000012" x="0" y="0" width="20" height="20" />
                <path
                  d="M3.20036965,14.2364916 L3.05651182,17.0175521 L5.8222408,16.8583627 L13.7845186,8.87577293 L11.1829594,6.27421371 L3.20036965,14.2364916 Z M15.1437864,3.38991919 C14.8391074,3.08524019 14.351621,3.08524019 14.046942,3.38991919 L12.6251067,4.81175452 L15.2469778,7.43362567 L16.6688132,6.01179034 C16.9734922,5.70711134 16.9734922,5.21962494 16.6688132,4.91494595 L15.1437864,3.38991919 Z"
                  id="Fill"
                  fill={fill}
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default Edit;
