import React from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { observer, inject } from 'mobx-react';
import theme from '../../../theme';

const routes = ['/traveler_app/trip/transfer'];

const routesRegx = new RegExp(routes.map((route) => `^${route}$`).join('|'));

const GreyFooter = ({
  children,
  location: { pathname },
  travelerAppStore: { loading },
  destinationsStore: { destinationsloading }
}) => {
  return (
    <StyledContainer
      isBottomNavbarHidden={routesRegx.test(pathname)}
      id="grey-footer"
      isLoading={loading || destinationsloading}
    >
      {children}
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  display: ${(props) => (props.isLoading ? 'none' : 'flex')};
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  background-color: #e9ebed;
  padding: 20px 27px;
  border-radius: 4px;
  width: 100%;

  a {
    border-bottom: 1px solid rgba(45, 59, 78, 0.08);
    width: 100%;

    :last-child {
      border-bottom: none;
    }
  }

  @media (max-width: ${theme.breakpoints[0]}px) {
    justify-content: center;
  }
`;

export default withRouter(inject('travelerAppStore', 'destinationsStore')(observer(GreyFooter)));
