import styled from 'styled-components';

export const ArchivedTitle = styled.div`
  display: none;
  height: 14px;
  width: 100%;
  margin-top: 32px;
  padding-left: 32px;
  margin-bottom: 4px;
  color: rgba(45, 59, 78, 0.5);
  font-size: 14px;

  &.with-channels {
    display: block;
  }
`;

export const ArchivedListEmptyStateIndicator = styled.div``;
