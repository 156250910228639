import React from 'react';
import PropTypes from 'prop-types';
import { IonRefresher, IonRefresherContent } from '@ionic/react';

const Refresher = (props) => {
  const { onRefresh } = props;

  return (
    <IonRefresher slot="fixed" onIonRefresh={onRefresh}>
      <IonRefresherContent pullingIcon="lines"></IonRefresherContent>
    </IonRefresher>
  );
};

Refresher.propTypes = {
  onRefresh: PropTypes.func.isRequired
};

export default Refresher;
