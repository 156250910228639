import React from 'react';
import styled from 'styled-components';
import theme from '../../../../theme';

const Products = ({ products }) =>
  products.map(({ photoUrl, title }) => (
    <StyledDiv key={title} className="ordered-product-wrapper">
      <img alt={title} src={photoUrl} className="ordered-product-pic" />
      <p>{title || ''}</p>
    </StyledDiv>
  ));

const StyledDiv = styled.div`
  display: flex;
  align-items: center;

  .ordered-product-pic {
    border-radius: 100%;
    width: 64px;
    height: 64px;
    background-color: #ebebeb;
    margin-right: 20px;
    margin-top: 32px;
  }

  p {
    color: ${theme.colors.base.graphite};
    margin-top: 32px;
  }
`;

export default Products;
