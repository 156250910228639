import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// Wraps the given elements in a Link component
const InternalLinkWrapper = (props) => {
  const { link, callback = () => {}, children } = props;

  return (
    <Link to={link} onClick={callback}>
      {children}
    </Link>
  );
};

InternalLinkWrapper.propTypes = {
  link: PropTypes.string.isRequired,
  callback: PropTypes.func
};

export default InternalLinkWrapper;
