import React from 'react';

const Luggage = ({ fill, styles }) => (
  <svg
    width="20px"
    height="20px"
    style={styles}
    viewBox="0 0 20 20"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xlink="http://www.w3.org/1999/xlink"
  >
    <title>Luggage</title>
    <desc>Created with sketchtool.</desc>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="01.12.09.D.Transfer.Scrolled" transform="translate(-510.000000, -10.000000)">
        <g id="Navigation.Panel.Desktop">
          <g id="Tab.Panel.2">
            <g transform="translate(480.000000, 0.000000)">
              <g id="Tab.01">
                <g id="Tab">
                  <g id="Icon" transform="translate(30.000000, 10.000000)">
                    <g id="Luggage.Icon">
                      <rect id="Bounds" x="0" y="0" width="20" height="20" />
                      <path
                        d="M13,7 L7,7 L7,16 L13,16 L13,7 Z M15,7 L15,16 L16,16 C16.5522847,16 17,15.5522847 17,15 L17,8 C17,7.44771525 16.5522847,7 16,7 L15,7 Z M5,7 L4,7 C3.44771525,7 3,7.44771525 3,8 L3,15 C3,15.5522847 3.44771525,16 4,16 L5,16 L5,7 Z M6,5 L6,4 C6,2.8954305 6.8954305,2 8,2 L12,2 C13.1045695,2 14,2.8954305 14,4 L14,5 L16,5 C17.6568542,5 19,6.34314575 19,8 L19,15 C19,16.6568542 17.6568542,18 16,18 L4,18 C2.34314575,18 1,16.6568542 1,15 L1,8 C1,6.34314575 2.34314575,5 4,5 L6,5 Z M8,5 L12,5 L12,4 L8,4 L8,5 Z"
                        id="Fill"
                        fill={fill}
                      />
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default Luggage;
