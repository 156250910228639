import React, { PureComponent } from 'react';
import { inject, observer } from 'mobx-react';
import SkeletonDetails from '../../../../components/common/ScarySkeletons/SkeletonDetails';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import LiveLocation from './LiveLocation';
import StaticDetails from './StaticDetails';
import mixpanel from '../../../../services/mixpanel';
import { isMobilePlatform, isTruthy } from '../../../../components/common/helpers';
import { MissingTripError } from '@/src/errors';

class TransferDetails extends PureComponent {
  // TODO: remove unused reference
  transferDetailsContainer = React.createRef();

  state = {
    driverInfoModalVisibility: false,
    meetingPointModalVisibility: false,
    showHeader: false
  };
  // TODO: add loading?

  componentDidMount() {
    const {
      travelerAppStore: { getTrips, chosenTransfer, setChosenTransfer },
      match: {
        params: { tripToken, transferToken }
      },
      location
    } = this.props;

    const { history } = this.props;
    const oldTripOrNoChosenTransfer = !chosenTransfer.id || !transferToken;
    // Add param to enforce fetching of trips/transfer
    const fetch = location?.state?.fetch;

    if (oldTripOrNoChosenTransfer || fetch) {
      getTrips(tripToken)
        .then((transfers) => {
          const transfer = transferToken
            ? transfers.find((chosenTransfer) => {
                return chosenTransfer.id === transferToken;
              })
            : transfers[0];
          if (!transfer) {
            history.push(`/trip/${tripToken}`);
          } else {
            setChosenTransfer(transfer);
          }
        })
        .then(this.trackPageView)
        .catch((error) => {
          if (error instanceof MissingTripError) {
            history.replace('/trips');
            return;
          }

          history.push(`/trip/${tripToken}`);
        });
    } else {
      this.trackPageView();
    }
  }

  componentWillUnmount() {
    const {
      travelerAppStore: { setChosenTransfer }
    } = this.props;
    // When user leaves the transfer details page, reset the selected transfer.
    setChosenTransfer({});
  }

  componentDidUpdate(prevProps) {
    const prevTransferToken = prevProps.match.params.transferToken;
    const transferToken = this.props.match.params.transferToken;

    // Track page view when user selects another transfer
    if (prevTransferToken !== transferToken) {
      this.trackPageView();
    }
  }

  trackPageView = () => {
    const {
      travelerAppStore: {
        currentTrip,
        chosenTransfer,
        chosenTransfer: { invitations = [], numberOfPassengers = 1, canInvitePassengers }
      }
    } = this.props;
    const driverData = !chosenTransfer.operatorConfirmedDriver
      ? chosenTransfer.confirmedDriver
      : chosenTransfer.operatorConfirmedDriver;

    mixpanel.trackTransferDetailsView({
      Has_Assigned_Driver: isTruthy(driverData),
      Available_Driver_Video: isTruthy(driverData?.introVideo),
      Type: this.showLiveView() ? 'live view' : 'static details',
      Invited_Passengers_Count: invitations.length,
      Potential_Invitations_Count: numberOfPassengers - 1,
      Invitation_Action_Visible: canInvitePassengers && invitations.length < numberOfPassengers - 1,
      City_Name: currentTrip?.city,
      isSride: !!chosenTransfer.tourLink
    });
  };

  showLiveView = () => {
    const {
      travelerAppStore: { chosenTransfer }
    } = this.props;
    return chosenTransfer.showLiveView && chosenTransfer.operatorConfirmedDriver;
  };

  render() {
    const {
      travelerAppStore: { loading }
    } = this.props;
    const DetailsPage = this.showLiveView() && isMobilePlatform() ? <LiveLocation /> : <StaticDetails />;

    return loading ? <SkeletonDetails /> : DetailsPage;
  }
}

export default withTranslation()(withRouter(inject('travelerAppStore')(observer(TransferDetails))));
