import React, { Component, Fragment } from 'react';
import { inject, observer } from 'mobx-react';
import styled from 'styled-components';
import { IonList } from '@ionic/react';
import { withRouter } from 'react-router-dom';
import SkeletonList from '../../../../components/common/ScarySkeletons/SkeletonList';

import AutoCheckErrors from '../../../../components/common/autoCheckErrors';
import TransferCard from './TransferCard';

class TransfersList extends Component {
  render() {
    const {
      isTransferDetailsPage = false,
      travelerAppStore: {
        currentTrip,
        travelerAutocheckErrors,
        transfers,
        chosenTransfer,
        setChosenTransfer,
        loading,
        removeTravelerError
      },
      alertsAreShownIn,
      showMarkerForSplitTr,
      showSplitSection,
      history,
      chosenMarker,
      errors
    } = this.props;

    return loading ? (
      <SkeletonList padding="40px 20px" />
    ) : (
      <Fragment>
        {!isTransferDetailsPage && (
          <AutoCheckErrors
            autocheckErrorList={travelerAutocheckErrors}
            alertsAreShownIn="Trip"
            removeError={removeTravelerError}
            errors
          />
        )}

        <StyledList>
          {transfers.map((transfer, i) => (
            <TransferCard
              isSideMenu={isTransferDetailsPage}
              showErrorsInDetails
              errors={errors}
              transferIndex={i}
              alertsAreShownIn={alertsAreShownIn}
              transfer={transfer}
              key={transfer.id}
              chosen={chosenMarker && !chosenTransfer.splittedTransferId && chosenTransfer.id === transfer.id}
              arrowVisible={!transfer.splittedTransferId}
              showSplitSection={showSplitSection}
              showMarkerForSplitTr={showMarkerForSplitTr}
              handleClick={() => {
                if (document.getElementById('right-container') && document.getElementById('right-container').scrollTo) {
                  document.getElementById('right-container').scrollTo(0, 0);
                }
                if (!transfer.splittedTransferId) {
                  setChosenTransfer(transfer);
                  history.push(`/trip/${currentTrip.id}/transfer/${transfer.id}`, { tripListItem: true });
                }
              }}
            />
          ))}
        </StyledList>
      </Fragment>
    );
  }
}
const StyledList = styled(IonList)`
  list-style: none;
  background-color: #fff;
  margin: 0;
  max-width: 600px;
  width: 100%;
`;

export default withRouter(inject('travelerAppStore')(observer(TransfersList)));
