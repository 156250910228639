import React from 'react';
import styled from 'styled-components';
import theme from '../../theme';

const Section = ({ children, title, subtitle }) => (
  <ExploreSectionBlock className="sectionWrapper">
    <h5 className="title">{title}</h5>
    <p className="subtitle">{subtitle}</p>
    {children}
  </ExploreSectionBlock>
);

const ExploreSectionBlock = styled.div`
  border-bottom: 1px solid rgba(45, 59, 78, 0.08);
  padding: 40px 0;
  :first-of-type {
    padding-top: 0;
    padding-bottom: 32px;
  }

  :last-child {
    padding-bottom: 0;
    border-bottom: none;
  }

  .title {
    margin: 0 0 10px 0;
    color: ${theme.colors.base.graphite};
    font-size: 20px;
    line-height: 26px;
    font-weight: 700;
  }

  .subtitle {
    color: ${theme.colors.greys.grey5};
    font-size: 14px;
    line-height: 21px;
    font-weight: 500;
    margin: 0;
  }
`;

export default Section;
