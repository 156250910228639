/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import theme from '../../theme';
import { trackEvent } from '../../tracking';
import { IonSkeletonText, IonToolbar } from '@ionic/react';
import { StyledCard, StyledContent, StyledPage, StyledModal } from '../../components/styled/IonElements';
import Section from './Section';
import ArrowDown from '../../components/svgs/ArrowDown';
import DestinationsList from './DestinationsList';
import { Heading2, Paragraph } from '../../components/styled/Typography';
import TourSRides from './TourSrides';
import nothingToDoIcon from '../../assets/icons/no_s_rides.svg';
import { TourServicesWhiteButton } from '../../components/styled/Buttons';
import Tickets from './Tickets';
import { withTranslation } from 'react-i18next';
import SkeletonGrid from '../../components/common/ScarySkeletons/SkeletonGrid';
import mixpanel from '../../services/mixpanel';
import { useStores } from '@/src/stores/useStores';
import Refresher from '@/src/components/common/Refresher';

const svgStyles = {
  display: 'inline-block',
  width: '20px',
  height: '20px'
};

const Explore = (props) => {
  const {
    t,
    match: {
      params: { destinationToken }
    }
  } = props;

  const [destinationModalVisibility, setDestinationModalVisibility] = useState(false);
  const [color, setColor] = useState(theme.colors.base.graphite);
  const [noSridesButtonClicked, setNoSridesButtonClicked] = useState(false);

  const {
    travelerAppStore: { currentCity, currentTrip, setCurrentCity },
    destinationsStore: {
      destinations,
      destinationsloading,
      sRidesLoading,
      sRides,
      widgets,
      fetchDestinations,
      getSRides
    }
  } = useStores();

  const onModalChangeVisibilty = () => {
    setDestinationModalVisibility(!destinationModalVisibility);
  };

  const trackPageView = () => {
    mixpanel.trackExploreTabView({
      Sightseeing_Rides_Count: sRides.length,
      City_Name: currentCity.name,
      Tiqets_Widget_Available: widgets.length > 0
    });
  };

  const getCurrentCityId = () => {
    if (!destinations.length) {
      return '';
    }

    const currentCity = destinations.find((destination) => {
      if (destinationToken) {
        return destinationToken === destination.id;
      }

      return destination.name === currentTrip.city;
    });

    setCurrentCity(currentCity || destinations[0]);

    return currentCity ? currentCity.id : destinations[0].id;
  };

  const onCityClick = ({ id, name }) => {
    onModalChangeVisibilty();
    trackEvent('traveler app', 'explore', `click city ${name}`);
    mixpanel.trackCitySelected({ City: name });

    setCurrentCity({
      id,
      name
    });
    getSRides(id)
      .then(trackPageView)
      .catch(() => {});

    setColor(theme.colors.base.graphite);
    setNoSridesButtonClicked(false);
  };

  const styledButtonAction = (e) => {
    trackEvent('traveler app', 'Unavailable Sride', currentCity.name);
    mixpanel.trackSendSRideRequestEmailClick({ City: currentCity.name });

    if (!noSridesButtonClicked) {
      window.location.href = 'mailto:localexpert@welcomepickups.com';
    }

    setColor(theme.colors.greys.grey3);
    setNoSridesButtonClicked(true);
  };

  const doRefresh = (event) => {
    if (destinations.length) {
      getSRides(getCurrentCityId())
        .then(trackPageView)
        .catch(() => {});
    } else if (!destinationsloading) {
      fetchDestinations().catch(() => {});
    }

    setTimeout(() => {
      event.detail.complete();
    }, 1000);
  };

  useEffect(() => {
    if (destinations.length) {
      getSRides(getCurrentCityId())
        .then(trackPageView)
        .catch(() => {});
    }
  }, [destinations.length]);

  useEffect(() => {
    if (!destinations.length && !destinationsloading) {
      fetchDestinations().catch(() => {});
    }
  }, []);

  return (
    <StyledPage>
      <StyledContent>
        <Refresher onRefresh={doRefresh} />

        <StyledModal
          isOpen={destinationModalVisibility}
          onClose={() => onModalChangeVisibilty()}
          ionBackButton={() => onModalChangeVisibilty()}
        >
          <DestinationsList
            fromExplorer
            destinations={destinations}
            onClose={() => onModalChangeVisibilty()}
            onChooseCity={onCityClick}
          />
        </StyledModal>

        <StyledCard padding="15px 20px" margin="20px">
          <StyledExplorePage>
            <Section>
              <div
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  trackEvent('traveler app', 'explore', 'open dropdown');
                  mixpanel.trackSelectACityClicked();
                  onModalChangeVisibilty();
                }}
              >
                <Heading2>{t('explore.explore_things')}</Heading2>
                {destinationsloading || !currentCity?.name ? (
                  <IonSkeletonText animated style={{ width: '70px' }} />
                ) : (
                  <CitiesListText>
                    {currentCity.name}
                    <ArrowDown styles={svgStyles} opacity="0.5" fill={theme.colors.base.graphite} />
                  </CitiesListText>
                )}
              </div>
            </Section>
            {sRidesLoading || !currentCity?.name ? (
              <SkeletonGrid />
            ) : (
              <Fragment>
                {sRides && sRides.length > 0 && (
                  <Section title={t('explore.sightseeing_rides')} subtitle={t('explore.sightseeing_subtitle')}>
                    <TourSRides />
                  </Section>
                )}
                {widgets.length > 0 && (
                  <Section title={t('explore.attraction_ticket')}>
                    <Tickets />
                  </Section>
                )}
                {(sRides === null || sRides.length <= 0) && (
                  <NoRidesMessageComponent>
                    <img alt="No Explore options" src={nothingToDoIcon} />
                    <NothingToDoMessage>
                      {t('explore.no_srides_header', { city_name: currentCity.name })}
                    </NothingToDoMessage>
                    <QuestionArea>{t('explore.no_srides_text_2')}</QuestionArea>
                    <SoundsInterestedStyledButton onClick={styledButtonAction}>
                      <StyledText color={color}>{t('explore.no_srides_button_2')}</StyledText>
                    </SoundsInterestedStyledButton>
                  </NoRidesMessageComponent>
                )}
              </Fragment>
            )}
          </StyledExplorePage>
        </StyledCard>
      </StyledContent>
    </StyledPage>
  );
};

const CitiesListText = styled(Paragraph)`
  text-align: left;
  display: flex;
`;

const StyledText = styled.span`
  font-weight: 600;
  font-size: 14px;
  color: ${(props) => props.color};
`;

const SoundsInterestedStyledButton = styled(TourServicesWhiteButton)`
  margin-top: 25px;
  max-width: 240px;
  min-height: 48px;
  display: block;
`;

const NoRidesMessageComponent = styled.div`
  color: ${theme.colors.greys.grey5};
  font-size: 24px;
  line-height: 31px;
  text-align: center;
  font-weight: 700;
  margin-top: 64px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 34px;
  @media (max-width: ${theme.breakpoints[0]}px) {
    margin-top: 10px;
  }
`;

const NothingToDoMessage = styled.div`
  min-height: 62px;
  width: 240px;
  color: ${theme.colors.greys.grey5};
  font-size: 24px;
  line-height: 31px;
  text-align: center;
  word-break: break-word;
  margin-top: 40px;

  @media (max-width: ${theme.breakpoints[0]}px) {
    width: 100%;
  }
`;

const QuestionArea = styled.div`
  margin-top: 25px;
  min-height: 63px;
  width: 240px;
  color: ${theme.colors.greys.grey5};
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  word-break: break-word;

  @media (max-width: ${theme.breakpoints[0]}px) {
    width: 100%;
  }
`;

const StyledExplorePage = styled(IonToolbar)``;

export default withTranslation()(observer(Explore));
