import { IonModal } from '@ionic/react';
import React from 'react';
import styled from 'styled-components';
import { TourServicesWhiteButton } from '../../../components/styled/Buttons';
import { Text2 } from '../../../components/styled/Typography';
import { FlexContainer } from '../../../components/styled/Wrappers';
import { StyledModal } from '@/src/components/styled/IonElements';

const SuccessDownloadModal = ({ accountDownloadDataResponse, onClose }) => {
  return (
    <CustomModal isOpen={accountDownloadDataResponse}>
      <div style={{ padding: '25px' }}>
        <Text2 weight="500">You will get an email with instructions on how to download your data</Text2>
        <FlexContainer align="flex-end" justify="flex-end">
          <CancelButton onClick={onClose}>
            <span>Cancel</span>
          </CancelButton>
        </FlexContainer>
      </div>
    </CustomModal>
  );
};

const CustomModal = styled(StyledModal)`
  --height: 170px;
  --width: 300px;
  --border-radius: 4px;
`;

const CancelButton = styled(TourServicesWhiteButton)`
  width: 96px;
  height: 48px;
  margin-top: 32px;
`;

export default SuccessDownloadModal;
