import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { AccordionContent, AccordionHeader, AccordionIcon, AccordionItem, AccordionWrapper } from './accordion.styled';
import { isTruthy } from '../helpers';

const Accordion = (props) => {
  const { data = [], style = {}, className = '' } = props;

  const [selectedItem, setSelectedItem] = useState(null);

  const contentRefs = useRef({}); // Use an object for refs to access by item id

  const toggleAccordion = (clickedItem) => {
    if (isTruthy(selectedItem) && selectedItem?.title === clickedItem?.title) {
      setSelectedItem(null);
      return;
    }

    setSelectedItem(clickedItem);

    // Scroll to the clicked item
    setTimeout(() => {
      contentRefs.current[clickedItem.title]?.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      });
    }, 100);
  };

  return (
    <AccordionWrapper style={style} className={className}>
      {data.map((item, index) => (
        <AccordionItem key={index}>
          <AccordionHeader onClick={() => toggleAccordion(item)}>
            <AccordionIcon fill="#00B579" open={item.title === selectedItem?.title} />
            {item.title}
          </AccordionHeader>
          <AccordionContent
            isOpen={item.title === selectedItem?.title}
            ref={(el) => (contentRefs.current[item.title] = el)} // Attach ref dynamically
          >
            <div className="inner-content-container">{item.content}</div>
          </AccordionContent>
        </AccordionItem>
      ))}
    </AccordionWrapper>
  );
};

Accordion.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      content: PropTypes.string.isRequired
    }).isRequired
  ),
  style: PropTypes.object,
  className: PropTypes.string
};

export default Accordion;
