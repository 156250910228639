import ModalWithIcon from '@/src/components/modals/ModalWithIcon';
import theme from '@/src/theme';
import styled from 'styled-components';

export const ModalWithIconStyled = styled(ModalWithIcon)`
  --height: auto;
  --max-width: calc(100% - 40px);
  --backdrop-opacity: 0.64;

  @media (min-width: ${theme.breakpoints[0]}px) {
    --width: 375px;
  }
`;

export const ModalTextContainer = styled.div`
  margin: 0 auto;
  max-width: 350px;

  @media (min-width: ${theme.breakpoints[0]}px) {
    max-width: 100%;
  }
`;

export const ModalText = styled.p`
  text-align: center;
  font-size: 14px;
  line-height: 18px;
  font-style: normal;
  font-weight: 400;
  margin: ${({ margin }) => margin || '0'};

  @media (min-width: ${theme.breakpoints[0]}px) {
    font-size: 16px;
    line-height: 20px;
  }

  ${({ bold }) => bold && 'font-weight: 700;'}
`;
