import React from 'react';
import LoaderSpinner from '../../svgs/LoaderSpinner';
import styled from 'styled-components';
import './spinner.css';
import { observer } from 'mobx-react';
import { useStores } from '../../../stores/useStores';

const Spinner = ({
  spinnerColor = null,
  size = '54px',
  color,
  position,
  containerStyles = {},
  spinnerStyles = {},
  text = false,
  margin = ''
}) => {
  const {
    brandedSettingsStore: {
      brandedSettings: {
        coBrandingThemeProperties: { mainColor }
      }
    }
  } = useStores();

  const sizeFormatted = spinnerStyles.size === 'sm' ? '20px' : spinnerStyles.size;

  const StyledSpinner = styled.div({
    display: 'inline-block',
    fontSize: `${size === 'sm' ? '20px' : size}`,
    lineHeight: 1,
    width: `${sizeFormatted}`,
    height: `${sizeFormatted}`
  });

  const spinnerStyle = {
    display: 'inline-block',
    fontSize: `${size === 'sm' ? '20px' : size}`,
    lineHeight: 1,
    width: `${sizeFormatted}`,
    height: `${sizeFormatted}`,
    ...spinnerStyles
  };

  const svgSpinnerStyles = {
    position: 'absolute',
    top: '57%',
    left: '57%',
    transform: 'translate(-57%, -57%)',
    width: spinnerStyle.width,
    height: spinnerStyle.height
  };

  return (
    <StyledSpinner className="fadeIn" data-test-id="spinner">
      <LoaderSpinner styles={svgSpinnerStyles} fill={spinnerColor || mainColor} />
    </StyledSpinner>
  );
};

export default observer(Spinner);
