import React from 'react';

const GiftSVG = ({ size = 24, fill = '#00905B', onClick, className, style }) => (
  <svg
    width={size}
    height={size}
    viewBox={`0 0 ${size} ${size}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    onClick={onClick}
    className={className}
    style={style}
  >
    <g clipPath="url(#clip0_685_29602)">
      <path
        d="M12.9642 12.9171C12.9642 13.1833 13.18 13.3992 13.4463 13.3992H21.642C22.5717 13.3939 23.3241 12.6415 23.3294 11.7118V9.78344C23.3294 8.85154 22.5739 8.09604 21.642 8.09604H13.4463C13.18 8.09604 12.9642 8.31194 12.9642 8.57814V12.9171Z"
        fill={fill}
      />
      <path
        d="M4.0453 13.3992H11.0358C11.302 13.3992 11.5179 13.1833 11.5179 12.9171V8.57814C11.5179 8.31194 11.302 8.09614 11.0358 8.09604H2.358C1.4261 8.09604 0.670601 8.85154 0.670601 9.78344V11.7118C0.675901 12.6415 1.4283 13.3939 2.358 13.3992H4.0453Z"
        fill={fill}
      />
      <path
        d="M11.5179 15.3277C11.5179 15.0614 11.3021 14.8456 11.0358 14.8456H3.0811C2.8149 14.8456 2.599 15.0614 2.599 15.3277V22.8001C2.6043 23.7299 3.3567 24.4823 4.2864 24.4876H11.0358C11.3021 24.4876 11.5179 24.2718 11.5179 24.0055V15.3277Z"
        fill={fill}
      />
      <path
        d="M13.4463 14.8456C13.18 14.8456 12.9642 15.0614 12.9642 15.3277V24.0055C12.9642 24.2718 13.18 24.4876 13.4463 24.4876H19.7136C20.6433 24.4823 21.3957 23.7298 21.401 22.8001V15.3277C21.401 15.0614 21.1851 14.8456 20.9189 14.8456H13.4463Z"
        fill={fill}
      />
      <path
        d="M12.9642 7.81644C13.2079 8.08284 13.58 8.19074 13.9284 8.09604C15.0662 7.81644 17.7852 7.13184 18.6434 6.24484C20.0011 4.47984 18.939 1.90704 16.7317 1.61374C15.9759 1.51324 15.211 1.71534 14.6033 2.17584C13.8802 2.90864 13.1763 5.14564 12.7521 6.90044C12.659 7.22194 12.7393 7.56864 12.9642 7.81644ZM15.934 3.54504C16.2786 2.88764 17.2056 2.84994 17.6026 3.47704C17.904 3.95314 17.7346 4.58504 17.2356 4.84674C16.5485 5.24724 15.8085 5.54914 15.0372 5.74344C15.2436 4.97014 15.5585 4.23004 15.9725 3.54504H15.934Z"
        fill={fill}
      />
      <path
        d="M11.73 6.90044C11.3058 5.14564 10.6019 2.90864 9.8788 2.17584C8.5821 0.365643 5.812 0.637843 4.8926 2.66594C4.3216 3.92554 4.7221 5.41324 5.8484 6.21584C6.6776 7.05474 9.4256 7.78754 10.5633 8.06714C10.9064 8.16894 11.2776 8.07244 11.5275 7.81644C11.749 7.56674 11.8256 7.22024 11.73 6.90044ZM7.179 4.84674C6.8166 4.19894 7.2914 3.40184 8.0335 3.41184C8.2011 3.41414 8.3652 3.46004 8.5096 3.54504C8.9102 4.23214 9.212 4.97214 9.4063 5.74344C8.6251 5.55174 7.8751 5.24984 7.179 4.84674Z"
        fill={fill}
      />
    </g>
    <defs>
      <clipPath id="clip0_685_29602">
        <rect width={size} height={size} fill="white" transform="translate(0 0.727539)" />
      </clipPath>
    </defs>
  </svg>
);

export default GiftSVG;
