import React, { useEffect, useState } from 'react';
// import { withTranslation } from "react-i18next";
import { StyledCard } from '@/src/components/styled/IonElements';
import ImageDesctiptionCard from '@/src/components/common/Cards/ImageDescriptionCard/ImageDescriptionCard';
import { FlexContainer } from '@/src/components/styled/Wrappers';
import { Heading4 } from '@/src/components/styled/Typography';
import CloseIcon from '@/src/assets/icons/close-delete-grey-icon.svg';
import PropTypes from 'prop-types';
import { InternalLinkButtonOutlined } from '@/src/components/styled/ButtonsV2';
import ExploreIcoBlack from '@/src/assets/menu-tabs/explore_icon_black.svg';
import { CloseIconImg, ContentText, Price, Text } from './banner.styled';
import useWindowSize from '@/src/hooks/useWindowSize';
import theme from '@/src/theme';
import { addParamsToUrl, isTruthy } from '@/src/components/common/helpers';
import { withTranslation } from 'react-i18next';
import mixpanel from '@/src/services/mixpanel';

const desktopSridesLimit = 3;
const mobileSridesLimit = 2;

const Banner = (props) => {
  const { destination, handleClose, sRides, t } = props;

  const [showMore, setShowMore] = useState(false);
  const [sRidesLimit, setSRidesLimit] = useState(mobileSridesLimit);

  const windowSize = useWindowSize();

  const limitSRides = () => {
    return sRides?.slice(0, sRidesLimit) || [];
  };

  const renderSubtitle = (currencySymbol, price) => {
    return (
      <ContentText as="span">
        <Text as="span">{'from'.toUpperCase()}</Text>
        <Price>
          {currencySymbol}
          {price}
        </Price>
      </ContentText>
    );
  };

  useEffect(() => {
    if (windowSize.width < theme.breakpoints[0] && sRidesLimit !== mobileSridesLimit) {
      setSRidesLimit(mobileSridesLimit);
    } else if (windowSize.width >= theme.breakpoints[0] && sRidesLimit !== desktopSridesLimit) {
      setSRidesLimit(desktopSridesLimit);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [windowSize.width]);

  useEffect(() => {
    if (sRides?.length > sRidesLimit) {
      setShowMore(true);
    } else {
      setShowMore(false);
    }
  }, [sRides, sRidesLimit]);

  return (
    <StyledCard margin="20px">
      <FlexContainer align="center" justify="space-between" margin="0 0 20px">
        <Heading4 margin="0">{t('hva_srides.want_to_explore', { cityName: destination.name })}</Heading4>
        {handleClose && <CloseIconImg src={CloseIcon} alt="close_icon" onClick={handleClose} />}
      </FlexContainer>

      <FlexContainer gap="16px">
        {limitSRides().map((sRide) => (
          <ImageDesctiptionCard
            key={sRide.id}
            title={sRide.title}
            subtitle={renderSubtitle(sRide.currency.symbol, sRide.price)}
            image={sRide?.photos[0]?.medium}
            desktopWidth="33.33%"
            link={addParamsToUrl(sRide.tourLink, { utm_medium: 'srides_hva', utm_source: 'traveler_app' })}
            isExternalLink
            onClick={mixpanel.trackHvaTripSridesClicked}
          />
        ))}
      </FlexContainer>

      {isTruthy(destination) && showMore && (
        <InternalLinkButtonOutlined
          to={`/explore/${destination.id}`}
          block="true"
          margin="20px 0 0"
          onClick={mixpanel.trackHvaTripSridesClicked}
        >
          <img src={ExploreIcoBlack} alt="compass" />
          {t('hva_srides.explore_more')}
        </InternalLinkButtonOutlined>
      )}
    </StyledCard>
  );
};

Banner.propTypes = {
  destination: PropTypes.instanceOf(Object).isRequired,
  handleClose: PropTypes.func,
  sRides: PropTypes.array.isRequired,
  t: PropTypes.func.isRequired
};

export default withTranslation()(Banner);
