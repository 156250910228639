import theme from '../theme';

/**
 * Accept an object of styles and apply media queries
 * @param {object} styles
 * @returns {object} The final styles object with media queries applied
 * @example
 * const styles = {
 *  margin: '20px',
 *  md: { margin: '30px' },
 *  lg: { margin: '40px' }
 * };
 */
export const applyStyles = (styles = {}) => {
  const finalStyles = {};

  // Loop through all styles and separate base and responsive
  Object.entries(styles).forEach(([key, value]) => {
    if (theme.screenSizes[key]) {
      // If the key is a breakpoint, create a media query
      const screenWidth = theme.screenSizes[key];
      const mediaQuery = `@media (min-width: ${screenWidth}px)`;

      finalStyles[mediaQuery] = {
        ...finalStyles[mediaQuery],
        ...value
      };
    } else {
      // Otherwise, directly assign the value to the key
      finalStyles[key] = value;
    }
  });

  /* Example output object:
    {
      margin: '20px',
      '@media (min-width: 768px)': { margin: '30px' },
      '@media (min-width: 1200px)': { margin: '40px' },
    }
  */
  return finalStyles;
};

/**
 * A utility function to extract media queries from a styles object.
 * @param {object} styles - The styles object
 * @returns {object} - Extracted media queries
 */
const extractMediaQueries = (styles) => {
  const mediaQueries = {};
  for (const key in styles) {
    if (key.startsWith('@media')) {
      mediaQueries[key] = styles[key];
    }
  }
  return mediaQueries;
};

/**
 * Merges media queries from multiple style objects.
 *
 * This function accepts any number of style objects and combines their media queries,
 * ensuring that the styles from later objects are merged with the styles from earlier ones.
 *
 * @param {...Object} styles - Any number of style objects that contain media queries.
 * @returns {Object} - A new object containing the merged media queries.
 */
export const mergeMediaQueries = (...styles) => {
  // Initialize the mergedMedia object
  const mergedMedia = {};

  // Loop through all the styles and merge their media queries
  styles.forEach((style) => {
    const styleMedia = extractMediaQueries(style);

    // Loop through each media query and merge
    for (const query in styleMedia) {
      mergedMedia[query] = {
        ...mergedMedia[query], // Existing media query styles
        ...styleMedia[query] // New styles from the current style object
      };
    }
  });

  return mergedMedia;
};
