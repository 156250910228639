import { debugConsoleError } from '../components/common/helpers';
import stores from '../stores';
// eslint-disable-next-line no-unused-vars
import axios, { AxiosError } from 'axios';
import { getAxiosErrorInfo } from './extractInfo';

/**
 * Returns the given extras object with additional info
 * @param {Error} error
 * @param {object} extras
 * @returns {object} The extras that was initially passed with extra network and request info (if available)
 */
const getEnhancedExtras = (error, extras = {}) => {
  let allExtras = { ...extras };
  const isAxiosError = axios.isAxiosError(error);

  // Add axios error info
  if (isAxiosError) {
    allExtras = { ...allExtras, ...getAxiosErrorInfo(error) };
  }

  return allExtras;
};

/**
 * Shows an error in the console and sends it to Rollbar
 * @param {string} errorMessage The description of the error
 * @param {Error} error The actual error that occurred
 * @param {object?} extras Any extra info we want to send to Rollbar
 */
export const logError = (errorMessage, error, extras = {}) => {
  const { rollbar } = stores.travelerAppStore;
  const isAxiosError = axios.isAxiosError(error);
  const enhancedExtras = getEnhancedExtras(error, extras);
  const message = `[TrApp] ${errorMessage}`;

  debugConsoleError(message, error, enhancedExtras);

  if (!isAxiosError || error?.response?.status < 500) {
    rollbar?.error(message, error, enhancedExtras);
  }
};
