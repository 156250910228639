import React from 'react';
import DrawerModal from '../../components/modals/DrawerModal';
import styled from 'styled-components';
import { Text1 } from '../../components/styled/Typography';
import theme from '../../theme';
import BookExtraTransferIcon from '../../assets/icons/trip-action-icons/book-extra-transfer-icon.svg';
import EditTransferIcon from '../../assets/icons/trip-action-icons/edit-transfer-icon.svg';
import DownloadReceiptIcon from '../../assets/icons/trip-action-icons/download-receipt-icon.svg';
import CancelTransferIcon from '../../assets/icons/trip-action-icons/cancel-transfer-icon.svg';
import { onExternalLinkClick } from '../../components/common/ExternalLink/ExternalLink';
import { t } from 'i18next';
import mixpanel from '../../services/mixpanel';
import { useHistory } from 'react-router-dom';

const TripPreviewActionsModal = (props) => {
  const { isOpen, onClose, tripPreview } = props;
  const { tripActions } = tripPreview;

  const handleClose = (e, internalLink) => {
    e.stopPropagation();
    onClose(e, internalLink);
  };

  const history = useHistory();

  const handleBookAgainClick = (e) => {
    e.stopPropagation();
    mixpanel.trackTripActionClick({ Action: 'Book again' });
    handleClose(e);
    const internalLink = `/book?city_id=${tripActions.bookAgain.cityId}&from_location_id=${tripActions.bookAgain.fromLocationId}&to_location_id=${tripActions.bookAgain.toLocationId}`;
    setTimeout(() => {
      history.replace(internalLink);
    }, 500);
  };

  const handleExternalLinkClick = (e, url, actionName) => {
    e.stopPropagation();
    mixpanel.trackTripActionClick({ Action: actionName });
    handleClose(e);
    onExternalLinkClick(e, url);
  };

  const handleDownloadReceiptClick = (e) => {
    e.stopPropagation();
    mixpanel.trackTripActionClick('Download receipt');
    handleClose(e);
  };

  return (
    <DrawerModal title={t('trips.trip_actions')} isOpen={isOpen} onClose={handleClose}>
      <ActionsList>
        {tripActions.receiptUrl && (
          <Action>
            <a href={tripActions.receiptUrl} target="_blank" rel="noreferrer" onClick={handleDownloadReceiptClick}>
              <img src={DownloadReceiptIcon} alt="download receipt" />
              <Text1 color={theme.colors.base.graphiteLighter}>{t('trips.download_receipt')}</Text1>
            </a>
          </Action>
        )}

        {tripActions.bookExtraTransferUrl && (
          <Action>
            <a
              href={tripActions.bookExtraTransferUrl}
              onClick={(e) => handleExternalLinkClick(e, tripActions.bookExtraTransferUrl, 'Book extra transfer')}
            >
              <img src={BookExtraTransferIcon} alt="book extra transfer" />
              <Text1 color={theme.colors.base.graphiteLighter}>{t('trips.book_extra_transfer')}</Text1>
            </a>
          </Action>
        )}

        {tripActions.editTransferUrl && (
          <Action>
            <a
              href={tripActions.editTransferUrl}
              onClick={(e) => handleExternalLinkClick(e, tripActions.editTransferUrl, 'Edit transfer')}
            >
              <img src={EditTransferIcon} alt="edit transfer" />
              <Text1 color={theme.colors.base.graphiteLighter}>{t('trips.edit_transfer')}</Text1>
            </a>
          </Action>
        )}

        {tripActions.bookAgain && (
          // We need to pass the link to the parent component so it can first close the modal and then redirect'
          <Action onClick={handleBookAgainClick}>
            <div className="internal-link">
              <img src={BookExtraTransferIcon} alt="book again" />
              <Text1 color={theme.colors.base.graphiteLighter}>{t('trips.book_again')}</Text1>
            </div>
          </Action>
        )}

        {tripActions.cancelTransferUrl && (
          <Action>
            <a
              href={tripActions.cancelTransferUrl}
              onClick={(e) => handleExternalLinkClick(e, tripActions.cancelTransferUrl, 'Cancel transfer')}
            >
              <img src={CancelTransferIcon} alt="cancel transfer" />
              <Text1 color={theme.colors.accent.red}>{t('trips.cancel_transfer')}</Text1>
            </a>
          </Action>
        )}
      </ActionsList>
    </DrawerModal>
  );
};

const ActionsList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;

  li:last-child {
    border: none !important;
  }
`;

const Action = styled.li`
  border-bottom: 1px solid ${theme.colors.greys.grey1};

  a,
  div.internal-link {
    text-decoration: none;
    padding: 18px 20px;
    cursor: pointer;

    display: flex;
    gap: 20px;
    align-items: center;
  }
`;

export default TripPreviewActionsModal;
