import React from 'react';

const Success = ({ fill = '#48D9A4', width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 112 112"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xlink="http://www.w3.org/1999/xlink"
  >
    <g id="Page-1" stroke="none" strokeidth="1" fill="none" fillRule="evenodd">
      <g id="05.14.42.M.Account_and_data.Feedback_sent" transform="translate(-124.000000, -176.000000)">
        <g id="Sad.Icon" transform="translate(124.000000, 176.000000)">
          <g id="Success.Icon">
            <circle id="Oval" fill={fill} cx="56" cy="56" r="56"></circle>
            <path
              d="M79.563481,43.51922 L58.6165804,64.3495218 C58.5850655,64.3885243 58.5970712,64.4440278 58.5625549,64.4815301 C58.262413,64.7800488 57.8692272,64.9105569 57.4745406,64.9000563 C57.0753519,64.9150572 56.6761632,64.7890493 56.3715193,64.4845303 C56.3279987,64.4440278 56.3430058,64.3765235 56.3069888,64.3285205 L47.4453003,55.5169698 C46.8645258,54.9409338 46.8645258,54.0048753 47.4453003,53.4258391 C48.0275755,52.848303 48.9685202,52.848303 49.547794,53.4258391 L57.4640356,61.2998312 L77.4534837,41.4220889 C78.0372596,40.8415526 78.9812058,40.8415526 79.5619803,41.4220889 C80.1457562,41.999625 80.1457562,42.9401838 79.563481,43.51922 L79.563481,43.51922 Z M55.9933405,34.9986874 C44.3943582,34.9986874 34.9924144,44.4027752 34.9924144,56 C34.9924144,67.5987249 44.3958589,76.9998125 55.9933405,76.9998125 C67.5908221,76.9998125 76.9942667,67.5987249 76.9942667,56 C76.9942667,55.5454716 76.889217,55.1224452 76.8607035,54.6769173 L79.6265108,51.9887493 C79.8456143,53.2968311 79.9881817,54.6304144 79.9881817,56 C79.9881817,69.2563285 69.2446038,80 55.9933405,80 C42.7420773,80 32,69.2563285 32,56 C32,42.7436715 42.7420773,32 55.9933405,32 C62.0757154,32 67.6103313,34.2801425 71.8408309,38.0108757 L69.6347882,40.1545097 C65.9580504,36.9833115 61.230816,34.9986874 55.9933405,34.9986874 L55.9933405,34.9986874 Z"
              id="Shape"
              fill="#FFFFFF"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default Success;
