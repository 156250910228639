import { Player } from 'bitmovin-player';
import { UIFactory } from 'bitmovin-player-ui';
import { Fragment, useEffect } from 'react';
import mixpanel from '../../../../../../services/mixpanel';
import 'bitmovin-player-ui/dist/css/bitmovinplayer-ui.css';
import './style.scss';
import { withTranslation } from 'react-i18next';
import { useStores } from '../../../../../../stores/useStores';
import PlayButtonIcon from '../../../../../../assets/icons/play-button.svg';
import styled from 'styled-components';
import { Text1 } from '../../../../../../components/styled/Typography';

const playerContainerId = 'driver-video-wrapper';
const videoThumbnailId = 'video-thumbnail';
let videoPlayTimes = 0; // How many times the video has been played
let player;

const DriverIntroVideo = (props) => {
  const { driverData, cityName, t } = props;
  const { introVideo } = driverData;

  const {
    travelerAppStore: { rollbar }
  } = useStores();

  const setupPlayer = () => {
    if (!introVideo) {
      return;
    }

    const playerConfig = {
      key: process.env.REACT_APP_BITMOVIN_PLAYER_KEY,
      analytics: {
        key: process.env.REACT_APP_BITMOVIN_ANALYTICS_LICENSE_KEY,
        videoId: `${driverData.fullName} - Driver intro master video`,
        title: `${driverData.fullName} - Driver intro master video`
      },
      playback: {
        muted: false,
        autoplay: true
      },
      ui: false
    };

    const playerSource = {
      dash: introVideo.dash,
      hls: introVideo.hls,
      title: t('trip_details.driver_video_title'),
      progressive: introVideo.progressive
      // poster: video.thumbnailUrl
    };

    const container = document.getElementById(playerContainerId);
    player = new Player(container, playerConfig);

    UIFactory.buildDefaultUI(player);

    player.load(playerSource).then(
      () => {},
      (error) => {
        if (rollbar) {
          rollbar.error('Error loading driver video', error);
        }
      }
    );

    player.on('play', () => {
      videoPlayTimes++;

      if (videoPlayTimes === 1) {
        mixpanel.trackDriverVideoView({
          City_Name: cityName
        });
      }

      player.setViewMode('fullscreen');
    });

    player.on('viewmodechanged', (event) => {
      if (event.to !== 'fullscreen') {
        player.pause();
        player.seek(0); // Reset the video to the beginning
      }
    });
  };

  useEffect(() => {
    const thumbnailContainer = document.getElementById(videoThumbnailId);
    thumbnailContainer.addEventListener('click', () => {
      if (!player) {
        setupPlayer();
      } else {
        player.play();
      }
    });

    return () => {
      if (player) {
        player = null;
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return introVideo ? (
    <Fragment>
      {/* Thumbnail image that opens the video in full screen*/}
      <VideoThumbnailWrapper id={videoThumbnailId} image={introVideo.thumbnailUrl} alt="Driver video thumbnail">
        <img src={PlayButtonIcon} id="play-button-icon" alt="play button icon" />
        <VideoThumbnailBluredBg image={introVideo.thumbnailUrl} />
        <VideoThumbnailImg src={introVideo.thumbnailUrl} alt="Driver video thumbnail" />
        <VideoTitle>{t('trip_details.driver_video_title')}</VideoTitle>
      </VideoThumbnailWrapper>

      {/* The video container hidden since it will only be visible in fullscreen */}
      <div id={playerContainerId} className="driver-video" style={{ width: 0, height: 0, minWidth: 0, minHeight: 0 }} />
    </Fragment>
  ) : null;
};

const VideoThumbnailWrapper = styled.div`
  width: 100%;
  overflow: hidden;
  margin-top: 16px;
  cursor: pointer;
  position: relative;
  border-radius: 4px;

  &:active {
    #play-button-icon {
      transform: scale(0.9) translate(-55%, -55%);
    }
  }

  #play-button-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 40px;
    height: 40px;
    z-index: 10;

    &:hover {
      transform: scale(1.2) translate(-40%, -40%);
    }
  }
`;

const VideoThumbnailBluredBg = styled.div`
  background-image: url(${(props) => props.image});
  background-size: cover;
  background-position: center;
  aspect-ratio: 16/9;
  background-repeat: no-repeat;
  filter: blur(10px) brightness(0.8);
  transform: scale(1.1);
`;

const VideoThumbnailImg = styled.img`
  position: absolute;
  left: 50%;
  top: 0;
  height: 100%;
  transform: translateX(-50%);
`;

const VideoTitle = styled(Text1)`
  position: absolute;
  left: 16px;
  bottom: 16px;
  font-weight: 600;
  color: white;
`;

export default withTranslation()(DriverIntroVideo);
