import { observer } from 'mobx-react';
import { useStores } from '../../../stores/useStores';
import { CheckboxStyled } from './index.styled';
import PropTypes from 'prop-types';

const Checkbox = (props) => {
  const {
    brandedSettingsStore: {
      brandedSettings: {
        coBrandingThemeProperties: { mainColor }
      }
    }
  } = useStores();

  const { color = mainColor, labelPlacement = 'end', children, wrapText = true, ...rest } = props;

  return (
    <CheckboxStyled color={color} labelPlacement={labelPlacement} {...rest} wrapText={wrapText}>
      <div className="content">{children}</div>
    </CheckboxStyled>
  );
};

Checkbox.propTypes = {
  color: PropTypes.string,
  labelPlacement: PropTypes.string,
  wrapText: PropTypes.bool
};

export default observer(Checkbox);
