import React from 'react';
import styled from 'styled-components';
import { Text3 } from '../../styled/Typography';
import theme from '../../../theme';
import { observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import SkeletonListItem from '../ScarySkeletons/SkeletonListItem';
import Invitation from './Invitation';
import { useStores } from '../../../stores/useStores';
import { t } from 'i18next';

const Invitations = (props) => {
  const { noTitle, loading, limit } = props;
  const {
    talonOneStore: { invitations }
  } = useStores();
  const invitationsList = limit ? invitations.data?.slice(0, limit) || [] : invitations.data || [];

  return invitationsList.length ? (
    <TransactionsContainer>
      {!noTitle && <Text3 className="invitation-history-title">{t('profile.invitation_history')}</Text3>}

      {invitationsList.map((invitation) =>
        loading ? (
          <SkeletonListItem key={invitation.name} />
        ) : (
          <Invitation invitation={invitation} key={invitation.name} />
        )
      )}
    </TransactionsContainer>
  ) : null;
};

const TransactionsContainer = styled.div`
  .invitation-history-title {
    color: ${theme.colors.base.graphiteLighter};
    text-transform: uppercase;
    margin-bottom: 20px;
  }
`;

export default withRouter(observer(Invitations));
