import { IonImg } from '@ionic/react';
import styled from 'styled-components';
import theme from '../../theme';

const HeaderLogo = styled(IonImg)`
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 10;
  display: none;
  max-width: ${(props) => props.maxWidth};

  @media (max-width: ${theme.breakpoints[0]}px) {
    display: block;
  }
`;

const PoweredByWhiteImg = styled(IonImg)`
  width: 150px;
`;

const MenuLogo = styled.img`
  width: auto !important;
  max-height: 100%;
  width: 100%;
  height: auto;
`;

export { HeaderLogo, PoweredByWhiteImg, MenuLogo };
