import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { Heading4 } from '../../../../../components/styled/Typography';
import styled from 'styled-components';
import { FlexContainer } from '../../../../../components/styled/Wrappers';
import { IonText } from '@ionic/react';
import theme from '../../../../../theme';
import DefaultAvatar from '../../../../../assets/icons/avatar.svg';
import { useStores } from '../../../../../stores/useStores';
import { withTranslation } from 'react-i18next';
import InvitePassengersModal from '../../../../../components/common/InvitePassengers/InvitePassengersModal';
import { ButtonOutlined } from '../../../../../components/styled/Buttons';
import PlusIconGraphite from '../../../../../assets/icons/plus_icon_graphite.svg';
import Divider from '../../../../../components/styled/Divider';

function Passengers(props) {
  const [invitationModalOpen, setInvitationModalOpen] = useState(false);
  const { t } = props;
  const {
    travelerAppStore: {
      chosenTransfer: { invitations = [], numberOfPassengers = 1, passengers = [], canInvitePassengers },
      travelerInfo = {}
    }
  } = useStores();
  const currentUserId = travelerInfo.id;

  const renderInvitation = (invitation, index) => {
    if (!invitation.email) {
      return null;
    }

    return (
      <PassengerInformation key={index}>
        <PassengerInfoWrapper passengerAvatar={invitation.joinedTraveler?.photoUrl}>
          <div className="passenger-info-avatar" />
          <FlexContainer align="center" flexDirection="column">
            <WrapIonText>
              {invitation.status === 'joined' ? (
                <Fragment>
                  <p>
                    {invitation.joinedTraveler?.fullName}
                    {currentUserId === invitation.joinedTraveler?.id && ` (${t('trip_details.you')})`}
                  </p>
                  <p className="passenger-info-additional">{invitation.joinedTraveler?.email || invitation.email}</p>
                </Fragment>
              ) : (
                <Fragment>
                  <p>{invitation.email}</p>
                  <p className="passenger-info-additional">{t('invite_passengers.pending_invitation')}</p>
                </Fragment>
              )}
            </WrapIonText>
          </FlexContainer>
        </PassengerInfoWrapper>
      </PassengerInformation>
    );
  };

  const renderInvitations = () => {
    return invitations.map((invitation, index) => renderInvitation(invitation, index));
  };

  const renderMainPassenger = () => {
    const passenger = passengers[0];
    const currentUserId = travelerInfo.id;

    return (
      passenger && (
        <PassengerInformation>
          <PassengerInfoWrapper passengerAvatar={passenger.photoUrl}>
            <div className="passenger-info-avatar" />
            <FlexContainer align="center" flex="1">
              <WrapIonText>
                <p>
                  {passenger.fullName}
                  {currentUserId === passenger.id ? ` (${t('trip_details.you')})` : ` (${t('trip_details.owner')})`}
                </p>
                <p className="passenger-info-additional">{passenger.email}</p>
                {/* <p className="passenger-info-additional">{passenger.phoneNumber}</p> */}
              </WrapIonText>
            </FlexContainer>
          </PassengerInfoWrapper>
        </PassengerInformation>
      )
    );
  };

  const renderInvitePassengersButton = () => {
    return (
      <Fragment>
        <ButtonOutlined
          width="100%"
          margin="24px 0 0"
          borderColor={theme.colors.greys.grey15}
          onClick={() => setInvitationModalOpen(true)}
        >
          <span>
            <img src={PlusIconGraphite} alt="plus icon" />
            {t('invite_passengers.invite_people_to_transfer')}
          </span>
        </ButtonOutlined>

        <InvitePassengersModal isOpen={invitationModalOpen} onClose={() => setInvitationModalOpen(false)} />
      </Fragment>
    );
  };

  return (
    <Fragment>
      <Divider margin="20px 20px 0 20px" />

      <PassengersContainer>
        <IonText>
          <Heading4Styled>
            {t('trip_details.passengers')} ({invitations.length + 1}/{numberOfPassengers})
          </Heading4Styled>
        </IonText>

        {renderMainPassenger()}
        {renderInvitations()}

        {canInvitePassengers && invitations.length < numberOfPassengers - 1 && renderInvitePassengersButton()}
      </PassengersContainer>
    </Fragment>
  );
}

const PassengersContainer = styled.div`
  padding: 0 20px;
`;

const Heading4Styled = styled(Heading4)`
  margin-bottom: 24px;
  text-transform: capitalize;
`;

const PassengerInformation = styled(FlexContainer)`
  margin-top: 16px;
`;

const PassengerInfoWrapper = styled(FlexContainer)`
  .passenger-info-avatar {
    border-radius: 100%;
    width: 44px;
    height: 44px;
    margin-right: 12px;
    background-repeat: no-repeat;
    background-image: ${(props) => (props.passengerAvatar ? `url(${props.passengerAvatar})` : `url(${DefaultAvatar})`)};
    background-size: 44px 44px;
    background-size: cover;
  }

  .passenger-info-additional {
    color: ${theme.colors.greys.grey5};
    font-size: 12px;
    line-height: 18px;

    &.success {
      color: ${theme.colors.cta.greenDark};
    }
  }
`;

const WrapIonText = styled(IonText)`
  max-width: calc(600px - 112px); // Max page width - paddings/margins - avatar width

  @media (max-width: 600px) {
    max-width: calc(
      100vw - 112px
    ); // 112px is the width of paddings/margins + the avatar in order to find the max width of the text
  }
`;

Passengers.propTypes = {
  onSuccess: PropTypes.func
};

export default withTranslation()(Passengers);
