/* eslint-disable no-new-wrappers */
/* eslint-disable no-sequences */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-expressions */
/*! For license information please see loader_js.js.LICENSE.txt */
const loadTiqets = () => {
  var t = {
      418: (t) => {
        var e = Object.getOwnPropertySymbols,
          r = Object.prototype.hasOwnProperty,
          n = Object.prototype.propertyIsEnumerable;
        function o(t) {
          if (null == t) throw new TypeError('Object.assign cannot be called with null or undefined');
          return Object(t);
        }
        t.exports = (function () {
          try {
            if (!Object.assign) return !1;
            var t = new String('abc');
            if (((t[5] = 'de'), '5' === Object.getOwnPropertyNames(t)[0])) return !1;
            for (var e = {}, r = 0; r < 10; r++) e['_' + String.fromCharCode(r)] = r;
            if (
              '0123456789' !==
              Object.getOwnPropertyNames(e)
                .map(function (t) {
                  return e[t];
                })
                .join('')
            )
              return !1;
            var n = {};
            return (
              'abcdefghijklmnopqrst'.split('').forEach(function (t) {
                n[t] = t;
              }),
              'abcdefghijklmnopqrst' === Object.keys(Object.assign({}, n)).join('')
            );
          } catch (t) {
            return !1;
          }
        })()
          ? Object.assign
          : function (t, a) {
              for (var i, c, s = o(t), u = 1; u < arguments.length; u++) {
                for (var d in (i = Object(arguments[u]))) r.call(i, d) && (s[d] = i[d]);
                if (e) {
                  c = e(i);
                  for (var l = 0; l < c.length; l++) n.call(i, c[l]) && (s[c[l]] = i[c[l]]);
                }
              }
              return s;
            };
      },
      408: (t, e, r) => {
        var n = r(418);
        if ('function' == typeof Symbol && Symbol.for) {
          var o = Symbol.for;
          // eslint-disable-next-line no-unused-expressions
          o('react.element'),
            o('react.portal'),
            o('react.fragment'),
            o('react.strict_mode'),
            o('react.profiler'),
            o('react.provider'),
            o('react.context'),
            o('react.forward_ref'),
            o('react.suspense'),
            o('react.memo'),
            o('react.lazy');
        }
        'function' == typeof Symbol && Symbol.iterator;
        function a(t) {
          for (var e = 'https://reactjs.org/docs/error-decoder.html?invariant=' + t, r = 1; r < arguments.length; r++)
            e += '&args[]=' + encodeURIComponent(arguments[r]);
          return (
            'Minified React error #' +
            t +
            '; visit ' +
            e +
            ' for the full message or use the non-minified dev environment for full errors and additional helpful warnings.'
          );
        }
        var i = {
            isMounted: function () {
              return !1;
            },
            enqueueForceUpdate: function () {},
            enqueueReplaceState: function () {},
            enqueueSetState: function () {}
          },
          c = {};
        function s(t, e, r) {
          (this.props = t), (this.context = e), (this.refs = c), (this.updater = r || i);
        }
        function u() {}
        function d(t, e, r) {
          (this.props = t), (this.context = e), (this.refs = c), (this.updater = r || i);
        }
        (s.prototype.isReactComponent = {}),
          (s.prototype.setState = function (t, e) {
            if ('object' != typeof t && 'function' != typeof t && null != t) throw Error(a(85));
            this.updater.enqueueSetState(this, t, e, 'setState');
          }),
          (s.prototype.forceUpdate = function (t) {
            this.updater.enqueueForceUpdate(this, t, 'forceUpdate');
          }),
          (u.prototype = s.prototype);
        var l = (d.prototype = new u());
        (l.constructor = d), n(l, s.prototype), (l.isPureReactComponent = !0);
        Object.prototype.hasOwnProperty;
      },
      294: (t, e, r) => {
        r(408);
      }
    },
    e = {};
  function r(n) {
    var o = e[n];
    if (void 0 !== o) return o.exports;
    var a = (e[n] = { exports: {} });
    return t[n](a, a.exports, r), a.exports;
  }
  (() => {
    JSON.parse(
      '{"EUR": "€","USD": "$", "GBP": "£", "AUD": "A$", "CAD": "CA$", "CHF": "Fr","DKK": "kr", "NOK": "kr", "PLN": "zł", "SEK": "kr", "AED": "د.إ", "HUF": "Ft", "SGD": "S$", "HKD": "HK$", "JPY": "¥", "COP": "$", "NZD": "$", "MYR": "RM", "CZK": "Kč", "MXN": "$"}'
    ),
      JSON.parse(
        '{"en": "English", "ca": "Català", "de": "Deutsch", "el": "Ελληνικά", "es": "Español", "fr": "Français", "ko": "한국어", "it": "Italiano", "nl": "Nederlands", "ja": "日本語", "pt": "Português", "pl": "Polski", "ru": "Русский", "zh": "中文"}'
      );
    let t = (t = 21) => {
      let e = '',
        r = crypto.getRandomValues(new Uint8Array(t));
      for (; t--; ) {
        let n = 63 & r[t];
        e += n < 36 ? n.toString(36) : n < 62 ? (n - 26).toString(36).toUpperCase() : n < 63 ? '_' : '-';
      }
      return e;
    };
    r(294);
    const e = {
        default: [
          'language',
          'currency',
          'partner',
          'tqCampaign',
          'widgetIndex',
          'altVi',
          'altGyg',
          'origin',
          'redirectUrl',
          'tiqetsVf'
        ],
        availability: ['productId', 'venueId', 'layout', 'coupon'],
        discovery: [
          'geoLat',
          'geoLng',
          'geoMaxDistance',
          'cityId',
          'contentType',
          'contentIds',
          'destinationType',
          'destinationId',
          'productIds',
          'cardsLayout',
          'width',
          'itemCount',
          'coupon',
          'hideDestinationLink',
          'search'
        ]
      },
      n = function () {
        let e = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : '';
        return ''.concat(e, '_').concat(t());
      },
      o = function () {
        let t = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : '';
        if (window[''.concat(t.toUpperCase(), '_VF')]) return window[''.concat(t.toUpperCase(), '_VF')];
        const e = n(t);
        return (window[''.concat(t.toUpperCase(), '_VF')] = e), e;
      },
      a = (t, r, n) => {
        var a;
        const i = t.getAttribute('data-tiqets-widget');
        if (t.getAttribute('data-initialized')) return;
        if (
          (t.setAttribute('data-initialized', !0),
          t.setAttribute('data-widget-index', r),
          'discovery' !== i && 'availability' !== i)
        )
          return;
        const c = Object.fromEntries(
          Object.entries({
            ...t.dataset,
            ...n,
            ...(t.dataset.item_count && { itemCount: t.dataset.item_count }),
            widgetIndex: r,
            language:
              t.dataset.language ||
              (null ===
                (a = ((Array.isArray(navigator.languages) && navigator.languages) || [
                  navigator.language || navigator.userLanguage || 'en'
                ])[0]) || void 0 === a
                ? void 0
                : a.substring(0, 2)),
            tiqetsVf: o(''.concat(((s = i), s.charAt(0).toUpperCase() + s.slice(1)), 'Widget')),
            origin: `${process.env.REACT_APP_TRAVELER_DOMAIN}/explore`
          })
            .filter((t) => {
              let [r] = t;
              return [...e.default, ...e[i]].includes(r);
            })
            .map((t) => {
              let [e, r] = t;
              return [e, 'true' === r || r];
            })
        );
        var s;
        if (
          !c.partner ||
          ('availability' === i && !c.productId && !c.venueId) ||
          !(
            'discovery' !== i ||
            c.cityId ||
            c.productIds ||
            c.contentIds ||
            c.search ||
            (c.destinationType && c.destinationId) ||
            (c.geoLat && c.geoLng)
          )
        )
          return;
        const u = ((t) => {
          let { url: e, width: r, node: n, styles: o, title: a } = t;
          const i = document.createElement('iframe');
          return (
            i.setAttribute('scrolling', 'no'),
            i.setAttribute('frameborder', '0'),
            i.setAttribute('src', e),
            i.setAttribute('width', r),
            i.setAttribute('title', a),
            o && i.setAttribute('style', o),
            n.appendChild(i),
            i
          );
        })({
          url: ''
            .concat('https://www.tiqets.com', '/widgets/')
            .concat(i, '?')
            .concat(new URLSearchParams(Object.entries(c)).toString()),
          width: (!Number.isNaN(parseInt(c.width, 10)) && c.width) || '100%',
          height: 'availability' === i ? '500' : void 0,
          node: t,
          styles: 'availability' === i ? 'border-radius: 8px; box-shadow: 0 4px 16px rgba(0,0,0,.1);' : void 0,
          title: ''.concat(i, ' widget #').concat(r, ' for ').concat(c.partner)
        });
        ((t, e, r) => {
          window.addEventListener('message', (e) => {
            try {
              const r = JSON.parse(e.data);
              if (parseInt(r.widgetIndex, 10) !== parseInt(t, 10)) return;
              'postWidgetSize' === r.event &&
                ((t, e) => {
                  let { width: r, height: n } = t;
                  Number.isNaN(r) || (e.style.width = ''.concat(r, 'px')),
                    Number.isNaN(n) || (e.style.height = ''.concat(n, 'px'));
                })(r.payload, u);
            } catch (t) {
              if (!(t instanceof SyntaxError)) throw t;
            }
          });
        })(r);
      };
    (() => {
      const t = document.querySelectorAll('div[data-tiqets-widget]'),
        e = document.querySelectorAll('[data-vi-partner-id]').length,
        r = document.querySelectorAll('[data-gyg-widget]').length;
      Array.from(t).forEach((t, n) => a(t, n, { altVi: e, altGyg: r }));
    })();
  })();
};

export default loadTiqets;
