import React, { Fragment } from 'react';
// import { ImageCardStyled, ImageContainer, MainContent } from "./ImageCard.styled.js";
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Text2 } from '@/src/components/styled/Typography.js';
import { FlexContainer } from '@/src/components/styled/Wrappers.js';
import theme from '@/src/theme';
import ExternalLinkWrapper from '@/src/components/wrappers/ExternalLinkWrapper';
import InternalLinkWrapper from '@/src/components/wrappers/InternalLinkWrapper';

// A card with an image header, a title and a subtitle.
const ImageDesctiptionCard = (props) => {
  const {
    link = '',
    isExternalLink = false,
    image,
    title,
    subtitle,
    desktopWidth = '100%',
    mobileWidth = '100%',
    onClick = () => {}
  } = props;

  // The main content of the card
  const renderContent = () => {
    return (
      <Fragment>
        <ImageContainer imageUrl={image} />
        <Description>
          <TitleText>{title}</TitleText>
          <SubtitleText>{subtitle}</SubtitleText>
        </Description>
      </Fragment>
    );
  };

  const renderInternalLinkCard = () => {
    return <InternalLinkWrapper link={link}>{renderContent()}</InternalLinkWrapper>;
  };

  const renderExternalLinkCard = () => {
    return <ExternalLinkWrapper link={link}>{renderContent()}</ExternalLinkWrapper>;
  };

  // The main card content wrapped in the correct link component if needed
  const renderCard = () => {
    if (isExternalLink && link) {
      return renderExternalLinkCard();
    } else if (link) {
      return renderInternalLinkCard();
    }

    // Card without a link
    return renderContent();
  };

  return (
    <CardWrapper desktopWidth={desktopWidth} mobileWidth={mobileWidth} onClick={onClick}>
      {renderCard()}
    </CardWrapper>
  );
};

const CardWrapper = styled.div`
  width: ${(props) => props.mobileWidth || '100%'};

  @media (min-width: ${theme.breakpoints[0]}px) {
    width: ${(props) => props.desktopWidth || '100%'};
  }
`;

const ImageContainer = styled.div`
  background-image: url(${(props) => props.imageUrl});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 96px;
  width: 100%;
  border-radius: 4px;
`;

const Description = styled(FlexContainer)`
  margin-top: 10px;
  flex-direction: column;
`;

const TitleText = styled(Text2)`
  outline: none;
  white-space: normal !important;
  height: auto;
  word-break: break-word;

  display: -webkit-box;
  display: -moz-box;
  display: box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const SubtitleText = styled(Text2)`
  outline: none;
  white-space: normal !important;
  height: auto;
  word-break: break-word;
`;

ImageDesctiptionCard.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.node.isRequired,
  link: PropTypes.string,
  isExternalLink: PropTypes.bool,
  desktopWidth: PropTypes.string,
  mobileWidth: PropTypes.string,
  onClick: PropTypes.func
};

export default ImageDesctiptionCard;
