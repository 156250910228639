import { IonCard } from '@ionic/react';
import styled from 'styled-components';
import theme from '../../../../theme';

export const CardStyled = styled(IonCard)`
  border-radius: 12px;
  margin: 0;

  ion-card-title {
    font-weight: 600;
    margin-bottom: 4px;
  }

  ion-card-subtitle {
    font-size: 14px;
    font-weight: 400;
    color: ${theme.colors.base.graphiteLighter};
  }
`;

export const MainContent = styled.div`
  padding: 20px;
`;
