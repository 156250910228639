import React, { useEffect, useRef } from 'react';
import { observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';

import TransfersReceiptForm from './TransferReceiptForm';
import DrawerModal from '../../modals/DrawerModal';
import ReceiptModalFooter from './ReceiptModalFooter';
import mixpanel from '../../../services/mixpanel';

/**
 *
 * @param {object} props
 * @param {boolean} props.isOpen Boolean to determine if modal is open
 * @param {function} props.onClose Function that executes on modal close
 * @param {string} props.triggerPage Used for tracking
 * @returns {React.Component}
 */
function ReceiptModal(props) {
  const { isOpen, onClose, t, triggerPage } = props;

  const modalRef = useRef(null);

  const handleClose = () => {
    modalRef.current?.dismiss();
    onClose();
  };

  useEffect(() => {
    if (isOpen) {
      mixpanel.trackDownloadShareReceiptModalView({
        Page: triggerPage
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <DrawerModal
      title={t('receipt_modal.title')}
      description={t('receipt_modal.description')}
      isOpen={isOpen}
      onClose={handleClose}
    >
      <div className="padding-x-lg">
        <TransfersReceiptForm />
        <ReceiptModalFooter />
      </div>
    </DrawerModal>
  );
}

export default withTranslation()(observer(ReceiptModal));
