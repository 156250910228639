import React from 'react';

const Minivan = ({ fill, styles }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="20" style={styles} viewBox="0 0 30 20">
    <title>Minivan</title>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h30v20H0z" />
      <path
        fill={fill}
        d="M22 5h-6v3c4-.004 6-.007 6-.01V5zm2 0v2.99c0 1.106-.897 2.01-1.997 2.01H7a1 1 0 0 1-.933-.639l-.052.027c-.4.202-.823.408-1.275.623l-.85.401c-.372.184-.89.844-.89.902.013 1.639.023 2.46.03 2.462.269.097.698.165 1.243.199.286.017.551.025.897.027A3 3 0 0 1 10.83 14h9.34a3 3 0 0 1 5.664.014c.054-.01.11-.014.166-.014a1 1 0 0 0 1-1V8.132C27 7.69 24.5 5 24 5zM14 5h-.23c-.495 0-1.361.196-1.894.473-.232.12-.842.536-1.653 1.123l-.13.095A113.16 113.16 0 0 0 8.328 8H14V5zm6.17 11h-9.34a3 3 0 0 1-5.659.002 16.85 16.85 0 0 1-1.022-.031C2.186 15.848 1 15.42 1 14v-2.686c0-.916 1.02-2.22 2.028-2.707l.852-.402a59.05 59.05 0 0 0 1.674-.829c.753-.393 1.29-.718 1.416-.844l.102-.089a106.473 106.473 0 0 1 1.845-1.37l.134-.097c.905-.655 1.546-1.092 1.901-1.277.83-.432 2.013-.699 2.818-.699H24c1.638 0 5 3.617 5 5.132V13a3 3 0 0 1-3 3c-.056 0-.112-.005-.166-.014a3 3 0 0 1-5.663.014zM22 15a1 1 0 1 0 1.999.001A1 1 0 0 0 22 15zM7 15a1 1 0 1 0 1.999.001A1 1 0 0 0 7 15z"
      />
    </g>
  </svg>
);

export default Minivan;
