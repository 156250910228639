import React from 'react';
import Login from '.';
import VerificationCodeInputPageEmail from './VerificationCodeInputPageEmail';
import VerificationCodeInputPageSMS from './VerificationCodeInputPageSMS';
import VerificationCodeInputPagePhoneOnly from './VerificationCodeInputPagePhoneOnly';
import TransferDetails from '../Trips/Trip/TransferDetails';
import LoginRoute from '@/src/components/common/Routes/LoginRoute';

const routes = [
  {
    path: '/login',
    exact: true,
    render: () => (
      <LoginRoute>
        <Login />
      </LoginRoute>
    )
  },
  {
    path: '/login/verification_code',
    exact: true,
    render: () => (
      <LoginRoute>
        <VerificationCodeInputPageEmail />
      </LoginRoute>
    )
  },
  {
    path: '/login/verification_code_sms',
    exact: true,
    render: () => (
      <LoginRoute>
        <VerificationCodeInputPageSMS />
      </LoginRoute>
    )
  },
  {
    path: '/login/verification_code_phone_only',
    exact: true,
    render: () => (
      <LoginRoute>
        <VerificationCodeInputPagePhoneOnly />
      </LoginRoute>
    )
  },
  {
    path: '/trip/:tripToken/transfer/:transferToken/live',
    exact: true,
    render: () => (
      <LoginRoute>
        <TransferDetails />
      </LoginRoute>
    )
  }
];
// TODO: remove unused route for live transfer

export default routes;
