const availableLanguages = [
  { name: 'Chinese', code: 'zh-Hans' },
  { name: 'English', code: 'en' },
  { name: 'French', code: 'fr' },
  { name: 'German', code: 'de' },
  { name: 'Greek', code: 'el' },
  { name: 'Italian', code: 'it' },
  { name: 'Polish', code: 'pl' },
  { name: 'Portuguese', code: 'pt-pt' },
  { name: 'Russian', code: 'ru' },
  { name: 'Serbian', code: 'sr' },
  { name: 'Spanish', code: 'es' },
  { name: 'Swedish', code: 'sv' },
  { name: 'Turkish', code: 'tr' },
  { name: 'Dutch', code: 'nl' },
  { name: 'Arabic', code: 'ar' },
  { name: 'Japanese', code: 'ja' }
];

export { availableLanguages };
