import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { observer, inject } from 'mobx-react';
import Timer from './Timer';
import VerificationCodeInput from './VerificationCodeInput';
import { trackEvent } from '../../tracking';
import { BackButton } from '../../components/styled/Buttons';
import { IonBackButton } from '@ionic/react';
import { Heading2, Paragraph } from '../../components/styled/Typography';
import AuthenticationService from '../../services/authentication.js';
import { StyledCard, StyledContent, StyledLoading, StyledPage } from '../../components/styled/IonElements';
import theme from '../../theme';
import { withTranslation } from 'react-i18next';
import mixpanel from '../../services/mixpanel';
import PropTypes from 'prop-types';
import { RETURN_URL_QUERY_PARAM } from '@/src/constants';
import { getQueryParam, urlWithReturnUrl } from '@/src/components/common/helpers';

const COUNT_DOWN_SECONDS = 30;

class VerificationCodeInputPageEmail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      resendAttempts: 0,
      showDidntGetCodePopup: false
    };
  }

  onBackButtonPress = () => {
    const {
      history: { goBack }
    } = this.props;
    trackEvent('traveler app', 'login', 'back');
    goBack();
  };

  onResendEmailButtonPress = () => {
    const {
      travelerAppStore: { keepTrackOfCountDown }
    } = this.props;
    const { resendAttempts } = this.state;

    if (resendAttempts > 2) {
      this.setState({
        resendAttempts: 0,
        showDidntGetCodePopup: true
      });
    }

    this.setState({
      resendAttempts: resendAttempts + 1
    });

    keepTrackOfCountDown(COUNT_DOWN_SECONDS);
    trackEvent('traveler app', 'login', 'resend email');
    this.resendCode('email');
  };

  onSendSmsButtonPress = () => {
    const {
      travelerAppStore: { keepTrackOfCountDown },
      location,
      history: { push }
    } = this.props;
    const email = location.state?.email;
    const phoneNumber = location.state?.phoneNumber;

    keepTrackOfCountDown(COUNT_DOWN_SECONDS);
    this.resendCode('sms');

    push(urlWithReturnUrl('/login/verification_code_sms'), {
      email,
      phoneNumber
    });
  };

  onLoginSuccess = async (data) => {
    const {
      location,
      history: { replace }
    } = this.props;

    trackEvent('traveler app', 'login', 'success');
    // save traveler info to storage, store, set API conf etc. synchronously
    AuthenticationService.onLoginSuccess(data);

    mixpanel.trackLoginCompleted('email', {
      $email: location.state?.email
    });

    const postLoginDestination = getQueryParam(RETURN_URL_QUERY_PARAM);
    replace(postLoginDestination && !postLoginDestination.includes('login') ? postLoginDestination : '/trips');
  };

  resendCode = async (param) => {
    const { location } = this.props;
    const {
      travelerAppStore: { codeRequest }
    } = this.props;
    const formData = new FormData();
    formData.append('email', location.state?.email);
    formData.append('message_method', param);
    formData.append('new_login', true);
    try {
      const res = await codeRequest(formData);
      return res;
      // eslint-disable-next-line no-unused-vars
    } catch (err) {}

    return null;
  };

  togglePopups = () => {
    const { showDidntGetCodePopup } = this.state;
    this.setState({
      showDidntGetCodePopup: !showDidntGetCodePopup
    });
  };

  login = (code) => {
    const {
      travelerAppStore: { login },
      location
    } = this.props;
    const loginData = new FormData();
    loginData.append('email', location.state?.email);
    loginData.append('code', code);

    trackEvent('traveler app', 'login', 'attempt');

    this.setState({ isLoading: true });
    login(loginData)
      .then((data) => {
        mixpanel.trackLoginAttempted({
          Login_Method: 'email',
          $email: location.state?.email,
          Status: 'success'
        });

        this.setState({ isLoading: false });
        this.onLoginSuccess(data);
      })
      .catch(() => {
        mixpanel.trackLoginAttempted({
          Login_Method: 'email',
          $email: location.state?.email,
          Status: 'fail'
        });

        this.setState({ isLoading: false });
      });
  };

  componentWillUnmount() {
    const {
      travelerAppStore: { setLoginError, clearAndCompleteTimer }
    } = this.props;
    setLoginError({});
    clearAndCompleteTimer();
  }

  render() {
    const {
      travelerAppStore: { loginError },
      history: { goBack },
      t
    } = this.props;
    const { isLoading } = this.state;
    const { error } = loginError;
    const {
      travelerAppStore: { timerCompleted },
      location
    } = this.props;
    const completed = timerCompleted;

    return (
      <StyledPage style={{ overflow: 'auto' }}>
        <StyledContent>
          <StyledLoading cssClass="loading-modal" isOpen={isLoading} message={t('please_wait')} duration={5000} />
          <StyledCard height="400px" margin="20px" padding="10px">
            <BackButton style={{ margin: '20px' }} onClick={() => goBack()} />
            <IonBackButton />
            <Heading2 align="center">{t('sign_in.code')}</Heading2>
            <VerificationEmailParagraph maxWidth="100%">{t('sign_in.email_code_sent')}</VerificationEmailParagraph>
            <VerificationCodeInput login={this.login} />
            {error && (
              <Paragraph maxWidth="100%" weight="700" margin="10px" color={theme.colors.accent.red}>
                {error}
              </Paragraph>
            )}
            {completed && (
              <Fragment>
                <Button
                  maxWidth="100%"
                  weight="700"
                  completed={completed}
                  margin="20px"
                  onClick={this.onResendEmailButtonPress}
                >
                  {' '}
                  {t('sign_in.resend_email')}
                </Button>
                {!!location.state?.hasValidPhone && (
                  <Button
                    maxWidth="100%"
                    weight="700"
                    completed={completed}
                    margin="0px"
                    onClick={this.onSendSmsButtonPress}
                  >
                    {t('sign_in.send_sms')}
                  </Button>
                )}
              </Fragment>
            )}
            {!completed && (
              <Button maxWidth="100%" weight="700" completed={completed} margin="20px">
                <Timer />
              </Button>
            )}
          </StyledCard>
        </StyledContent>
      </StyledPage>
    );
  }
}

const Button = styled(Paragraph)`
  ${(props) => (!props.completed ? 'Disabled' : '')};
  margin-top: ${(props) => props.margin};
  cursor: pointer;
`;

const VerificationEmailParagraph = styled(Paragraph)`
  margin-bottom: 40px;
  margin-top: 0;
`;

VerificationCodeInputPageEmail.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  travelerAppStore: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired
};

export default withTranslation()(withRouter(inject('travelerAppStore')(observer(VerificationCodeInputPageEmail))));
