import React from 'react';
import { IonItem, IonLabel, IonSkeletonText, IonList, IonAvatar, IonThumbnail } from '@ionic/react';
import { FlexContainer } from '../../styled/Wrappers';

import './SkeletonTextExample.css';
import { StyledCard, StyledContent } from '../../styled/IonElements';

const SkeletonDetails = () => (
  <StyledContent>
    <StyledCard padding="0">
      <IonList>
        <IonItem lines="none">
          <IonThumbnail style={{ width: '100%', height: '200px', margin: 0 }} slot="start">
            <IonSkeletonText animated />
          </IonThumbnail>
        </IonItem>
        <IonItem>
          <IonLabel>
            <IonSkeletonText animated style={{ width: '60px', height: '18px' }} />
            <IonSkeletonText animated style={{ width: '50px', marginBottom: '20px' }} />

            <FlexContainer justify="space-between" align="start">
              <div>
                <IonSkeletonText animated style={{ width: '80px' }} />
                <IonSkeletonText animated style={{ width: '120px' }} />
              </div>
              <IonSkeletonText animated style={{ width: '10%' }} />
            </FlexContainer>
            <FlexContainer justify="space-between" align="start">
              <div>
                <IonSkeletonText animated style={{ width: '80px' }} />
                <IonSkeletonText animated style={{ width: '120px' }} />
              </div>
              <IonSkeletonText animated style={{ width: '10%' }} />
            </FlexContainer>
          </IonLabel>
        </IonItem>
        <IonItem>
          <IonLabel>
            <IonSkeletonText animated style={{ width: '30%', margin: '20px 0' }} />

            <FlexContainer>
              <IonAvatar slot="start">
                <IonSkeletonText animated />
              </IonAvatar>
              <div style={{ marginLeft: '20px' }}>
                <IonSkeletonText animated style={{ width: '70px' }} />
                <IonSkeletonText animated style={{ width: '70px' }} />
                <IonSkeletonText animated style={{ width: '50px' }} />
              </div>
            </FlexContainer>
          </IonLabel>
        </IonItem>
        <IonItem>
          <IonLabel>
            <IonSkeletonText animated style={{ width: '40%', margin: '20px 0' }} />
            <IonThumbnail style={{ width: '100%', height: '200px', margin: 0 }} slot="start">
              <IonSkeletonText animated />
            </IonThumbnail>
            <p>
              <IonSkeletonText animated style={{ width: '80%' }} />
            </p>
          </IonLabel>
        </IonItem>
      </IonList>
    </StyledCard>
  </StyledContent>
);

export default SkeletonDetails;
