import { makeAutoObservable } from 'mobx';

class WidgetStore {
  // Every time this updates, the widget will reload.
  // It can take any value but should be different on every update
  widgetReloadKey = '';

  // Indicates if we are still waiting for the feature flag to be finalized.
  widgetCitySelectorLocked = false;
  widgetRouteSelectorLocked = false;

  // Keeps the final/locked value of the feature flag after the timeout has passed
  widgetCitySelectorLockedValue;
  widgetRouteSelectorLockedValue;

  constructor() {
    makeAutoObservable(this);
  }

  updateWidgetReloadKey = (customValue) => {
    this.widgetReloadKey = customValue || new Date().getTime();
  };

  updateWidgetCitySelectorLocked = (value) => {
    this.widgetCitySelectorLocked = value;
  };
  updateWidgetRouteSelectorLocked = (value) => {
    this.widgetRouteSelectorLocked = value;
  };

  updateWidgetCitySelectorLockedValue = (value) => {
    this.widgetCitySelectorLockedValue = value;
  };
  updateWidgetRouteSelectorLockedValue = (value) => {
    this.widgetRouteSelectorLockedValue = value;
  };
}

export default new WidgetStore();
