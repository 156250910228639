import React, { Fragment } from 'react';
import { Text1 } from '../../styled/Typography';
import { withTranslation } from 'react-i18next';
import { ButtonOutlined } from '../../styled/Buttons';
import { FlexContainer } from '../../styled/Wrappers';
import theme from '../../../theme';
import CloseDeleteIcon from '../../../assets/icons/close-delete-grey-icon.svg';
import { useStores } from '../../../stores/useStores';
import TravelerApi from '../../../API';
import { IonToast } from '@ionic/react';
import { observer } from 'mobx-react';
import mixpanel from '../../../services/mixpanel';
import styled from 'styled-components';
import { StyledCard } from '../../styled/IonElements';
import { logError } from '@/src/helpers/log';

function AirbnbHostInvitationBanner(props) {
  const {
    travelerAppStore: {
      currentTrip: { id, potentialAirbnbLocationTitle },
      setAskAirbnbQuestionOfCurrentTrip,
      setAirbnbHostInvitationOfCurrentTrip
    }
  } = useStores();

  const { t } = props;
  const [showErrorToast, setShowErrorToast] = React.useState(false);

  /**
   * @param {('true'|'false'|null)} answer
   */
  const onAnswer = (answer) => {
    TravelerApi.saveAirbnbQuestionResponse(id, answer)
      .then((data) => {
        setAskAirbnbQuestionOfCurrentTrip(false);

        if (data?.id && answer) {
          setAirbnbHostInvitationOfCurrentTrip(data);
        }

        // eslint-disable-next-line default-case
        switch (answer) {
          case true:
            mixpanel.trackPotentialAirbnbYesClick();
            break;
          case false:
            mixpanel.trackPotentialAirbnbNoClick();
            break;
          case null:
            mixpanel.trackPotentialAirbnbClosed();
            break;
        }
      })
      .catch((err) => {
        setShowErrorToast(true);
        logError('Save airbnb question response error', err);
      });
  };

  return potentialAirbnbLocationTitle ? (
    <Fragment>
      <BannerContainer margin="20px">
        <FlexContainer gap="12px" justify="space-between" align="flex-start">
          <Text1>
            {t('airbnb_host_invitations.potential_airbnb_home', {
              potential_airbnb_location_title: potentialAirbnbLocationTitle
            })}
          </Text1>

          <img
            src={CloseDeleteIcon}
            alt="close icon"
            onClick={() => onAnswer(null)}
            style={{ cursor: 'pointer', marginTop: '3px' }}
          />
        </FlexContainer>

        <FlexContainer gap="12px" margin="16px 0 0">
          <ButtonOutlined width="100%" borderColor={theme.colors.base.graphite} onClick={() => onAnswer(true)}>
            Yes
          </ButtonOutlined>
          <ButtonOutlined width="100%" borderColor={theme.colors.base.graphite} onClick={() => onAnswer(false)}>
            No
          </ButtonOutlined>
        </FlexContainer>
      </BannerContainer>

      <IonToast
        isOpen={showErrorToast}
        onDidDismiss={() => {
          setShowErrorToast(false);
        }}
        message={t('alert.something_went_wrong')}
        duration={2000}
        cssClass="styled-toast-error"
      />
    </Fragment>
  ) : null;
}

const BannerContainer = styled(StyledCard)`
  --background: #eaebed;
  box-shadow: none;
  border: 1px solid #d5d8dc;
`;

export default withTranslation()(observer(AirbnbHostInvitationBanner));
