import React, { memo, useRef, Fragment, useState } from 'react';
import styled from 'styled-components';
import SimpleLightbox from 'simple-lightbox';
import offlineImage from '../../../../../assets/offline-image.svg';
import './lightBox.css';
import { Text2 } from '../../../../../components/styled/Typography';
import Bullets from './Bullets';
import { withTranslation } from 'react-i18next';
import { useStores } from '../../../../../stores/useStores';
import { observer } from 'mobx-react';

SimpleLightbox.defaults = Object.assign(SimpleLightbox.defaults, {
  closeBtnClass: ' customCloseButton',
  nextBtnClass: ' customNextButton',
  prevBtnClass: ' customPrevButton'
});

/**
 * @param props
 */
const MeetingPoint = (props) => {
  const firstImageRef = useRef(null);
  const [lazyImages, setLazyImages] = useState(false);
  const {
    fromLive = false,
    chosenTransfer,
    chosenTransfer: { meetingPoint },
    forwardedRef,
    t
  } = props;
  const {
    travelerAppStore: { openMeetingPoint, setOpenMeetingPoint }
  } = useStores();

  SimpleLightbox.defaults.beforeClose = () => {
    setOpenMeetingPoint(false);
  };

  const bringLazyImages = (e, gallery) => {
    if (e) {
      e.preventDefault();
    }

    if (lazyImages) return;

    setLazyImages(true);

    document.querySelectorAll('#imageGallery a').forEach((item, index) => {
      item.href = gallery[index].imageUrls.view3X;
      item.title = `<p class="lsb-title">${t('trip_details.meeting_point')} | <span class="lsb-subtitle">${meetingPoint.title}</span></p><div class="image-description"><p style="font-weight:700; color: white">${gallery[index].title}</p> <br><br> <p style="white-space: pre-wrap; color: white">${gallery[index].description}</p></div>`;
    });

    document.querySelectorAll('#imageGallery a img').forEach((item, index) => {
      if (index === 0) return;
      item.src = gallery[index].imageUrls.view3X;
    });

    new SimpleLightbox({
      elements: '#imageGallery a'
    });

    setTimeout(() => {
      firstImageRef.current.click();
    }, 200);
  };

  if (openMeetingPoint) {
    bringLazyImages(null, meetingPoint.photos);
  }

  const bringOnlyFirst = (index, image) => (index === 0 ? image : ' ');

  const setRefs = (el, index) => {
    if (index === 0) {
      forwardedRef.current = el;
      firstImageRef.current = el;
    }
  };

  return (
    <Fragment>
      {!fromLive && <SubHeader color="grey5">{(meetingPoint && meetingPoint.title) || ''}</SubHeader>}
      <Container>
        {meetingPoint && (
          <ImageGallery id="imageGallery">
            {meetingPoint.photos.map((image, index) => {
              return (
                <a
                  key={`gallery-${index}`}
                  ref={(el) => setRefs(el, index)}
                  onClick={
                    index === 0
                      ? (e) => {
                          bringLazyImages(e, meetingPoint.photos);
                        }
                      : () => {}
                  }
                  href={bringOnlyFirst(index, image.imageUrls.view2X)}
                  title={image.description}
                  style={{ position: 'relative', display: fromLive && 'none' }}
                >
                  <ImageWrapper>
                    <img
                      alt="meeting-point"
                      onError={(ev) => {
                        ev.target.src = offlineImage;
                      }}
                      src={bringOnlyFirst(index, `${image.imageUrls.view2X}`)}
                    />
                  </ImageWrapper>
                  <Bullets photos={meetingPoint.photos} />
                </a>
              );
            })}
          </ImageGallery>
        )}
        {!fromLive && (
          <Description>{`
        ${
          (meetingPoint && meetingPoint.description) ||
          chosenTransfer.signText ||
          chosenTransfer.meetingPointSignText ||
          ''
        }`}</Description>
        )}
      </Container>
    </Fragment>
  );
};

const Container = styled.div`
  margin: 10px 0;
`;

const ImageWrapper = styled.div`
  text-align: center;

  img {
    width: 100%;
    height: 100%;
    max-width: 520px;
    max-height: 392px;
    object-fit: cover;
    @media (max-width: 767px) {
      max-height: 292px;
    }
  }
`;

const SubHeader = styled(Text2)``;

const Description = styled.p`
  margin-top: 24px !important;
`;

const ImageGallery = styled.div`
  a {
    display: none;
    :first-of-type {
      display: block;
    }
  }
`;

export default withTranslation()(memo(observer(MeetingPoint)));
