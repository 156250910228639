import React from 'react';
import theme from '@/src/theme';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { applyStyles, mergeMediaQueries } from '@/src/helpers/styling';

/**
 * A function that returns the correct font size and line height for a given size.
 * @param {string} size
 * @returns {object}
 */
const getTextSizing = (size) => {
  switch (size) {
    case '11':
      return {
        fontSize: theme.typography[11].fontSize,
        lineHeight: theme.typography[11].lineHeight
      };
    case '12':
      return {
        fontSize: theme.typography[12].fontSize,
        lineHeight: theme.typography[12].lineHeight
      };
    case '14':
      return {
        fontSize: theme.typography[14].fontSize,
        lineHeight: theme.typography[14].lineHeight
      };
    case '16':
      return {
        fontSize: theme.typography[16].fontSize,
        lineHeight: theme.typography[16].lineHeight
      };
    case '18':
      return {
        fontSize: theme.typography[18].fontSize,
        lineHeight: theme.typography[18].lineHeight
      };
    case '20':
      return {
        fontSize: theme.typography[20].fontSize,
        lineHeight: theme.typography[20].lineHeight
      };
    case '24':
      return {
        fontSize: theme.typography[24].fontSize,
        lineHeight: theme.typography[24].lineHeight
      };
    case '30':
      return {
        fontSize: theme.typography[30].fontSize,
        lineHeight: theme.typography[30].lineHeight
      };
    case '36':
      return {
        fontSize: theme.typography[36].fontSize,
        lineHeight: theme.typography[36].lineHeight
      };
    default:
      return {
        fontSize: '14px',
        lineHeight: '18px'
      };
  }
};

// The basic style that will be applied to all Text components
const getBaseStyle = ({ size, smSize, mdSize, lgSize, weight }) => {
  return {
    margin: '0',
    color: theme.colors.base.graphite,
    ...(size && getTextSizing(size)),
    ...(weight && { fontWeight: weight }),

    [`@media (min-width: ${theme.screenSizes.sm}px)`]: {
      ...(smSize && getTextSizing(smSize))
    },
    [`@media (min-width: ${theme.screenSizes.md}px)`]: {
      ...(mdSize && getTextSizing(mdSize))
    },
    [`@media (min-width: ${theme.screenSizes.lg}px)`]: {
      ...(lgSize && getTextSizing(lgSize))
    }
  };
};

// Specific styles for each text variant
const getVariantStyles = (props) => {
  const { element, variant, size, weight } = props;
  const style = variant || element;

  if (!style) return {};

  const styles = {
    h1: {
      fontWeight: weight || '700',
      ...getTextSizing(size || '36')
    },
    h2: {
      fontWeight: weight || '700',
      ...getTextSizing(size || '30')
    },
    h3: {
      fontWeight: weight || '700',
      ...getTextSizing(size || '24')
    },
    h4: {
      fontWeight: weight || '700',
      ...getTextSizing(size || '20')
    },
    h5: {
      fontWeight: weight || '700',
      ...getTextSizing(size || '18')
    },
    h6: {
      fontWeight: weight || '500',
      ...getTextSizing(size || '18')
    },
    p: {
      fontWeight: weight || '500',
      ...getTextSizing(size || '14')
    },
    span: {
      fontWeight: weight || '500',
      ...getTextSizing(size || '14')
    }
  };

  return styles[style];
};

/**
 * Final styles function to merge all styles.
 */
const finalStyles = (props) => {
  const baseStyle = getBaseStyle(props);
  const variantStyle = getVariantStyles(props);
  const appliedStyles = applyStyles(props.styles);

  // Merge media queries from both baseStyle and appliedStyles
  const mergedMediaQueries = mergeMediaQueries(baseStyle, appliedStyles);

  // Return the final merged styles
  return {
    ...baseStyle,
    ...variantStyle,
    ...appliedStyles,
    ...mergedMediaQueries
  };
};

/**
 *
 * @example
 * <Text
 *  element="h1"
 *  variant="h2"
 *  weight="400"
 *  mdSize="20"
 *  styles={{
 *    margin: '20px',
 *    md: { margin: '30px' },
 *    lg: { margin: '40px' }
 *  }}
 * >
 *  This is a text
 * </Text>
 *
 */
export const Text = styled(({ element: Component = 'p', ...props }) => <Component {...props} />).withConfig({
  shouldForwardProp: (prop) => !['size', 'smSize', 'mdSize', 'lgSize', 'weight', 'styles'].includes(prop)
})(finalStyles);

Text.propTypes = {
  // The element that the text should be rendered as.
  element: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p', 'span']),

  // Variant for predefined styles (e.g., h1, h2, etc.)
  variant: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p', 'span']),

  // Text size and its variations for different screen sizes
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  smSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  mdSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  lgSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

  // Text weight (font weight)
  weight: PropTypes.oneOf(['300', '500', '700', '900']),

  // Styles object for additional custom styling
  styles: PropTypes.object
};
