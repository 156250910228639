import mixpanel from 'mixpanel-browser';
import { getPlatformForTravelerAppEvent, isIosMobileApp } from '../components/common/helpers';
import debounce from '../helpers/debounce';
import stores from '../stores';
import { WELCOME_MIXPANEL_INSTANCE_NAME } from '../constants';
import { Device } from '@capacitor/device';

let deviceInfo;

class MixPanel {
  init = async () => {
    deviceInfo = await Device.getInfo();

    mixpanel.init(process.env.REACT_APP_MIXPANEL_KEY, {
      debug: process.env.REACT_APP_ENVIRONMENT !== 'production', // Show logs in development and staging
      persistence: isIosMobileApp() ? 'localStorage' : 'cookie',
      loaded: () => {
        this.isInitialized = true;
        console.log('Mixpanel initialized');
      }
    });
  };

  /**
   * Tracks a mixpanel event
   * @param {string} eventName
   * @param {object} eventData
   */
  track = (eventName, eventData = {}, options = {}) => {
    const {
      travelerAppStore: { isAuthorised }
    } = stores;
    let pageName = window.location.pathname.split('/')[1] || 'N/A';

    if (window.location.pathname.includes('trip') && window.location.pathname.includes('transfer')) {
      pageName = 'transfer_details';
    }

    const extraProps = {
      Platform: getPlatformForTravelerAppEvent(),
      Page_Name: pageName,
      Logged_In: isAuthorised,
      app_version: process.env.REACT_APP_VERSION,
      os_version: deviceInfo?.osVersion
    };

    if (this.isInitialized) {
      return mixpanel.track(eventName, { ...eventData, ...extraProps }, options);
    }
    console.log('"track" can not be used because mixpanel is not initialized');
  };

  /**
   * Sets user attributes like name or phone number to a user profile
   * @param {object} props
   */
  peopleSet = (props) => {
    if (this.isInitialized) {
      return mixpanel.people.set(props);
    }
    console.log('"people.set" can not be used because mixpanel is not initialized');
  };

  /**
   * Sets user attributes like name or phone number to a user profile only if they don't exist
   * @param {object} props
   */
  peopleSetOnce = (props) => {
    if (this.isInitialized) {
      return mixpanel.people.set_once(props);
    }
    console.log('"people.set_once" can not be used because mixpanel is not initialized');
  };

  /**
   * Associates the profile properties with the given user
   * @param {string} userId
   */
  identify = (userId) => {
    if (this.isInitialized) {
      return mixpanel.identify(userId);
    }
    console.log('"identify" can not be used because mixpanel is not initialized');
  };

  /**
   * Should be called on user logout in order to start tracking actions on a new user profile.
   */
  reset = () => {
    if (this.isInitialized) {
      mixpanel.reset();
      return;
    }
    console.log('"reset" can not be used because mixpanel is not initialized');
  };

  /**
   * Returns the current distinct id of the user. This is either the id automatically generated by the library or the id
   * that has been passed by a call to identify().
   */
  getDistinctId = () => {
    if (this.isInitialized) {
      return mixpanel.get_distinct_id();
    }
    console.log('"get_distinct_id" can not be used because mixpanel is not initialized');
  };

  /**
   * Returns the value of the super property named property_name.
   * @param {string} propertyName
   */
  getProperty = (propertyName) => {
    if (this.isInitialized) {
      return mixpanel.get_property(propertyName);
    }
    console.log('"get_property" can not be used because mixpanel is not initialized');
  };

  // ----- Authentication Tracking -----

  /**
   * Triggers every time the user tries to login (successfully or not).
   * @param {?object} data
   * @param {?string} data.$email
   * @param {?string} data.$phone
   * @param {('success'|'fail')} data.Status
   * @param {('email'|'phone')} data.Login_Method
   **/
  trackLoginContinue = (data) => {
    this.track('login_continue', data);
  };
  /**
   * Triggers every time the user tries to login (successfully or not).
   * @param {?object} data
   * @param {?string} data.$email
   * @param {?string} data.$phone
   * @param {('success'|'fail')} data.Status
   * @param {('email'|'phone')} data.Login_Method
   **/
  trackLoginAttempted = (data) => {
    this.track('login_attempted', data);
  };

  /**
   * Should be triggered every time the user logs in.
   * @param {('email'|'phone')} loginMethod
   * @param {?object} data
   * @param {?string} data.$email
   * @param {?string} data.$phone
   */
  trackLoginCompleted = async (loginMethod, data = {}) => {
    const { travelerInfo } = stores.travelerAppStore;

    if (travelerInfo.id) {
      this.identify(travelerInfo.id);
    }

    this.track('login_completed', {
      Login_Method: loginMethod
    });

    // Saves email and phone when a user tries to login
    if (data.$email || data.$phone) {
      this.peopleSetOnce(data);
    }
  };

  // ----- Misc Tracking -----

  /**
   * @param {object} data
   * @param {string} data.Text
   */
  trackThisInformationIsCorrectClick = (data) => this.track('this_information_is_correct_clicked', data);
  /**
   * @param {object} data
   * @param {string} data.Text
   */
  trackAddCorrectInformationClick = (data) =>
    this.track('add_correct_information_clicked', data, { send_immediately: true });
  /**
   * @param {object} data
   * @param {string} data.Id
   * @param {string} data.Title
   */
  trackPromoCardClick = (data) => this.track('promo_card_clicked', data);
  trackBackButtonClick = () => this.track('back_button_clicked');
  trackSupportView = () => this.trackLoginAttempted('support_viewed');

  // ----- Book Tab Tracking -----
  trackBookTabView = () => this.track('book_tab_viewed');
  trackLanguageSelectorClick = () => this.track('language_switch_clicked');
  trackTravelerTokenExpired = (error) => {
    const { token_expired_at, token_expired_days_ago, token_type } = error?.response?.data || {};

    this.track('traveler_token_expired', {
      token_expired_at,
      token_expired_days_ago,
      token_type
    });
  };

  // ----- Trips Tab Tracking -----

  /**
   * @param {object} data
   * @param {number} data.Trips_Count
   * @param {object} data.HVA_Placements
   * @param {boolean} data.HVA_Placements.return_transfer
   * @param {boolean} data.HVA_Placements.traveler_invitations
   * @param {boolean} data.HVA_Placements.merge_accounts_visible
   */
  trackUpcomingTripsView = (data) => this.track('upcoming_trips_viewed', data);
  /**
   * @param {object} data
   * @param {number} data.Trips_Count
   * @param {object} data.HVA_Placements
   * @param {boolean} data.HVA_Placements.return_transfer
   * @param {boolean} data.HVA_Placements.traveler_invitations
   * @param {boolean} data.HVA_Placements.merge_accounts_visible
   */
  trackCompletedTripsView = (data) => this.track('completed_trips_viewed', data);
  /**
   * @param {object} data
   * @param {Array} data.Available_Actions
   */
  trackTripActionsClick = (data) => this.track('trip_actions_clicked', data);
  /**
   * @param {object} data
   * @param {string} data.Action
   */
  trackTripActionClick = (data) => this.track('trip_action_clicked', data);

  // ----- Trip Tab Tracking -----

  /**
   * @param {object} data
   * @param {boolean} data.Book_Return_Transfer_Available
   * @param {boolean} data.Book_Another_Ride_Available
   * @param {number} data.Active_Trips_Count
   * @param {string} data.Selected_Trip_Id
   * @param {('active'|'archived')} data.Selected_Trip_Status
   * @param {number} data.Trip_Transfers_Count
   * @param {number} data.Errors_Count
   * @param {number} data.Potential_Airbnb_Question_Visible
   * @param {number} data.Airbnb_Invitation_Visible
   * @param {boolean} data.has_sride
   * @param {boolean} data.hva_b2b_promo_visible
   * @param {boolean} data.boh_relationship
   * @param {boolean} data.srides_hva_visible
   */
  trackTripTabView = (data) => this.track('trip_tab_viewed', data);
  trackBookATransferClick = () => this.track('book_transfer_clicked');
  trackSelectATripClick = () => this.track('trip_switch_clicked');
  trackEditThisTransferClick = () => this.track('edit_transfer_clicked');
  trackCancelThisTransferClick = () => this.track('cancel_transfer_clicked');
  trackDownloadReceiptClick = () => this.track('download_receipt');
  /**
   * @param {object} data
   * @param {('section'|'details Button')} data.Clicked_Item Indicates where the user clicked to trigger that event
   */
  trackTransferDetailsClick = (data) => this.track('transfer_details_clicked', data);
  /**
   *
   * @param {object} data
   * @param {bool} data.Has_Assigned_Driver
   * @param {bool} data.Available_Driver_Video
   * @param {('live view'|'static details')} data.Type
   * @param {number} data.Invited_Passengers_Count
   * @param {boolean} data.Invitation_Action_Visible
   * @param {number} data.Potential_Invitations_Count
   * @param {string} data.City_Name
   * @param {boolean} data.isSride
   */
  trackTransferDetailsView = (data) => this.track('transfer_details_viewed', data);
  /**
   *
   * @param {object} data
   * @param {string} data.City_Name
   */
  trackDriverVideoView = (data) => this.track('driver_video_viewed', data);
  trackBookAReturnTransferClick = () => this.track('return_transfer_clicked');
  /**
   * @param {object} data
   * @param {string} data.Destination
   * @param {string} data.Destinations_Count // The number of featured return transfers
   */
  trackFeaturedReturnTransferClick = (data) => this.track('featured_return_transfer_clicked', data);
  trackBookAnExtraTransferClick = () => this.track('extra_transfer_clicked');
  /**
   * @param {object} data
   * @param {string} data.Destination
   * @param {string} data.Destinations_Count // The number of featured additional transfers
   */
  trackFeaturedExtraTransferClick = (data) => this.track('featured_extra_transfer_clicked', data);
  trackMeetingPointClick = () => this.track('view_meeting_point_clicked');
  trackMessageDriverClick = () => this.track('message_driver_clicked');
  trackShowDriverClick = () => this.track('show_driver_clicked');
  trackCallDriverClick = () => this.track('call_driver_clicked');
  trackAndroidAppDownloadClick = () => this.track('download_android_app_clicked');
  trackIosAppDownloadClick = () => this.track('download_ios_app_clicked');
  trackPotentialAirbnbYesClick = () => this.track('potential_airbnb_yes_clicked');
  trackPotentialAirbnbNoClick = () => this.track('potential_airbnb_no_clicked');
  trackPotentialAirbnbClosed = () => this.track('potential_airbnb_closed');
  trackAirbnbInvitationClosed = () => this.track('airbnb_invitation_closed');
  trackAirbnbInvitationCopied = () => this.track('airbnb_invitation_copied');
  trackAirbnbShareOpened = () => this.track('airbnb_share_opened');
  trackAirbnbSharableLinkCopied = () => this.track('airbnb_sharable_link_copied');
  /**
   * @param {object} data
   * @param {string} data.App_name
   */
  trackAirbnbInvitationShared = (data) => this.track('airbnb_invitation_shared', data);
  trackAirbnbInvitationPreviewed = () => this.track('airbnb_invitation_previewed');
  trackHvaB2bPromoClosed = () => this.track('hva_b2b_promo_closed');
  trackHvaB2bPromoClicked = () => this.track('hva_b2b_promo_clicked', {}, { send_immediately: true });
  trackHvaTripSridesClicked = () => this.track('trip_srides_hva_clicked', {}, { send_immediately: true });

  // ---- Explore Tab Tracking

  /**
   * @param {object} data
   * @param {string} data.Sightseeing_Rides_Count
   * @param {string} data.City_Name
   * @param {string} data.Tiqets_Widget_Available
   */
  trackExploreTabView = (data) => this.track('explore_tab_viewed', data);
  trackSelectACityClicked = () => this.track('select_a_city_clicked');
  /**
   * @param {object} data
   * @param {string} data.City
   */
  trackSendSRideRequestEmailClick = (data) => this.track('send_sightseeing_ride_request_email_clicked', data);
  /**
   * @param {object} data
   * @param {string} data.City
   */
  trackCitySelected = (data) => this.track('city_selected', data);
  /**
   * @param {object} data
   * @param {string} data.Title
   */
  trackSRideView = (data) => this.track('sightseeing_ride_viewed', data);
  trackMoreSRidesClick = () => this.track('more_sightseeing_rides_clicked');
  trackTiqetsClick = () => this.track('tiqets_clicked');
  trackMoreAttractionsClick = () => this.track('more_attractions_clicked');

  // ---- Messages Tab Tracking

  trackChatlListView = () => {
    const {
      travelerAppStore: { travelerInfo },
      messagesStore: { chatClient, activeChannels, archivedChannels, totalUnreadMessages }
    } = stores;

    const noMessages = !travelerInfo.chatUser || !chatClient;
    if (!noMessages) {
      this.track('chat_list_viewed', {
        Active_Chats: activeChannels.length,
        Archived_Chats: archivedChannels.length,
        Unread_Messages_Count: totalUnreadMessages
      });
    }
  };
  /**
   * @param {object} data
   * @param {string} data.Chat_Id
   * @param {string} data.Order_Name
   * @param {string} data.Order_Id
   * @param {boolean} data.Is_Chat_Active
   * @param {string} data.Created_By
   */
  trackChatOpenDebounced = debounce((data) => this._trackChatOpen(data), 1000);
  _trackChatOpen = (data) => {
    this.track('chat_opened', data);
  };
  /**
   * @param {object} data
   * @param {string} data.Chat_Id
   * @param {string} data.Order_Name
   * @param {string} data.Order_Id
   * @param {boolean} data.Is_Chat_Active
   * @param {string} data.Created_By
   */
  trackChatClose = (data) => this.track('chat_closed', data);
  trackMessageRead = () => this.track('message_read');
  trackMessageSent = () => this.track('message_sent');
  trackMessageReceived = () => this.track('message_received');
  trackUserWithNoActiveChats = () => this.track('user_has_no_active_chats');

  // ---- Profile Tab Tracking ----

  /**
   * @param {object} data
   * @param {number} data.Errors_Count
   * @param {boolean} data.Referral_Banner_Visible
   */
  trackProfileView = (data) => this.track('profile_tab_viewed', data);
  trackEditProfileClick = () => this.track('edit_profile_clicked');
  /**
   * @param {object} data
   * @param {?string} data.$first_name
   * @param {?string} data.$last_name
   * @param {?string} data.$phone
   * @param {?string} data.$email
   * @param {boolean} data.Email_Updates_Selected
   * @param {boolean} data.Email_Promotions_Selected
   */
  trackProfileChangesSave = (data) => {
    this.track('profile_changes_saved', data);
    this.peopleSet(data);
  };
  trackProfilePhotoChange = () => this.track('profile_photo_changed');
  /**
   * @param {object} data
   * @param {number} data.Trips_Count
   */
  trackTripHistoryView = (data) => this.track('trip_history_viewed', data);
  trackTermsAndConditionsView = () => this.track('terms_and_conditions_viewed');
  trackAccountAndDataView = () => this.track('account_and_data_viewed');
  trackAccountDataDownloadClick = () => this.track('account_data_download_clicked');
  trackDeleteAccountClick = () => this.track('delete_account_click');
  trackDeleteAccountAccept = () => this.track('delete_account_accepted');
  trackDeleteAccountCancel = () => this.track('delete_account_canceled');
  trackLogoutClick = () => this.track('logout_clicked');
  trackLogoutConfirm = () => this.track('logout_confirmed');
  trackLogoutCancel = () => this.track('logout_canceled');
  trackSignInClick = () => this.track('sign_in_clicked');

  /**
   * @param {object} data
   * @param {number} data.Wallet_Amount
   * @param {boolean} data.Referral_Banner_Visible
   */
  trackWalletPageView = (data) => this.track('wallet_page_viewed', data);
  trackReferralPageView = () => this.track('referral_page_viewed');
  /**
   * @param {object} data
   * @param {number} data.Question
   */
  trackFaqRead = (data) => this.track('faq_read', data);
  /**
   * @param {object} data
   * @param {number} data.Number_Of_Transactions
   */
  trackTransactionHistoryView = (data) => this.track('transaction_history_viewed', data);
  trackReferralUrlCopied = () => this.track('referral_url_copied');
  /**
   * @param {object} data
   * @param {string} data.App_name
   */
  trackReferralUrlShared = (data) => this.track('referral_url_shared', data);
  trackReferralUrlShareOpened = () => this.track('referral_url_share_opened');
  /**
   * @param {object} data
   * @param {number} data.Number_Of_Invitations
   */
  trackInvitationHistoryView = (data) => this.track('invitation_history_viewed', data);

  trackMergeAccountsButtonClick = () => this.track('merge_accounts_button_clicked');

  /**
   * @param {object} data
   * @param {number} data.accounts_number
   */
  trackMergeAccountsPageView = (data) => this.track('merge_accounts_page_viewed', data);

  trackMergeAccountsSuccessPageView = () => this.track('merge_accounts_success_completed');

  // ---- Invite all passengers tracking ----
  /**
   * @param {object} data
   * @param {number} data.Invited_Passengers_Count
   * @param {number} data.Potential_Invitations_Count
   */
  trackTravelerInvitePeopleModalView = (data) => this.track('traveler_invite_people_modal_viewed', data);
  trackTravelerSharingLinkCopied = () => this.track('traveler_sharing_link_copied');
  trackTravelerInvitationSharableLinkCopied = () => this.track('traveler_invitation_sharable_link_copied');
  trackTravelerInvitationShareOpened = () => this.track('traveler_invitation_share_opened');
  /**
   * @param {object} data
   * @param {string} data.App_name
   */
  trackTravelerInvitationShared = (data) => this.track('traveler_invitation_shared', data);
  trackTravelerInvitationEmailShared = () => this.track('traveler_invitation_email_shared');

  // ---- Share Receipt tracking ----
  /**
   * @param {object} data
   * @param {string} data.Relationship
   */
  trackDownloadShareReceiptModalView = (data) => this.track('receipt_modal_viewed', data);
  trackShareReceiptEmailSend = (data) => this.track('receipt_email_sent', data);
}

window[WELCOME_MIXPANEL_INSTANCE_NAME] = mixpanel;

export default new MixPanel();
