import React, { Component } from 'react';
import TravelerApi from '@/src/API';
import { parseISO } from 'date-fns';
import { format, isToday } from 'date-fns';
import { getDriver } from '@/src/components/common/Chat/helpers';
import { stringWithEllipsis } from '@/src/components/common/helpers';
import {
  Avatar,
  AvatarWrapper,
  Details,
  Divider,
  DriverIcon,
  DriverIconWrapper,
  DriverName,
  Info,
  LastMessage,
  NotificationBadge,
  Time,
  TransferDetails,
  Wrapper
} from './myChannelPreview.styled';
import { IonSpinner } from '@ionic/react';
import PropTypes from 'prop-types';
import driverIcon from '@/src/assets/icons/driver_icon.svg';
import { inject } from 'mobx-react';

class MyChannelPreview extends Component {
  constructor(props) {
    super(props);

    this._isMounted = false;

    this.state = {
      fetchingTransferData: true,
      fetchedTransferData: false
    };
  }

  parseAndSaveTransferToState(transfer) {
    const transferFromTitle = transfer.fromTitle;
    const transferToTitle = transfer.toTitle;
    const transferFromDateTime = this.transferFromDatetimeTimeFormatted(transfer.fromDatetime);

    this.setState({
      fetchingTransferData: false,
      fetchedTransferData: true,
      transferFromTitle,
      transferToTitle,
      transferFromDateTime
    });
  }

  parseAndSaveTourTransferToState(transfer) {
    const transferFromTitle = transfer.fromTitle;
    const transferToTitle = transfer.title;
    const transferFromDateTime = this.transferFromDatetimeTimeFormatted(transfer.fromDatetime);

    this.setState({
      fetchingTransferData: false,
      fetchedTransferData: true,
      transferFromTitle,
      transferToTitle,
      transferFromDateTime
    });
  }

  onUnableToFetchTransfer() {
    this.setState({
      fetchingTransferData: false,
      fetchedTransferData: false
    });
  }

  componentDidMount() {
    this._isMounted = true;
    const { travelerAppStore, messagesStore, channel } = this.props;

    const service = channel.data.service;
    if (!service) {
      this.onUnableToFetchTransfer();
      return;
    }

    const transferToken = service.token || service.service_token; //TODO: remove_service
    const transferType = service.type || service.service_type; //TODO: remove service

    if (transferToken && messagesStore.failedToFetchTransfers.has(transferToken)) {
      this.onUnableToFetchTransfer();
      return;
    }

    if (transferType === 'Transfer' && transferToken) {
      const foundTransfer =
        messagesStore.getTransferForChannelToken(transferToken) ||
        travelerAppStore.transfers.find((tr) => tr.id === transferToken);
      if (foundTransfer && foundTransfer.transferType !== undefined) {
        messagesStore.saveTransferWithChannelToken(foundTransfer, transferToken);
        this.parseAndSaveTransferToState(foundTransfer);
        return;
      }

      TravelerApi.getTransferById(transferToken)
        .then((transfer) => {
          messagesStore.saveTransferWithChannelToken(transfer, transferToken);
          if (!this._isMounted) {
            return;
          }
          this.parseAndSaveTransferToState(transfer);
        })
        .catch(() => {
          this.onUnableToFetchTransfer();
          messagesStore.failedToFetchTransfers.add(transferToken);
        });
    } else if (transferType === 'TourTransfer' && transferToken) {
      const foundTransfer =
        messagesStore.getTransferForChannelToken(transferToken) ||
        travelerAppStore.transfers.find((tr) => tr.id === transferToken);

      if (foundTransfer && foundTransfer.transferType === undefined) {
        messagesStore.saveTransferWithChannelToken(foundTransfer, transferToken);
        this.parseAndSaveTourTransferToState(foundTransfer);
        return;
      }

      TravelerApi.getTourById(transferToken)
        .then((transfer) => {
          messagesStore.saveTransferWithChannelToken(transfer, transferToken);
          if (!this._isMounted) {
            return;
          }
          this.parseAndSaveTourTransferToState(transfer);
        })
        .catch(() => {
          this.onUnableToFetchTransfer();
        });
    } else {
      this.onUnableToFetchTransfer();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  lastMessageTimeFormatted(channel, datetime) {
    if (!datetime) {
      return null;
    }
    const date = typeof datetime === 'string' ? parseISO(datetime) : datetime;

    if (date.toDateString() === 'Invalid Date') {
      return '';
    }

    if (channel.data.frozen) {
      return format(date, 'd MMM y');
    } else {
      if (isToday(date)) {
        return format(date, 'HH:mm');
      } else {
        return format(date, 'd MMM');
      }
    }
  }

  transferFromDatetimeTimeFormatted(datetime) {
    const date = parseISO(datetime);
    return format(date, 'd MMM, HH:mm');
  }

  render() {
    const { setActiveChannel, channel, active, displayImage, latestMessage, unread } = this.props;
    const hasUnread = unread > 0;
    const driverImage = displayImage;
    const driver = getDriver(channel);
    const driverFirstName = driver?.first_name || driver?.name; // change to first_name
    const lastMessageText = stringWithEllipsis(latestMessage, 25);
    const lastMessageDateTime = this.lastMessageTimeFormatted(channel, channel.state.last_message_at);
    const archived = channel.data.frozen;
    const { fetchingTransferData, fetchedTransferData, transferFromTitle, transferToTitle, transferFromDateTime } =
      this.state;
    const className = `${active ? 'active' : ''} ${archived ? 'archived' : ''}`;

    return [
      <Wrapper
        key={'wrapper-' + channel.id}
        className={className}
        onClick={(e) => {
          setActiveChannel(channel);
        }}
      >
        <AvatarWrapper>
          <Avatar driverAvatar={driverImage} className={className}></Avatar>
          <DriverIconWrapper>
            <DriverIcon>
              <img alt="Driver" src={driverIcon}></img>
            </DriverIcon>
          </DriverIconWrapper>
        </AvatarWrapper>

        <Details>
          <Info>
            <DriverName className={className}>{driverFirstName}</DriverName>
            <Time>
              {hasUnread && <NotificationBadge />}
              <span style={{ color: 'rgba(45,59,78,0.5)' }}>{lastMessageDateTime}</span>
            </Time>
          </Info>

          <LastMessage className={className}>{lastMessageText}</LastMessage>

          {fetchingTransferData ? (
            <IonSpinner name="dots" />
          ) : fetchedTransferData ? (
            <TransferDetails>
              <span>{`${transferFromTitle} ${String.fromCharCode(8594)}`}</span>
              <br />
              <span>{`${stringWithEllipsis(transferToTitle, 20)} - `}</span>
              <span>{transferFromDateTime}</span>
            </TransferDetails>
          ) : null}
        </Details>
      </Wrapper>,
      <Divider key={'divider-' + channel.id} />
    ];
  }
}

MyChannelPreview.propTypes = {
  setActiveChannel: PropTypes.func,
  channel: PropTypes.object,
  active: PropTypes.bool,
  displayImage: PropTypes.string,
  latestMessage: PropTypes.object,
  unread: PropTypes.number,
  travelerAppStore: PropTypes.object,
  messagesStore: PropTypes.object
};

export default inject('travelerAppStore', 'messagesStore')(MyChannelPreview);
