import React, { Fragment } from 'react';
import { IonLabel, IonSkeletonText, IonList } from '@ionic/react';
import { FlexContainer } from '../../styled/Wrappers';

import './SkeletonTextExample.css';
import { StyledItem } from '../../styled/IonElements';

const SkeletonList = ({ history = false, padding }) => (
  <IonList style={{ padding: padding || '0' }}>
    {[1, 2, 3, 4, 5].map((i) => (
      <StyledItem key={i} padding="0" margin={history && '0'}>
        <IonLabel>
          {!history && (
            <Fragment>
              <FlexContainer justify="space-between">
                <IonSkeletonText animated style={{ width: '60px' }} />
                <IonSkeletonText animated style={{ width: '30px' }} />
              </FlexContainer>
              <IonSkeletonText animated style={{ width: '40%', margin: '20px 0' }} />
              <p>
                <IonSkeletonText animated style={{ width: '40%' }} />
              </p>
              <p>
                <IonSkeletonText animated style={{ width: '80%' }} />
              </p>
            </Fragment>
          )}
          <h3>
            <IonSkeletonText animated style={{ width: '50%' }} />
          </h3>
          <p>
            <IonSkeletonText animated style={{ width: '80%' }} />
          </p>
        </IonLabel>
      </StyledItem>
    ))}
  </IonList>
);

export default SkeletonList;
