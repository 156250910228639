import React, { useEffect } from 'react';
import { StyledCard, StyledContent, StyledPage } from '../../../../components/styled/IonElements';
import StickyHeader from '../../../../components/common/StickyHeader';
import { observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import talonOne from '../../../../services/talonOne';
import { t } from 'i18next';
import Transactions from '../../../../components/common/TalonOne/Transactions';
import { useStores } from '../../../../stores/useStores';
import { DesktopFlexContainer, DesktopList, DesktopRightContainer } from '../../../../components/styled/Wrappers';
import LoggedInUser from '../../LoggedInUser';
import mixpanel from '../../../../services/mixpanel';
import Refresher from '@/src/components/common/Refresher';

const TransactionHistory = (props) => {
  const { history } = props;
  const [loading, setLoading] = React.useState(true);
  const [isDataReady, setIsDataReady] = React.useState(false);

  const {
    talonOneStore: { transactions }
  } = useStores();

  const fetchTalonOneTransactions = async () => {
    const transactionsRes = await talonOne.getTransactionsAndInvitations();
    if (transactionsRes.status === 404) {
      await talonOne.updateOrCreateCustomerProfile();
      await talonOne.getTransactionsAndInvitations();
    }
  };

  const doRefresh = async (event) => {
    setLoading(true);
    Promise.all([fetchTalonOneTransactions(), talonOne.getLoyaltyBalances()]);
    setLoading(false);

    event.detail.complete();
  };

  const trackPageView = () => {
    mixpanel.trackTransactionHistoryView({
      Number_Of_Transactions: transactions.data?.length || 0
    });
  };

  useEffect(() => {
    if (isDataReady) {
      if (!transactions.data?.length) {
        history.push('/profile/rewards_and_wallet');
        return;
      }

      trackPageView();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDataReady]);

  useEffect(() => {
    const onMount = async () => {
      if (!transactions.data) {
        await fetchTalonOneTransactions();
      }

      setLoading(false);
      setIsDataReady(true);
    };

    onMount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StyledPage>
      {window.innerWidth < 768 && (
        <StickyHeader
          goBack={() => history.push('/profile/rewards_and_wallet')}
          title={t('profile.transactions')}
          subtitle=""
          noBorder
        />
      )}
      <StyledContent>
        <Refresher onRefresh={doRefresh} />

        <DesktopFlexContainer align="flex-start">
          <DesktopList padding="0">
            <LoggedInUser isSide />
          </DesktopList>

          <DesktopRightContainer>
            <StyledCard margin="10px" mobileMarginTop="50px" padding="38px 40px" mobilePadding="20px">
              <Transactions loading={loading} noTitle />
            </StyledCard>
          </DesktopRightContainer>
        </DesktopFlexContainer>
      </StyledContent>
    </StyledPage>
  );
};

export default withRouter(observer(TransactionHistory));
