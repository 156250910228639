import React from 'react';

const Warning = ({ size = 16, fill = '#616C7A', onClick, className, style }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    onClick={onClick}
    className={className}
    style={style}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.50078 15.2C4.52433 15.2 1.30078 11.9764 1.30078 7.99999C1.30078 4.02354 4.52433 0.799988 8.50078 0.799988C12.4772 0.799988 15.7008 4.02354 15.7008 7.99999C15.7008 11.9764 12.4772 15.2 8.50078 15.2ZM8.50078 10.3568C8.95642 10.3568 9.32578 10.7262 9.32578 11.1818C9.32578 11.6375 8.95642 12.0068 8.50078 12.0068C8.04515 12.0068 7.67578 11.6375 7.67578 11.1818C7.67578 10.7262 8.04515 10.3568 8.50078 10.3568ZM8.50078 4.15995C8.88739 4.15995 9.20078 4.47334 9.20078 4.85995V8.90679C9.20078 9.29339 8.88739 9.60678 8.50078 9.60678C8.11417 9.60678 7.80078 9.29339 7.80078 8.90679V4.85995C7.80078 4.47334 8.11417 4.15995 8.50078 4.15995Z"
      fill={fill}
    />
  </svg>
);

export default Warning;
