import { observer } from 'mobx-react';
import React from 'react';
import { useStores } from '../../../../stores/useStores';
import styled from 'styled-components';

const SightSeeingImage = () => {
  const {
    travelerAppStore: { chosenTransfer }
  } = useStores();

  return (
    <StyledDiv>
      <img src={chosenTransfer.mainPhotoUrls?.view2X} alt="sightseeing" />
    </StyledDiv>
  );
};

export default observer(SightSeeingImage);

const StyledDiv = styled.div`
  width: 100%;
  height: 240px;
  position: relative;

  &::after {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background: rgba(22, 35, 54, 0.2);
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;
