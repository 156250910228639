import React from 'react';
import { IonSkeletonText, IonCardContent, IonRow, IonCol } from '@ionic/react';

import './SkeletonTextExample.css';

const SkeletonWidget = () => (
  <IonCardContent style={{ padding: '32px' }}>
    {/* City selector */}
    <div style={{ marginBottom: '20px' }}>
      <IonSkeletonText animated style={{ height: '48px' }} />
    </div>

    {/* From/To */}
    <div style={{ marginBottom: '16px' }}>
      <IonSkeletonText animated style={{ height: '48px', marginBottom: '1px' }} />
      <IonSkeletonText animated style={{ height: '48px' }} />
    </div>

    {/* Date/Time */}
    <IonRow style={{ marginTop: '32px' }}>
      <IonCol style={{ padding: '0', marginRight: '16px' }}>
        <IonSkeletonText animated style={{ height: '14px', width: '100px', marginBottom: '12px' }} />
        <IonSkeletonText animated style={{ height: '48px' }} />
      </IonCol>
      <IonCol style={{ padding: '0', marginLeft: '16px' }}>
        <IonSkeletonText animated style={{ height: '14px', width: '100px', marginBottom: '12px' }} />
        <IonSkeletonText animated style={{ height: '48px' }} />
      </IonCol>
    </IonRow>

    {/* Passengers/Luggage */}
    <IonRow style={{ marginTop: '16px' }}>
      <IonCol style={{ padding: '0', marginRight: '16px' }}>
        <IonSkeletonText animated style={{ height: '14px', width: '100px', marginBottom: '12px' }} />
        <IonSkeletonText animated style={{ height: '48px' }} />
      </IonCol>
      <IonCol style={{ padding: '0', marginLeft: '16px' }}>
        <IonSkeletonText animated style={{ height: '14px', width: '100px', marginBottom: '12px' }} />
        <IonSkeletonText animated style={{ height: '48px' }} />
      </IonCol>
    </IonRow>

    {/* Submit */}
    <div style={{ marginTop: '16px' }}>
      <IonSkeletonText animated style={{ height: '60px' }} />
    </div>
  </IonCardContent>
);

export default SkeletonWidget;
