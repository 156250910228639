import { isWebPlatform, isMobileAppPlatform, debugConsoleLog } from '../components/common/helpers';
import AppboyPlugin from 'appboy-cordova-sdk/www/AppboyPlugin.js';
import stores from '../stores';

const { contentCardsStore, travelerAppStore } = stores;

class Braze {
  constructor() {
    // Here we should import the correct sdk for web, android or ios.
    this.braze = require('@braze/web-sdk');
    this.isBrazeInitialized = false;
    this.refreshContentCardsIntervalId = null;
  }

  // [Web] Initializes the brake sdk.
  initialize = () => {
    if (isWebPlatform()) {
      this.isBrazeInitialized = this.braze.initialize(process.env.REACT_APP_BRAZE_WEB_API_KEY, {
        baseUrl: process.env.REACT_APP_BRAZE_SDK_ENDPOINT
      });
      this.openSession();
    }

    return this.isBrazeInitialized;
  };

  // [Web] Initializes a braze session.
  openSession = () => {
    if (this.isBrazeInitialized) {
      return this.braze.openSession();
    }
  };

  // [Web] Subscribes to content cards changes and saves them to the store.
  subscribeToContentCardsUpdates = () => {
    if (this.isBrazeInitialized && travelerAppStore.isAuthorised && !contentCardsStore.contentCardsSubscriptionId) {
      const token = this.braze.subscribeToContentCardsUpdates((res) => {
        // [Web] When new content cards are available we save them to the store.
        contentCardsStore.setContentCards(res.cards);
      });

      contentCardsStore.setContentCardsSubscriptionId(token);
    }
  };

  // Saves the correct user id after login.
  changeUser = (userId) => {
    if (isWebPlatform() && this.isBrazeInitialized) {
      return this.braze.changeUser(userId);
    }

    if (isMobileAppPlatform()) {
      return AppboyPlugin.changeUser(userId);
    }
  };

  // [Web] Shows braze content cards on a specific html element.
  showContentCards = (parentNode, filterFunction) => {
    if (this.isBrazeInitialized) {
      this.braze.showContentCards(parentNode, filterFunction);
    }
  };

  /**
   * [Web] Handles the fetching and displaying of content cards.
   * @param {string} parentNodeName
   * @param {Function} filter
   */
  renderContentCards = (parentNodeName, filter) => {
    if (isWebPlatform()) {
      setTimeout(() => {
        const contentCardContainer = document.getElementById(parentNodeName);
        if (contentCardContainer) {
          this.showContentCards(contentCardContainer, (cards) => cards.filter(filter));
        }
      }, 500);
    }
  };

  // [Mobile] Fetches the content cards from the server and saves it to the travelerAppStore.
  getContentCardsFromServer = async () => {
    if (isMobileAppPlatform() && travelerAppStore.isAuthorised) {
      return await AppboyPlugin.getContentCardsFromServer(
        (res) => contentCardsStore.setContentCards(res),
        (error) => {
          if (travelerAppStore.rollbar) {
            travelerAppStore.rollbar.error('Content Cards Error', error);
          }
          console.log('Content Cards Error', error);
        }
      );
    }
  };

  /**
   * [Mobile & Web] Refreshes content cards every given seconds
   * @param {number} time // Time in milliseconds. Default is 10000ms (10 seconds)
   */
  refreshContentCardsPolling = (time = 10000) => {
    this.refreshContentCardsIntervalId = setInterval(() => {
      if (travelerAppStore.isAuthorised) {
        if (isMobileAppPlatform()) {
          this.getContentCardsFromServer();
        } else if (this.isBrazeInitialized) {
          this.braze.requestContentCardsRefresh();
        }
        debugConsoleLog('Content cards refreshed');
      }
    }, time);
  };

  clearRefreshContentCardsPolling = () => {
    if (this.refreshContentCardsIntervalId) {
      clearInterval(this.refreshContentCardsIntervalId);
      this.refreshContentCardsIntervalId = null;
    }
  };

  // [Mobile] Triggers a content card click event
  logContentCardClicked = (cardId) => {
    if (isMobileAppPlatform()) {
      return AppboyPlugin.logContentCardClicked(cardId);
    }
  };

  // [Mobile] Triggers a content card impression event
  logContentCardImpression = (cardId) => {
    if (isMobileAppPlatform()) {
      return AppboyPlugin.logContentCardImpression(cardId);
    }
  };

  // [Mobile] Triggers a content card dismiss event
  logContentCardDismissed = (cardId) => {
    if (isMobileAppPlatform()) {
      return AppboyPlugin.logContentCardDismissed(cardId);
    }
  };

  // Triggers a custom event
  logCustomEvent = (eventName, properties = {}) => {
    if (isWebPlatform() && this.isBrazeInitialized) {
      this.braze.logCustomEvent(eventName, properties);
      this.braze.requestImmediateDataFlush(); // Sends the data to the server immediately
    } else if (isMobileAppPlatform()) {
      AppboyPlugin.logCustomEvent(eventName, properties);
      AppboyPlugin.requestImmediateDataFlush(); // Sends the data to the server immediately
    }
  };

  reset = () => {
    const _contentCardsSubscriptionId = contentCardsStore.contentCardsSubscriptionId;

    if (isWebPlatform() && this.isBrazeInitialized && _contentCardsSubscriptionId) {
      this.braze.removeSubscription(_contentCardsSubscriptionId);
      contentCardsStore.setContentCardsSubscriptionId(null);
    }

    this.clearRefreshContentCardsPolling();

    contentCardsStore.removeContentCards();
  };
}

export default new Braze();
