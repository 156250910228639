import React from 'react';
import { PhoneInputStyled } from './index.styled';
import PropTypes from 'prop-types';

const PhoneInput = (props) => {
  const {
    country = 'gr',
    autoFormat = false,
    pattern = '\d*',
    buttonStyle = { background: 'white' },
    error,
    ...restProps
  } = props;

  return (
    <PhoneInputStyled
      country={country}
      autoFormat={autoFormat}
      buttonStyle={buttonStyle}
      pattern={pattern}
      error={error}
      {...restProps}
    />
  );
};

PhoneInput.propTypes = {
  country: PropTypes.string,
  autoFormat: PropTypes.bool,
  pattern: PropTypes.string,
  buttonStyle: PropTypes.object,
  error: PropTypes.string
};

export default PhoneInput;
