import theme from '@/src/theme';
import styled from 'styled-components';

export const InputStyled = styled.input`
  padding: ${(props) => props.padding || '14px 16px'};
  margin: ${(props) => props.margin || '0'};
  width: 100%;

  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  text-overflow: ellipsis;
  color: ${(props) => (props.error ? 'rgba(242,116,112,1)' : props.textColor || theme.colors.base.graphite)};

  outline: none;
  border: 1px solid ${theme.colors.greys.grey15};
  border-radius: 4px;
  border: ${(props) => (props.error ? '2px solid rgba(242,116,112,1)' : `1px solid ${theme.colors.greys.grey15}`)};

  ::placeholder {
    color: ${theme.colors.greys.grey5};
    display: flex;
    flex-direction: column;
  }

  ${({ readOnly }) =>
    !readOnly &&
    `
    &:focus {
    border: 1px solid ${theme.colors.base.graphite};
  }
  `}
`;
