import styled from 'styled-components';
import theme from '../../../../theme';

export const OuterContainer = styled.div`
  display: flex;
  gap: 16px;
  justify-content: center;
`;

export const InnerContainer = styled.div`
  display: flex;
  gap: 16px;
  justify-content: space-between;
  align-items: start;
  flex: 1;
`;

export const LocationInfoStyled = styled.div`
  flex: 1 !important;
  margin-bottom: ${(props) => props.marginBottom || '0'};
  justify-content: center;
  cursor: ${(props) => (props.isLink ? 'pointer' : 'inherit')};
`;

export const LocationIcon = styled.img`
  min-width: 20px;
  max-width: 20px;
`;

export const Line = styled.div`
  width: 1px;
  background: ${theme.colors.greys.grey2};

  min-height: 24px;
  margin-left: 9px;
  height: calc(100% - 10px);
`;
