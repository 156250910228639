import React, { useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import noMessagesIcon from '@/src/assets/icons/no_messages_icon.svg';
import mixpanel from '@/src/services/mixpanel';
import { useStores } from '@/src/stores/useStores';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { NoMessagesContainer, NoMessagesSubtitle, NoMessagesTitle } from './noMessages.styled';

const NoMessages = (props) => {
  const { t } = props;

  const {
    messagesStore: { activeChannels, messagesReady }
  } = useStores();
  const _messagesReady = messagesReady;

  useEffect(() => {
    if (_messagesReady && !activeChannels.length) {
      mixpanel.trackUserWithNoActiveChats();
    }
  }, [activeChannels.length, _messagesReady]);

  return (
    <NoMessagesContainer>
      <img alt="No Messages" src={noMessagesIcon} />
      <NoMessagesTitle>{t('messages.no_messages_title')}</NoMessagesTitle>
      <NoMessagesSubtitle>{t('messages.no_messages_message')}</NoMessagesSubtitle>
    </NoMessagesContainer>
  );
};

NoMessages.propTypes = {
  t: PropTypes.func
};

export default withTranslation()(observer(NoMessages));
