import { IonImg, IonItem } from '@ionic/react';
import React, { Fragment } from 'react';
import styled from 'styled-components';
import { dateLocale } from '../../../../components/common/helpers';
import AutoCheckErrors from '../../../../components/common/autoCheckErrors';
import { Text3 } from '../../../../components/styled/Typography';
import { FlexContainer } from '../../../../components/styled/Wrappers';
import { useStores } from '../../../../stores/useStores';
import theme from '../../../../theme';
import GoToLayout from '../GoToLayout';
import TransferPoint from './TransferPoint';
import combinationIcon from '../../../../assets/icons/combination.svg';
import { onExternalLinkClick } from '../../../../components/common/ExternalLink/ExternalLink';
import { observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import SplittedTransferSection from './SplittedTransferSection';
import { useRouteMatch } from 'react-router-dom';
import mixpanel from '../../../../services/mixpanel';

const chooseRightData = (firstData, secondData) => firstData || secondData;

const TransferCard = ({
  t,
  i18n,
  isSideMenu = false,
  showErrorsInDetails = false,
  alertsAreShownIn = 'Transfers',
  transfer,
  handleClick,
  chosen,
  arrowVisible,
  isTourDetailsButtonVisible,
  transferIndex = false,
  errors
}) => {
  const {
    travelerAppStore: { transfers, chosenTransfer, transfersAutocheckErrors, removeTransferError },
    brandedSettingsStore: {
      brandedSettings: {
        coBrandingThemeProperties: { mainColor }
      }
    }
  } = useStores();
  const showGreenBorder = chosenTransfer.id === transfer.id && isSideMenu;
  const showBorderBottom = transferIndex + 1 < transfers.length;
  const isSride = typeof transfer.transferType === 'undefined';
  const sidebarPadding = showGreenBorder ? '0 17px ' : '0 20px';
  const customPadding = isSideMenu ? sidebarPadding : '0';
  const isDetailsPage = useRouteMatch('/trip/:tripId/transfer/:transferId');
  const isTripPageWithId = useRouteMatch('/trip/:tripId');
  const isTripPage = useRouteMatch('/trip');
  const showSplittedTransfers =
    (isSideMenu && isDetailsPage) || (isTripPageWithId && isTripPageWithId?.isExact) || isTripPage?.isExact;

  const sidebarCheck = isSideMenu; // isDetailsPage && isDetailsPage.isExact ? true : false

  return (
    <Fragment>
      <StyledListItem
        showGreenBorder={showGreenBorder}
        customPadding={customPadding}
        lines="none"
        onClick={() => {
          mixpanel.trackTransferDetailsClick({ Clicked_Item: 'section' });
          handleClick();
        }}
        chosen={chosen}
        arrowVisible={arrowVisible}
        isTourDetailsButtonVisible={isTourDetailsButtonVisible}
        color={mainColor}
      >
        <div style={{ width: '100%' }}>
          {chosen && <div className="chosenMarker" />}
          <div className="go-to-wrapper">
            <GoToLayout
              onClickFunc={(e) => {
                e.stopPropagation();
                mixpanel.trackTransferDetailsClick({ Clicked_Item: 'details button' });
                handleClick();
              }}
              arrowVisible={arrowVisible}
              textContent={isSideMenu ? '' : t('my_trip.details')}
            >
              <span className="dateTitle">
                {transfer.fromDatetime &&
                  dateLocale(transfer.fromDatetime, transfer.timeZone, 'd MMM, iii', i18n.language)}
              </span>
            </GoToLayout>
            {isSride && <span className="sightseeing-ride-title">{t('explore.sightseeing_rides')}</span>}
            <Text3 color="grey5">
              {t('my_trip.order')} #{transfer.orderNameId}
            </Text3>
          </div>
          {showErrorsInDetails && (
            <AutoCheckErrors
              isDetailsPage={sidebarCheck}
              transferId={transfer.id}
              alertsAreShownIn={alertsAreShownIn}
              autocheckErrorList={transfersAutocheckErrors[transfer.id]}
              editUrl={transfer.editLink}
              removeError={removeTransferError}
              errors={errors}
            />
          )}
          <div className="transfer-points-wrapper">
            <TransferPoint
              title={transfer.fromTitle === null ? transfer.fromAddress.split(', ')[0] : transfer.fromTitle}
              subtitle={transfer.fromAddress}
              isSideMenu={isSideMenu}
              transfer={transfer}
              date={
                transfer.fromDatetime && dateLocale(transfer.fromDatetime, transfer.timeZone, 'HH:mm', i18n.language)
              }
            />
            <TransferPoint
              title={chooseRightData(transfer.toTitle, transfer.title)}
              subtitle={transfer.toAddress}
              date=""
            />
          </div>
          {transfer.splittedTransferId && transfer.splittedTransferId !== undefined && showSplittedTransfers && (
            <SplittedTransferSection
              handleClick={handleClick}
              splittedTransferId={transfer.splittedTransferId}
              transfer={transfer}
            />
          )}
        </div>
      </StyledListItem>
      {isTourDetailsButtonVisible && (
        <StyledTourDetailsButton className="tour-details-button-wrapper">
          <TourDetailsButton
            href={transfer.tourLink}
            target="_blank"
            onClick={(e) => onExternalLinkClick(e, transfer.tourLink)}
          >
            <CombinationIcon src={combinationIcon} />

            {t('tour_details.itinerary_and_details')}
          </TourDetailsButton>
        </StyledTourDetailsButton>
      )}
      <TransfersDivider showBorderBottom={showBorderBottom} />
    </Fragment>
  );
};

const TransfersDivider = styled.div`
  margin: 0 20px;
  border-bottom: ${(props) => props.showBorderBottom && `1px solid ${theme.colors.greys.grey15}`};
`;

const StyledListItem = styled(IonItem)`
  position: relative;
  list-style: none;
  padding: ${(props) => props.customPadding};
  border-left: ${(props) => (props.showGreenBorder ? `3px solid ${props.color}` : 'none')};
  margin: 20px 0;

  :last-child {
    padding-bottom: 0;
    border-bottom: unset;
    margin-bottom: 0;

    ::after {
      display: none;
    }
  }
  .chosenMarker {
    position: absolute;
    width: 3px;
    height: 100%;
    border-radius: 0 2px 2px 0;
    background-color: ${(props) => props.color};
    left: -32px;
  }
  .go-to-wrapper {
    margin-bottom: 24px;
    margin-top: 24px;
  }
  .dateTitle {
    display: inline-block;
    font-weight: 700;
    color: ${theme.colors.base.graphite};
    font-size: 20px;
    line-height: 26px;
    margin: 0 0 0 0;
  }
  .sightseeing-ride-title {
    color: ${theme.colors.greys.grey5};
    font-size: 14px;
    line-height: 21px;
    text-align: right;
    white-space: nowrap;
  }
`;

const TourDetailsButton = styled.a`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  padding: 0 24px;
  max-width: 300px;
  min-height: 48px;
  border: 1px solid ${theme.colors.greys.grey15};
  border-radius: 4px;
  margin-bottom: 20px;
  color: ${theme.colors.base.graphite};
  font-weight: 700;
  font-size: 14px;
`;

const CombinationIcon = styled(IonImg)`
  width: 20px;
  height: 20px;
  margin-right: 10px;
`;

const StyledTourDetailsButton = styled(FlexContainer)`
  justify-content: center;
  margin-top: 24px΄;
`;

export default withTranslation()(observer(TransferCard));
