import React, { Component } from 'react';
import styled from 'styled-components';
import rightWhiteArrow from '../../assets/icons/rightWhiteArrow.svg';
import { withLoading } from '../../components/HOCs';
import { ButtonBase } from '../../components/styled/Buttons';
import theme from '../../theme';
import { inject, observer } from 'mobx-react';
import { IonImg } from '@ionic/react';

const ButtonContainer = styled.div`
  position: absolute;
  border-radius: 4px;
  background-color: ${(props) => props.color};
  top: 8px;
  right: 9px;
  border-radius: 4px;
  height: 35px;
  width: 35px;
`;

const ArrowIcon = styled(IonImg)`
  position: absolute;
  top: 8px;
  width: 20px;
  left: 8px;
  cursor: pointer;
`;

const Continue = styled(ButtonBase)`
  width: 100%;
  height: 100% !important;
  color: ${theme.colors.base.white};
  font-size: 16px;
  font-weight: 700;
  outline: none;
  white-space: normal !important;
  height: auto;
  word-break: break-word;
  display: flex;
  align-items: center;
`;

const ContinueButtonWithLoading = withLoading(Continue, {
  spinnerColor: '#FFF',
  backgroundColor: `${(props) => props.bgcolor}`,
  color: 'white',
  minWidth: '32px !important',
  maxWidth: '32px !important',
  width: '32px !important',
  containerStyles: {
    position: 'sticky',
    padding: '0',
    width: '32px',
    height: '32px',
    borderRadius: '4px',
    boxSizing: 'border-box',
    marginTop: '20px',
    '@media(max-width: 769px)': {
      height: '32px',
      width: '32px',
      borderRadius: '4px'
    }
  },
  spinnerStyles: {
    width: '18px',
    height: '18px',
    backgroundColor: `${(props) => props.bgcolor} !important`,
    size: '32px'
  }
});

// const sendGetRequest = () => {

//     return axios({
//       url: URL,
//       method: 'get'
//     }).then(response => {

//       console.log(response);
//       return response.data;
//     })
//   };

class ContinueButton extends Component {
  render() {
    const {
      onContinuePress,
      error,
      disabled,
      travelerAppStore: { authEndpointProcessing },
      brandedSettingsStore: {
        brandedSettings: {
          coBrandingThemeProperties: { mainColor }
        }
      }
    } = this.props;

    return (
      <ButtonContainer color={mainColor}>
        <ContinueButtonWithLoading
          size="sm"
          href="#"
          onClick={onContinuePress}
          isLoading={authEndpointProcessing}
          disabled={disabled && 'disabled'}
          bgcolor={mainColor}
          css={{
            minWidth: '32px',
            display: 'flex',
            alignItems: 'center',
            backgroundColor: error ? 'rgba(242,116,112,1)' : mainColor
          }}
        ></ContinueButtonWithLoading>
        {!authEndpointProcessing && <ArrowIcon onClick={onContinuePress} alt="" src={rightWhiteArrow} />}
      </ButtonContainer>
    );
  }
}

export default inject('travelerAppStore', 'brandedSettingsStore')(observer(ContinueButton));
