import React from 'react';
import PropTypes from 'prop-types';
import { Text2, Text3 } from '../../../styled/Typography';
import { InnerContainer, Line, LocationIcon, LocationInfoStyled, OuterContainer } from './LocationInfo.styled';
import theme from '../../../../theme';
import AirportIcon from '../../../../assets/icons/airport-icon.svg';
import PortIcon from '../../../../assets/icons/port-icon.svg';
import AddressIcon from '../../../../assets/icons/address-icon.svg';
import HotelIcon from '../../../../assets/icons/hotel-icon.svg';
import { dateLocale } from '../../helpers';
import i18n from '../../../../services/i18n';
import { FlexContainer } from '../../../styled/Wrappers';
import ArrowRight from '../../../svgs/ArrowRight';
import { useStores } from '../../../../stores/useStores';
import { observer } from 'mobx-react';
import LocationInfoWrapper from './LocationInfoWrapper';

const LocationInfo = (props) => {
  const {
    from = false,
    title = '',
    address = '',
    kind = '',
    dateTime = '',
    timeZone = '',
    showDate = false,
    showLine = false,
    marginBottom = '20px',
    href = ''
  } = props;

  const {
    brandedSettingsStore: {
      brandedSettings: {
        coBrandingThemeProperties: { mainColor }
      }
    }
  } = useStores();

  const renderIcon = (kind) => {
    switch (kind) {
      case 'Hotel':
        return HotelIcon;
      case 'Airport':
        return AirportIcon;
      case 'Port':
        return PortIcon;
      default:
        return AddressIcon;
    }
  };

  return (
    <LocationInfoWrapper href={href}>
      <LocationInfoStyled marginBottom={marginBottom} isLink={href}>
        <OuterContainer>
          <div>
            <LocationIcon src={renderIcon(kind)} alt="location kind" className="location-icon" />
            {from && showLine && <Line />}
          </div>

          <InnerContainer>
            <div style={{ flex: 1 }}>
              <Text2 weight="400" margin="0 0 5px 0">
                {title}
              </Text2>
              <FlexContainer align="center" justify="space-between" gap="8px">
                <Text3 margin="0px 0 10px 0" color={theme.colors.base.graphiteLighter} style={{ flexBasis: '100%' }}>
                  {address}
                </Text3>
                {href && <ArrowRight fill={mainColor} style={{ flexBasis: '20px' }} />}
              </FlexContainer>
            </div>

            {/* Show dateTime only when it is a "from location" */}
            {from && dateTime && (
              <Text3 color={theme.colors.base.graphiteLighter}>
                {dateLocale(dateTime, timeZone, showDate ? 'dd MMM, HH:mm' : 'HH:mm', i18n.language)}
              </Text3>
            )}
          </InnerContainer>
        </OuterContainer>
      </LocationInfoStyled>
    </LocationInfoWrapper>
  );
};

LocationInfo.propTypes = {
  from: PropTypes.bool, // Indicates if it is "from" location
  title: PropTypes.string,
  address: PropTypes.string,
  kind: PropTypes.string,
  dateTime: PropTypes.string,
  timeZone: PropTypes.string,
  showDate: PropTypes.bool, // In trips with multiple transfers, show the the full date time instead of just the time
  showLine: PropTypes.bool, // Show line between "from" and "to" locations
  marginBottom: PropTypes.string,
  href: PropTypes.string
};

export default observer(LocationInfo);
