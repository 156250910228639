import theme from '@/src/theme';
import styled from 'styled-components';

export const StyledOtpInput = styled.input`
  height: 40px !important;
  width: 30px !important;
  font-size: 16px;
  border-radius: 4px;
  color: #2d3b4e;
  border: 1px solid #d5d8dc;
  font-family: inherit;
  outline: none;

  &:focus {
    zindex: 100;
    border-color: ${theme.colors.base.graphite};
  }
`;
