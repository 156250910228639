import React, { useEffect } from 'react';
import { StyledCard, StyledContent, StyledPage } from '../../../../components/styled/IonElements';
import StickyHeader from '../../../../components/common/StickyHeader';
import { observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import talonOne from '../../../../services/talonOne';
import { t } from 'i18next';
import Invitations from '../../../../components/common/TalonOne/Invitations';
import { useStores } from '../../../../stores/useStores';
import { DesktopFlexContainer, DesktopList, DesktopRightContainer } from '../../../../components/styled/Wrappers';
import LoggedInUser from '../../LoggedInUser';
import mixpanel from '../../../../services/mixpanel';
import Refresher from '@/src/components/common/Refresher';

const TransactionHistory = (props) => {
  const { history } = props;
  const [loading, setLoading] = React.useState(true);
  const [isDataReady, setIsDataReady] = React.useState(false);

  const {
    talonOneStore: { invitations }
  } = useStores();

  const fetchTalonOneInvitations = async () => {
    const invitationsRes = await talonOne.getTransactionsAndInvitations();
    if (invitationsRes.status === 404) {
      await talonOne.updateOrCreateCustomerProfile();
      await talonOne.getTransactionsAndInvitations();
    }
  };

  const doRefresh = async (event) => {
    setLoading(true);
    Promise.all([fetchTalonOneInvitations(), talonOne.getLoyaltyBalances()]);
    setLoading(false);

    event.detail.complete();
  };

  const trackPageView = () => {
    mixpanel.trackInvitationHistoryView({
      Number_Of_Invitations: invitations.data?.length || 0
    });
  };

  useEffect(() => {
    if (isDataReady) {
      if (!invitations.data?.length) {
        history.push('/profile/refer_a_friend');
        return;
      }

      trackPageView();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDataReady]);

  useEffect(() => {
    const onMount = async () => {
      if (!invitations.data) {
        await fetchTalonOneInvitations();
      }

      setLoading(false);
      setIsDataReady(true);
    };

    onMount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StyledPage>
      {window.innerWidth < 768 && (
        <StickyHeader
          goBack={() => history.push('/profile/refer_a_friend')}
          title={t('profile.invitation_history')}
          subtitle=""
          noBorder
        />
      )}
      <StyledContent>
        <DesktopFlexContainer align="flex-start">
          <Refresher onRefresh={doRefresh} />
          <DesktopList padding="0">
            <LoggedInUser isSide />
          </DesktopList>

          <DesktopRightContainer>
            <StyledCard margin="10px" mobileMarginTop="50px" padding="38px 40px" mobilePadding="20px">
              <Invitations loading={loading} noTitle />
            </StyledCard>
          </DesktopRightContainer>
        </DesktopFlexContainer>
      </StyledContent>
    </StyledPage>
  );
};

export default withRouter(observer(TransactionHistory));
