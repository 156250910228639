import TravelerApi from '../API';
import stores from '../stores';
import history from '../components/common/history';
import { Device } from '@capacitor/device';
import { PushNotifications } from '@capacitor/push-notifications';
import { isAndroidPlatform, isIosPlatform } from '../components/common/helpers';
import { logError } from '../helpers/log';

const { travelerAppStore, messagesStore } = stores;

/**
 * Redirects the user to the link contained in the notification.
 * If it is an external link, it will open it in the browser.
 * @param {object} notification
 */
const redirect = (notification) => {
  const genericUrl = notification?.data?.open_url;
  const brazeUrl = notification?.data?.ab_uri;
  const url = genericUrl || brazeUrl;
  const separator = url.includes('traveler_app') ? 'traveler_app' : 'traveler-app:/';

  if (url) {
    // Redirect to internal url
    if (url.includes(separator)) {
      const internalUrl = url.split(separator).pop();

      setTimeout(() => {
        history.push(internalUrl, { fetch: true });
      }, 500);
    }
    // Open external url in browser
    else {
      window.open(url, '_blank').focus();
    }
  }
};

class PushNotificationsService {
  register = () => {
    // Request permission to use push notifications
    // iOS will prompt user and return if they granted permission or not
    // Android will just grant without prompting
    PushNotifications.requestPermissions().then((result) => {
      if (result.receive === 'granted') {
        // Register with Apple / Google to receive push via APNS/FCM
        // console.log("PushNotifications: Request granted");
        PushNotifications.register();
      } else {
        // Show some error
      }
    });

    PushNotifications.addListener('registration', (token) => {
      console.log(token);
      console.log('Push registration success, token: ' + token.value);
      // console.log(`PushNotifications: Registered with token: ${token.value}`);
      // saves firebase token to store
      // calls API to register device
      this.onRegisterSuccess(token.value);
    });

    PushNotifications.addListener('registrationError', (error) => {
      // console.log('Error on registration: ', JSON.stringify(error));
      // TODO: log error on rollbar;
    });

    // Method called when receiving a notification when app is opened
    PushNotifications.addListener('pushNotificationReceived', (notification) => {
      console.log('Push received: ', JSON.stringify(notification));
      redirect(notification);
    });
    // Method called when tapping on a notification.
    // When app is closed, this method is called after FirebaseDynamicLinks.getDynamicLink() is called.
    PushNotifications.addListener('pushNotificationActionPerformed', (pushNotificationActionPerformed) => {
      console.log('Push action performed: ', JSON.stringify(pushNotificationActionPerformed));
      redirect(pushNotificationActionPerformed.notification);
    });
  };

  onRegisterSuccess = async (firebaseToken) => {
    // TODO: return if same token is already saved in store
    //  TODO: save in store only after registering device in API
    travelerAppStore.firebaseToken = firebaseToken;
    messagesStore.registerDevice(firebaseToken);

    if (!travelerAppStore.isAuthorised) {
      return true;
    }

    let uid = null;
    let device_type = null;

    uid = (await Device.getId()).identifier;

    if (isIosPlatform()) {
      device_type = 'ios';
    } else if (isAndroidPlatform()) {
      device_type = 'android';
    }

    console.log('PushNotificationsService: Will register device:', uid, firebaseToken, device_type);
    return TravelerApi.registerDevice(uid, firebaseToken, device_type)
      .then((data) => {
        // on success
        // save traveler info to storage
        // save traveler info to store
        console.log('Registered device to BE:', uid, firebaseToken, device_type);
        return true;
      })
      .catch((err) => {
        logError('Register device error', err, { uid, firebaseToken, device_type });
        return false;
      });
  };

  unregister = async () => {
    PushNotifications.removeAllListeners();
    travelerAppStore.firebaseToken = '';

    if (!travelerAppStore.isAuthorised) {
      return true;
    }

    let uid = null;
    let device_type = null;

    uid = (await Device.getId()).identifier;

    if (isIosPlatform()) {
      device_type = 'ios';
    } else if (isAndroidPlatform()) {
      device_type = 'android';
    }
    console.log('PushNotificationsService: Will unregister device:', uid, null, device_type);
    return TravelerApi.registerDevice(uid, null, device_type)
      .then((data) => {
        // on success
        // save traveler info to storage
        // save traveler info to store
        console.log('UnRegistered device from BE:', uid, null, device_type);
        return true;
      })
      .catch((err) => {
        logError('Unregister device error', err);
        return false;
      });
  };
}
export default new PushNotificationsService();
