import { css } from 'styled-components';

/**
 * Applies a CSS style to truncate text to a specific number of lines and adding ellipsis at the end
 * @param {number} lines
 * @param {string} lineHeight
 * @param {boolean} returnObject
 */
const truncateLines = (lines = 3, lineHeight = '1.4em', returnObject = false) => {
  if (returnObject) {
    return {
      display: '-webkit-box',
      WebkitLineClamp: lines, // Proper camelCase for -webkit-line-clamp
      WebkitBoxOrient: 'vertical', // Proper camelCase for -webkit-box-orient
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      lineHeight: lineHeight,
      maxHeight: `calc(${lineHeight} * ${lines})`
    };
  }

  return css`
    display: -webkit-box;
    -webkit-line-clamp: ${lines};
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: ${lineHeight};
    max-height: calc(${lineHeight} * ${lines});
  `;
};

export default truncateLines;
