import { observer, inject } from 'mobx-react';
import { withTranslation } from 'react-i18next';

const Timer = ({
  t,
  travelerAppStore: {
    secondsUntilResend: { value = 0 }
  }
}) => {
  const hours = Math.floor(value / 3600);
  const minutes = Math.floor((value - hours * 3600) / 60);
  const seconds = value - hours * 3600 - minutes * 60;

  // Add leading zeros so the timer is always 2 digits
  const hoursStr = hours.toString().padStart(2, '0');
  const minutesStr = minutes.toString().padStart(2, '0');
  const secondsStr = seconds.toString().padStart(2, '0');

  const time = hours > 0 ? `${hoursStr}:${minutesStr}:${secondsStr}` : `${minutesStr}:${secondsStr}`;

  return `${t('sign_in.resend_in')} ${time}`;
};

export default withTranslation()(inject('travelerAppStore')(observer(Timer)));
