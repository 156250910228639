import React from 'react';

const PoweredBy = ({ styles, fill, opacity }) => (
  <svg styles={styles} width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
    <path
      fill={fill || '#2D3B4E'}
      opacity={opacity}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17 2V16C17 17.1046 16.1046 18 15 18H5C3.89543 18 3 17.1046 3 16V2L4.9895 3L7.47739 2L9.96527 3L12.4532 2L14.941 3L17 2ZM12.4532 4.15552L9.96527 5.15552L7.47739 4.15552L5 5.1513V16H15V5.17921L12.4532 4.15552ZM12.0092 12C12.5173 12 12.9361 12.3827 12.9933 12.8827L13 13C13 13.5523 12.549 14 12.0092 14H7.99078C7.44359 14 7 13.5561 7 13C7 12.4477 7.45098 12 7.99078 12H12.0092ZM12.0092 9C12.5173 9 12.9361 9.38272 12.9933 9.88267L13 10C13 10.5523 12.549 11 12.0092 11H7.99078C7.44359 11 7 10.5561 7 10C7 9.44772 7.45098 9 7.99078 9H12.0092ZM12.0092 6C12.5173 6 12.9361 6.38272 12.9933 6.88267L13 7C13 7.55228 12.549 8 12.0092 8H7.99078C7.44359 8 7 7.55614 7 7C7 6.44772 7.45098 6 7.99078 6H12.0092Z"
    />
  </svg>
);

export default PoweredBy;
