import React, { Fragment } from 'react';
import { IonContent, IonFooter, IonHeader } from '@ionic/react';
import { withRouter, Route } from 'react-router-dom';
import MainMenu from '../menus/MainMenu/index.js';
import useWindowSize from '../../hooks/useWindowSize.js';
import theme from '../../theme/index.js';
import { Switch } from 'react-router-dom';
import Routes from '../../routes.js';
import loginRoutes from '../../pages/Login/routes';

const AppContent = () => {
  const windowSize = useWindowSize();

  const renderMainContent = () => {
    return (
      <Switch>
        {loginRoutes.map((route) => (
          <Route key={route.path} {...route} />
        ))}
        <Routes />
      </Switch>
    );
  };

  return (
    <Fragment>
      {windowSize.width > theme.breakpoints[1] && (
        <IonHeader className="ion-no-border" style={{ boxShadow: '0px 2px 4px 0px #2D3B4E0F' }}>
          <MainMenu />
        </IonHeader>
      )}

      <IonContent>{renderMainContent()}</IonContent>

      {windowSize.width <= theme.breakpoints[1] && (
        <IonFooter
          className="ion-no-border"
          style={{
            borderTop: '1px solid rgba(45,59,78,.15)',
            paddingBottom: 'var(--ion-safe-area-bottom)',
            background: '#fff'
          }}
        >
          <MainMenu isMobile padding="0" />
        </IonFooter>
      )}
    </Fragment>
  );
};

export default withRouter(AppContent);
