import React from 'react';
import ProptTypes from 'prop-types';
import DoubleSpinner from '@/src/assets/icons/double-spinner.svg';
import { Text } from '@/src/theme/components/Typography';

const Loading = (props) => {
  const { spinerSize = '47px', loadingText = '' } = props;

  return (
    <div style={{ textAlign: 'center' }}>
      <img src={DoubleSpinner} alt="loading spinner" style={{ width: spinerSize }} />
      {loadingText && (
        <Text weight="700" size="14">
          {loadingText}
        </Text>
      )}
    </div>
  );
};

Loading.propTypes = {
  spinerSize: ProptTypes.string,
  loadingText: ProptTypes.string
};

export default Loading;
