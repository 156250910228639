import React, { useRef } from 'react';
import Header from '../../common/Modals/Header';
import { StyledModal } from '../../styled/IonElements';
import PropTypes from 'prop-types';
import { IconContainer } from './index.styled';

const ModalWithIcon = (props) => {
  const { isOpen, onClose, icon, children, className = '' } = props;

  const modalRef = useRef(null);

  const handleClose = (e) => {
    modalRef.current?.dismiss();
    onClose(e);
  };

  return (
    <StyledModal ref={modalRef} isOpen={isOpen} onClose={handleClose} onDidDismiss={handleClose} className={className}>
      <div className="padding-x-xl padding-top-xl">
        <Header onClose={handleClose} />
      </div>
      <IconContainer>{icon}</IconContainer>
      <div className="padding-x-xl padding-bottom-xl">{children}</div>
    </StyledModal>
  );
};

ModalWithIcon.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  icon: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string
};

export default ModalWithIcon;
