import React from 'react';
import PropTypes from 'prop-types';
import Card from '../../components/common/Cards/Card';
import { FlexContainer } from '../../components/styled/Wrappers';
import { IonCardTitle } from '@ionic/react';
import Avatar from '../../components/common/Avatar';
import Rating from '../../components/common/Rating';
import { ButtonGrey } from '../../components/styled/ButtonsV2';
import Warning from '../../components/svgs/Warning';
import { isTruthy } from '@/src/components/common/helpers';

const ReviewTransferHva = (props) => {
  const { title, driverAvatar, notMyDriverUrl, notMyDriverText, reviewUrls } = props;

  const handleRatingClick = (rating) => {
    const newWindow = window.open(reviewUrls[rating - 1], '_blank');

    if (newWindow) {
      newWindow.focus();
    }
  };

  return isTruthy(reviewUrls) && reviewUrls.length > 0 ? (
    <Card>
      <FlexContainer align="center" justify="space-between" gap="8px" margin="0 0 4px">
        <div>
          <IonCardTitle>{title}</IonCardTitle>
        </div>

        <Avatar src={driverAvatar} size="44px" />
      </FlexContainer>

      <Rating noHighlight onRate={(rating) => handleRatingClick(rating)} />

      <a href={notMyDriverUrl} target="_blank" rel="noreferrer" style={{ marginTop: '12px' }}>
        <ButtonGrey block round size="sm" style={{ marginTop: '16px' }}>
          <Warning />
          {notMyDriverText}
        </ButtonGrey>
      </a>
    </Card>
  ) : null;
};

ReviewTransferHva.propTypes = {
  title: PropTypes.string.isRequired,
  driverAvatar: PropTypes.string.isRequired,
  notMyDriverUrl: PropTypes.string.isRequired,
  notMyDriverText: PropTypes.string.isRequired,
  reviewUrls: PropTypes.array.isRequired
};

export default ReviewTransferHva;
