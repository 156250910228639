import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { IonItem, IonSkeletonText, IonThumbnail } from '@ionic/react';
import { PoweredByWhiteImg } from '../../../components/styled/Images';
import { ContentCardsWrapper, FullHeightOverlay, HeaderBottomFlexContainer } from '../../../components/styled/Wrappers';
import { Heading2 } from '../../../components/styled/Typography';
import theme from '../../../theme';
import { StyledCard, StyledContent, StyledPage } from '../../../components/styled/IonElements';
import NoUpcomingTrips from './List/NoUpcomingTrips';
import TransfersList from './List/TransfersList';
import AppsBlock from './List/AppsBlock';
import ExtraTripBlocks from './List/ExtraTripBlocks';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import HeaderRightContainer from '../../../components/common/HeaderRightContainer';
import poweredByWhite from '../../../assets/branding/poweredByLight.svg';
import braze from '../../../services/braze';
import MobileContentCards from '../../../components/common/MobileContentCards';
import {
  currentPageIs,
  getContentCardsFilterForPage,
  isFalsy,
  isMobileAppPlatform,
  isTruthy
} from '../../../components/common/helpers';
import mixpanel from '../../../services/mixpanel';
import arrowLeft from '../../../assets/icons/leftGraphiteArrow.svg';
import { LinkButton } from '../../../components/styled/ButtonsV2';
import Refresher from '@/src/components/common/Refresher';
import { useStores } from '@/src/stores/useStores';
import { LogoWrapper, MyTripHeader, StyledIonToolbar } from './index.styled';
import SkeletonList from '@/src/components/common/ScarySkeletons/SkeletonList';
import { HVA_SRIDES_LIMIT } from '@/src/constants';
import useWindowSize from '@/src/hooks/useWindowSize';
import PromoPopupMyTrips from './List/PromoPopupMyTrips';
import { useHistory } from 'react-router-dom';
import { MissingTripError } from '@/src/errors';
import Container from '@/src/components/layouts/containers/Container';

const contentCardsFilter = getContentCardsFilterForPage('trip');
let sRidesFetched = false;
// We need this flag to know when we are ready to show the first content to the user.
// Should be set to true when the component is mounted and api requests have started in order to show the initial loading.
let mounted = false;

const Trip = (props) => {
  const {
    t,
    location,
    match: {
      params: { tripToken }
    }
  } = props;

  const [shouldRerenderContentCards, setShouldRerenderContentCards] = useState(false);

  const history = useHistory();

  const {
    travelerAppStore: {
      currentTrip,
      trips,
      getTrips,
      activeTrips,
      transfers,
      transfersAutocheckErrors,
      isAuthorised,
      setPromoVisiblePopupMyTrips,
      promoVisiblePopupMyTrips,
      loading,
      tripsLoading,
      tripDetailsLoading,
      cityImageObject
    },
    destinationsStore: { destinations, fetchDestinations, destinationsloading, getSRides, sRides, currentSRidesCityId },
    brandedSettingsStore: {
      isPartner,
      brandedSettings: { partnerId, brandLogoForTravelerAppUrl }
    },
    tripsStore: { tripPreviews },
    contentCardsStore: { contentCards }
  } = useStores();

  const windowSize = useWindowSize();

  const filteredCards = contentCards.filter(contentCardsFilter);
  const isPartnerTraveler = partnerId !== '';

  const trackPageView = () => {
    let errorsCount = 0;
    // "transfersAutocheckErrors" contains arrays with the errors that each transfer has.
    for (let transferId in transfersAutocheckErrors) {
      errorsCount += transfersAutocheckErrors[transferId].length;
    }

    mixpanel.trackTripTabView({
      Book_Return_Transfer_Available: showPopularDestinationsReturn(),
      Book_Another_Ride_Available: showPopularDestinations(),
      Active_Trips_Count: activeTrips.length || 0,
      Selected_Trip_Id: currentTrip.id,
      Selected_Trip_Status: activeTrips.find((item) => item.id === currentTrip.id) ? 'active' : 'archived',
      Trip_Transfers_Count: transfers.length || 0,
      Errors_Count: errorsCount,
      Potential_Airbnb_Question_Visible: currentTrip.askAirbnbQuestion,
      Airbnb_Invitation_Visible: !!currentTrip.airbnbHostInvitation,
      has_sride: currentTrip.tourTransfers?.length > 0,
      hva_b2b_promo_visible: currentTrip.hvaB2BPromoTrAgentUrl || currentTrip.hvaB2BPromoBusinessUrl,
      boh_relationship: currentTrip.bohRelationship,
      srides_hva_visible: sRides.length > 0
    });
  };

  const hasPopularDestinationsReturn = () => {
    return currentTrip.popularDestinationsReturn && currentTrip.popularDestinationsReturn.length > 0;
  };

  const showPopularDestinationsReturn = () => {
    return hasPopularDestinationsReturn() && !currentTrip.hasReturnTransfer && transfers.length > 0;
  };

  const showPopularDestinations = () => {
    return currentTrip.popularDestinationsOutsideCity && currentTrip.popularDestinationsOutsideCity.length > 0;
  };

  const shouldFetchTrip = () => {
    // param to enforce fetching of trips/transfer
    const fetch = location?.state?.fetch;
    return isAuthorised && (trips.length === 0 || tripToken !== currentTrip.id || fetch);
  };

  /**
   * Makes sure that the trip content is ready to be rendered.
   * When this becomes true, we know that there is a trip to show but we don't know the trip transfers.
   * If the currentTrip is empty, it is a canceled transfer so we skip the check tripToken === currentTrip.id
   * If the user is not authorised, the page is already ready because we don't have to fetch trip data.
   * @returns {boolean}
   */
  const isReadyToRender = () => {
    return (
      (!isAuthorised || (isAuthorised && !tripsLoading && !tripDetailsLoading && tripToken === currentTrip.id)) &&
      !destinationsloading &&
      destinations.length > 0
    );
  };

  /**
   * Checks if all data is fetched
   * @returns {boolean}
   */
  const isReady = () => {
    return isReadyToRender() && !loading;
  };

  const fetchTrip = async (tripToken) => {
    try {
      const data = await getTrips(tripToken);
      return data;
    } catch (error) {
      if (error instanceof MissingTripError) {
        history.replace('/trips');
      }
    }
  };

  const doRefresh = (event) => {
    fetchTrip(tripToken);

    // Fetch content cards on mobile
    if (isMobileAppPlatform() && currentPageIs('/trip')) {
      braze.getContentCardsFromServer();
    }

    setTimeout(() => {
      console.log('Async operation has ended');
      event.detail.complete();
    }, 1000);
  };

  const togglePopups = () => {
    setPromoVisiblePopupMyTrips(!promoVisiblePopupMyTrips);
    setShouldRerenderContentCards(true);
  };

  const refresher = () => {
    return <Refresher onRefresh={doRefresh} />;
  };

  const isLoading = () => {
    return tripsLoading || tripDetailsLoading;
  };

  const getImageSize = () => {
    const { travelerAppMobile, travelerAppDesktop, view3X } = cityImageObject;

    if (windowSize.width <= theme.mobileScreenWidths.lg) {
      return travelerAppMobile || view3X;
    }

    return travelerAppDesktop || view3X;
  };

  const renderNoUpcomingTrips = () => {
    return <NoUpcomingTrips />;
  };

  const renderUpcomingTrips = () => {
    const showPoweredBy = isPartner && window.innerWidth < 768;
    const tripWelcomeMessage = (currentTrip && currentTrip.greeting) || t('my_trip.my_trip');
    const hasPopularDestinationsReturn =
      currentTrip.popularDestinationsReturn && currentTrip.popularDestinationsReturn.length > 0;

    const showBookTransfer = !hasPopularDestinationsReturn && !currentTrip.hasReturnTransfer && transfers.length > 0;

    const getBackButtonUrl = () => {
      if (tripPreviews?.upcoming?.find((trip) => trip.sharingToken === currentTrip.id)) {
        return '/trips';
      } else if (tripPreviews?.completed?.find((trip) => trip.sharingToken === currentTrip.id)) {
        return '/trips?tab_name=completed';
      }

      return '/trips';
    };

    return (
      <>
        <StyledCard padding="0" margin="20px">
          <StyledIonToolbar image={getImageSize()}>
            <FullHeightOverlay />

            <LogoWrapper>
              <LinkButton to={getBackButtonUrl()}>
                <img src={arrowLeft} alt="back button" style={{ filter: 'brightness(0) invert(1)' }} />
              </LinkButton>
              <img
                src={brandLogoForTravelerAppUrl}
                alt="logo"
                style={{ maxWidth: showPoweredBy ? '70px' : '100%' }}
                className="trip-logo"
              />
            </LogoWrapper>
            <HeaderRightContainer />

            <HeaderBottomFlexContainer align="flex-end" justify={showPoweredBy ? 'space-around' : 'flex-start'}>
              <MyTripHeader>
                <Heading2 color={theme.colors.base.white}>{tripWelcomeMessage}</Heading2>
              </MyTripHeader>

              {showPoweredBy && <PoweredByWhiteImg src={poweredByWhite} alt="powered by Welcome Pickups" />}
            </HeaderBottomFlexContainer>
          </StyledIonToolbar>

          <TransfersList errors />
        </StyledCard>

        <ExtraTripBlocks
          currentTrip={currentTrip}
          transfers={transfers}
          showBookTransfer={showBookTransfer}
          showPopularDestinationsReturn={showPopularDestinationsReturn()}
          showPopularDestinations={showPopularDestinations()}
        />
      </>
    );
  };

  const renderLoadingState = () => {
    return (
      <StyledCard padding="0" margin="20px">
        <IonItem lines="none" style={{ width: '100%', maxWidth: '600px', margin: '15px auto' }}>
          <IonThumbnail style={{ width: '100%', height: '200px', margin: 0 }} slot="start">
            <IonSkeletonText animated />
          </IonThumbnail>
        </IonItem>
        <SkeletonList padding="40px 20px" />
      </StyledCard>
    );
  };

  const renderPageContent = () => {
    if (!mounted || !isReadyToRender()) {
      return renderLoadingState();
    }

    if (!isAuthorised || (!isLoading() && isFalsy(currentTrip))) {
      return renderNoUpcomingTrips();
    }

    return renderUpcomingTrips();
  };

  useEffect(() => {
    // We need to initialize this variable every time the component is mounted because it's value remain after the component is unmounted.
    // This variable is used to prevent multiple requests to fetch srides of the current city during the lifecycle of the component.
    sRidesFetched = false;

    // Fetch and show contentCards on web
    braze.renderContentCards('content-cards-container-trip', contentCardsFilter);

    if (shouldFetchTrip()) {
      if (!destinations.length && !destinationsloading) {
        fetchDestinations().catch(() => {});
      }

      fetchTrip(tripToken);
    }

    mounted = true;

    return () => {
      // Reset this value when the component is unmounted.
      mounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Prefetches the srides of the current city once we have the trip details to show the HvaSRides component faster.
    const prefetchSRides = () => {
      const findDestinationByCityName = (cityName) => {
        const destination = destinations?.find((dest) => cityName === dest.name);
        return destination;
      };

      // currentTrip value remains after user exits the trip page in order to prevent future requests if the user returns.
      // This means it may contain a previous trip initially, so we need to check if the current trip is the same as the tripToken.
      if (
        isAuthorised &&
        !tripsLoading &&
        isTruthy(currentTrip) &&
        currentTrip.id === tripToken &&
        destinations.length > 0
      ) {
        // At this point, we know that trips have been fetched.
        // The value of currentTrip is not final because extra data will be added after trip details are fetched.
        // We only need the city for now but if we need more data, we should change tripsLoading to tripDetailsLoading.
        const destination = findDestinationByCityName(currentTrip.city);

        // If we already have the srides for the current city, we don't need to fetch them again
        if (currentSRidesCityId !== destination.id) {
          try {
            // We need to prefetch the srides of the current city for the HvaSRides component to load faster after the trip is loaded.
            // We are fetching +1 service than needed to check if there are more services available
            getSRides(destination.id, {
              services_limit: HVA_SRIDES_LIMIT + 1,
              include_widgets: false,
              include_services: true
            });
          } catch (error) {}
        }
        sRidesFetched = true;
      }
    };

    if (!sRidesFetched) {
      prefetchSRides();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTrip.id, destinations]);

  useEffect(() => {
    if (shouldRerenderContentCards) {
      braze.renderContentCards('content-cards-container-trip', contentCardsFilter);
      setShouldRerenderContentCards(false);
    }
  }, [shouldRerenderContentCards]);

  useEffect(() => {
    if (isReady()) {
      trackPageView();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReady()]);

  return (
    <StyledPage>
      <StyledContent>
        {refresher()}

        {renderPageContent()}
        <PromoPopupMyTrips />

        <Container>
          <ContentCardsWrapper
            display={filteredCards.length ? 'block' : 'none'}
            style={{ marginTop: '20px' }}
            id="content-cards-container-trip"
          >
            {isMobileAppPlatform() && <MobileContentCards filteredCards={filteredCards} />}
          </ContentCardsWrapper>
        </Container>

        {!isPartnerTraveler && !isMobileAppPlatform() && <AppsBlock onClick={togglePopups} showText />}
      </StyledContent>
    </StyledPage>
  );
};

export default withTranslation()(withRouter(observer(Trip)));
