import styled from 'styled-components';
import theme from '../../theme';

// TODO: Replace this file with a new one that has some common attributes for all variants (like margin, padding, etc.) and uses the theme variables for colors, font sizes, etc. Check ButtonsV2.js for example.

const Heading2 = styled.h2`
  color: ${(props) => props.color || theme.colors.base.graphite};
  font-size: ${(props) => props.fontSize || '24px'};
  line-height: 31px;
  font-weight: 700;
  margin-top: ${(props) => props.marginTop || '20px'};
  margin-bottom: ${(props) => props.marginBottom || '12px'};
  text-align: ${(props) => props.align || 'initial'};
  max-width: ${(props) => props.maxWidth || 'intitial'};
  text-overflow: ${(props) => props.textOverflow || 'intitial'};
  text-wrap: ${(props) => props.textWrap || 'wrap'};
  overflow: ${(props) => props.overflow || 'intitial'};

  @media (max-height: 442px) and (max-width: ${theme.breakpoints[0]}px) {
    display: inline-block;
    margin-bottom: ${(props) => props.marginBottom || '10px'};
    margin-left: 10px;
  }
`;

const Paragraph = styled.p`
  color: ${(props) => props.color || theme.colors.greys.grey5};
  font-size: ${(props) => props.size || '14px'};
  line-height: 21px;
  font-weight: ${(props) => props.weight || '500'};
  max-width: ${(props) => props.maxWidth || '240px'};
  text-align: ${(props) => props.align || 'center'};
  margin: ${(props) => props.margin || '0'};

  @media (max-height: 442px) and (max-width: ${theme.breakpoints[0]}px) {
    display: none;
  }
`;

const Heading3 = styled.h3`
  font-size: ${(props) => props.fontSize || '24px'};
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.3;
  letter-spacing: normal;
  color: ${theme.colors.base.graphite};
  margin: ${(props) => props.margin};
`;

const Heading4 = styled.h4`
  font-size: ${(props) => props.fontSize || '20px'};
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.3;
  letter-spacing: normal;
  color: ${theme.colors.base.graphite};
  margin: ${(props) => props.margin};
`;

const Heading5 = styled.h5`
  font-size: 18px;
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.3;
  letter-spacing: normal;
  color: ${theme.colors.base.graphite};
  margin: ${(props) => props.margin};
`;

const _Text = styled.p`
  text-align: ${(props) => props.align || 'auto'};
  color: ${({ color }) => color || theme.colors.base.graphite};
  letter-spacing: normal;
  margin=${(props) => props.margin || '0'};
  display: ${(props) => props.display || 'block'};
`;

const Text2 = styled(_Text)`
  font-size: 14px;
  font-weight: ${(props) => props.weight || '500'} !important;
  font-style: normal;
  line-height: 1.5;

  @media (min-width: ${theme.breakpoints[1]}px) {
    font-size: ${(props) => props.desktopSize || '14px'} !important;
  }
`;

const Text1 = styled(_Text)`
  font-size: 16px;
  font-weight: ${(props) => props.weight || '700'};
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: ${(props) => props.color || theme.colors.base.graphite};
`;

const Text3 = styled(_Text)`
  font-size: 12px !important;
  font-weight: ${(props) => props.weight || '500'};
  font-style: normal;
  line-height: 1.5;
  color: ${(props) => props.color || 'default'};
`;

const TextInfo = styled.p`
  font-size: 12px;
  font-weight: 500;
  line-height: 1.3;
  color: ${theme.colors.greys.grey5};
  margin: ${(props) => props.margin || 'initial'};
`;

const ErrorMessage = styled.p`
  font-size: 12px;
  font-weight: 500;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: normal;
  margin-top: 8px;
  color: ${theme.colors.accent.red};
`;

const GreenText = styled.span`
  color: ${theme.colors.cta.greenMedium};
  font-weight: ${(props) => (props.bold && '600') || '400'};
`;

export { Heading2, Heading3, Heading5, Paragraph, Text1, Text2, Text3, Heading4, TextInfo, ErrorMessage, GreenText };
