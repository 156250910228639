import React, { Fragment, useState } from 'react';
import { Heading3, Heading4 } from '../../styled/Typography';
import { withTranslation } from 'react-i18next';
import { StyledCard } from '../../styled/IonElements';
import styled from 'styled-components';
import theme from '../../../theme';
import BellIcon from '../../../assets/icons/bell-icon.svg';
import ClockIcon from '../../../assets/icons/clock-icon.svg';
import CarIcon from '../../../assets/icons/car-icon.svg';
import AirbnbLogo from '../../../assets/icons/airbnb-logo.svg';
import CopyIcon from '../../../assets/icons/copy-icon.svg';
import CloseIcon from '../../../assets/icons/close-delete-icon.svg';
import ShareIcon from '../../../assets/icons/share-icon.svg';
import UpCurvedArrowIcon from '../../../assets/icons/up-curved-arrow-icon.svg';
import DownCurvedArrowIcon from '../../../assets/icons/down-curved-arrow-icon.svg';
import { FlexContainer } from '../../styled/Wrappers';
import { ButtonGrey, ButtonPrimary, ButtonText } from '../../styled/ButtonsV2';
import { copyToClipboard, isMobileAppPlatform } from '../helpers';
import { IonCard, IonToast } from '@ionic/react';
import { useStores } from '../../../stores/useStores';
import AirbnbHostInvitationPreview from './AirbnbHostInvitationPreview';
import TravelerApi from '../../../API';
import { Share } from '@capacitor/share';
import { observer } from 'mobx-react';
import mixpanel from '../../../services/mixpanel';
import { logError } from '@/src/helpers/log';

function AirbnbHostInvitationScreen(props) {
  const { t } = props;
  const {
    travelerAppStore: {
      currentTrip: { airbnbHostInvitation },
      setAirbnbHostInvitationOfCurrentTrip
    }
  } = useStores();
  const [showSuccessToast, setShowSuccessToast] = useState(false);
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);

  const invitationFirstParagraphHtml = () => {
    return (
      <p style={{ marginBottom: '20px' }}>
        {t('airbnb_host_invitations.first_paragraph', { from_location: airbnbHostInvitation?.locationTitle })}
      </p>
    );
  };

  const invitationSecondParagraphHtml = () => {
    const previewLink = airbnbHostInvitation?.invitationUrl;

    return (
      <p>
        {t('airbnb_host_invitations.you_can_click_here')} <a href={previewLink}>{previewLink}</a>{' '}
        {t('airbnb_host_invitations.to_see_my_arrival_details')}
      </p>
    );
  };

  const invitationTextHtml = () => {
    return (
      <Fragment>
        {invitationFirstParagraphHtml()}
        {invitationSecondParagraphHtml()}
      </Fragment>
    );
  };

  const renderIconSection = () => {
    return (
      <HostInfoContainer>
        <FlexContainer justify="space-between" align="start">
          <FlexContainer align="center" flexDirection="column" textAlign="center" gap="8px">
            <img src={BellIcon} alt="Notify your host" />
            <p>{t('airbnb_host_invitations.notify_your_host')}</p>
          </FlexContainer>

          <img src={UpCurvedArrowIcon} alt="Notify your host" />

          <FlexContainer align="center" flexDirection="column" textAlign="center" gap="8px">
            <img src={ClockIcon} alt="Share exact arrival time" />
            <p>{t('airbnb_host_invitations.share_exact_arrival_time')}</p>
          </FlexContainer>

          <img src={DownCurvedArrowIcon} alt="Share exact arrival time" />

          <FlexContainer align="center" flexDirection="column" textAlign="center" gap="8px">
            <img src={CarIcon} alt="Check-in stressfree" />
            <p>{t('airbnb_host_invitations.check_in_stressfree')}</p>
          </FlexContainer>
        </FlexContainer>
      </HostInfoContainer>
    );
  };

  const extractTextFromElement = (element) => {
    if (typeof element === 'string') {
      return element;
    }

    if (React.isValidElement(element)) {
      return React.Children.toArray(element.props.children)
        .map((child) => extractTextFromElement(child))
        .join('');
    }

    return '';
  };

  const _copyToClipboard = async () => {
    const text = extractTextFromElement(invitationTextHtml());
    const success = await copyToClipboard(text);

    if (success) {
      setShowSuccessToast(true);
      mixpanel.trackAirbnbInvitationCopied();

      if (!airbnbHostInvitation?.invitation_copied) {
        TravelerApi.updateAirbnbHostInvitation(airbnbHostInvitation.id, true)
          .then((data) => {
            setAirbnbHostInvitationOfCurrentTrip(data);
          })
          .catch((err) => {
            logError('Failed to update airbnb host invitation', err);
          });
      }
    }
  };

  const renderCopyText = () => {
    return <div className="copy-container-inner">{invitationTextHtml()}</div>;
  };

  const renderCopyContainer = () => {
    return (
      <CopyContainer>
        {renderCopyText()}

        <ButtonGrey block onClick={_copyToClipboard}>
          <img src={CopyIcon} alt="Copy" />
          {t('airbnb_host_invitations.copy_text')}
        </ButtonGrey>
      </CopyContainer>
    );
  };

  const shareTripDetails = async () => {
    const text = extractTextFromElement(invitationTextHtml());

    mixpanel.trackAirbnbShareOpened();

    let res;
    try {
      res = await Share.share({
        title: t('airbnb_host_invitations.share_trip_details'),
        text: text,
        dialogTitle: t('airbnb_host_invitations.share_trip_details')
      });
    } catch (error) {}

    if (res) {
      // Means the user copied the link from inside the share modal
      const linkCopied = res.activityType === '' || res.activityType === 'com.apple.UIKit.activity.CopyToPasteboard';

      if (linkCopied) {
        mixpanel.trackAirbnbSharableLinkCopied();
      } else if (res.activityType) {
        mixpanel.trackAirbnbInvitationShared({ App_name: res.activityType });
      }

      if (!airbnbHostInvitation?.invitation_copied) {
        TravelerApi.updateAirbnbHostInvitation(airbnbHostInvitation.id, true)
          .then((data) => {
            setAirbnbHostInvitationOfCurrentTrip(data);
          })
          .catch((err) => {
            logError('Share trip details error', err);
          });
      }
    }
  };

  const renderShareButton = () => {
    if (isMobileAppPlatform()) {
      return (
        <ButtonPrimary block onClick={shareTripDetails} margin="20px 0 8px">
          <img src={ShareIcon} alt="Share trip details" />
          {t('airbnb_host_invitations.share_trip_details')}
        </ButtonPrimary>
      );
    }
    return null;
  };

  return (
    <Fragment>
      <StyledCard margin="20px">
        <div style={{ textAlign: 'right' }}>
          <img
            src={CloseIcon}
            alt="close"
            style={{ cursor: 'pointer' }}
            onClick={() => {
              setAirbnbHostInvitationOfCurrentTrip(null);
              mixpanel.trackAirbnbInvitationClosed();
            }}
          />
        </div>

        <figure style={{ textAlign: 'center', marginTop: 0 }}>
          <img src={AirbnbLogo} alt="Airbnb logo" />
        </figure>

        <StyledH3>{t('airbnb_host_invitations.send_a_message_to_your_host')}</StyledH3>

        {renderIconSection()}

        <StyledH4>{t('airbnb_host_invitations.copy_and_send_via_airbnb_inbox')}</StyledH4>

        {renderCopyContainer()}

        {renderShareButton()}

        <ButtonText
          margin="14px auto 0"
          color={theme.colors.base.graphiteLighter}
          onClick={() => setIsPreviewOpen(true)}
        >
          {t('airbnb_host_invitations.preview_what_your_host_will_see')}
        </ButtonText>
      </StyledCard>

      <AirbnbHostInvitationPreview isOpen={isPreviewOpen} onClose={() => setIsPreviewOpen(false)} />

      <IonToast
        isOpen={showSuccessToast}
        message={t('alert.your_invitation_copied')}
        duration={3000}
        onDidDismiss={() => setShowSuccessToast(false)}
        cssClass="styled-toast-black"
      />
    </Fragment>
  );
}

const StyledH3 = styled(Heading3)`
  color: ${theme.colors.base.graphite};
  font-size: 20px;
  line-height: 26px;
  margin: 0;
  text-align: center;
`;

const StyledH4 = styled(Heading4)`
  font-size: 14px;
  font-weight: 600;
`;

const CopyContainer = styled.div`
  border-radius: 4px;
  border: 1px solid ${theme.colors.greys.grey15};

  .copy-container-inner {
    padding: 14px 16px;
  }

  a {
    color: ${theme.colors.cta.greenDark} !important;
  }
`;

const HostInfoContainer = styled(IonCard)`
  --background: ${theme.colors.background.grey};
  padding: 20px 12px 18px;
  margin: 16px 0 24px;
  box-shadow: none;
  border-radius: 12px;
`;

export default withTranslation()(observer(AirbnbHostInvitationScreen));
