import React from 'react';
import styled from 'styled-components';
import theme from '../../../theme';

const svgStyles = {
  display: 'inline-block',
  marginRight: '20px',
  width: '20px',
  height: '20px',
  flexShrink: 0
};

const GreyFooterItem = ({ ico, text, onClick, children, disabled }) => {
  const SvgIcon = ico;

  return (
    <StyledItemsWrapper className="footer-button" ico={ico} onClick={onClick} disabled={disabled}>
      <StyledItems disabled={disabled}>
        <SvgIcon styles={svgStyles} fill={theme.colors.base.graphite} opacity={disabled ? '0.3' : '0.5'} />

        <span className="item-text">{text}</span>
      </StyledItems>
      {children}
    </StyledItemsWrapper>
  );
};
const StyledItems = styled.div`
  display: flex;

  svg {
    margin-right: 20px;
  }

  .item-text {
    color: ${(props) => (props.disabled ? theme.colors.greys.grey3 : theme.colors.greys.grey5)};
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
  }

  @media (max-width: ${theme.breakpoints[0]}px) {
    .ico {
      margin-right: 17px;
    }
  }
`;

const StyledItemsWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: ${(props) => (props.disabled ? 'auto' : 'pointer')};
  position: relative;
  min-height: 60px;
  height: 60px;
  width: 100%;
  :visited {
    color: red;
  }
`;

export default GreyFooterItem;
