import React from 'react';
import styled from 'styled-components';
import { onExternalLinkClick } from '../common/ExternalLink/ExternalLink';
import PropTypes from 'prop-types';

// Wraps the given elements in an anchor tag
const ExternalLinkWrapper = (props) => {
  const { link, callback, noIab = false, children } = props;

  return (
    <Anchor
      href={link}
      target="_blank"
      rel="noopener noreferrer"
      onClick={(e) => {
        if (callback) {
          callback();
        }

        if (!noIab) {
          onExternalLinkClick(e, link);
        }
      }}
    >
      {children}
    </Anchor>
  );
};

const Anchor = styled.a`
  width: auto;
  :visited,
  :focus,
  :active,
  :hover {
    outline: 0 none !important;
  }
`;

ExternalLinkWrapper.propTypes = {
  link: PropTypes.string.isRequired,
  callback: PropTypes.func,
  noIab: PropTypes.bool
};

export default ExternalLinkWrapper;
