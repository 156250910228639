import { debugConsoleLog } from '../components/common/helpers';
import stores from '../stores';

const TalonOne = require('talon_one');

const { travelerAppStore, talonOneStore } = stores;

const loyaltyProgramId = Number(process.env.REACT_APP_TALON_ONE_LOYALTY_PROGRAM_ID);
const campaignId = Number(process.env.REACT_APP_TALON_ONE_CAMPAIGN_ID);
export const PENDING_TRANSACTION_SUBLEDGER_ID = 'PendingLedger';
export const REMOVE_PENDING_POINTS_TRANSACTION_NAME = 'Remove Pending Points';

export const ADVOCATE_REWARD_SESSION_PREFIX = 'adv';
export const FRIEND_SIGN_UP_SESSION_PREFIX = 'frie';

class TalonOneService {
  constructor() {
    this.defaultClient = null;
    this.api_key_v1 = null;
    this.apiInstance = null;
    this.talonOneInitialized = false;
  }

  initialize() {
    this.defaultClient = TalonOne.ApiClient.instance;
    this.defaultClient.basePath = process.env.REACT_APP_TALON_ONE_BASE_PATH;

    // Configure API key authorization: api_key_v1
    this.api_key_v1 = this.defaultClient.authentications['api_key_v1'];
    this.api_key_v1.apiKey = process.env.REACT_APP_TALON_ONE_KEY;
    this.api_key_v1.apiKeyPrefix = 'ApiKey-v1';

    this.apiInstance = new TalonOne.IntegrationApi();

    this.talonOneInitialized = true;
    debugConsoleLog('Talon One initialized');
  }

  async getLoyaltyBalances() {
    const integrationId = travelerAppStore.travelerInfo.id;

    if (this.talonOneInitialized) {
      try {
        const data = await this.apiInstance.getLoyaltyBalances(loyaltyProgramId, integrationId);
        talonOneStore.setBalance(data.balance || {});
        return data;
      } catch (error) {
        debugConsoleLog(error);
        return error;
      }
    }
  }

  async updateOrCreateCustomerProfile(profileData) {
    if (this.talonOneInitialized) {
      if (!travelerAppStore.profileInformation?.firstName || !travelerAppStore.profileInformation?.lastName) {
        await travelerAppStore.fetchProfileInformation();
      }

      const integrationId = travelerAppStore.travelerInfo.id;
      let body = new TalonOne.CustomerProfileIntegrationRequestV2();

      if (profileData) {
        body.attributes = { ...profileData };
      } else {
        body.attributes = {};
        body.attributes.Name = `${travelerAppStore.profileInformation.firstName} ${travelerAppStore.profileInformation.lastName}`;
      }

      try {
        const data = await this.apiInstance.updateCustomerProfileV2(integrationId, body);
        talonOneStore.setProfile(data.customerProfile);
        return data;
      } catch (error) {
        debugConsoleLog(error);
        return error;
      }
    }
  }

  /**
   * @param {object} customOptions
   * @param {boolean} customOptions.profile
   * @param {boolean} customOptions.referrals
   * @param {boolean} customOptions.coupons
   * @param {boolean} customOptions.loyalty
   */
  async getCustomerInventory(customOptions) {
    if (this.talonOneInitialized) {
      const integrationId = travelerAppStore.travelerInfo.id;
      let opts = customOptions || {
        profile: true, // Boolean | Set to `true` to include customer profile information in the response.
        referrals: true, // Boolean | Set to `true` to include referral information in the response.
        coupons: true // Boolean | Set to `true` to include coupon information in the response.
        // 'loyalty': true, // Boolean | Set to `true` to include loyalty information in the response.
        // 'giveaways': false // Boolean | Set to `true` to include giveaways information in the response.
      };

      try {
        const data = await this.apiInstance.getCustomerInventory(integrationId, opts);
        opts.profile && talonOneStore.setProfile(data.profile);
        opts.referrals && talonOneStore.setReferrals(data.referrals);
        opts.coupons && talonOneStore.setCoupons(data.coupons);

        return data;
      } catch (error) {
        debugConsoleLog(error);
        return error;
      }
    }
  }

  async createReferralCode() {
    if (this.talonOneInitialized) {
      const body = new TalonOne.NewReferral();
      body.campaignId = campaignId;
      body.advocateProfileIntegrationId = travelerAppStore.travelerInfo.id;

      try {
        const data = await this.apiInstance.createReferral(body);
        talonOneStore.setReferrals([...talonOneStore.referrals, data]);
        return data;
      } catch (error) {
        debugConsoleLog(error);
        return error;
      }
    }
  }

  /**
   * When a pending referral invitation is completed, we need to remove the pending transaction in order to show only the completed one
   * @param {array} transactionsList
   * @param {object} transaction
   */
  _shouldRemovePendingTransaction(transactionsList, transaction) {
    let completedTransactionIsPresent = false;

    // If the given transaction is a pending referral invitation
    // we need to check if there is a completed invitation for the same user
    if (
      transaction.customerSessionId?.startsWith(FRIEND_SIGN_UP_SESSION_PREFIX) &&
      transaction.subledgerId === PENDING_TRANSACTION_SUBLEDGER_ID
    ) {
      completedTransactionIsPresent = !!transactionsList.find(
        (_trans) =>
          _trans.name === transaction.name && _trans.customerSessionId?.startsWith(ADVOCATE_REWARD_SESSION_PREFIX)
      );
    }

    return completedTransactionIsPresent;
  }

  async getTransactionsAndInvitations() {
    if (this.talonOneInitialized) {
      const integrationId = travelerAppStore.travelerInfo.id;

      try {
        const transactions = await this.apiInstance.getLoyaltyProgramProfileTransactions(
          loyaltyProgramId,
          integrationId
        );

        const finalTransactions = this._storeTransactions(transactions);
        const finalInvitations = this._storeInvitations(transactions);

        return {
          transactions: finalTransactions,
          invitations: finalInvitations
        };
      } catch (error) {
        debugConsoleLog(error);
        return error;
      }
    }
  }

  _storeTransactions(allTransactions = {}) {
    let finalTransactionsList = [];

    if (this.talonOneInitialized) {
      try {
        // Filter transactions
        for (let transaction of allTransactions.data) {
          if (
            this._shouldRemovePendingTransaction(allTransactions.data, transaction) ||
            transaction.name === REMOVE_PENDING_POINTS_TRANSACTION_NAME
          ) {
            continue;
          }

          finalTransactionsList.push(transaction);
        }

        const finalTransactions = {
          data: finalTransactionsList
        };

        talonOneStore.setTransactions(finalTransactions);

        return finalTransactions;
      } catch (error) {
        debugConsoleLog(error);
        return error;
      }
    }
  }

  _storeInvitations(allTransactions = {}) {
    if (this.talonOneInitialized) {
      let finalInvitationsList = [];

      try {
        // Check if the invitation is pending or not
        for (let transaction of allTransactions.data) {
          let invitation = {
            status: undefined,
            name: undefined,
            amount: undefined
          };

          // Completed invitations
          if (transaction.customerSessionId?.startsWith(ADVOCATE_REWARD_SESSION_PREFIX)) {
            invitation.status = 'trip_operated';
          }
          // Pending invitations
          else if (
            transaction.customerSessionId?.startsWith(FRIEND_SIGN_UP_SESSION_PREFIX) &&
            transaction.subledgerId === PENDING_TRANSACTION_SUBLEDGER_ID
          ) {
            // In case we find another invitation for the same user and is already completed, we don't show the old pending invitation
            const isCompleted = finalInvitationsList.find(
              (invitation) => invitation.name === transaction.name && invitation.status === 'trip_operated'
            );
            if (isCompleted) {
              continue;
            }

            invitation.status = 'sign_up';
          }
          // Other transactions that are not invitations so we skip them
          else {
            continue;
          }

          invitation.name = transaction.name;
          invitation.amount = transaction.amount;

          finalInvitationsList.push(invitation);
        }

        const finalInvitations = {
          data: finalInvitationsList
        };

        talonOneStore.setInvitations(finalInvitations);

        return finalInvitations;
      } catch (error) {
        debugConsoleLog(error);
        return error;
      }
    }
  }
}

export default new TalonOneService();
