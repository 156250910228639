import truncateLines from '@/src/helpers/truncateLines';
import theme from '@/src/theme';
import styled from 'styled-components';

export const Card = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 16px;
  background: white;
  outline: ${({ selected }) => (selected ? `2px solid #00B579` : '1px solid #D5D8DC')};
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  width: 100%;
  cursor: pointer;
`;

export const InfoContainer = styled.div`
  flex: 1;
  min-width: 0;
`;

export const Name = styled.h2`
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  font-style: normal;
  ${truncateLines(3, '18px')};

  margin: 0;
  color: ${theme.colors.base.graphite};
  flex: 1;
`;

export const Phone = styled.p`
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  font-style: normal;
  ${truncateLines(3, '18px')};

  margin-top: 4px;
  color: ${theme.colors.base.graphiteLight};
`;

export const Trips = styled.p`
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  font-style: normal;
  ${truncateLines(3, '18px')};

  margin-top: 2px;
  color: ${theme.colors.base.graphiteLighter};
`;

export const VerifiedIconWrapper = styled.div`
  flex-shrink: 0;
  width: 32px;
  height: 32px;
`;

export const VerifiedIcon = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: ${theme.colors.background.greenLight};
  color: ${theme.colors.cta.greenDark};
`;
