import {
  setSafeAreaVars,
  handleExpiredToken,
  isEmptyObject,
  isIosMobileApp,
  isMobileAppPlatform,
  isTruthy
} from './components/common/helpers';
import React, { Component } from 'react';
import { setupIonicReact } from '@ionic/react';
import styled from 'styled-components';
import { Provider } from 'mobx-react';
import stores from './stores';
import braze from './services/braze';
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';

import './App.scss';

import { IonApp, IonImg } from '@ionic/react';
import Rollbar from 'rollbar';
import AuthenticationService from './services/authentication.js';
import history from './components/common/history';
import { FlexContainer } from './components/styled/Wrappers';
import logoAnimated from './assets/branding/welcomeAnimated.gif';
import welcomeText from './assets/branding/WelcomeTextWhite.svg';
import { Text1 } from './components/styled/Typography';
import { get as getStorage } from './services/storage';
import { FirebaseDynamicLinks } from '@awesome-cordova-plugins/firebase-dynamic-links';
import { FirebaseAnalytics } from '@awesome-cordova-plugins/firebase-analytics';
import { FirebaseCrashlytics } from '@awesome-cordova-plugins/firebase-crashlytics';
import queryString from 'query-string';
import GitInfo from 'react-git-info/macro';
import { LaunchReview } from '@awesome-cordova-plugins/launch-review';
import InAppReviewService from './services/inAppReview';
import { Keyboard } from '@capacitor/keyboard';
import { App as Application } from '@capacitor/app';
import { Preferences } from '@capacitor/preferences';
import mixpanel from './services/mixpanel';
import talonOne from './services/talonOne';
import { Router } from 'react-router';
import AppContent from './components/common/AppContent';
import posthog, { PostHogProvider } from '@/src/services/posthog';
import { getConnectionInfo } from './helpers/extractInfo';
import { Device } from '@capacitor/device';

setupIonicReact({
  mode: 'md' // Needed to load styling correctly
});

const gitInfo = GitInfo();
const appVersion = process.env.REACT_APP_VERSION;

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      initializingApp: true,
      showAnimation: isMobileAppPlatform() ? true : false,
      transitionClassRight: '',
      transitionClassLeft: ''
    };

    this.appStateChangeListenner = null;

    // Initialize App Center Crashes
    if (isMobileAppPlatform()) {
      FirebaseAnalytics.setEnabled(true);
      this.crashlytics = FirebaseCrashlytics.initialise();
    }

    // Init all services after firebase analytics/crashlytics initialization

    if (isTruthy(process.env.REACT_APP_NEW_RELIC_ENABLED)) {
      this.newRelic = require('./services/newRelic').default;
      this.newRelic.init();
    }

    // Clear the old capacitor v2 storage entries
    // TODO: Remove this line in a future version when there are no users with the app version 1.1.11 or older installed
    Preferences.removeOld();

    // Initialize Braze
    braze.initialize();

    // Initialize Talon One
    talonOne.initialize();

    // Init Rollbar
    this.initRollbar();

    // Init Mixpanel
    mixpanel.init();

    // Setup dynamic links
    if (isMobileAppPlatform()) {
      this.handleMobileAppAuthenticationAndLinkHandling();
    }

    const {
      destinationsStore: { destinationsCount, fetchDestinations, destinationsloading }
    } = stores;
    if (!destinationsCount && !destinationsloading) {
      fetchDestinations().catch(() => {});
    }

    // Handle back button on mobile devices
    if (isMobileAppPlatform()) {
      document.addEventListener('ionBackButton', (ev) => {
        ev.detail.register(10, (processNextHandler) => {
          history.goBack();
          processNextHandler();
        });
      });
    }
  }

  // Actions after app becomes active after being in background
  handleAppStateChange = (state) => {
    if (state.isActive) {
      braze.getContentCardsFromServer();
    }
  };

  async componentDidMount() {
    setSafeAreaVars();

    // Set whether the accessory bar(DONE) should be visible on the keyboard. Fix for iphones.
    if (isIosMobileApp()) {
      Keyboard.setAccessoryBarVisible({ isVisible: true });
    }

    // authenticate traveler if possible
    console.log('App.js: on componentDidMount');

    // On mobile devices, the authentication of the user will happen after the deeplink
    // initialization and only if the app did not open from a deeplink.
    let authenticated = false;
    if (!isMobileAppPlatform()) {
      authenticated = await AuthenticationService.authenticateTraveler();
    }

    if (!authenticated) {
      const locale = await getStorage('locale');
      if (locale) {
        stores.travelerAppStore.setLocale(locale);
      }
      //if isWeb and the host is different than traveler domain make an API call to get branding settings
      const { brandedSettingsStore } = stores;
      await brandedSettingsStore.fetchBrandedSettings();
    }

    if (isMobileAppPlatform() && !this.appStateChangeListenner) {
      this.appStateChangeListenner = Application.addListener('appStateChange', this.handleAppStateChange);
    }

    if (isMobileAppPlatform()) {
      this.setAnimationDelays();
    }

    // Updates content cards every 30 seconds
    braze.refreshContentCardsPolling(30000);

    this.setState({ initializingApp: false });
  }

  initRollbar = async () => {
    if (process.env.REACT_APP_ROLLBAR_CLIENT_ACCESS_TOKEN && process.env.NODE_ENV !== 'development') {
      const travelerId = stores.travelerAppStore.isAuthorised ? stores.travelerAppStore.travelerInfo.id : null;
      const deviceInfo = await Device.getInfo();

      const rollbar = new Rollbar({
        enabled: true,
        accessToken: process.env.REACT_APP_ROLLBAR_CLIENT_ACCESS_TOKEN,
        captureUncaught: true,
        captureUnhandledRejections: true,
        reportLevel: 'error',
        payload: {
          environment: process.env.REACT_APP_ENVIRONMENT,
          client: {
            javascript: {
              source_map_enabled: true,
              guess_uncaught_frames: true,
              code_version: gitInfo.commit.hash
            }
          },
          person: {
            id: travelerId
          },
          appVersion: appVersion,
          os_version: deviceInfo?.osVersion
        },
        transform: (payload) => {
          // Add network info to the payload only if it's available
          const connectionInfo = getConnectionInfo();
          if (!isEmptyObject(connectionInfo)) {
            // Ensure payload.custom exists
            if (!payload.custom) {
              payload.custom = {};
            }
            payload.custom.navigatorConnection = connectionInfo;
          }
        }
      });
      console.log('Rollbar initialized:', rollbar?.options?.enabled);
      stores.travelerAppStore.setRollbar(rollbar);
    }
  };

  showInAppReview = () => {
    const {
      travelerAppStore: { isAuthorised, travelerInfo }
    } = stores;

    if (
      isMobileAppPlatform() &&
      LaunchReview.isRatingSupported() &&
      isAuthorised &&
      travelerInfo.promptForStoreReview
    ) {
      InAppReviewService.showInAppReviewWindow();
    }
  };

  setAnimationDelays = () => {
    setTimeout(() => {
      this.setState({
        transitionClassRight: 'transition-right',
        transitionClassLeft: 'transition-left'
      });
    }, 2000);

    setTimeout(() => {
      this.setState(
        {
          showAnimation: false
        },
        () => {
          // Show rating dialog some seconds after the animiation disappears
          setTimeout(this.showInAppReview, 2000);
        }
      );
    }, 4000);
  };

  /**
   * Authenticates and redirects the user to the requested location
   * @param {*} slug The path you want the user to be redirected
   */
  authenticateAndRedirect = async (slug = '/') => {
    const values = queryString.parse(slug.split('?').pop());

    let { travelers_access_token } = values;
    let res;
    if (travelers_access_token) {
      console.log(`Deeplink authenticating traveler with token: ${travelers_access_token}`);
      res = await AuthenticationService.loginWithToken(travelers_access_token);
    } else {
      console.log(`Deeplink authenticating traveler without token`);
      res = await AuthenticationService.authenticateTraveler();
    }

    // On iOS devices, the first time the application opens after the installation, the "appUrlOpen" event is triggered and contains a link
    // like the following: com.dopios.travelerapp://google/link/?request_ip_version=...
    // The following check prevents the redirection to a page that does not exist when the app opens for the first time.
    if (slug.includes('com.dopios.travelerapp')) {
      return;
    }

    // In case of an expired or wrong token, we want to redirect the user to the login page.
    if (!res?.authenticated && res.error?.response?.status === 401) {
      mixpanel.trackTravelerTokenExpired(res.error);
      handleExpiredToken();
      return;
    }

    console.log(`Deeplink redirecting: ${slug}`);
    history.push(slug, { fetch: true });
  };

  handleMobileAppAuthenticationAndLinkHandling = async () => {
    // We get the url that the app was launched from, if any.
    // Application.getLaunchUrl() -> { url: "traveler-app://trips?travelers_access_token=..." } or { url: "https://dopios.page.link/?link=https%3A%2F%2Ftraveler.stgazure.welcomd.com%2Ftraveler_app%2Ftrips%3Ftravelers_access_token..." }
    const { url: launchUrl } = (await Application.getLaunchUrl()) || {};

    // Regular app launch
    // ex. launchUrl = undefined
    if (!launchUrl) {
      await this.authenticateAndRedirect();
    }

    // App launch from a firebase dynamic link
    // ex. launchUrl = "https://dopios.page.link/?link=https%3A%2F%2Ftraveler.stgazure.welcomd.com%2Ftraveler_app%2Ftrips%3Ftravelers_access_token..."
    else if (launchUrl.includes('traveler_app')) {
      console.log(`App launch from a firebase dynamic link: ${JSON.stringify(launchUrl)}`);
      const slug = launchUrl.split('traveler_app').pop();
      await this.authenticateAndRedirect(slug);
    }

    // App launch from a deep-link
    // data -> { url: "traveler-app://book" }
    Application.addListener('appUrlOpen', async (data) => {
      console.log(`appUrlOpen: ${JSON.stringify(data)}`);
      const slug = data.url?.split('traveler-app:/').pop();
      await this.authenticateAndRedirect(slug);
    });

    // onDynamicLink() is called only when the app is open and receives a firebase dynamic link.
    FirebaseDynamicLinks.onDynamicLink()
      // data -> {"deepLink":"https://traveler.stgazure...","clickTimestamp":1666362246900,"minimumAppVersion":0}
      .subscribe(
        async (data) => {
          console.log(`onDynamicLink received: ${JSON.stringify(data)}`);
          const slug = data.deepLink?.split('traveler_app').pop();
          this.authenticateAndRedirect(slug);
        },
        (error) => {
          console.log(error);
        }
      );
    console.log('After Initialization of Dynamic Links');
  };

  render() {
    const { destinationsStore } = stores;
    const { initializingApp, transitionClassRight, transitionClassLeft, showAnimation } = this.state;
    const { destinationsCount } = destinationsStore;

    return (
      <IonApp>
        {(showAnimation || (isMobileAppPlatform() && initializingApp)) && (
          <FlexContainer
            className="splash-container"
            align="center"
            justify="center"
            style={{ zIndex: 10000, position: 'absolute', top: 0, height: '100vh', width: '100%' }}
          >
            <div>
              <LogoContainer className={transitionClassRight}>
                <AnimatedLogo src={logoAnimated} />
              </LogoContainer>
              <WelcomeText src={welcomeText} className={transitionClassLeft} />
              {destinationsCount && (
                <DestinationsText color="#fff" className={transitionClassLeft}>
                  Available in {destinationsCount} destinations
                </DestinationsText>
              )}
            </div>
          </FlexContainer>
        )}
        {!initializingApp && (
          <Provider {...stores}>
            <PostHogProvider client={posthog}>
              <Router basename={process.env.REACT_APP_RAILS_PUBLIC_URL} history={history}>
                <AppContent />
              </Router>
            </PostHogProvider>
          </Provider>
        )}
      </IonApp>
    );
  }
}
export default App;

const LogoContainer = styled.div`
  -webkit-transition: all 2s ease;
  -moz-transition: all 2s ease;
  -o-transition: all 2s ease;
  -ms-transition: all 2s ease;
  transition: all 2s ease;
  position: absolute;
  top: 32%;
  left: 25%;
  right: 25%;
`;

const AnimatedLogo = styled.img`
  width: 112px;
  display: block;
  margin: 0 auto;
  border-radius: 60px;
`;

const WelcomeText = styled(IonImg)`
  left: 25%;
  right: 25%;
  display: block;
  margin: 5px auto;
  -webkit-transition: all 1.5s ease;
  -moz-transition: all 1.5s ease;
  -o-transition: all 1.5s ease;
  -ms-transition: all 1.5s ease;
  transition: all 1.5s ease;
  position: absolute;
`;

const DestinationsText = styled(Text1)`
  bottom: 50px;
  left: 0;
  width: 100%;
  text-align: center;
  display: block;
  margin: 32px auto;
  -webkit-transition: all 1.5s ease;
  -moz-transition: all 1.5s ease;
  -o-transition: all 1.5s ease;
  -ms-transition: all vs ease;
  transition: all 1.5s ease;
  position: absolute;
`;
