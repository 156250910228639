import React from 'react';
import { IonSkeletonText } from '@ionic/react';

import './SkeletonTextExample.css';
import { StyledCard } from '../../styled/IonElements';
import theme from '../../../theme';

const renderCard = () => {
  return (
    <StyledCard
      style={{
        marginBottom: '20px',
        borderRadius: '12px',
        padding: 0,
        border: `1px solid ${theme.colors.greys.grey15}`
      }}
    >
      <IonSkeletonText animated style={{ height: '120px', margin: '0' }} />
      <div style={{ background: '#fff', height: '200px', padding: '20px' }}>
        <div>
          <div style={{ display: 'flex', gap: '20px', marginBottom: '16px' }}>
            <IonSkeletonText
              animated
              style={{ width: '20px', height: '20px', marginBottom: '8px', borderRadius: '20px' }}
            />
            <div>
              <IonSkeletonText animated style={{ width: '200px', height: '13px', marginBottom: '8px' }} />
              <IonSkeletonText animated style={{ width: '180px', height: '8px', marginBottom: '8px' }} />
            </div>
          </div>

          <div style={{ display: 'flex', gap: '20px' }}>
            <IonSkeletonText
              animated
              style={{ width: '20px', height: '20px', marginBottom: '8px', borderRadius: '20px' }}
            />
            <div>
              <IonSkeletonText animated style={{ width: '150px', height: '13px', marginBottom: '8px' }} />
              <IonSkeletonText animated style={{ width: '190px', height: '8px', marginBottom: '8px' }} />
            </div>
          </div>

          <IonSkeletonText animated style={{ margin: '30px auto 0', width: '300px', height: '20px' }} />
        </div>
      </div>
    </StyledCard>
  );
};

const SkeletonTripsPreview = () => {
  return (
    <div>
      {renderCard()}
      {renderCard()}
    </div>
  );
};

export default SkeletonTripsPreview;
