import { makeAutoObservable } from 'mobx';
import { isIosMobileApp, isObject } from '../components/common/helpers';

class ContentCardsStore {
  // Observables
  contentCards = [];
  contentCardsSubscriptionId = null;
  dismissedContentCards = []; // Keeps track of dismissed cards for future renders

  constructor() {
    makeAutoObservable(this);
  }

  setContentCards = (newContentCards) => {
    const _cards = [];

    // On ios the "extras" field is a JSON object so it needs parsing.
    // On android and web is a normal object.
    if (isIosMobileApp()) {
      try {
        for (let card of newContentCards) {
          _cards.push({ ...card, extras: isObject(card.extras) ? card.extras : JSON.parse(card.extras) });
        }
      } catch (err) {
        console.error(err);
      }
      this.contentCards.replace(_cards);
      return;
    }

    this.contentCards.replace(newContentCards);
  };

  setContentCardsSubscriptionId = (id) => {
    this.contentCardsSubscriptionId = id;
  };

  removeContentCard = (id) => {
    const filteredCards = this.contentCards.filter((item) => item.id !== id);
    this.dismissedContentCards.push(this.contentCards.find((item) => item.id === id));
    this.setContentCards(filteredCards);
  };

  removeContentCards = () => {
    this.contentCards.replace([]);
    this.dismissedContentCards = [];
  };
}

const contentCardsStore = new ContentCardsStore();
export default contentCardsStore;
