import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import GoToLayout from '../GoToLayout';
import styled from 'styled-components';
import theme from '../../../../theme';
import { withTranslation } from 'react-i18next';
import { Paragraph } from '../../../../components/styled/Typography';
import { FlexContainer } from '../../../../components/styled/Wrappers';

class SplitedTransferSection extends Component {
  goToSiblingTransfer = (e, transfer) => {
    // e.stopPropagation();
    // const { history, travelerAppStore: { currentTrip, setChosenTransfer } } = this.props;
    // if (document.getElementById("right-container")) {
    //     document.getElementById("right-container").scrollTo(0, 0);
    // }
    // setChosenTransfer(transfer);
    // history.push(`/trip/${currentTrip.id}/transfer/${transfer.id}`);
  };

  isDriverConfirmed = (siblingTransfer) => {
    if (siblingTransfer.operatorConfirmedDriver) {
      const {
        operatorConfirmedDriver: { vehicleColor, vehicleBrand, vehicleModel, fullName }
      } = siblingTransfer;
      return {
        fullName,
        vehicleColor,
        vehicleBrand,
        vehicleModel
      };
    }
    return {
      fullName: "Driver hasn't been confirmed yet",
      vehicleColor: 'See more details'
    };
  };

  handleSplittedTransfer = (id) => {
    const {
      transfer,
      history,
      travelerAppStore: { currentTrip, setChosenTransfer }
    } = this.props;

    if (document.getElementById('right-container') && document.getElementById('right-container').scrollTo) {
      document.getElementById('right-container').scrollTo(0, 0);
    }
    setChosenTransfer(transfer);
    history.push(`/trip/${currentTrip.id}/transfer/${id}`, { tripListItem: true });
  };

  render() {
    const {
      transfer,
      travelerAppStore: { siblingTransfers, chosenTransfer },
      splittedTransferId,
      t,
      brandedSettingsStore: {
        brandedSettings: {
          coBrandingThemeProperties: { mainColor }
        }
      }
    } = this.props;
    if (transfer.splittedTransferId) {
      return (
        <StyledSplittedTransferSection align="flex-start" justify="center">
          <Title maxWidth="100%" align="left" size="12px">
            {t('trip_details.split_info').toUpperCase()} {siblingTransfers[splittedTransferId].length}
          </Title>
          <div style={{ width: '100%' }}>
            {siblingTransfers[splittedTransferId].map((siblingTransfer, index) => {
              const { vehicleColor, vehicleBrand, vehicleModel, fullName } = this.isDriverConfirmed(siblingTransfer);
              return (
                <div key={siblingTransfer.id} onClick={() => this.handleSplittedTransfer(siblingTransfer.id)}>
                  <GoToLayout arrowVisible>
                    {chosenTransfer.id === siblingTransfer.id && <div className="chosenMarker" />}
                    <SplittedTransfer align="center" justify="space-between">
                      <TransferNumber align="center" justify="center" color={mainColor}>
                        {index + 1}
                      </TransferNumber>
                      <div className="driver-info-wrapper">
                        <p className="driver-name">{fullName}</p>
                        <p className="driver-car">
                          {vehicleColor && <span>{vehicleColor}</span>}
                          {vehicleBrand && <span> {vehicleBrand}</span>}
                          {vehicleModel && <span> {vehicleModel}</span>}
                        </p>
                      </div>
                    </SplittedTransfer>
                  </GoToLayout>
                </div>
              );
            })}
          </div>
        </StyledSplittedTransferSection>
      );
    }
    return null;
  }
}

const SplittedTransfer = styled(FlexContainer)`
  flex-direction: row;

  .driver-info-wrapper {
    .driver-name {
      color: ${theme.colors.base.graphite};
      font-size: 14px;
      line-height: 21px;
      margin: 0;
    }

    .driver-car {
      color: ${theme.colors.greys.grey5};
      font-size: 12px;
      line-height: 18px;
      margin: 2px 0;
    }
  }
`;

const TransferNumber = styled(FlexContainer)`
  width: 20px;
  height: 20px;
  background-color: ${(props) => props.color};
  border-radius: 100%;
  color: #fff;
  font-size: 12px;
  font-weight: 700;
  margin-right: 15px;
`;

const Title = styled(Paragraph)`
  letter-spacing: 0.4px;
  line-height: 15px !important;
  border-top: 1px dashed rgba(45, 59, 78, 0.12);
  padding-top: 20px;
  margin-top: 16px;
  margin-bottom: 5px;
  width: 100%;
`;

const StyledSplittedTransferSection = styled(FlexContainer)`
  flex-direction: column;

  .main-container {
    padding-top: 16px;
    height: 48px;
    border-bottom: 1px dashed rgba(45, 59, 78, 0.12);
    position: relative;
    padding-bottom: 16px;

    :last-child {
      border-bottom: none;
      padding-bottom: none;
    }

    .chosenMarker {
      position: absolute;
      width: 3px;
      height: 100%;
      border-radius: 0 2px 2px 0;
      background-color: ${theme.colors.cta.green};
      left: -72px;
    }
  }

  .splitted-transfer-wrapper {
    width: calc(100% - 40px);
    padding: 0 0 16px 0;
    position: relative;
    margin-left: 40px;
  }

  @media (max-width: ${theme.breakpoints[0]}px) {
    .subtitle {
      margin-left: 36px;
    }

    .splitted-transfer-wrapper {
      margin-left: 36px;
    }
  }
`;

export default withTranslation()(
  withRouter(inject('travelerAppStore', 'brandedSettingsStore')(observer(SplitedTransferSection)))
);
