import { Preferences } from '@capacitor/preferences';

export async function set(key, value) {
  // TODO: return without await
  try {
    await Preferences.set({
      key: key,
      value: JSON.stringify(value)
    });
  } catch (e) {
    return Promise.resolve(null);
  }
}

export async function get(key) {
  let item = null;
  try {
    item = await Preferences.get({ key: key });
  } catch (e) {
    return Promise.resolve(null);
  }

  if (item && typeof item.value === 'string') {
    let parsed = null;
    try {
      parsed = JSON.parse(item.value);
    } catch (e) {
      parsed = null;
    }
    return Promise.resolve(parsed);
  }
  return Promise.resolve(null);
}

export async function remove(key) {
  try {
    await Preferences.remove({
      key: key
    });
  } catch (err) {}
}
