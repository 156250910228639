import React, { memo } from 'react';
import { Text2 } from '../../components/styled/Typography';
import BookBowtie from '../../components/svgs/BookBowTie';
import BookClock from '../../components/svgs/BookClock';
import BookHand from '../../components/svgs/BookHand';
import BookPlane from '../../components/svgs/BookPlane';
import styled from 'styled-components';
import theme from '../../theme';
import { withTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { useStores } from '../../stores/useStores';

const Footer = ({ t }) => {
  const {
    brandedSettingsStore: {
      brandedSettings: {
        coBrandingThemeProperties: { mainColor }
      }
    }
  } = useStores();

  const blocks = [
    {
      icon: BookBowtie,
      text: t('book.enjoy_ride')
    },
    {
      icon: BookPlane,
      text: t('book.driver_monitors')
    },
    {
      icon: BookClock,
      text: t('book.cutt_off')
    },
    {
      icon: BookHand,
      text: t('book.cancel_refund')
    }
  ];

  return (
    <FooterWrapper>
      {blocks.map(({ icon, text }) => {
        const BookIcon = icon;

        return (
          <Block key={text}>
            <div>
              <BlockImage>
                <BookIcon fill={mainColor} />
              </BlockImage>
            </div>
            <Text2 as="span">{text}</Text2>
          </Block>
        );
      })}
    </FooterWrapper>
  );
};

export default withTranslation()(memo(observer(Footer)));

const FooterWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 20px auto;
  padding: 0 20px;
`;

const Block = styled.div`
  display: flex;
  justify-content: space-between;
  width: 250px;
  margin-right: 40px;
  margin-bottom: 20px;

  :nth-of-type(even) {
    margin-right: 0px;
  }

  @media (max-width: ${theme.breakpoints[0]}px) {
    width: 100%;
    margin-right: 0px;
    justify-content: flex-start;
    align-items: center;
  }
`;

const BlockImage = styled.div`
  min-width: 40px;
  max-width: 40px;
  min-height: 40px;
  max-height: 40px;
  margin-right: 20px;
`;
