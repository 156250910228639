import React, { Fragment, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import OneTimePassword from '@/src/components/formFields/OneTimePassword';
import { useStores } from '@/src/stores/useStores';
import { Text } from '@/src/theme/components/Typography';
import { Wrapper } from '@/src/theme/components/Wrapper';
import { IonToast } from '@ionic/react';
import { observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import { get as getStorage } from '@/src/services/storage';
import theme from '@/src/theme';
import { formatTime } from '@/src/helpers/timer';
import Loading from '@/src/components/common/Loading';
import AuthenticationService from '@/src/services/authentication.js';
import { MERGE_ACCOUNTS_CONFIRMATION_TIMER } from '@/src/constants';

const VerificationScreen = (props) => {
  const { t, history } = props;

  const [showErrorToast, setShowErrorToast] = useState(false);
  const [errorToastMessage, setErrorToastMessage] = useState('');
  const [confirmationMethod, setConfirmationMethod] = useState();

  const {
    duplicateTravelersStore: {
      duplicateTravelersInfo,
      sendConfirmationCode,
      mergeAccounts,
      secondsUntilResend,
      startTimer,
      confirmationCodeLoading,
      mergeAccountsLoading
    },
    travelerAppStore: { travelerInfo, profileInformation, loginWithTokenInProgress }
  } = useStores();

  const isLoading = mergeAccountsLoading || loginWithTokenInProgress;

  /**
   * @param {'email'|'phone'} [_confirmationMethod]
   * @param {string} [code]
   */
  const completeMerge = async (_confirmationMethod, code) => {
    try {
      const data = await mergeAccounts(_confirmationMethod, code);

      // We always re-login the user after merging accounts
      // to make sure we have the correct data
      await AuthenticationService.loginWithToken(data.mainAccountTravelerToken, true);

      history.replace('/profile/merge_accounts/success');
      return;
    } catch (err) {
      if (err?.response?.status === 401) {
        setErrorToastMessage(t('duplicate_travelers.invalid_code_error'));
      } else {
        setErrorToastMessage(t('duplicate_travelers.merge_error'));
      }

      setShowErrorToast(true);
    }
  };

  const resendCode = async (method) => {
    setConfirmationMethod(method);
    try {
      await sendConfirmationCode(method);
      // eslint-disable-next-line no-unused-vars
    } catch (err) {}
  };

  const renderResendButtons = () => {
    if (confirmationCodeLoading) {
      return null;
    }

    return (
      <Fragment>
        <Wrapper
          styles={{
            margin: '8px 0'
          }}
        >
          <Text
            element="span"
            color={theme.colors.base.graphiteLight}
            onClick={() => resendCode('phone')}
            styles={{
              cursor: 'pointer'
            }}
          >
            {t('duplicate_travelers.resend_code_via_sms')}
          </Text>
        </Wrapper>

        <Wrapper
          styles={{
            margin: '8px 0'
          }}
        >
          <Text
            element="span"
            color={theme.colors.base.graphiteLight}
            onClick={() => resendCode('email')}
            styles={{
              cursor: 'pointer'
            }}
          >
            {t('duplicate_travelers.resend_code_via_email')}
          </Text>
        </Wrapper>
      </Fragment>
    );
  };

  useEffect(() => {
    let _confirmationMethod;

    // If user has verified email or phone, we start the merge process immediately
    if (travelerInfo.emailConfirmedAt) {
      _confirmationMethod = 'email';
      completeMerge(_confirmationMethod);
    } else if (travelerInfo.phoneConfirmedAt) {
      _confirmationMethod = 'phone';
      completeMerge(_confirmationMethod);
    } else {
      // If user has not verified email or phone, we check the duplicateOn value to send the verification code
      const { duplicateOn } = duplicateTravelersInfo;

      // Checks and starts the timer to resend verification code if found in storage
      const startTimerOrResendCode = async () => {
        const time = await getStorage(MERGE_ACCOUNTS_CONFIRMATION_TIMER);

        // If time is found in storage, we start the timer from that time
        // Otherwise, we send the verification code immediately
        if (time && time !== '0') {
          startTimer(time);
        } else {
          try {
            await resendCode(_confirmationMethod);
            // eslint-disable-next-line no-unused-vars
          } catch (err) {}
        }
      };

      // If accounts are duplicated on email or both email and phone, we send the verification code to email
      // Otherwise, we send it to phone
      if (duplicateOn === 'both' || duplicateOn === 'email') {
        _confirmationMethod = 'email';
      } else {
        _confirmationMethod = 'phone';
      }

      startTimerOrResendCode();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return isLoading ? (
    <Loading loadingText={t('duplicate_travelers.wait_while_updating_account')} />
  ) : (
    <Fragment>
      <Text element="h3" variant="h4" styles={{ marginBottom: '22px' }}>
        {confirmationMethod === 'phone'
          ? t('duplicate_travelers.verify_your_phone')
          : t('duplicate_travelers.verify_your_email')}
      </Text>

      <Text size="16" weight="700" styles={{ marginBottom: '55px' }}>
        {t('duplicate_travelers.enter_code')}
        <br />
        {confirmationMethod === 'phone' ? profileInformation.phoneOrigin : profileInformation.contactEmail}
        <br />
        {confirmationMethod === 'phone'
          ? t('duplicate_travelers.to_verify_phone')
          : t('duplicate_travelers.to_verify_email')}
      </Text>

      <OneTimePassword onComplete={(code) => completeMerge(confirmationMethod, code)} />

      {secondsUntilResend === 0 ? (
        renderResendButtons()
      ) : (
        <Text styles={{ marginTop: '8px', color: '#ABB1B8' }}>
          {t('duplicate_travelers.resend_in', { time: formatTime(secondsUntilResend) })}
        </Text>
      )}

      <IonToast
        isOpen={showErrorToast}
        onDidDismiss={() => {
          setShowErrorToast(false);
        }}
        message={errorToastMessage}
        duration={2000}
        cssClass="styled-toast-notify"
      />
    </Fragment>
  );
};

export default withTranslation()(withRouter(observer(VerificationScreen)));
