export const getDriver = (channel, currentUser) => {
  const members = Object.values(channel?.state?.members);

  if (members.length === 2) {
    const driver = members.find((m) => m.user.member_type === 'driver');
    return driver?.user;
  }
};

export const scrollToLastMessage = () => {
  const messages = document.querySelectorAll('.str-chat__li');
  const lastMessage = messages[messages.length - 1];

  if (lastMessage) {
    lastMessage.scrollIntoView();
  }
};
