import React from 'react';

const WelcomeLogo = ({ styles, fill }) => (
  <svg
    width="26px"
    height="26px"
    style={styles}
    viewBox="0 0 26 26"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xlink="http://www.w3.org/1999/xlink"
  >
    <title>Welcome Logo</title>
    <desc>Created with Sketch.</desc>
    <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="My_trip.Hero.Section-/-Mobile" transform="translate(-20.000000, -17.000000)" fill="#FFFFFF">
        <g id="Navigation.Panel" transform="translate(20.000000, 17.000000)">
          <g id="Welcome.Logo.Container">
            <g id="Welcome.Logo">
              <path
                fill={fill}
                d="M21.1966931,12.9561814 L16.505115,17.6477595 C16.3537129,17.7991616 16.1551941,17.8748626 15.9566752,17.8748626 C15.7584705,17.8748626 15.5596376,17.7991616 15.4085497,17.6477595 C15.1054315,17.3449555 15.1054315,16.8539983 15.4085497,16.5511942 L20.1001278,11.8593019 C20.4026178,11.556812 20.8942032,11.556812 21.1966931,11.8593019 C21.4994972,12.1624201 21.4994972,12.6533773 21.1966931,12.9561814 M12.9999381,15.2382057 C11.3323171,15.2382057 9.97566695,13.8815556 9.97566695,12.2139345 C9.97566695,10.5463134 11.3323171,9.1896633 12.9999381,9.1896633 C14.6678733,9.1896633 16.0245235,10.5463134 16.0245235,12.2139345 C16.0245235,13.8815556 14.6678733,15.2382057 12.9999381,15.2382057 M10.5919549,17.6477595 C10.4405528,17.7991616 10.242034,17.8748626 10.0435152,17.8748626 C9.84499632,17.8748626 9.64647748,17.7991616 9.49507545,17.6477595 L4.80349728,12.9561814 C4.50037911,12.6533773 4.50037911,12.1624201 4.80349728,11.8593019 C5.10630133,11.556812 5.59757264,11.556812 5.9003767,11.8593019 L10.5919549,16.5511942 C10.8947589,16.8539983 10.8947589,17.3449555 10.5919549,17.6477595 M12.9999381,0 C5.82027811,0 9.52042224e-05,5.82049702 9.52042224e-05,13.0001571 C9.52042224e-05,20.179503 5.82027811,26 12.9999381,26 C20.1799123,26 26.0000952,20.179503 26.0000952,13.0001571 C26.0000952,5.82049702 20.1799123,0 12.9999381,0 M12.9999381,10.7404347 C12.1873302,10.7404347 11.5264383,11.4016406 11.5264383,12.2139345 C11.5264383,13.0265425 12.1873302,13.6874343 12.9999381,13.6874343 C13.8125461,13.6874343 14.4737521,13.0265425 14.4737521,12.2139345 C14.4737521,11.4016406 13.8125461,10.7404347 12.9999381,10.7404347"
                id="Glyph"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default WelcomeLogo;
