import React, { Fragment, useEffect, useState } from 'react';
import { FlexContainer } from '../../components/styled/Wrappers';
import { Heading2, Paragraph } from '../../components/styled/Typography';
import MenuIcon from '../../assets/icons/ellipsis_horizontal_white_icon.svg';
import styled from 'styled-components';
import TripPreviewActionsModal from './TripPreviewActionsModal';
import { dateLocale } from '../../components/common/helpers';
import i18n from '../../services/i18n';
import { t } from 'i18next';
import mixpanel from '../../services/mixpanel';
import PropTypes from 'prop-types';

const TripPreviewCardHeaderContent = (props) => {
  const { tripPreview } = props;
  const { title, startDate, endDate, tripActions, timeZone } = tripPreview;

  const [showActionsModal, setShowActionsModal] = useState(false);

  const shouldShowActions = () => {
    for (const tripActionKey in tripActions) {
      if (tripActions[tripActionKey]) {
        return true;
      }
    }

    return false;
  };

  const openActionsMenu = (e) => {
    e.stopPropagation();
    setShowActionsModal(true);
  };

  useEffect(() => {
    if (showActionsModal) {
      const activeTripActions = [];

      if (tripActions.bookAgain) {
        activeTripActions.push('Book Again');
      }
      if (tripActions.bookExtraTransferUrl) {
        activeTripActions.push('Book extra transfer');
      }
      if (tripActions.cancelTransferUrl) {
        activeTripActions.push('Cancel transfer');
      }
      if (tripActions.editTransferUrl) {
        activeTripActions.push('Edit transfer');
      }
      if (tripActions.receiptUrl) {
        activeTripActions.push('Download receipt');
      }

      mixpanel.trackTripActionsClick({ Available_Actions: activeTripActions });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showActionsModal]);

  return (
    <Fragment>
      <FlexContainer align="center" justify="center" flexDirection="column" height="100%">
        <Heading2
          marginTop="0"
          marginBottom="4px"
          color="#fff"
          textOverflow="ellipsis"
          textWrap="nowrap"
          maxWidth="330px"
          overflow="hidden"
        >
          {title}
        </Heading2>

        <Paragraph color="#fff">
          {startDate && endDate
            ? `${dateLocale(startDate, timeZone, 'dd MMM, EEE', i18n.language)} - ${dateLocale(endDate, timeZone, 'dd MMM, EEE, yyyy', i18n.language)}`
            : t('trips.trip_exact_date', {
                date: dateLocale(startDate, timeZone, 'dd MMMM, EEE', i18n.language),
                time: dateLocale(startDate, timeZone, 'HH:mm, yyyy', i18n.language)
              })}
        </Paragraph>

        {shouldShowActions() && (
          <ActionsMenu src={MenuIcon} alt="Menu" style={{ width: '24px', height: '24px' }} onClick={openActionsMenu} />
        )}
      </FlexContainer>

      {shouldShowActions() && (
        <TripPreviewActionsModal
          isOpen={showActionsModal}
          onClose={(e, internalLink) => {
            setShowActionsModal(false);
          }}
          tripPreview={tripPreview}
        />
      )}
    </Fragment>
  );
};

const ActionsMenu = styled.img`
  position: absolute;
  top: 16px;
  right: 20px;
  padding: 4px;
  box-sizing: initial;
  cursor: pointer;
`;

TripPreviewCardHeaderContent.propTypes = {
  tripPreview: PropTypes.object.isRequired
};

export default TripPreviewCardHeaderContent;
