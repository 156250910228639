import { makeAutoObservable, set } from 'mobx';
import TravelerApi from '../API';
import { isWebPlatform, fromCoBrandedDomain } from '../components/common/helpers';
import welcomeLogo from '../assets/branding/welcome_graphite.svg';
import whiteWelcomeLogo from '../assets/branding/welcome_light.svg';
import welcomeFavicon from '../assets/branding/welcome-favicon.png';
import theme from '../theme';
import travelerAppStore from './travelerAppStore';
import { logError } from '../helpers/log';

const welcomeThemeSettings = {
  partnerId: '',
  partnerName: 'Welcome Pickups',
  quoteUrl: '',
  coBrandedTermsUrl: '/terms_and_conditions',
  brandLogoUrl: welcomeLogo,
  brandLogoForTravelerAppUrl: whiteWelcomeLogo,
  helpCenterUrl: process.env.REACT_APP_HELP_CENTER_URL,
  brandFaviconUrl: welcomeFavicon,
  coBrandingThemeProperties: {
    themeName: 'Welcome',
    mainColor: theme.colors.cta.green,
    mainMediumColor: theme.colors.cta.greenMedium,
    widgetButtonBackgroundColor: theme.colors.cta.green,
    widgetButtonFontColor: theme.colors.base.white
  }
};

function changeFavicon(src) {
  var favicon = document.getElementById('favicon');
  favicon.href = src;
}

class BrandedSettingsStore {
  brandedSettings = { ...welcomeThemeSettings };

  constructor() {
    makeAutoObservable(this);
  }

  get isPartner() {
    return this.brandedSettings.partnerId !== '';
  }

  get getCoBrandedHelpCenterUrl() {
    return travelerAppStore.isAuthorised && travelerAppStore.travelerInfo.accessToken
      ? `${this.brandedSettings.helpCenterUrl}?travelers_access_token=${travelerAppStore.travelerInfo.accessToken}`
      : this.brandedSettings.helpCenterUrl;
  }

  setBrandedSettings = (brandedSettings) => {
    if (brandedSettings.partnerId !== this.brandedSettings.partnerId) {
      document.title = `${brandedSettings.partnerName} Transfer App`;
      changeFavicon(brandedSettings.brandFaviconUrl);
      set(this.brandedSettings, brandedSettings);
    }
  };

  resetBrandedSettings = () => {
    if (this.isPartner) {
      document.title = `Welcome Pickups Traveler App`;
      changeFavicon(welcomeThemeSettings.brandFaviconUrl);
      set(this.brandedSettings, welcomeThemeSettings);
    }
  };

  fetchBrandedSettings = async () => {
    if (isWebPlatform() && fromCoBrandedDomain()) {
      console.log('Web branded case');
      return TravelerApi.getBrandedSettings()
        .then((brandedSettings) => {
          if (brandedSettings?.id) {
            this.setBrandedSettings(brandedSettings);
            console.log('Branded property', brandedSettings.id);
            return true;
          } else {
            return false;
          }
        })
        .catch((err) => {
          if (err.response?.status !== 404) {
            logError('Fetch branded settings error', err);
          }

          return false;
        });
    } else {
      return Promise.resolve(false);
    }
  };
}
const brandedSettingsStore = new BrandedSettingsStore();

export default brandedSettingsStore;
