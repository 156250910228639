import React from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react';
import theme from '../../../theme';
import { withTranslation } from 'react-i18next';

import Clock from '../../svgs/Clock';
import Plane from '../../svgs/Plane';
import Ferry from '../../svgs/Ferry';
import Train from '../../svgs/Train';

const HubPickupInfo = ({
  t,
  pickupWaitingTimeMinutes = null,
  hubPickupArrivalStatus = null,
  hubPickupItirenaryInfo = null,
  hubPickupKind = null,
  isHubPickup = null
}) => {
  const HubPickupInfoIcon = (hubPickupKind) => {
    switch (hubPickupKind) {
      case 'flight':
        return <Plane />;
      case 'ferry':
        return <Ferry />;
      case 'train':
        return <Train />;
      default:
        return null;
    }
  };

  const hubPickupArrivalStatusColor = hubPickupArrivalStatus
    ? hubPickupArrivalStatus === 'On time'
      ? theme.colors.cta.greenMedium
      : theme.colors.accent.red
    : '';

  const HubPickupInfoContainer = styled.div`
    display: flex;
    flex-flow: row wrap;
  `;

  const HubPickupInfoCard = styled.div`
    display: flex;
    min-width: unset;
    width: auto;
    align-items: center;
    padding: 9px 18px;
    justify-content: center;
    background: #f4f5f6;
    border-radius: 5em;
    font-weight: 700;
    font-size: 12px;
    line-height: 18px;
    margin-top: 8px;
    margin-right: 8px;
  `;

  const HubPickupInfoCardStatus = styled.span`
    flex: 1 1 auto;
    color: ${(props) => props.color};
    font-weight: 700;
    &:before {
      content: '|';
      margin: 0 12px;
      display: inline-block;
      color: #d8dadd;
      font-weight: 500;
    }
  `;

  const HubPickupInfoCardText = styled.span`
    color: #808994;
    flex: 1 1 auto;
    display: flex;
    font-weight: 500;
    align-items: center;
    svg {
      margin-right: 5px;
    }
  `;

  const Break = styled.div`
    flex-basis: 100%;
    height: 0;
  `;
  return (
    <HubPickupInfoContainer>
      {hubPickupItirenaryInfo && isHubPickup && (
        <HubPickupInfoCard>
          <HubPickupInfoCardText>
            {HubPickupInfoIcon(hubPickupKind)}
            {hubPickupItirenaryInfo}
          </HubPickupInfoCardText>
          {hubPickupArrivalStatus && (
            <HubPickupInfoCardStatus color={hubPickupArrivalStatusColor}>
              {hubPickupArrivalStatus}
            </HubPickupInfoCardStatus>
          )}
        </HubPickupInfoCard>
      )}
      <Break />
      {pickupWaitingTimeMinutes && pickupWaitingTimeMinutes > 0 && (
        <HubPickupInfoCard>
          <HubPickupInfoCardText>
            <Clock />
            {t('trip_details.waiting_time', { pickup_time_minutes: pickupWaitingTimeMinutes })}
          </HubPickupInfoCardText>
        </HubPickupInfoCard>
      )}
    </HubPickupInfoContainer>
  );
};

export default withTranslation()(observer(HubPickupInfo));
