import React, { Component } from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { observer, inject } from 'mobx-react';
import Timer from './Timer';
import VerificationCodeInput from './VerificationCodeInput';
import { trackEvent } from '../../tracking';
import { IonBackButton } from '@ionic/react';
import { Heading2, Paragraph } from '../../components/styled/Typography';
import { BackButton } from '../../components/styled/Buttons';
import AuthenticationService from '../../services/authentication.js';
import { StyledCard, StyledContent, StyledLoading, StyledPage } from '../../components/styled/IonElements';
import theme from '../../theme';
import { withTranslation } from 'react-i18next';
import mixpanel from '../../services/mixpanel';
import { get as getStorage } from '../../services/storage';
import PropTypes from 'prop-types';
import { RETURN_URL_QUERY_PARAM } from '@/src/constants';
import { getQueryParam } from '@/src/components/common/helpers';

const COUNT_DOWN_MIN_SECONDS = 30;
class VerificationCodeInputPagePhoneOnly extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      resendAttempts: 0,
      showDidntGetCodePopup: false
    };
  }

  onBackButtonPress = () => {
    const {
      history: { goBack }
    } = this.props;
    trackEvent('traveler app', 'login', 'back');
    goBack();
  };

  onResendSMSButtonPress = async () => {
    const { resendAttempts } = this.state;

    if (resendAttempts > 2) {
      this.setState({
        resendAttempts: 0,
        showDidntGetCodePopup: true
      });
    }

    this.setState({
      resendAttempts: resendAttempts + 1
    });

    trackEvent('traveler app', 'login', 'resend sms');
    this.resendCode('sms').then(() => this.startTimer());
    // NotificationToast(codeResent);
  };

  onLoginSuccess = async (data) => {
    const {
      location,
      history: { replace }
    } = this.props;

    trackEvent('traveler app', 'login', 'success');
    // save traveler info to storage, store, set API conf etc. synchronously
    await AuthenticationService.onLoginSuccess(data);

    mixpanel.trackLoginCompleted('phone', {
      $phone: location.state?.phone
    });

    const postLoginDestination = getQueryParam(RETURN_URL_QUERY_PARAM);
    replace(postLoginDestination && !postLoginDestination.includes('login') ? postLoginDestination : '/trips');
  };

  resendCode = async (param) => {
    const { location } = this.props;
    const {
      travelerAppStore: { codeRequest }
    } = this.props;
    const formData = new FormData();
    formData.append('phone', location.state?.phone);
    formData.append('message_method', param);
    formData.append('new_login', true);
    try {
      const res = await codeRequest(formData);
      return res;
      // eslint-disable-next-line no-unused-vars
    } catch (err) {}

    return null;
  };

  togglePopups = () => {
    const { showDidntGetCodePopup } = this.state;
    this.setState({
      showDidntGetCodePopup: !showDidntGetCodePopup
    });
  };

  login = (code) => {
    const {
      travelerAppStore: { login },
      location
    } = this.props;
    const loginData = new FormData();
    this.setState({ isLoading: true });
    loginData.append('phone', location.state?.phone);
    loginData.append('code', code);

    trackEvent('traveler app', 'login', 'attempt');

    login(loginData)
      .then((data) => {
        mixpanel.trackLoginAttempted({
          Login_Method: 'phone',
          $phone: location.state?.phone,
          Status: 'success'
        });

        this.onLoginSuccess(data);
        this.setState({ isLoading: false });
      })
      .catch(() => {
        mixpanel.trackLoginAttempted({
          Login_Method: 'phone',
          $phone: location.state?.phone,
          Status: 'fail'
        });

        this.setState({ isLoading: false });
      });
  };

  async startTimer() {
    const {
      travelerAppStore: { keepTrackOfCountDown, apiTimeUntilSmsResend }
    } = this.props;
    const storageTimeUntilSmsResend = parseInt(await getStorage('timeUntilSmsResend'));
    const time =
      apiTimeUntilSmsResend ||
      (storageTimeUntilSmsResend > COUNT_DOWN_MIN_SECONDS && storageTimeUntilSmsResend) ||
      COUNT_DOWN_MIN_SECONDS;
    keepTrackOfCountDown(time);
  }

  componentDidMount() {
    this.startTimer();
  }

  componentWillUnmount() {
    const {
      travelerAppStore: { setLoginError, clearAndCompleteTimer }
    } = this.props;
    setLoginError({});
    clearAndCompleteTimer();
  }

  render() {
    const {
      history: { goBack },
      travelerAppStore: { loginError, timerCompleted },
      location,
      t
    } = this.props;
    const { error } = loginError;
    const completed = timerCompleted;
    const { isLoading } = this.state;

    return (
      <StyledPage>
        <StyledContent>
          <StyledLoading cssClass="loading-modal" isOpen={isLoading} message={t('please_wait')} duration={5000} />
          <StyledCard height="400px" margin="20px" padding="10px">
            <BackButton style={{ margin: '20px' }} onClick={() => goBack()} />
            <IonBackButton />
            <Heading2 align="center">{t('sign_in.sms_code')}</Heading2>
            <VerificationSMSParagraph maxWidth="100%">{`${t('sign_in.sms_code_sent')} ${location.state?.phoneNumber}`}</VerificationSMSParagraph>
            <VerificationCodeInput login={this.login} />
            {error && (
              <Paragraph maxWidth="100%" weight="700" margin="10px" color={theme.colors.accent.red}>
                {error}
              </Paragraph>
            )}
            {completed ? (
              <Button
                maxWidth="100%"
                weight="700"
                completed={completed}
                margin="20px"
                onClick={this.onResendSMSButtonPress}
              >
                {' '}
                {t('sign_in.resend_sms')}
              </Button>
            ) : (
              <Button maxWidth="100%" weight="700" margin="20px" completed={completed}>
                <Timer />
              </Button>
            )}
          </StyledCard>
        </StyledContent>
      </StyledPage>
    );
  }
}

const Button = styled(Paragraph)`
  ${(props) => (!props.completed ? 'Disabled' : '')};
  margin-top: ${(props) => props.margin};
  cursor: pointer;
`;

const VerificationSMSParagraph = styled(Paragraph)`
  margin-bottom: 40px;
  margin-top: 0;
`;

VerificationCodeInputPagePhoneOnly.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  travelerAppStore: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired
};

export default withTranslation()(withRouter(inject('travelerAppStore')(observer(VerificationCodeInputPagePhoneOnly))));
