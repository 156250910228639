import React from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import ArrowRight from '../../components/svgs/ArrowRight';
import { observer } from 'mobx-react';
import { useStores } from '../../stores/useStores';

const GreenArrow = ({ dontShowArrow }) => {
  const {
    brandedSettingsStore: {
      brandedSettings: {
        coBrandingThemeProperties: { mainColor }
      }
    }
  } = useStores();

  return (
    !dontShowArrow && (
      <ArrowRightWrapper>
        <ArrowRight fill={mainColor} />
      </ArrowRightWrapper>
    )
  );
};

const ArrowRightWrapper = styled.div`
  width: 20px;
  height: 20px;
  position: absolute;
  top: calc(50% - 10px);
  right: 0;
  display: ${(props) => (props.dontShowArrow ? 'none' : 'block')};
`;

export default withRouter(observer(GreenArrow));
