import { Heading2 } from '@/src/components/styled/Typography';
import truncateLines from '@/src/helpers/truncateLines';
import styled from 'styled-components';

export const LinkToTransfer = styled.a`
  cursor: pointer;

  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: center;
  overflow-wrap: no-wrap;
  flex: 0 0 auto;
`;

export const ChannelTitle = styled(Heading2)`
  margin-top: unset;
  margin-bottom: unset;
`;

export const ChannelHeading = styled.div`
  height: 96px;

  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  border-bottom: 1px solid #0000001a;
  padding: 24px;

  h2 {
    ${truncateLines(3, '31px')};
  }
`;

export const ChannelOuter = styled.div`
  max-height: 525px;
  overflow-y: auto;
`;
