import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { IonLabel } from '@ionic/react';
import theme from '../../../theme';
import truncateLines from '@/src/helpers/truncateLines';

export const MainMenuLink = styled(Link)`
  height: 100%;
  border-bottom: 3px solid ${(props) => (props.active === 'true' ? props.color : 'transparent')};
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 75px;
  padding-top: 8px;

  @media (min-width: ${theme.breakpoints[1]}px) {
    min-width: 80px;
  }

  ion-icon {
    width: 22px;
    height: 22px;
  }

  ion-label {
    font-size: 12px;
    margin-top: 3px;
    padding: 0 4px;
    ${truncateLines(1, '1.4em')};
  }
`;

export const MenuLabel = styled(IonLabel)`
  color: ${(props) => props.isActive && theme.colors.base.graphite};
  color: ${(props) => !props.isActive && props.hasNotification && '#F27470'};
`;

export const SmallNotificationBadge = styled.div`
  height: 6px;
  width: 6px;
  border-radius: 6px;
  background-color: #f27470;
  position: absolute;
  margin-bottom: 35px;
  margin-left: 28px;
`;
