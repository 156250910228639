import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import { withIonLifeCycle } from '@ionic/react';
import { Loader } from '@googlemaps/js-api-loader';

import { rendererOptions, mapOptions } from './MapTemplate';
import DirectionsService from './DirectionsService';
import { FlexContainer } from '../../../../../components/styled/Wrappers';
import theme from '../../../../../theme';
import { getDiffInSecondsFromTimestamps, isMobileAppPlatform } from '../../../../../components/common/helpers';

import { getTravelerFirebaseDb, getDriversFirebaseDb } from '../../../../../services/firebase.js';
import debounce from '../../../../../helpers/debounce';
import { App } from '@capacitor/app';
import { Geolocation } from '@capacitor/geolocation';

// https://developer.mozilla.org/en-US/docs/Web/API/PositionOptions
const geolocationOptions = {
  maximumAge: 15000,
  timeout: 5000,
  enableHighAccuracy: true
};

const mapLoader = new Loader({
  apiKey: process.env.REACT_APP_GMAPS_API_KEY,
  id: 'google-maps'
});

const driverPin = 'https://www.welcomepickups.com/wp-content/uploads/2020/11/driver-marker.png';
const noDriverPin = 'https://www.welcomepickups.com/wp-content/uploads/2020/11/no-driver-pin.png';
const travelerPin = 'https://www.welcomepickups.com/wp-content/uploads/2020/11/traveler-pin.png';
const carPin = 'https://www.welcomepickups.com/wp-content/uploads/2020/11/car-pin.png';

const DRIVER_LOCATION_UPDATE_FREQUENCY = 60; // After these seconds the driver's location will be updated
const TRAVELER_LOCATION_UPDATE_FREQUENCY = 60; // After these seconds the traveler's location will be updated

const defaultState = {
  directionsRenderer: null,
  driverMarker: null,
  mapDriverPin: null,
  lastUpdatedGpsStatus: 0,
  loadingMap: true,
  travelerMarker: null,
  watchingDriverLocation: false,
  driverLastLocation: null,
  travelerLastLocation: null
};

class MapCreator extends Component {
  constructor(props) {
    super(props);

    this.state = { ...defaultState };
    this.initializedMap = false;
    this.travelerLocationWatch = null;
    this.appStateChangeListener = null;
    this.driverLastLocationRef = null;

    this.updateDriverLocationDebounced = debounce(
      this.updateDriverLocation,
      DRIVER_LOCATION_UPDATE_FREQUENCY * 1000,
      true
    );

    this.updateTravelerLocationDebounced = debounce(
      this.updateTravelerLocation,
      TRAVELER_LOCATION_UPDATE_FREQUENCY * 1000,
      true
    );
  }

  handleAppStateChange = (state) => {
    if (state.isActive) {
      console.log('App is active again');
      this.initMap();
    } else {
      console.log('App is not active');
      this.clear();
    }
  };

  clear() {
    console.log('Run clear');
    if (this.travelerLocationWatch) {
      Geolocation.clearWatch({ id: this.travelerLocationWatch });
      this.travelerLocationWatch = null;
    }
    if (this.driverLastLocationRef) {
      this.driverLastLocationRef.off();
      this.driverLastLocationRef = null;
    }
    this.initializedMap = false;

    this.setState({ ...defaultState });

    this.updateDriverLocationDebounced.stop();
    this.updateTravelerLocationDebounced.stop();
  }

  componentDidMount() {
    console.log('componentDidMount');

    if (isMobileAppPlatform() && !this.appStateChangeListener) {
      this.appStateChangeListener = App.addListener('appStateChange', this.handleAppStateChange);
    }

    mapLoader.loadCallback((e) => {
      console.log('Map loaded');
      if (e) {
        console.log(e);
      } else {
        this.initMap();
      }
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { shareCurrentLocation } = this.props;
    const { shareCurrentLocation: previousShareCurrentLocation } = prevProps;

    // Start watching traveler's location if we do not already and if traveler changed his preference to true
    if (shareCurrentLocation && !previousShareCurrentLocation && !this.travelerLocationWatch) {
      this.watchTravelerLocation();
    }
  }

  componentWillUnmount() {
    console.log('componentWillUnmount');
    this.clear();

    if (this.appStateChangeListener) {
      this.appStateChangeListener.remove();
    }
  }

  watchTravelerLocation = async () => {
    if (this.travelerLocationWatch) {
      return;
    }

    let position = null;
    try {
      position = await Geolocation.getCurrentPosition(geolocationOptions);
    } catch (err) {
      console.log('No traveler position');
      position = null;
    }

    console.log('Current', position);
    if (!position) {
      return;
    }
    const location = this.geolocationPositionToLocation(position);

    this.setState({ travelerLastLocation: location }, async () => {
      this.updateTravelerMarkerOnMap(location);

      // Watch location
      this.travelerLocationWatch = await Geolocation.watchPosition(geolocationOptions, (position, err) => {
        if (err) {
          console.log('Watch traveler position error', err);
          return;
        }
        this.updateTravelerLocationDebounced.init(this.geolocationPositionToLocation(position));
      });
    });
  };

  watchDriverLocation = async () => {
    console.log('watchDriverLocation');
    const { transfer } = this.props;
    const driverLocationFirebasePath = transfer.driverLocationFirebasePath;
    if (!driverLocationFirebasePath) {
      console.log('no driver location in firebase');
      this.props.setNoDriverLocation(true);
      return;
    }

    const driversFirebaseDb = await getDriversFirebaseDb();
    this.driverLastLocationRef = driversFirebaseDb.ref(`/${driverLocationFirebasePath}`);
    this.driverLastLocationRef.on('value', (snapshot) => {
      const data = snapshot.val();
      if (!data) {
        console.log('no driver location data');
        this.props.setNoDriverLocation(true);
        return;
      }
      const location = this.driverFirebaseLocationToLocation(data);
      // Ignore driver location if is less than 5 seconds than previous one
      const { driverLastLocation } = this.state;
      if (
        driverLastLocation &&
        driverLastLocation.timestamp &&
        getDiffInSecondsFromTimestamps(driverLastLocation.timestamp, location.timestamp) <=
          DRIVER_LOCATION_UPDATE_FREQUENCY
      ) {
        return;
      }

      this.setState({ driverLastLocation: location }, () => {
        this.updateDriverLocationDebounced.init(location);
      });
    });
    this.setState({ watchingDriverLocation: true });
  };

  updateTravelerMarkerOnMap = (location) => {
    const { travelerMarker } = this.state;
    if (!travelerMarker || !location.lat || !location.lng) {
      return;
    }

    travelerMarker.setPosition({ lat: location.lat, lng: location.lng });
    this.zoomMapToMarkers();
    console.log('Traveler pin updated');
  };

  updateTravelerLocation = async (travelerLastLocation) => {
    if (!travelerLastLocation.lat && !travelerLastLocation.lng) {
      return;
    }
    console.log('New Traveler Location:', JSON.stringify(travelerLastLocation));
    const { travelerMarker } = this.state;

    if (
      !travelerMarker ||
      (this.state.travelerLastLocation &&
        this.state.travelerLastLocation.timestamp &&
        getDiffInSecondsFromTimestamps(this.state.travelerLastLocation.timestamp, travelerLastLocation.timestamp) <=
          TRAVELER_LOCATION_UPDATE_FREQUENCY)
    ) {
      return;
    }

    this.setState({ travelerLastLocation });

    this.updateTravelerMarkerOnMap(travelerLastLocation);

    // Send location to Firebase
    const { transfer } = this.props;
    const travelerFirebaseDb = await getTravelerFirebaseDb();
    travelerFirebaseDb.ref(`/transfers/${transfer.transferId}/traveler_last_known_position`).set(
      {
        lat: travelerLastLocation?.lat,
        lng: travelerLastLocation?.lng,
        timestamp: travelerLastLocation?.timestamp,
        accuracy: travelerLastLocation?.accuracy
      },
      (error) => {
        if (error) {
          // The write failed...
          console.log(error);
          // TODO: call rollbar
        }
      }
    );
  };

  geolocationPositionToLocation = (position) => {
    return {
      lat: position?.coords?.latitude,
      lng: position?.coords?.longitude,
      timestamp: position?.timestamp,
      accuracy: position?.coords?.accuracy
    };
  };

  driverFirebaseLocationToLocation = (data) => {
    return {
      lat: data[0],
      lng: data[1],
      timestamp: data['t'],
      accuracy: data['accuracy']
    };
  };

  updateDriverLocation = (driverLastLocation) => {
    console.log('New Driver Location:', JSON.stringify(driverLastLocation));
    const { mapDriverPin, driverMarker, directionsRenderer } = this.state;

    // Inform parent component for driver location status
    this.props.setNoDriverLocation(false);

    if (!driverMarker) {
      return;
    }

    // Change driver's pin if needed
    if (mapDriverPin === noDriverPin) {
      const newPin = this.getDriverPin();
      driverMarker.getIcon().url = newPin;
      this.setState({ mapDriverPin: newPin });
    }
    driverMarker.setPosition({ lat: driverLastLocation?.lat, lng: driverLastLocation?.lng });
    if (directionsRenderer) {
      this.reRenderDirections(driverLastLocation);
    }
    this.zoomMapToMarkers();
  };

  reRenderDirections = (driverLastLocation) => {
    const { directionsRenderer } = this.state;
    const destinationLat = this.props.transfer?.fromLatitude;
    const destinationLng = this.props.transfer?.fromLongitude;

    const routeOptions = {
      origin: `${parseFloat(driverLastLocation.lat)},${parseFloat(driverLastLocation.lng)}`,
      destination: `${destinationLat},${destinationLng}`,
      travelMode: 'DRIVING'
    };

    DirectionsService.getService().route(routeOptions, (response, status) => {
      if (status !== 'OK') {
        throw new Error(`Directions request failed due to ${status}`);
      }

      directionsRenderer.setDirections(response);
    });
  };

  zoomMapToMarkers = () => {
    if (!this.map) {
      return;
    }

    const { transfer } = this.props;
    const { driverMarker, travelerMarker, travelerLastLocation } = this.state;
    const driverPoint = driverMarker.getPosition();
    const travelerPoint = travelerLastLocation ? travelerMarker.getPosition() : null;
    const meetingPoint = new window.google.maps.LatLng(transfer.fromLatitude, transfer.fromLongitude);

    const bounds = new window.google.maps.LatLngBounds();
    bounds.extend(driverPoint);
    bounds.extend(meetingPoint);
    if (travelerPoint) {
      bounds.extend(travelerPoint);
    }

    this.map.fitBounds(bounds);
  };

  getDriverPin = () => {
    const { driverLastLocation } = this.state;
    if (!driverLastLocation) {
      return noDriverPin;
    }
    const transferType = this.props.transfer?.transferType;
    if (transferType === 'airport_pickup') {
      return driverPin;
    } else if (transferType === 'airport_dropoff') {
      return carPin;
    } else {
      return carPin;
    }
  };

  initMap = async () => {
    console.log('Init Map');

    if (this.initializedMap) {
      return;
    }

    this.initializedMap = true;

    const { transfer } = this.props;
    const { shareCurrentLocation } = this.props;
    const transferIsDropoff = transfer.transferType === 'airport_dropoff';

    const { driverLastLocation, travelerLastLocation } = this.state;
    const mapDriverPin = this.getDriverPin();

    const driverIcon = new window.google.maps.MarkerImage(
      mapDriverPin,
      null,
      null,
      new window.google.maps.Point(5, 5),
      new window.google.maps.Size(55, 55)
    );

    const travelerLocationIcon = new window.google.maps.MarkerImage(
      travelerPin,
      null,
      null,
      new window.google.maps.Point(10, 25),
      new window.google.maps.Size(55, 55)
    );

    // Set center at the transfer fromLocation
    let map = null;
    try {
      map = new window.google.maps.Map(
        document.getElementById('welcome-map'),
        mapOptions(transfer.fromLatitude, transfer.fromLongitude, transfer.transferType)
      );
    } catch (error) {
      console.log('Map: Expected mapDiv of type HTMLElement but was passed null.', error);
      this.setState({ loadingMap: false });
      return;
    }

    this.map = map;

    // Set driver marker if we have his location
    let driverMarkerPosition = {
      lat: null,
      lng: null
    };
    if (driverLastLocation) {
      driverMarkerPosition = {
        lat: driverLastLocation.lat,
        lng: driverLastLocation.ln
      };
    } else if (transfer.meetingPoint?.latitude) {
      driverMarkerPosition = {
        lat: transfer.meetingPoint.latitude,
        lng: transfer.meetingPoint.longitude
      };
    } else if (transfer.fromLatitude) {
      driverMarkerPosition = {
        lat: transfer.fromLatitude,
        lng: transfer.fromLongitude
      };
    }
    const driverMarker = new window.google.maps.Marker({
      position: { lat: Number(driverMarkerPosition?.lat), lng: Number(driverMarkerPosition?.lng) },
      map,
      icon: driverIcon,
      title: 'Driver',
      optimized: false
    });

    // Set traveler marker if we have his location
    const travelerMarker = new window.google.maps.Marker({
      position: { lat: Number(travelerLastLocation?.lat), lng: Number(travelerLastLocation?.lng) },
      map,
      icon: travelerLocationIcon,
      title: 'Traveler',
      optimized: false
    });

    // Set direction Options if dropoff and update it when driver location exists
    const directionsRenderer = transferIsDropoff
      ? await new window.google.maps.DirectionsRenderer(rendererOptions())
      : null;

    if (directionsRenderer) {
      directionsRenderer.setMap(map);
    }

    // Save all gmaps objects in state
    this.setState(
      {
        mapDriverPin,
        driverMarker,
        travelerMarker,
        directionsRenderer,
        loadingMap: false
      },
      () => {
        this.zoomMapToMarkers();

        this.watchDriverLocation();

        console.log('shareCurrentLocation', shareCurrentLocation);
        if (shareCurrentLocation) {
          this.watchTravelerLocation();
        }
      }
    );
  };

  render() {
    const { loadingMap } = this.state;

    return (
      <Fragment>
        {loadingMap && <LoadingMapContainer>Loading Map...</LoadingMapContainer>}
        <MapContainer id="welcome-map" style={{ height: `${window.innerHeight - 150}px` }} />
      </Fragment>
    );
  }
}

export default withIonLifeCycle(MapCreator);

const MapContainer = styled.div`
  width: 100%;

  @media (max-width: 768px) {
    height: 100%;
  }
`;

const LoadingMapContainer = styled(FlexContainer)`
  position: fixed;
  z-index: 10000;
  width: 100%;
  height: 100%;
  background: ${theme.colors.greys.grey6};
  top: 0;
  color: white;
  font-size: 20px;
  align-items: center;
  justify-content: center;
  font-weight: 600;
`;
