import React from 'react';
import { FlexContainer } from '../../styled/Wrappers';
import styled from 'styled-components';
import { Text2, Text3 } from '../../styled/Typography';
import theme from '../../../theme';
import { t } from 'i18next';
import { observer } from 'mobx-react';
import TalonOneProgress1 from '../../../assets/icons/talon-one-progress-1.svg';
import TalonOneProgressSuccess from '../../../assets/icons/talon-one-progress-success.svg';

const Invitation = ({ invitation }) => {
  const { name, amount, status } = invitation;

  const getTransactionSubtitle = () => {
    if (isTransactionPending()) {
      return t('talon_one.sign_up_completed');
    }

    return t('talon_one.trip_operated');
  };

  const getTransactionIcon = () => {
    if (!isTransactionPending()) {
      return TalonOneProgressSuccess;
    }

    return TalonOneProgress1;
  };

  const getTransactionColor = () => {
    if (!isTransactionPending()) {
      return theme.colors.cta.greenDark;
    } else {
      return '#E6943F';
    }
  };

  const isTransactionPending = () => {
    if (status === 'trip_operated') {
      return false;
    }

    return true;
  };

  const renderPrice = () => {
    const prefix = isTransactionPending() ? '' : '+';
    return `${prefix}${amount.toFixed(2)}€`;
  };

  return (
    <TransactionContainer align="center" gap="12px" justify="space-between">
      <img src={getTransactionIcon()} alt="transaction icon" />

      <div style={{ flex: 1 }}>
        <Text2 weight="600">{name}</Text2>

        <Text3 color={theme.colors.base.graphiteLight}>{getTransactionSubtitle()}</Text3>
      </div>

      <div className="amount">
        <Text2 weight="600" color={getTransactionColor()}>
          {renderPrice()}
        </Text2>

        {isTransactionPending() && <Text3 color={getTransactionColor()}>{t('talon_one.pending')}</Text3>}
      </div>
    </TransactionContainer>
  );
};

const TransactionContainer = styled(FlexContainer)`
  margin-bottom: 24px;

  .amount {
    min-width: 50px;
    text-align: right;
  }
`;

export default observer(Invitation);
