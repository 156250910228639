import React, { useEffect, useRef } from 'react';
import { withTranslation } from 'react-i18next';
import { useStores } from '../../../../../stores/useStores';
import { observer } from 'mobx-react';
import { getDriversFirebaseDb } from '../../../../../services/firebase.js';
import styled from 'styled-components';
import theme from '../../../../../theme';
import { IonContent, IonProgressBar, IonRow, IonCol } from '@ionic/react';
import Header from '../../../../../components/common/Modals/Header';
import { sortObjectsByDate } from '../../../../../components/common/helpers';
import emailIcon from '../../../../../assets/icons/mail_icon.svg';
import smsIcon from '../../../../../assets/icons/sms_icon.svg';
import { format, parseISO } from 'date-fns';
import './TimelineModal.css';
import { StyledModal } from '../../../../../components/styled/IonElements';

const eventsMapping = {
  driver_at_pickup_location: { icon: 'driver', className: 'driver' },
  driver_cannot_find_traveler: { icon: 'driver', className: 'driver' },
  driver_terminated_ride: { icon: 'driver', className: 'driver' },
  email_opened: { icon: emailIcon, className: 'traveler' },
  email_sent: { icon: null, className: 'welcome' },
  sms_received: { icon: smsIcon, className: 'traveler' },
  sms_sent: { icon: null, className: 'welcome' }
};

const formattedEvents = (timelineData) => {
  let travelerMessages = timelineData.traveler_messages;
  let formattedEvents = timelineData.events.map((event) => {
    const key = Object.keys(event)[0];
    let timestamp = event[key];
    if (timestamp) {
      timestamp = parseISO(timestamp);
    }
    const message = travelerMessages[key];
    const icon = eventsMapping[key]?.icon;
    const className = eventsMapping[key]?.className;
    return {
      timestamp,
      key,
      message,
      icon,
      className
    };
  });

  const sortedEvents = sortObjectsByDate(formattedEvents, 'timestamp', false);

  return sortedEvents;
};

const TimelineModal = (props) => {
  const { travelerAppStore } = useStores();
  const {
    brandedSettingsStore: {
      brandedSettings: {
        coBrandingThemeProperties: { mainColor }
      }
    }
  } = useStores();
  const chosenTransfer = travelerAppStore.chosenTransfer;
  const timelineEvents = chosenTransfer.timelineData;
  const fetchedTimelineEvents = timelineEvents !== undefined;
  // TODO: use state and update on result of first fetch of events
  const isLoading = !fetchedTimelineEvents;
  const timelineEventsRef = useRef(null);

  const { isOpen, t } = props;
  const events = fetchedTimelineEvents ? formattedEvents(timelineEvents) : null;
  const driverImage = chosenTransfer?.operatorConfirmedDriver?.professionalPhotoUrl;

  const modalContainerRef = useRef(null);

  const onClose = () => {
    modalContainerRef.current?.dismiss();
    props.onClose();
  };

  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    async function fetchTimelineEvents(travelerAppStore, chosenTransfer) {
      const driversFirebaseDb = await getDriversFirebaseDb();
      timelineEventsRef.current = driversFirebaseDb.ref(`/${chosenTransfer.tnsTimelineFirebasePath}`);
      timelineEventsRef.current.on('value', (snapshot) => {
        const data = snapshot.val();
        if (!data) {
          return;
        }
        travelerAppStore.setTimelineData(chosenTransfer.id, data);
      });
    }

    if (isOpen && !fetchedTimelineEvents) {
      fetchTimelineEvents(travelerAppStore, chosenTransfer);
    }

    // Specify how to clean up after this effect:
    return () => {
      if (!isOpen && timelineEventsRef.current) {
        timelineEventsRef.current.off();
      }
    };
  }, [isOpen, fetchedTimelineEvents, chosenTransfer, travelerAppStore]);

  // Unmount
  useEffect(() => {
    // Specify how to clean up after this effect:
    return function cleanup() {
      if (timelineEventsRef.current) {
        timelineEventsRef.current.off();
      }
    };
  }, []);

  if (!isOpen) {
    return null;
  }

  return (
    <ModalContainer
      onClose={onClose}
      isOpen={isOpen}
      ionBackButton={onClose}
      onDidDismiss={onClose}
      ref={modalContainerRef}
    >
      <StyledContainer className="timeline-events-container">
        <Header title={t('timeline.modal_header')} onClose={onClose} ionBackButton={onClose} />
        <IonContent style={{ '--overflow': 'hidden' }}>
          {isLoading ? (
            <IonProgressBar
              style={{ '--progress-background': mainColor, '--buffer-background': theme.colors.base.white }}
              type="indeterminate"
            ></IonProgressBar>
          ) : events && events.length > 0 ? (
            events.map((event, index) => {
              let icon = null;
              let iconElement = null;
              if (event.icon && event.icon !== 'driver') {
                icon = event.icon;
                iconElement = <IconImage image={icon} />;
              } else if (event.icon === 'driver' && driverImage) {
                icon = driverImage;
                iconElement = <DriverImage image={icon} />;
              }

              return (
                <IonRow className="timeline-message-row" key={`event-${index}`}>
                  <IonCol sizeSm="1.5" size="2" className="timeline-message-row-time">
                    <span>{format(event.timestamp, 'HH:mm')}</span>
                  </IonCol>
                  <IonCol sizeSm="10.5" size="10" className={`timeline-message-row-message ${event.className}`}>
                    <span>{event.message}</span>
                    {icon && iconElement}
                  </IonCol>
                </IonRow>
              );
            })
          ) : (
            <IonRow>
              <IonCol size="12">No events yet</IonCol>
            </IonRow>
          )}
        </IonContent>
      </StyledContainer>
    </ModalContainer>
  );
};

export default withTranslation()(observer(TimelineModal));

const ModalContainer = styled(StyledModal)`
  --max-width: 460px;
  --height: 100%;
  --max-height: 640px;

  @media (max-width: ${theme.breakpoints[0]}px) {
    --max-width: unset;
    --max-height: unset;
    --border-radius: 5px;
  }
`;

const StyledContainer = styled.div`
  box-shadow: unset;
  width: 100%;
  padding: 0 20px 0px 20px;
  // max-width: 340px;

  .header {
    position: sticky;
    margin-top: 14px;
    margin-bottom: 14px;
    // min-width: 340px;
    // padding: 0 20px 0px 20px;
  }

  @media (max-width: ${theme.breakpoints[0]}px) {
    min-width: unset;
    .header {
      // padding: 0 20px 0 20px;
      height: 60px;
      margin: 0 0 17px 0;
      min-width: unset;
    }
  }
`;

const IconImage = styled.div`
  background-image: url(${(props) => props.image});
  background-position: center;
  // background-size: cover;
  background-size: auto;
  background-repeat: no-repeat;
  border-radius: 6px;
  width: 30px;
  height: 30px;
  margin: 11px 6px;
  position: absolute;
  right: 0px;
`;

const DriverImage = styled.div`
  background-image: url(${(props) => props.image});
  background-position: center;
  background-size: cover;
  border-radius: 6px;
  width: 30px;
  height: 30px;
  margin: 11px 6px;
  position: absolute;
  right: 0px;
`;
