import { DesktopFlexContainer } from '@/src/components/styled/Wrappers';
import theme from '@/src/theme';
import styled from 'styled-components';

export const Container = styled(DesktopFlexContainer)`
  padding-top: 24px;
  height: auto;
  @media (max-width: ${theme.breakpoints[0]}px) {
    padding-top: 0px;
    padding-bottom: 0px;
  }
`;

export const StyledIonList = styled.div`
  height: 535px;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 2px 4px 0 rgba(45, 59, 78, 0.06);
  padding-top: 0px;
  padding-bottom: 0px;
  overflow-y: auto;

  .str-chat__channel-list-messenger {
    padding-bottom: 0;
  }

  @media (max-width: ${theme.breakpoints[0]}px) {
    height: auto;
    width: 100%;
    overflow-x: hidden;
  }
`;

export const MobileHeader = styled.div`
  margin-top: 18px;
  margin-bottom: 18px;
  margin-left: 20px;
  margin-right: 20px;

  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
`;

export const HelpButton = styled.a`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: center;
`;

export const HeaderDivider = styled.div`
  height: 1px;
  background-color: rgba(45, 59, 78, 0.08);
  margin-left: 20px;
  margin-right: 20px;
`;
