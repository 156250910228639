import React from 'react';

const Minibus = ({ fill, styles }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="18" style={styles} viewBox="0 0 30 18">
    <title>Minibus</title>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h30v20H0z" />
      <path
        fill={fill}
        d="M26.997 6.923a13.098 13.098 0 0 0-.03-.675c-.036-.533-.107-.956-.214-1.248h-4.76v3h5V7c0-.026.002-.052.004-.077zM26.994 10h-20a.998.998 0 0 1-.768-.359c-.254.101-.545.2-.88.305-.207.065-.86.258-.912.274a4.823 4.823 0 0 0-.538.186c-.372.175-.902.85-.902.908.012 1.639.022 2.46.03 2.462.269.097.697.165 1.243.199.286.017.55.025.897.027a3 3 0 0 1 5.66-.002h9.34a3 3 0 0 1 5.664.014c.054-.01.11-.014.166-.014a1 1 0 0 0 1-1v-3zm-7-5h-5v3c3.333.001 5-.002 5-.01V5zm-7 0h-1.23c-.495 0-1.362.196-1.895.473-.07.036-.438.455-.867 1.046A35.97 35.97 0 0 0 8 8h4.994V5zm7.17 11h-9.34a3 3 0 0 1-5.659.002 16.85 16.85 0 0 1-1.023-.031C2.18 15.848.994 15.42.994 14v-2.686c0-.923 1.035-2.24 2.05-2.717.2-.095.439-.178.807-.29.064-.02.708-.21.9-.27.584-.183.983-.347 1.15-.456a42.076 42.076 0 0 1 1.143-1.757c.116-.168.23-.328.34-.48.633-.873 1.103-1.406 1.562-1.645.83-.432 2.013-.699 2.818-.699h15.23c1.402 0 1.835 1.178 1.969 3.11.036.524.042.992.034 1.69-.003.193-.003.264-.003.332V13a3 3 0 0 1-3 3c-.057 0-.112-.005-.166-.014a3 3 0 0 1-5.664.014zm1.83-1a1 1 0 1 0 1.999.001 1 1 0 0 0-2-.001zm-15 0a1 1 0 1 0 1.999.001 1 1 0 0 0-2-.001z"
      />
    </g>
  </svg>
);

export default Minibus;
