import { makeAutoObservable } from 'mobx';
import TravelerApi from '../API';
import { logError } from '../helpers/log';
import { set as setStorage, remove as removeStorage } from '../services/storage';
import { createTimer } from '../helpers/timer';
import { debugConsoleLog } from '../components/common/helpers';
import { MERGE_ACCOUNTS_CONFIRMATION_TIMER } from '../constants';

let timer;

class DuplicateTravelersStore {
  duplicateTravelersInfo = {};
  getDuplicateTravelersLoading = false;
  selectedAccount = {};
  confirmationCodeData = {};
  confirmationCodeLoading = false;

  mergeAccountsData = {};
  mergeAccountsLoading = false;

  secondsUntilResend = 0;

  constructor() {
    makeAutoObservable(this);
  }

  // Actions
  /**
   * @param {object} newInfo
   */
  setDuplicateTravelersInfo = (newInfo) => {
    this.duplicateTravelersInfo = newInfo;
  };

  setGetDuplicateTravelersLoading = (loading) => {
    this.getDuplicateTravelersLoading = loading;
  };

  setSelectedAccount = (account) => {
    this.selectedAccount = account;
  };

  setConfirmationCodeData = (data) => {
    this.confirmationCodeData = data;
  };

  setConfirmationCodeLoading = (loading) => {
    this.confirmationCodeLoading = loading;
  };

  setMergeAccountsData = (data) => {
    this.mergeAccountsData = data;
  };

  setMergeAccountsLoading = (loading) => {
    this.mergeAccountsLoading = loading;
  };

  setSecondsUntilResend = (time) => {
    this.secondsUntilResend = time;
  };

  getInfo = async () => {
    try {
      this.setGetDuplicateTravelersLoading(true);
      const data = await TravelerApi.getDuplicateAccountsInfo();
      this.setDuplicateTravelersInfo(data || {});
      this.setGetDuplicateTravelersLoading(false);
    } catch (err) {
      logError('Failed to fetch duplicate travelers info', err);
      this.setGetDuplicateTravelersLoading(false);
    }
  };

  startTimer = (time) => {
    if (!timer) {
      timer = createTimer();
    }

    timer.start(time, (time) => {
      this.setSecondsUntilResend(time);
      setStorage(MERGE_ACCOUNTS_CONFIRMATION_TIMER, time);
    });
  };

  clearTimer = () => {
    if (timer) {
      timer.stop();
      this.setSecondsUntilResend(0);
      removeStorage(MERGE_ACCOUNTS_CONFIRMATION_TIMER);
    }
  };

  sendConfirmationCode = async (method) => {
    try {
      this.setConfirmationCodeLoading(true);
      const data = await TravelerApi.sendDuplicateAccountsConfirmationCode(method);

      debugConsoleLog('Confirmation code sent');
      this.setConfirmationCodeData(data || {});

      // If there is no timeout, we start the timer from 30 seconds by default
      this.startTimer(data.timeToNextAttempt === 0 ? 30 : data.timeToNextAttempt);

      this.setConfirmationCodeLoading(false);
    } catch (err) {
      this.setConfirmationCodeLoading(false);
      throw err;
    }
  };

  mergeAccounts = async (confirmationMethod, code) => {
    try {
      this.setMergeAccountsLoading(true);
      const data = (await TravelerApi.mergeAccounts(this.selectedAccount.id, confirmationMethod, code)) || {};
      this.setMergeAccountsData(data);
      this.setMergeAccountsLoading(false);

      return data;
    } catch (err) {
      if (err.response?.status !== 401) {
        logError('Failed to merge account', err);
      }
      this.setMergeAccountsLoading(false);
      throw err;
    }
  };

  resetDuplicateTravelersStore = () => {
    this.setDuplicateTravelersInfo({});
    this.setSelectedAccount({});
    this.setConfirmationCodeData({});
    this.clearTimer();
    this.setMergeAccountsData({});
  };
}

const duplicateTravelersStore = new DuplicateTravelersStore();
export default duplicateTravelersStore;
