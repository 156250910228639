import React, { Fragment, useEffect } from 'react';
import TripPreviewCard from '../TripPreviewCard';
import { observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { useStores } from '../../../stores/useStores';
import NoTrips from '../NoTrips';
import MobileContentCards from '../../../components/common/MobileContentCards';
import { getContentCardsFilterForPage, isMobileAppPlatform } from '../../../components/common/helpers';
import { ContentCardsSimpleWrapper } from '../../../components/styled/Wrappers';
import braze from '../../../services/braze';
import PropTypes from 'prop-types';

const TripPreviews = (props) => {
  const { tabName } = props;

  const [webContentCardsTopRendered, setWebContentCardsTopRendered] = React.useState(false);
  const [webContentCardsBottomRendered, setWebContentCardsBottomRendered] = React.useState(false);

  const {
    tripsStore: { tripPreviews },
    contentCardsStore: { contentCards }
  } = useStores();

  /**
   *
   * @param {('top'|'bottom')} position
   * @returns
   */
  const renderContentCards = (position) => {
    if (!position) {
      return null;
    }

    const fullKey = `${tabName}_trips_${position}`;
    const contentCardsFilter = getContentCardsFilterForPage(fullKey);
    const filteredCards = contentCards.filter(contentCardsFilter);

    // We check if the cards are already rendered to avoid rendering them multiple times
    if (position === 'top' && !webContentCardsTopRendered) {
      braze.renderContentCards(fullKey, contentCardsFilter);
      setWebContentCardsTopRendered(true);
    } else if (position === 'bottom' && !webContentCardsBottomRendered) {
      braze.renderContentCards(fullKey, contentCardsFilter);
      setWebContentCardsBottomRendered(true);
    }

    return (
      <ContentCardsSimpleWrapper display={filteredCards.length ? 'block' : 'none'} id={fullKey}>
        {isMobileAppPlatform() && <MobileContentCards filteredCards={filteredCards} />}
      </ContentCardsSimpleWrapper>
    );
  };

  const renderTrips = () => {
    return (
      <Fragment>
        {renderContentCards('top')}
        {tripPreviews[tabName]?.map((tripPreview, index) => (
          <TripPreviewCard
            key={tripPreview.id}
            tripPreview={tripPreview}
            index={index}
            reviewTransferHva={tripPreviews.reviewTransferHva}
          />
        ))}
        {renderContentCards('bottom')}
      </Fragment>
    );
  };

  useEffect(() => {
    setWebContentCardsTopRendered(false);
    setWebContentCardsBottomRendered(false);
  }, [tabName]);

  return tripPreviews[tabName]?.length ? (
    renderTrips()
  ) : (
    <NoTrips contentCardsKey={`${tabName}_trips_empty`} reviewTransferHva={tripPreviews.reviewTransferHva} />
  );
};

TripPreviews.propTypes = {
  tabName: PropTypes.oneOf(['upcoming', 'completed']).isRequired
};

export default withRouter(observer(TripPreviews));
