import styled from 'styled-components';
import theme from '../../theme';
import arrowLeft from '../../assets/icons/leftGraphiteArrow.svg';

const sizes = {
  lg: '56px',
  md: '48px',
  sm: '40px'
};

const ButtonBase = styled.button`
  border-radius: 4px;
  border: 0;
  height: ${(props) => sizes[props.size] || sizes.md};
  background-color: ${(props) => props.bgcolor};
  width: ${(props) => props.width || '180px'};
  cursor: pointer;
  white-space: nowrap;

  span {
    font-size: ${(props) => (sizes[props.size] === '56px' ? '16px' : '14px')};
    font-weight: 700;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.29;
    letter-spacing: normal;
    text-align: center;
    color: ${(props) => props.color || '#ffffff'};
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
  }

  :hover,
  :focus {
    background-color: ${(props) => props.bgcolor}e0;
    color: ${(props) => props.color || '#ffffff'};
  }

  :active {
    background-color: ${(props) => props.bgcolor};
  }

  :disabled,
  [disabled] {
    background-color: ${(props) => props.bgcolor}59;
  }
`;

const ButtonOutlined = styled.button`
  border-radius: 4px;
  border: 0;
  height: ${(props) => sizes[props.size] || sizes.md};
  background-color: transparent;
  border: 1px solid ${({ borderColor }) => borderColor || theme.colors.base.graphite};
  width: ${(props) => props.width || '180px'};
  cursor: pointer;
  white-space: nowrap;
  margin: ${({ margin }) => margin || 0};
  font-weight: 600;

  &:hover {
    filter: brightness(180%);
  }

  span {
    font-size: ${(props) => (sizes[props.size] === '56px' ? '16px' : '14px')};
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.29;
    letter-spacing: normal;
    color: ${({ color }) => color || theme.colors.base.graphite};
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      margin-right: 8px;
    }
  }
`;

const ButtonSecondary3 = styled.button`
  height: ${(props) => sizes[props.size] || sizes.md};
  border-radius: 4px;
  border: 1px solid ${theme.colors.greys.grey15};
  padding: 0 24px;
  cursor: pointer;
  color: ${theme.colors.base.graphite};
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  white-space: nowrap;

  &:hover {
    border-color: ${theme.colors.greys.grey3};
  }

  &:active {
    border-color: ${theme.colors.greys.grey1};
    background-color: rgba(45, 59, 78, 0.05);
  }

  span {
    display: block;
    font-size: ${(props) => (sizes[props.size] === '56px' ? '16px' : '14px')};
    font-weight: 700;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.29;
    letter-spacing: normal;
    text-align: center;
  }
`;

const TourServicesWhiteButton = styled(ButtonSecondary3)`
  width: 100%;
  background-color: ${theme.colors.base.white};

  :visited,
  :focus,
  :active,
  :hover {
    background-color: ${theme.colors.base.white};
    border-color: ${theme.colors.greys.grey15};
  }

  font-weight: 700;
  font-size: 14px;
  color: ${(props) => props.color};

  outline: none;
  white-space: normal !important;
  height: auto;
  word-break: break-word;

  :visited,
  :focus,
  :active,
  :hover {
    outline: 0 none !important;
  }
`;

const ButtonTabber = styled.button`
  height: 38px;
  background-color: ${theme.colors.base.white};
  padding: 0 24px;
  cursor: pointer;
  color: ${theme.colors.base.graphite};
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  white-space: nowrap;
  display: inline-flex;
  border: none;
  font-size: 14px;

  &:hover {
    border-color: rgba(45, 59, 78, 0.3);
  }

  &.active {
    color: ${theme.colors.base.white};
    border-color: ${theme.colors.base.graphite};
    background-color: ${theme.colors.base.graphite};
  }

  &:focus {
    outline: none;
  }
`;

const ButtonDiscard = styled.button`
  height: ${(props) => sizes[props.size] || sizes.md};
  border-radius: 4px;
  border: 1px solid ${theme.colors.accent.red};
  background-color: ${theme.colors.accent.red};
  font-weight: 700;
  padding: 0 24px;
  cursor: pointer;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  box-sizing: border-box;
  white-space: nowrap;

  &:hover {
    border-color: #ff7f7a;
  }

  &:active {
    background-color: #e06763;
  }

  span {
    display: block;
    font-size: ${(props) => (sizes[props.size] === '56px' ? '16px' : '14px')};
    font-weight: 700;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.29;
    letter-spacing: normal;
    text-align: center;
  }
`;

const BackButton = styled.button`
  cursor: pointer;
  width: 40px;
  height: 40px;
  margin-bottom: 20px;
  z-index: 10;
  background-image: url(${arrowLeft});
  background-size: 20px 20px;
  background-position: center;
  background-repeat: no-repeat;
  border: none;
  background-color: white;
`;

export {
  ButtonBase,
  TourServicesWhiteButton,
  ButtonSecondary3,
  ButtonDiscard,
  ButtonTabber,
  BackButton,
  ButtonOutlined
};
