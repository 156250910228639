import React, { useEffect, useMemo, useState } from 'react';
import Footer from './Footer';
import {
  addScript,
  currentPageIs,
  getContentCardsFilterForPage,
  isMobileAppPlatform,
  removeScript,
  isIosMobileApp,
  isTruthy
} from '../../components/common/helpers';
import { StyledCard, StyledContent, StyledPage } from '../../components/styled/IonElements';
import Header from './Header';
import { withTranslation } from 'react-i18next';
import { useStores } from '../../stores/useStores';
import { observer } from 'mobx-react';
import { onExternalLinkClick } from '../../components/common/ExternalLink/ExternalLink';
import braze from '../../services/braze';
import MobileContentCards from '../../components/common/MobileContentCards';
import debounce from '../../helpers/debounce';
import { ContentCardsWrapper } from '../../components/styled/Wrappers';
import { useLocation, withRouter } from 'react-router-dom';
import queryString from 'query-string';
import SkeletonWidget from '../../components/common/ScarySkeletons/SkeletonWidget';
import mixpanel from '../../services/mixpanel';
import ReferralInfoModal from '../../components/common/TalonOne/ReferralInfoModal';
import Refresher from '@/src/components/common/Refresher';
import posthog, {
  posthogIdentify,
  posthogInitialize,
  useFeatureFlagVariantKey,
  useFeatureFlagEnabled
} from '@/src/services/posthog';
import { WELCOME_MIXPANEL_INSTANCE_NAME } from '@/src/constants';
import Container from '@/src/components/layouts/containers/Container';

const trackBookTabViewDebounced = debounce(mixpanel.trackBookTabView, 800);
const contentCardsFilter = getContentCardsFilterForPage('book');
const allowedSearchParams = [
  'city_id',
  'city_name',
  'from_iata_code',
  'from_port_code',
  'date',
  'locale',
  'from_location_id',
  'to_location_id'
];
let savedWidgetCitySelectorValue = undefined;
let savedWidgetRouteSelectorValue = undefined;

const Book = (props) => {
  const {
    travelerAppStore: {
      locale,
      isAuthorised,
      travelerInfo: { id },
      setLocale
    },
    brandedSettingsStore: {
      brandedSettings: {
        coBrandingThemeProperties: { widgetButtonBackgroundColor, widgetButtonFontColor }
      }
    },
    contentCardsStore: { contentCards },
    widgetStore: {
      widgetReloadKey,
      widgetCitySelectorLocked,
      widgetCitySelectorLockedValue,
      updateWidgetReloadKey,
      widgetRouteSelectorLocked,
      widgetRouteSelectorLockedValue,
      updateWidgetCitySelectorLocked,
      updateWidgetCitySelectorLockedValue,
      updateWidgetRouteSelectorLocked,
      updateWidgetRouteSelectorLockedValue
    }
  } = useStores();

  const _locale = locale;
  const _widgetReloadKey = widgetReloadKey;

  const [translatedCity, setTranslatedCity] = useState();
  const [customSubtitle, setCustomSubtitle] = useState();
  const [shouldShowReferralModal, setShouldShowReferralModal] = useState(false);

  let location = useLocation();

  const urlParams = useMemo(() => queryString.parse(location.search), [location.search]);

  const hasCustomUrlParams = useMemo(() => {
    for (const key of Object.keys(urlParams)) {
      if (allowedSearchParams.find((param) => param === key)) {
        return true;
      }
    }
    return false;
  }, [urlParams]);

  const firstRender = React.useRef(true);

  const filteredCards = contentCards.filter(contentCardsFilter);

  const { history } = props;

  const widgetCitySelectorValue = useFeatureFlagVariantKey('widget_city_selector');
  const widgetRouteSelectorValue = useFeatureFlagEnabled('route_select');

  savedWidgetCitySelectorValue = widgetCitySelectorValue;
  savedWidgetRouteSelectorValue = widgetRouteSelectorValue;
  // We don't want to show the experiement if we have custom url params
  // The value of the feature flag may change after the timeout has passed so we need to check the locked value
  const isWidgetNoCitySelectorActive =
    (widgetCitySelectorLocked
      ? widgetCitySelectorLockedValue == 'no-city-selector'
      : widgetCitySelectorValue === 'no-city-selector') && !hasCustomUrlParams;
  const isWidgetSearchCitySelectorActive =
    (widgetCitySelectorLocked
      ? widgetCitySelectorLockedValue === 'dropdown-search'
      : widgetCitySelectorValue === 'dropdown-search') && !hasCustomUrlParams;

  const isWidgetRouteSelectorActive =
    (widgetRouteSelectorLocked ? widgetRouteSelectorLockedValue : widgetRouteSelectorValue) && !hasCustomUrlParams;
  // The widget should only load when we have a value for the widgetNoCitySelector feature flag.
  // We have also added a fallback in case the feature flag does not get a value after some time so we don't block the widget entirely.
  const isWidgetAllowedToRender = widgetCitySelectorValue !== undefined || widgetCitySelectorLocked;

  const loadWidget = () => {
    const urlParams = queryString.parse(location.search);
    const language = urlParams.locale || _locale;
    if (language !== _locale) {
      setLocale(language);
    }

    if (isMobileAppPlatform()) {
      // console.log("Setting iabCallback on window");
      window.iabCallback = onExternalLinkClick;
    }

    const experimentsProps = {};
    const widgetContainer = document.getElementById('welcomepickups');
    const widgetPropsScript = document.createElement('script');
    widgetPropsScript.type = 'text/props';
    widgetPropsScript.id = 'widget-props';
    let widgetProps = {
      locale: language,
      button_color: widgetButtonBackgroundColor,
      button_text_color: widgetButtonFontColor,
      no_card: '',
      fluid: '0',
      no_header_block: '1',
      is_extra_transfer: isAuthorised,
      traveler_id: isAuthorised ? `${id}` : null,
      iab_callback: isMobileAppPlatform(),
      from_ios_app: isIosMobileApp(),
      tracking_info: {
        enabled: true,
        widget_placement: 'Traveler App',
        mixpanel_instance_name: WELCOME_MIXPANEL_INSTANCE_NAME
      }
    };

    // Hide city selector
    if (isWidgetNoCitySelectorActive) {
      widgetProps['world'] = true;
      widgetProps['force_location_service'] = true;
      widgetProps['experiments'] = {
        city_selector_experiment: 'no-city-selector'
      };
    }

    if (isWidgetSearchCitySelectorActive) {
      experimentsProps['city_selector_experiment'] = 'dropdown-search';
    }

    if (isWidgetRouteSelectorActive) {
      experimentsProps['route_select'] = true;
    }

    if (isWidgetSearchCitySelectorActive || isWidgetRouteSelectorActive) {
      widgetProps['experiments'] = experimentsProps;
    }

    for (const key of Object.keys(urlParams)) {
      if (allowedSearchParams.find((param) => param === key)) {
        widgetProps[key] = urlParams[key];
      }
    }

    // Find city
    setTranslatedCity(urlParams.translated_city);
    setCustomSubtitle(urlParams.custom_title);

    widgetPropsScript.innerHTML = JSON.stringify(widgetProps);
    widgetContainer.append(widgetPropsScript);

    addScript(
      `${process.env.REACT_APP_WIDGET_URL}?time=${new Date().getTime()}`,
      () => {},
      'welcome-widget',
      true,
      () => {
        console.log('widget script loaded');
      }
    );
  };

  const handleReferralModal = () => {
    const params = new URLSearchParams(window.location.search);
    const referralParam = params.get('referral');
    setShouldShowReferralModal(isTruthy(referralParam));

    const url = new URL(window.location.href);
    url.searchParams.delete('referral');
    window.history.pushState({}, document.title, url);
  };

  const doRefresh = (event) => {
    if (isMobileAppPlatform() && currentPageIs('/book')) {
      braze.getContentCardsFromServer();
    }

    setTimeout(() => {
      event.detail.complete();

      // We need to do this action after the refresher is completed otherwise the page will rerender and the refresher will throw an error
      updateWidgetReloadKey();
    }, 200);
  };

  useEffect(() => {
    if (!firstRender.current && isWidgetAllowedToRender) {
      updateWidgetReloadKey();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    id,
    isAuthorised,
    _locale,
    widgetButtonBackgroundColor,
    widgetButtonFontColor,
    location,
    isWidgetAllowedToRender,
    widgetCitySelectorValue
  ]);

  useEffect(() => {
    if (isWidgetAllowedToRender) {
      loadWidget();

      // Fetch and display content cards on web
      braze.renderContentCards('content-cards-container-book', contentCardsFilter);
    }

    return () => {
      removeScript('welcome-widget');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_widgetReloadKey]);

  useEffect(() => {
    if (!posthog.__loaded) {
      posthogInitialize();
    } else {
      posthogIdentify(id);
    }

    // Sets the final value of the feature flag after the timeout has passed
    // and only if posthog failed to set the value.
    const widgetExperimentsTimeoutFunc = () => {
      if (savedWidgetCitySelectorValue === undefined) {
        updateWidgetCitySelectorLocked(true);
        updateWidgetCitySelectorLockedValue('dropdown');
      }

      if (savedWidgetRouteSelectorValue === undefined) {
        updateWidgetRouteSelectorLocked(true);
        updateWidgetRouteSelectorLockedValue(false);
      }
    };

    // We wait 3 seconds before finalizing the feature flag value.
    // After 3 seconds we lock its value and we allow the widget to load.
    const widgetExperimentsTimeout = setTimeout(widgetExperimentsTimeoutFunc, 3000);

    trackBookTabViewDebounced.init();

    handleReferralModal();

    firstRender.current = false;

    return () => {
      clearTimeout(widgetExperimentsTimeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StyledPage style={{ overflow: 'auto' }} key={_widgetReloadKey}>
      <StyledContent>
        <Refresher onRefresh={doRefresh} />

        <Header cityName={translatedCity} customSubtitle={customSubtitle} />
        <StyledCard style={{ overflow: 'unset', contain: 'unset' }} margin={'0'} padding="0">
          <div id="welcomepickups">
            <SkeletonWidget />
          </div>
          <Footer />
        </StyledCard>

        <Container>
          <ContentCardsWrapper
            display={filteredCards.length ? 'block' : 'none'}
            style={{ marginBottom: '24px' }}
            id="content-cards-container-book"
          >
            {isMobileAppPlatform() && <MobileContentCards filteredCards={filteredCards} />}
          </ContentCardsWrapper>
        </Container>
      </StyledContent>

      <ReferralInfoModal
        isOpen={shouldShowReferralModal}
        onClose={(url) => {
          setShouldShowReferralModal(false);
          if (url) {
            setTimeout(() => {
              history.push(url);
            }, 300);
          }
        }}
      />
    </StyledPage>
  );
};

export default withTranslation()(withRouter(observer(Book)));
