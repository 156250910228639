import React from 'react';
import { observer, inject } from 'mobx-react';
import styled from 'styled-components';
import { trackEvent } from '../../../../tracking';
import { externalLinkRedirection } from '../helpers';
import { Heading5, Heading3 } from '../../../../components/styled/Typography';
import { ButtonBase } from '../../../../components/styled/Buttons';
import theme from '../../../../theme';
import { StyledCard } from '../../../../components/styled/IonElements';
import { withTranslation } from 'react-i18next';
import { onExternalLinkClick } from '../../../../components/common/ExternalLink/ExternalLink';
import mixpanel from '../../../../services/mixpanel';

const ReturnTransferButton = ({ redirectTo, t }) => (
  <TransferButtonBase kind="secondary" onClick={redirectTo} bgcolor={theme.colors.base.graphite}>
    {t('my_trip.book')}
  </TransferButtonBase>
);

const BookReturnTransfer = ({ travelerAppStore: { currentTrip, transfers }, t }) => {
  const [{ fromTitle, toTitle }] = transfers;

  return (
    <StyledCard style={{ margin: '10px auto' }}>
      <BookReturnTransferHeader>{t('my_trip.book_a_return_transfer')}</BookReturnTransferHeader>
      <Content>{`${'from'} ${toTitle || fromTitle}`}</Content>
      <ButtonWrapper>
        <ReturnTransferButton
          t={t}
          redirectTo={async (e) => {
            trackEvent('traveler app', 'trip', 'book return transfer');
            mixpanel.trackBookAReturnTransferClick();
            const isWebLink = await onExternalLinkClick(e, currentTrip.returnTransferLink);
            if (isWebLink) {
              externalLinkRedirection(currentTrip.returnTransferLink);
            }
          }}
        />
      </ButtonWrapper>
    </StyledCard>
  );
};

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const Content = styled(Heading5)`
  color: ${theme.colors.greys.grey5};
  font-size: 14px;
  line-height: 21px;
  margin: 4px 0 24px 0;
  font-weight: 500;
`;

const BookReturnTransferHeader = styled(Heading3)`
  color: ${theme.colors.base.graphite};
  font-size: 20px;
  line-height: 26px;
  margin: 0;
`;

const TransferButtonBase = styled(ButtonBase)`
  height: 48px;
  max-width: 50%;
  width: calc(100% - 40px);
  border-radius: 4px;
  color: ${theme.colors.base.white};
  width: 100%;
  text-transform: none;
  font-weight: 700;
  font-size: 16px;
  min-height: 48px;
  outline: none;
  white-space: normal !important;
  height: auto;
  word-break: break-word;

  :hover {
    background-color: #425269;
  }

  :active {
    outline: none;
    background-color: #1f2836;
  }

  :focus {
    background-color: ${theme.colors.base.graphite};
  }
`;

export default withTranslation()(inject('travelerAppStore')(observer(BookReturnTransfer)));
