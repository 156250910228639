import styled from 'styled-components';
import arrowLeft from '@/src/assets/icons/leftGraphiteArrow.svg';

export const MobileChannelContainer = styled.div`
  height: 100%;
  overflow: hidden;
  padding-bottom: 8px;
`;

export const MobileChannelTitle = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const MobileChannelHeader = styled.div`
  padding: 10px 0;
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(45, 59, 78, 0.06);
  border-bottom: 1px solid #0000001a;
  gap: 10px;

  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;

  .back-arrow-icon {
    background-image: url(${arrowLeft});
    background-size: 20px 20px;
    width: 40px;
    height: 40px;
    background-position: center;
    background-repeat: no-repeat;
    margin-left: 10px;
    cursor: pointer;
  }
`;

const DriverName = styled.div`
  font-size: 16px;
  letter-spacing: 0;
  line-height: 20.8px;
  text-overflow: ellipsis;
  white-space: nowrap;

  &.active {
    font-weight: bold;
  }

  &.archived {
    color: rgba(45, 59, 78, 0.5);
  }
`;

export const MobileDriverName = styled(DriverName)`
  color: #2d3b4e;
`;
