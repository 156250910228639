import React from 'react';
import helpIcon from '../../../assets/icons/circle-help-white.svg';
import styled from 'styled-components';
import { IonImg } from '@ionic/react';
import LanguageSelector from '../LanguageSelector';
import { FlexContainer } from '../../styled/Wrappers';
import { useStores } from '../../../stores/useStores';
import { observer } from 'mobx-react';
import mixpanel from '../../../services/mixpanel';
import theme from '@/src/theme';
import { useIsMobileScreen } from '@/src/hooks/useWindowSize';

const HeaderRightContainer = () => {
  const {
    brandedSettingsStore: { getCoBrandedHelpCenterUrl }
  } = useStores();

  const isMobileScreen = useIsMobileScreen();

  return (
    <RightContainer className={isMobileScreen && 'mobile_lang_picker'}>
      {isMobileScreen && <LanguageSelector />}
      <HelpButton onClick={mixpanel.trackSupportView} target="_blank" href={getCoBrandedHelpCenterUrl}>
        <IonImg src={helpIcon} style={{ width: '20px' }} />
      </HelpButton>
    </RightContainer>
  );
};

export default observer(HeaderRightContainer);

const HelpButton = styled.a``;

const RightContainer = styled(FlexContainer)`
  position: absolute;
  right: 20px;
  top: 10px;
  display: flex;
  align-items: center;
  gap: 8px;

  @media (min-width: ${theme.screenSizes.sm}px) {
    top: 16px;
  }
`;
