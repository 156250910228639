import React from 'react';
import { useStores } from '../../../stores/useStores';
import AirbnbHostInvitationBanner from './AirbnbHostInvitationBanner';
import AirbnbHostInvitationScreen from './AirbnbHostInvitationScreen';
import { observer } from 'mobx-react';

function AirbnbHostInvitation(props) {
  const {
    travelerAppStore: {
      currentTrip: { askAirbnbQuestion, airbnbHostInvitation }
    }
  } = useStores();

  if (askAirbnbQuestion) {
    return <AirbnbHostInvitationBanner />;
  }

  if (airbnbHostInvitation) {
    return <AirbnbHostInvitationScreen />;
  }

  return null;
}

export default observer(AirbnbHostInvitation);
