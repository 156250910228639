import React from 'react';
import { IonSelect, IonSelectOption } from '@ionic/react';
import { withRouter } from 'react-router-dom';
import { useStores } from '../../../stores/useStores';
import { observer } from 'mobx-react';
import { availableLanguages } from '../../../locales/languages';
import styled from 'styled-components';
import './styles.scss';
import { withTranslation } from 'react-i18next';
import theme from '../../../theme';
import mixpanel from '../../../services/mixpanel';
import { isIosPlatform } from '../helpers';
import CaretDown from '@/src/assets/icons/caret-down-grey-icon.svg';

const LanguageSelector = ({ i18n }) => {
  const {
    travelerAppStore: { setLocale }
  } = useStores();
  const bigLangWidth = window.innerWidth < 768 ? '70px' : '100px';
  let smallLangWidth = null;

  if (isIosPlatform()) {
    smallLangWidth = window.innerWidth < 768 ? '50px' : '55px';
  } else {
    smallLangWidth = window.innerWidth < 768 ? '30px' : '35px';
  }

  const changeLanguage = (lang = '') => {
    setLocale(lang);
  };

  return (
    <StyledSelect
      onClick={mixpanel.trackLanguageSelectorClick}
      width={i18n.language !== 'zh-hans' && i18n.language !== 'pt-pt' ? smallLangWidth : bigLangWidth}
      className="language-select"
      mode="ios"
      interface={window.innerWidth > 768 ? 'alert' : 'action-sheet'}
      text={{ textWeight: '700' }}
      value={i18n.language}
      selectedText={i18n.language.toUpperCase()}
      okText="Okay"
      cancelText="Dismiss"
      onIonChange={(e) => changeLanguage(e.detail.value)}
      toggleIcon={CaretDown}
    >
      {availableLanguages.map(({ name, code }) => (
        <IonSelectOption key={code} value={code}>
          {name}
        </IonSelectOption>
      ))}
    </StyledSelect>
  );
};

export default withTranslation()(withRouter(observer(LanguageSelector)));

const StyledSelect = styled(IonSelect)`
  // --padding-start: 0;
  --padding-end: 0;
  // width: ${(props) => props.width};
  z-index: 100;
  color: ${theme.colors.base.white};

  --text {
    font-weight: 700;
  }
`;
