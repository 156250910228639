import * as React from 'react';
import Avatar from '../../Avatar';
import Tick from '@/src/components/svgs/Tick';
import theme from '@/src/theme';
import { Card, Name, Phone, Trips, InfoContainer, VerifiedIcon, VerifiedIconWrapper } from './index.styled';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

const UserCard = ({
  name,
  phone = '',
  numberOfTrips = 0,
  avatarUrl = '',
  style = {},
  selected = false,
  onClick = () => {},
  t
}) => {
  return (
    <Card style={{ ...style }} selected={selected} onClick={onClick}>
      <Avatar src={avatarUrl} alt={name} size="48px" />
      <InfoContainer>
        <Name>{name}</Name>
        <Phone>{phone}</Phone>
        <Trips>{t('duplicate_travelers.trips_found', { count: numberOfTrips })}</Trips>
      </InfoContainer>
      <VerifiedIconWrapper>
        {selected && (
          <VerifiedIcon>
            <Tick fill={theme.colors.cta.greenDark} size="16px" />
          </VerifiedIcon>
        )}
      </VerifiedIconWrapper>
    </Card>
  );
};

UserCard.propTypes = {
  name: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
  numberOfTrips: PropTypes.number,
  avatarUrl: PropTypes.string,
  style: PropTypes.object,
  selected: PropTypes.bool,
  onClick: PropTypes.func,
  t: PropTypes.func.isRequired
};

export default withTranslation()(UserCard);
