import React from 'react';

const Ferry = ({ styles, fill = '#808994', opacity }) => (
  <svg style={styles} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 4C2 2.89543 2.89543 2 4 2H16C17.1046 2 18 2.89543 18 4V15V16C18 17.1046 17.1046 18 16 18H15H5H4C2.89543 18 2 17.1046 2 16V15V4ZM4 15C4 15.5523 4.44772 16 5 16C5.55228 16 6 15.5523 6 15C6 14.4477 5.55228 14 5 14C4.44772 14 4 14.4477 4 15ZM7.82929 16H12.1707C12.0602 15.6872 12 15.3506 12 15C12 14.2316 12.2889 13.5308 12.7639 13H7.23611C7.71115 13.5308 8 14.2316 8 15C8 15.3506 7.93985 15.6872 7.82929 16ZM15 16C15.5523 16 16 15.5523 16 15C16 14.4477 15.5523 14 15 14C14.4477 14 14 14.4477 14 15C14 15.5523 14.4477 16 15 16ZM16 11H4V5C4 4.44772 4.44772 4 5 4H7V5C7 5.55228 7.44772 6 8 6H12C12.5523 6 13 5.55228 13 5V4H15C15.5523 4 16 4.44772 16 5V11Z"
      fill={fill}
    />
  </svg>
);

export default Ferry;
