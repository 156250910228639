import { useState, useEffect, useRef } from 'react';
import debounce from '../helpers/debounce';
import theme from '../theme';

/**
 * Listens to page width changes.
 * https://usehooks.com/useWindowSize/
 */
function useWindowSize() {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined
  });

  useEffect(() => {
    let isMounted = true;

    const _setWindowSize = () => {
      if (isMounted) {
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight
        });
      }
    };

    const setWindowSizeDebounced = debounce(_setWindowSize, 100); // Use debounce in order to avoid unnecessary state updates
    /**
     * Handler to call on window resize
     */
    function handleResize() {
      // Set window width/height to state
      setWindowSizeDebounced.init();
    }

    // Add event listener
    window.addEventListener('resize', handleResize);
    // Call _setWindowSize right away so state gets updated with initial window size
    _setWindowSize();

    // Remove event listener on cleanup
    return () => {
      isMounted = false;
      setWindowSizeDebounced.stop(); // Cancel any pending debounce calls
      window.removeEventListener('resize', handleResize);
    };
  }, []); // Empty array ensures that effect is only run on mount
  return windowSize;
}

const useCheckBreakpoint = (minBreakpoint = 0, maxBreakpoint = 99999, delay = 300) => {
  const [value, setValue] = useState(window.innerWidth >= minBreakpoint && window.innerWidth < maxBreakpoint);

  const debouncedResize = useRef(null);

  useEffect(() => {
    // Initialize the debounced function
    debouncedResize.current = debounce(() => {
      setValue(window.innerWidth >= minBreakpoint && window.innerWidth < maxBreakpoint);
    }, delay);

    function handleResize() {
      debouncedResize.current.init();
    }

    window.addEventListener('resize', handleResize);
    handleResize(); // Initial check

    return () => {
      window.removeEventListener('resize', handleResize);
      if (debouncedResize.current) {
        debouncedResize.current.stop();
      }
    };
  }, [minBreakpoint, maxBreakpoint, delay]);

  return value;
};

export const useIsMobileScreen = () => {
  const value = useCheckBreakpoint(theme.screenSizes.xs, theme.screenSizes.sm);
  return value;
};

export const useIsTabletScreen = () => {
  const value = useCheckBreakpoint(theme.screenSizes.sm, theme.screenSizes.lg);
  return value;
};

export const useIsDesktopScreen = () => {
  const value = useCheckBreakpoint(theme.screenSizes.lg);
  return value;
};

export default useWindowSize;
