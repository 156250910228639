import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { StyledOtpInput } from './OneTimePassword.styled';
import OtpInput from 'react-otp-input';

const OneTimePassword = (props) => {
  const { codeLength = 6, onChange = () => {}, onComplete = () => {} } = props;

  const [value, setValue] = useState('');

  const handleChange = (newValue) => {
    if (value.length > codeLength) {
      onComplete(newValue);
      return;
    }

    // If user selects another input from the one they are supposed to, the focus will return to the right one.
    // It works only when user types and not when deletes
    if (newValue.length > value.length && newValue.length < codeLength) {
      const currentOtpInput = document.getElementsByClassName('otp-input')[value.length];
      currentOtpInput.focus();
    }

    if (newValue.length === codeLength) {
      onComplete(newValue);
    } else {
      onChange(newValue);
    }

    setValue(newValue);
  };

  return (
    <OtpInput
      value={value}
      onChange={handleChange}
      numInputs={codeLength}
      inputStyle="otp-input"
      containerStyle={{ justifyContent: 'center', gap: '4px' }}
      inputType="number"
      shouldAutoFocus={true}
      renderInput={(props) => <StyledOtpInput {...props} />}
    />
  );
};

OneTimePassword.propTypes = {
  codeLength: PropTypes.number,
  onChange: PropTypes.func, // Triggered on every input change (except the last one)
  onComplete: PropTypes.func // Triggered when the last input is filled
};

export default OneTimePassword;
