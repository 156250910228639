import React, { Fragment, PureComponent } from 'react';
import { inject, observer } from 'mobx-react';
import styled from 'styled-components';
import SightSeeingImage from './SightSeeingHeader';
import Driver from './Driver';
import Section from './Section';
import MeetingPoint from './MeetingPoint';
import InRideInfo from './MeetingPoint/InRideInfo';
import Products from './Products';
import GreyFooter from '@/components/common/GreyFooter';
import GreyFooterItem from '@/components/common/GreyFooter/GreyFooterItem';
import Edit from '@/components/svgs/Edit';
import Close from '@/components/svgs/Close';
import Receipt from '@/components/svgs/Receipt';
import theme from '@/src/theme';
import { withRouter } from 'react-router-dom';
import { StyledCard, StyledItem, StyledModal } from '@/components/styled/IonElements';
import { IonToolbar } from '@ionic/react';
import DriverDetailsModal from './Driver/DriverDetailsModal';
import { withTranslation } from 'react-i18next';
import { onExternalLinkClick } from '@/components/common/ExternalLink/ExternalLink';
import { externalLinkRedirection } from '../helpers';
import MapHeader from './StaticDetails/MapHeader';
import TransferCard from '../List/TransferCard';
import { ButtonDiscard } from '@/components/styled/Buttons';
import TimelineModal from './Timeline/TimelineModal';
import mixpanel from '@/services/mixpanel';
import debounce from '@/src/helpers/debounce';
import Passengers from './Passengers';
import Divider from '@/components/styled/Divider';
import ReceiptModal from '../../../../components/common/Receipt/ReceiptModal';

const isSightSeeingRide = ({ transferType }) => typeof transferType === 'undefined';

const trackMeetingPointClickDebounced = debounce(mixpanel.trackMeetingPointClick, 1000);

class CommonDetails extends PureComponent {
  transferDetailsContainer = React.createRef();
  firstImageRef = React.createRef();

  state = {
    driverInfoModalVisibility: false,
    meetingPointModalVisibility: false,
    timelineModalVisibility: false,
    showHeader: false,
    isReceiptModalOpen: false
  };

  componentDidMount() {
    if (this.props.forwardedRef) {
      this.props.forwardedRef(this);
    }
  }

  onTransferAction = async (makeRedirection, link, event) => {
    event.preventDefault();
    if (makeRedirection) {
      // trackEvent("traveler app", "grey footer link", link);
      const isWebLink = await onExternalLinkClick(event, link);
      if (isWebLink) {
        externalLinkRedirection(link);
      }
    }
  };

  onChangeModalVisibility = (modalStateName) => {
    this.setState((state) => {
      return {
        [modalStateName]: !state[modalStateName]
      };
    });
  };

  hideTimelineModal = () => {
    this.setState({ timelineModalVisibility: false });
  };

  onMeetingPointClick = () => {
    this.firstImageRef.current.click();
  };

  onCloseReceiptModal = () => {
    this.setState({ isReceiptModalOpen: false });
  };

  render() {
    const {
      hideMap = false,
      travelerAppStore: { chosenTransfer },
      t
    } = this.props;
    const { driverInfoModalVisibility, timelineModalVisibility } = this.state;
    const isSRide = isSightSeeingRide(chosenTransfer);
    const isTns = chosenTransfer.isTns;
    const meetingPointWithPhotos = chosenTransfer.meetingPoint && chosenTransfer.meetingPoint?.photos?.length > 0;

    return (
      <div>
        {!hideMap &&
          (isSRide ? (
            <SightSeeingImage />
          ) : (
            <IonToolbar>
              <MapHeader transfer={chosenTransfer} transferDetailsContainer={this.transferDetailsContainer}>
                {isTns && (
                  <RideTnsButton
                    onClick={() => {
                      this.onChangeModalVisibility('timelineModalVisibility');
                    }}
                  >
                    <span style={{ fontWeight: 500, color: 'inherit', paddingRight: '10px' }}>
                      {t('live.transfer_terminated')}
                    </span>{' '}
                    <span style={{ fontWeight: 700, color: 'inherit' }}>{t('trip_details.details')}</span>
                  </RideTnsButton>
                )}
                {isTns && <TimelineModal isOpen={timelineModalVisibility} onClose={this.hideTimelineModal} />}
              </MapHeader>
            </IonToolbar>
          ))}
        {/**
         * TODO: Fix spacing inconcistenies. The right side has more space than the left side
         * - Use the build in lines of the IonItem component as separators
         * - Handle paddings and margins here instead of the child components
         *  */}
        <StyledCard padding="0">
          <TransferCard
            errors
            handleClick={() => {}}
            transfer={chosenTransfer}
            arrowVisible={false}
            showErrorsInDetails={true}
            isTourDetailsButtonVisible={isSRide}
          />
          <StyledItem lines="none" padding="0" margin="0">
            <Driver onChangeModalVisibility={this.onChangeModalVisibility} />
            <DriverModal isOpen={driverInfoModalVisibility}>
              <DriverDetailsModal onChangeModalVisibility={this.onChangeModalVisibility} />
            </DriverModal>
          </StyledItem>
          {!isSRide && (
            <StyledItem lines="none" padding="0" margin="0">
              <div style={{ width: '100%' }}>
                <Passengers />
              </div>
            </StyledItem>
          )}
          <StyledItem lines="none" padding="0" margin="0" onClick={trackMeetingPointClickDebounced.init}>
            <div>
              <Divider margin="20px" />
              <Section
                handleClick={this.onMeetingPointClick}
                drilldown={meetingPointWithPhotos}
                title={t('trip_details.meeting_point')}
              >
                <MeetingPoint forwardedRef={this.firstImageRef} chosenTransfer={chosenTransfer} />
              </Section>
            </div>
          </StyledItem>
          <StyledItem lines="none" padding="0">
            <Section title={t('my_trip.details')}>
              <InRideInfo chosenTransfer={chosenTransfer} isSRide={isSRide} />
            </Section>
          </StyledItem>
          {!isSRide && chosenTransfer.products.length ? (
            <Section title={t('trip_details.products_ordered')}>
              <Products products={chosenTransfer.products} />
            </Section>
          ) : null}
        </StyledCard>
        {((!chosenTransfer.isFinished && (chosenTransfer.isEditable || chosenTransfer.isCancelable)) ||
          chosenTransfer.receiptUrl) && (
          <GreyFooterContainer>
            <GreyFooter>
              {chosenTransfer.receiptUrl && (
                <Fragment>
                  <ReceiptModal
                    isOpen={this.state.isReceiptModalOpen}
                    onClose={this.onCloseReceiptModal}
                    triggerPage={isSRide ? 'tour_transfer_details' : 'transfer_details'}
                  />
                  <StyledLink
                    href="#"
                    onClick={(event) => {
                      event.preventDefault();
                      this.setState({ isReceiptModalOpen: true });
                    }}
                  >
                    <GreyFooterItem text={t('trip_details.download_receipt')} ico={Receipt} />
                  </StyledLink>
                </Fragment>
              )}
              {!isSRide && chosenTransfer.isEditable && (
                <StyledLink
                  disabled={!chosenTransfer.isEditable}
                  href={chosenTransfer.isEditable ? chosenTransfer.editLink : '#'}
                  onClick={(e) => {
                    mixpanel.trackEditThisTransferClick();
                    this.onTransferAction(chosenTransfer.isEditable, chosenTransfer.editLink, e);
                  }}
                >
                  <GreyFooterItem
                    text={
                      chosenTransfer.isEditable ? t('trip_details.edit_this_transfer') : t('trip_details.late_edit')
                    }
                    ico={Edit}
                    disabled={!chosenTransfer.isEditable}
                  />
                </StyledLink>
              )}
              {!isSRide && chosenTransfer.isCancelable && (
                <StyledLink
                  href={chosenTransfer.cancelLink}
                  onClick={(e) => {
                    mixpanel.trackCancelThisTransferClick();
                    this.onTransferAction(true, chosenTransfer.cancelLink, e);
                  }}
                >
                  <GreyFooterItem text={t('trip_details.cancel_this_transfer')} ico={Close} />
                </StyledLink>
              )}
            </GreyFooter>
          </GreyFooterContainer>
        )}
      </div>
    );
  }
}

export default withTranslation()(withRouter(inject('travelerAppStore')(observer(CommonDetails))));

const GreyFooterContainer = styled.div`
  min-height: 128px;
  display: flex;
  align-items: center;
  max-width: 600px;
  margin: 0 auto;
  z-index: 0;

  @media (max-width: ${theme.breakpoints[0]}px) {
    background-color: #e9ebed;
    padding: 20px 0px 20px 0px;
  }
`;

const DriverModal = styled(StyledModal)`
  --max-width: 340px;
  --height: 100%;
  --max-height: 610px;

  @media (max-width: ${theme.breakpoints[0]}px) {
    --max-height: 610px;
    --border-radius: 5px;
  }
`;

const StyledLink = styled.a`
  pointer-events: ${(props) => (props.disabled ? 'none' : 'auto')};
  color: ${(props) => (props.disabled ? theme.colors.greys.grey3 : theme.colors.greys.grey5)};

  :visited {
    pointer-events: ${(props) => (props.disabled ? 'none' : 'auto')};
    color: ${(props) => (props.disabled ? theme.colors.greys.grey3 : theme.colors.greys.grey5)};
  }
`;

const RideTnsButton = styled(ButtonDiscard)`
  height: 38px;
  padding: 0 10px;
  position: absolute;
  bottom: 8px;
  left: 50%;
  transform: translate(-50%, 0);
`;
