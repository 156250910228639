import styled from 'styled-components';
import theme from '@/src/theme';
import { IonCard } from '@ionic/react';

// Space applied from tablet and up.
const spaceTablet = '20px';

export const MainWrapper = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: center;
  gap: 16px;

  margin-top: ${({ hide_mobile_header }) => (hide_mobile_header ? '0' : '50px')};
  min-height: ${({ hide_mobile_header }) => (hide_mobile_header ? '100%' : 'calc(100% - 50px)')};

  @media (min-width: ${theme.breakpoints[0]}px) {
    min-height: auto;
    margin-left: 16px;
    margin-right: 16px;
    margin-top: ${({ custom_space }) => custom_space || spaceTablet};
    margin-bottom: ${({ custom_space }) => custom_space || spaceTablet};
  }

  @media (min-width: ${theme.breakpoints[1]}px) {
    min-height: auto;
  }
`;

export const Sidebar = styled(IonCard)`
  position: sticky;
  top: ${({ custom_space }) => custom_space || spaceTablet};
  vertical-align: top;
  max-width: 360px;
  width: 100%;
  margin: 0;
  max-height: fit-content;

  @media (max-width: ${theme.breakpoints[0]}px) {
    display: none;
  }
`;

export const MainContent = styled(IonCard)`
  width: 100%;
  max-width: 100%;
  min-height: 100%;

  margin: 0;
  padding: 20px;

  @media (min-width: ${theme.breakpoints[0]}px) {
    max-width: 600px;
    padding: ${({ padding }) => padding};
  }
`;

export const ExtraContent = styled.div`
  width: 100%;
  margin: 0;

  @media (min-width: ${theme.breakpoints[0]}px) {
    max-width: 600px;
  }
`;
