import React from 'react';
import { IonSkeletonText } from '@ionic/react';
import { Card } from './index.styled';

const UserCardSkeleton = () => {
  return (
    <Card>
      <IonSkeletonText
        animated
        style={{ flex: '0 0 48px', height: '48px', borderRadius: '50%', marginBottom: '4px' }}
      />

      <div style={{ width: '100%' }}>
        <IonSkeletonText
          animated
          style={{
            height: '18px',
            width: '80%',
            marginBottom: '4px'
          }}
        />
        <IonSkeletonText
          animated
          style={{
            height: '16px',
            width: '40%',
            marginBottom: '2px'
          }}
        />
        <IonSkeletonText animated style={{ height: '14px', width: '55%' }} />
      </div>
    </Card>
  );
};

export default UserCardSkeleton;
