import React, { Fragment, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';

import { withChatContext, Chat, ChannelList } from 'stream-chat-react';

// import 'stream-chat-react/dist/css/index.css';
import '@stream-io/stream-chat-css/dist/v2/css/index.css';
import './welcome-chat.css';
import { IonToast } from '@ionic/react';
import SkeletonDetails from '../../components/common/ScarySkeletons/SkeletonDetails';
import { StyledCard } from '../../components/styled/IonElements';
import { ContentCardsWrapper } from '../../components/styled/Wrappers';
import { Heading2, Text2 } from '../../components/styled/Typography';
import helpIcon from '../../assets/icons/help_icon_black.svg';
import PropTypes from 'prop-types';
import {
  isMobilePlatform,
  isMobileAppPlatform,
  currentPageIs,
  getContentCardsFilterForPage,
  isFalsy
} from '../../components/common/helpers';
import MobileContentCards from '../../components/common/MobileContentCards';
import braze from '../../services/braze';
import mixpanel from '../../services/mixpanel';
import Refresher from '@/src/components/common/Refresher';
import MobileChannel from '../../components/common/Chat/MobileChannel';
import { ArchivedListEmptyStateIndicator } from '../../components/common/Chat/ArchivedList/archivedList.styled';
import ArchivedList from '../../components/common/Chat/ArchivedList';
import MyChannelPreview from '../../components/common/Chat/MyChannelPreview';
import { HeaderDivider, HelpButton, MobileHeader, StyledIonList } from './messages.styled';
import DesktopChannel from '../../components/common/Chat/DesktopChannel';
import NoMessages from '../../components/common/Chat/NoMessages';
import DoubleLayout from '@/src/components/layouts/DoubleLayout';
import SingleLayout from '@/src/components/layouts/SingleLayout';
import { scrollToLastMessage } from '@/src/components/common/Chat/helpers';
import { useStores } from '@/src/stores/useStores';
import { useLocation } from 'react-router-dom';
import { useIsMobileScreen } from '@/src/hooks/useWindowSize';
import Container from '@/src/components/layouts/containers/Container';

const contentCardsFilter = getContentCardsFilterForPage('messages');

let sort;
let options;

const Messages = (props) => {
  const {
    t,
    match: { params }
  } = props;

  const [connecting, setConnecting] = useState(true);
  const [showErrorToast, setShowErrorToast] = useState(false);

  const {
    travelerAppStore: { travelerInfo, getChatUser },
    messagesStore: {
      connect,
      showNewMessagesBadge,
      setshowNewMessagesBadge,
      activeChannelsFilters,
      archivedChannelsFilters,
      chatClient,
      reconnecting,
      failedToFetchTransfers
    },
    brandedSettingsStore: { getCoBrandedHelpCenterUrl: helpUrl },
    contentCardsStore: { contentCards }
  } = useStores();

  const location = useLocation();

  const isMobileScreen = useIsMobileScreen();

  const onViewTransferClick = (e, channel) => {
    e.preventDefault();

    const tripToken = channel.data.service.trip_token;
    const transferToken = channel.data.service.token;
    props.history.push(`/trip/${tripToken}/transfer/${transferToken}`);
  };

  const doRefresh = (event) => {
    console.log('Refreshing');
    // Fetch content cards on mobile
    if (isMobileAppPlatform() && currentPageIs('/messages')) {
      braze.getContentCardsFromServer();
    }

    setTimeout(() => {
      event.detail.complete();

      // We need to do these actions after the refresher is completed otherwise the page will rerender and the refresher will throw an error
      setConnecting(true);
      mounted();
    }, 100);
  };

  const keyboardDidShowListener = (e) => {
    scrollToLastMessage();

    // Do not lose focus when user clicks send message button
    const sendButton = document.querySelector('.str-chat__send-button');
    if (sendButton) {
      sendButton.onclick = (e) => {
        // console.log("Will focus");
        const inputElement = document.querySelector('.str-chat__textarea textarea');
        if (inputElement) {
          inputElement.focus();
          // or select
        }
      };
    }
  };

  const keyboardDidHideListener = () => {
    scrollToLastMessage();
  };

  const removeKeyboardListeners = () => {
    // console.log("Removing keyboard listeners");
    window.removeEventListener('ionKeyboardDidShow', keyboardDidShowListener);
    window.removeEventListener('ionKeyboardDidHide', keyboardDidHideListener);
  };

  const mounted = async () => {
    // Get channel from params if exists
    console.log('Messages componentDidMount');

    // Add keyboard handler for mobile
    if (isMobilePlatform()) {
      removeKeyboardListeners();
      // When keyboard shows: scroll to last message and prevent send button to lose focus onClick
      window.addEventListener('ionKeyboardDidShow', keyboardDidShowListener);

      // Scroll to last message when keyboard hides
      window.addEventListener('ionKeyboardDidHide', keyboardDidHideListener);
    }

    if (isFalsy(travelerInfo.chatUser)) {
      try {
        await getChatUser();
        // eslint-disable-next-line no-unused-vars
      } catch (error) {}
    }

    if (travelerInfo.chatUser) {
      const connected = await connect(travelerInfo.chatUser);
      if (connected) {
        sort = { last_message_at: -1 };
        options = { watch: true, state: true };

        mixpanel.trackChatlListView();
      }
    }

    setConnecting(false);

    if (showNewMessagesBadge) {
      setshowNewMessagesBadge(false);
    }

    // Fetch and display content cards on web
    braze.renderContentCards('content-cards-container-messages', contentCardsFilter);
  };

  const renderContentCardsWrapper = (options = { noSpace: false }) => {
    const filteredCards = contentCards.filter(contentCardsFilter);
    return (
      <Container styles={{ marginTop: options.noSpace ? 0 : '20px' }}>
        <ContentCardsWrapper display={filteredCards.length ? 'block' : 'none'} id="content-cards-container-messages">
          {isMobileAppPlatform() && <MobileContentCards filteredCards={filteredCards} />}
        </ContentCardsWrapper>
      </Container>
    );
  };

  const renderMobileNoMessagesSection = () => {
    return (
      <StyledCard padding="0" style={{ width: '100%' }}>
        <MobileHeader>
          <Heading2>{t('messages.menu_button')}</Heading2>
          <HelpButton target="_blank" href={helpUrl}>
            <img alt={t('my_trip.help')} src={helpIcon} />
            <Text2 style={{ paddingLeft: '8px' }}>{t('my_trip.help')}</Text2>
          </HelpButton>
        </MobileHeader>

        <HeaderDivider />
        <NoMessages />
      </StyledCard>
    );
  };

  const renderDesktopNoMessagesSection = () => {
    return (
      <div style={{ width: '100%' }}>
        <StyledCard padding="0">
          <NoMessages />
        </StyledCard>
      </div>
    );
  };

  const renderNoMessagesSection = () => {
    return (
      <SingleLayout>
        {isMobileScreen ? renderMobileNoMessagesSection() : renderDesktopNoMessagesSection()}
        {renderContentCardsWrapper()}
      </SingleLayout>
    );
  };

  const renderChannelList = () => {
    const preselectedChannelToken = location?.state?.selectedChannelToken || params.channelToken;
    const activeListFilters = activeChannelsFilters();
    const archivedListFilters = archivedChannelsFilters();

    return (
      <Fragment>
        <ChannelList
          key="active-list"
          setActiveChannelOnMount={isMobileScreen ? false : true}
          filters={activeListFilters}
          sort={sort}
          options={options}
          customActiveChannel={preselectedChannelToken || ''}
          Preview={MyChannelPreview}
          EmptyStateIndicator={ArchivedListEmptyStateIndicator}
          allowNewMessagesFromUnfilteredChannels={false}
        />
        <ChannelList
          key={'archived-list'}
          className="archived"
          setActiveChannelOnMount={false}
          filters={archivedListFilters}
          sort={sort}
          options={options}
          customActiveChannel={preselectedChannelToken || ''}
          Preview={MyChannelPreview}
          EmptyStateIndicator={ArchivedListEmptyStateIndicator}
          List={withTranslation()(ArchivedList)}
          allowNewMessagesFromUnfilteredChannels={false}
        />
      </Fragment>
    );
  };

  const renderMobileMessagesSection = () => {
    return (
      <SingleLayout>
        <StyledIonList>{renderChannelList()}</StyledIonList>

        {renderContentCardsWrapper()}

        <MobileChannel helpUrl={helpUrl} onViewTransferClick={onViewTransferClick} />
      </SingleLayout>
    );
  };

  const renderDesktopMessagesSection = () => {
    return (
      <DoubleLayout
        hideMobileHeader
        subtitle=""
        sidebarContent={<div style={{ maxHeight: '535px', overflowY: 'auto' }}>{renderChannelList()}</div>}
        mainContent={<DesktopChannel onViewTransferClick={onViewTransferClick} />}
        extraContent={renderContentCardsWrapper({ noSpace: true })}
        mainContentPadding="0"
      />
    );
  };

  const renderMessagesSection = () => {
    return (
      <Chat client={chatClient} theme="messaging light" initialNavOpen={true}>
        {isMobileScreen ? renderMobileMessagesSection() : renderDesktopMessagesSection()}
      </Chat>
    );
  };

  useEffect(() => {
    mounted();

    return () => {
      if (showNewMessagesBadge) {
        setshowNewMessagesBadge(false);
      }

      if (isMobilePlatform()) {
        removeKeyboardListeners();
      }

      // We clear the failedToFetchTransfers so we can try to fetch them again
      failedToFetchTransfers.clear();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const noMessages = !travelerInfo.chatUser || !chatClient;

  if (connecting || reconnecting) {
    return <SkeletonDetails />;
  }

  return (
    <Fragment>
      <Refresher onRefresh={doRefresh} />

      {noMessages ? renderNoMessagesSection() : renderMessagesSection()}

      <IonToast
        isOpen={showErrorToast}
        onDidDismiss={() => {
          setShowErrorToast(false);
        }}
        message={t('alert.something_went_wrong')}
        duration={2000}
        cssClass="styled-toast-error"
      />
    </Fragment>
  );
};

Messages.propTypes = {
  travelerAppStore: PropTypes.object,
  messagesStore: PropTypes.object,
  location: PropTypes.object,
  match: PropTypes.object,
  brandedSettingsStore: PropTypes.object,
  contentCardsStore: PropTypes.object,
  t: PropTypes.func,
  history: PropTypes.object
};

export default withChatContext(withTranslation()(withRouter(observer(Messages))));
