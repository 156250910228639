import styled from 'styled-components';
import theme from '@/src/theme';
import ChevronRight from '../../svgs/ChevronRight';

export const AccordionWrapper = styled.div`
  width: 100%;
`;

export const AccordionItem = styled.div`
  margin-bottom: 16px;
`;

export const AccordionHeader = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  color: ${theme.colors.base.graphite};

  @media (max-width: ${theme.breakpoints.md}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;

export const AccordionIcon = styled(ChevronRight)`
  transition: transform 0.2s ease-in-out;
  ${({ open }) =>
    open &&
    `
    transform: rotate(90deg);
  `}
`;
export const AccordionContent = styled.div`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;

  .inner-content-container {
    padding-top: 12px;
  }

  overflow: hidden;
  max-height: ${(props) => (props.isOpen ? '1000px' : '0')};
  opacity: ${(props) => (props.isOpen ? '1' : '0')};
  transform: ${(props) => (props.isOpen ? 'translateY(0)' : 'translateY(-10px)')};
  transition:
    max-height 0.2s ease-in-out,
    opacity 0.2s ease-in-out,
    transform 0.2s ease-in-out;
`;
