import React, { useEffect } from 'react';
import './contentCard.scss';
import CloseIcon from '../../../assets/icons/close-delete-icon.svg';
import braze from '../../../services/braze';
import mixpanel from '../../../services/mixpanel';

const ContentCard = (props) => {
  const { cardDescription, domain, id, image, title, url, dismissible, pinned, type, viewed } = props;

  const handleOnClick = () => {
    braze.logContentCardClicked(id);
    mixpanel.trackPromoCardClick({ Id: id, Title: title });

    if (url) {
      window.location.href = url;
    }
  };

  const handleDismiss = (e) => {
    if (e.stopPropagation) {
      e.stopPropagation();
    }

    braze.logContentCardDismissed(id);
    props.onDismiss(id);
  };

  useEffect(() => {
    if (!viewed) {
      braze.logContentCardImpression(id);
    }
  }, [id, viewed]);

  return type ? (
    <div className={`${type.toLowerCase()}-contentCard ${viewed ? '' : 'unread'}`} onClick={handleOnClick}>
      {image && <img className="image" src={image} alt={cardDescription} />}

      <div className="content">
        {title && <h1 className="title">{title}</h1>}

        {cardDescription && <div className="description">{cardDescription}</div>}

        {domain && <div className="domain">{domain}</div>}
      </div>

      {dismissible && !pinned && <img className="closeIcon" src={CloseIcon} alt="close icon" onClick={handleDismiss} />}
    </div>
  ) : null;
};

export default ContentCard;
