import { runInAction, set, makeAutoObservable } from 'mobx';

import TravelerApi from '../API';
import { trackEvent } from '../tracking';
import { filterSplittedTransfer, getActiveTrips, sortTransfersByDate } from './helpers';
import i18n from '../services/i18n.js';
import { set as setStorage } from '../services/storage';
import brandedSettingsStore from './brandedSettingsStore';
import messagesStore from './messagesStore';
import stores from '../stores';
import { logError } from '../helpers/log';
import posthog from '../services/posthog';
import { MissingTripError } from '../errors';

class TravelerAppStore {
  trips = [];
  activeTrips = [];
  transfers = [];
  transfersAutocheckErrors = {};
  travelerAutocheckErrors = [];
  loading = false;
  tripsLoading = false;
  tripDetailsLoading = false;
  tripTransfersLoading = false;
  profileInfoLoading = false;
  openMeetingPoint = false;
  error = null;
  chosenTransfer = {};
  destinations = [];
  currentTrip = {};
  cityImageObject = {};
  showExploreBadge = false;
  promoVisiblePopupMyTrips = false;
  tripsTabVisited = false;
  isAuthorised = false;
  travelerToken = '';
  travelerInfo = {};
  hideTabs = false;
  loginWithTokenInProgress = false;
  profileData = {};
  locale = '';
  firebaseToken = '';
  rollbar = null;
  profileInformation = {};
  shareCurrentLocation = null;
  currentCity = {};
  accountOperationProcessing = false;
  accountDownloadDataResponse = false;
  deletingAccountResponse = {};
  codeRequestError = {};
  loginError = {};
  authEndpointProcessing = false;
  secondsUntilResend = { value: 0, timer: null }; // Note: Keep as an object if necessary for functionality.
  apiTimeUntilSmsResend = null;
  timerCompleted = true;

  constructor() {
    makeAutoObservable(this);
  }

  setLocale = async (_locale) => {
    let locale = _locale;

    // i18n sends zh-Hans when you select a language.
    // Our API accepts only the lowercase version of it.
    // i18n accepts the lowercase version if you set it to the locale so we always use the lowercase version.
    if (locale === 'zh-Hans') {
      locale = 'zh-hans';
    }

    this.locale = locale;
    i18n.changeLanguage(locale);
    await setStorage('locale', locale);

    if (posthog.__loaded && posthog.persistence?.props?.$stored_person_properties?.wp_locale !== locale) {
      posthog.setPersonProperties({ wp_locale: locale });
    }

    // If traveler changed his locale save it in API
    if (this.isAuthorised && this.travelerInfo.locale !== locale) {
      TravelerApi.changeLocale(locale)
        .then(async (data) => {
          this.travelerInfo.locale = locale;
          await setStorage('travelerInfo', this.travelerInfo);
        })
        .catch((err) => {
          logError('Change locale error', err);
        });
    }
  };

  /* SETTERS */
  setTravelerInfo = (travelerInfo) => {
    TravelerApi.setAuthenticationHeaders(travelerInfo.accessToken);
    this.isAuthorised = true;
    this.travelerToken = travelerInfo.accessToken;
    this.travelerInfo = travelerInfo;
    this.setLocale(travelerInfo.locale);
    this.setTravelerInfoToRollbar(travelerInfo);
  };

  resetTravelerInfo = () => {
    this.isAuthorised = false;
    this.travelerToken = '';

    TravelerApi.resetAuthenticationHeaders();

    this.travelerInfo = {};
    this.resetTravelerInfoToRollbar();
    this.resetTravelerData();
  };

  resetTravelerData = () => {
    const { tripsStore } = stores;

    this.trips.clear();
    this.activeTrips.clear();
    this.resetCurrentTrip();
    this.profileInformation = {};
    tripsStore.setTripPreviews({});
  };

  setSiblingTransfers = (siblingTransfers) => {
    this.siblingTransfers = siblingTransfers;
  };

  setCurrentCity = (currentCity) => (this.currentCity = currentCity);

  setCurrentTrip = (trip) => {
    runInAction(() => {
      if (!trip) {
        this.resetCurrentTrip();
        return;
      }

      // TODO: we merge trip summary with full trip when we fetch full trip
      // Shouldn't we replace the trip?

      const currentTripFromTrips = this.trips.find((item) => item.id === trip.id) || {};
      // currentTrip = trip from the trip list + new trip extra info
      set(this.currentTrip, { ...currentTripFromTrips, ...trip });
      if (trip.branded) {
        brandedSettingsStore.setBrandedSettings(trip.brandedSettings);
      } else {
        brandedSettingsStore.resetBrandedSettings();
      }
      if (trip.travelerAutocheckErrors) {
        this.setTravelerAutocheckErrors(trip.travelerAutocheckErrors);
      } else {
        this.setTravelerAutocheckErrors([]);
      }
    });
  };

  setAskAirbnbQuestionOfCurrentTrip = (value) => {
    runInAction(() => {
      this.currentTrip = { ...this.currentTrip, askAirbnbQuestion: value };
    });
  };

  setAirbnbHostInvitationOfCurrentTrip = (value) => {
    runInAction(() => {
      this.currentTrip = { ...this.currentTrip, airbnbHostInvitation: value };
    });
  };

  resetCurrentTrip = () => {
    runInAction(() => {
      // TODO: check if current trip is reset correctly
      if (this.currentTrip.branded) {
        brandedSettingsStore.resetBrandedSettings();
      }
      this.currentTrip = {};
      this.setTravelerAutocheckErrors([]);
    });
  };

  setTransfersAutocheckErrors = (transfersAutocheckErrors) => {
    this.transfersAutocheckErrors = transfersAutocheckErrors;
  };

  setTravelerAutocheckErrors = (travelerAutocheckErrors) => {
    this.travelerAutocheckErrors.replace(travelerAutocheckErrors);
  };

  removeTravelerError = (id) => {
    const filteredTravelerAutocheckErrors = this.travelerAutocheckErrors.filter((item) => item.id !== id);
    this.setTravelerAutocheckErrors(filteredTravelerAutocheckErrors);
  };
  removeTransferError = (id, transferId) => {
    const newTransferErrors = this.transfersAutocheckErrors[transferId].filter((item) => item.id !== id);

    this.transfersAutocheckErrors[transferId] = newTransferErrors;
  };

  setShowExploreBadge = (state) => {
    this.showExploreBadge = state;
  };

  setPromoVisiblePopupMyTrips = (state) => {
    this.promoVisiblePopupMyTrips = state;
  };

  setTripsTabVisited = (state) => {
    this.tripsTabVisited = state;
  };

  setOpenMeetingPoint = (state) => {
    this.openMeetingPoint = state;
  };

  setProfileInfoLoading = (status) => (this.profileInfoLoading = status);

  setChosenTransfer = (chosenTransfer) => {
    this.chosenTransfer = chosenTransfer;
  };

  setProfileInformation = (props) => {
    if (props.autocheckErrors) {
      this.setTravelerAutocheckErrors(props.autocheckErrors);
    } else {
      this.setTravelerAutocheckErrors([]);
    }
    set(this.profileInformation, props);
  };

  setTrips(receivedTrips) {
    this.trips.replace(receivedTrips);
  }

  setShareCurrentLocation = (state) => {
    this.shareCurrentLocation = state;
    setStorage('shareCurrentLocation', state);
  };

  setTransfers(receivedTransfers) {
    this.transfers.replace(receivedTransfers);

    //Check for Autocheck errors

    const errors = {};
    if (receivedTransfers) {
      receivedTransfers.forEach((transfer) => {
        let errorList = [];

        transfer.autocheckErrors &&
          transfer.autocheckErrors.forEach((errorNode, index) => {
            if (errorNode.appliesTo === 'transfer') {
              errorList.push(errorNode);
            }
          });
        errors[transfer.id] = errorList;
      });
      this.setTransfersAutocheckErrors(errors);
    }
  }

  setAccountOperationProcessingStatus = (status) => (this.accountOperationProcessing = status);

  setAccountDownloadDataResponse = (status) => (this.accountDownloadDataResponse = status);

  setCodeRequestError = (status) => {
    this.codeRequestError = status;
  };

  setLoginError = (status) => {
    this.loginError = status;
  };

  setApiTimeUntilSmsResend = (seconds) => {
    this.apiTimeUntilSmsResend = seconds;
  };

  setAuthEndpointProcessing = (status) => {
    this.authEndpointProcessing = status;
  };

  setActiveTrips = (activeTrips) => {
    this.activeTrips.replace(activeTrips);
  };

  /* METHODS */

  setLoadingState = (loadingState) => {
    this.loading = loadingState;
  };

  setTripsLoadingState = (loadingState) => {
    this.tripsLoading = loadingState;
  };

  setTripDetailsLoadingState = (loadingState) => {
    this.tripDetailsLoading = loadingState;
  };

  setTripTransfersLoadingState = (loadingState) => {
    this.tripTransfersLoading = loadingState;
  };

  getTripTransfers = (tripId) => {
    this.setTripDetailsLoadingState(true);
    return TravelerApi.getTripById(tripId)
      .then((trip) => {
        const { transfers, tourTransfers, cityPhotoUrls } = trip;
        this.setCurrentTrip(trip);

        // FIXME: Here we have all the required data to show 'at least' something. Should we defer the next promises?
        this.cityImageObject = cityPhotoUrls;

        this.setTripDetailsLoadingState(false);

        const transferFetchOperations = Object.keys(transfers).map((transferIndex) =>
          TravelerApi.getTransferById(transfers[transferIndex].id)
        );
        const toursFetchOperations = Object.keys(tourTransfers).map((transferIndex) =>
          TravelerApi.getTourById(tourTransfers[transferIndex].id)
        );
        const allTypeTransfersFetchOperations = transferFetchOperations.concat(toursFetchOperations);

        this.setTripTransfersLoadingState(true);
        return Promise.all(allTypeTransfersFetchOperations);
      })
      .then((tripTransfers) => {
        const filteredSplittedTransfers = filterSplittedTransfer(tripTransfers, this.setSiblingTransfers);
        const sortedTransfers = sortTransfersByDate(filteredSplittedTransfers);
        this.setTransfers(sortedTransfers);

        this.setLoadingState(false);
        this.setTripTransfersLoadingState(false);
        return tripTransfers;
      })
      .catch((err) => {
        this.setTripDetailsLoadingState(false);
        this.setTripTransfersLoadingState(false);
        this.setLoadingState(false);
        logError('Get trip transfers error', err);
        throw err;
      });
  };

  // Get trips needs to be revised and implemented differently.
  // Transfers logic needs to be seperated from get trips and only be executed inside of each component that needs transfers.
  getTrips = async (tripToken) => {
    this.setLoadingState(true);
    this.setTripsLoadingState(true);
    return TravelerApi.getTrips()
      .then((trips) => {
        this.setTrips(trips);
        let currentTrip;

        if (tripToken) {
          currentTrip = trips.find((trip) => {
            return trip.id === tripToken;
          });

          this.setCurrentTrip(currentTrip);

          // If there is a tripToken, and we can not find it in the list of the trips,
          // we don't want to continue the execution of the function.
          if (!currentTrip) {
            throw new MissingTripError('Missing trip');
          }
        }

        const activeTrips = getActiveTrips(trips);
        this.setActiveTrips(activeTrips);

        return currentTrip ? currentTrip.id : null;
      })
      .then((currentTripId) => {
        this.setTripsLoadingState(false);
        if (!currentTripId) {
          this.setLoadingState(false);
          return [];
        }

        return this.getTripTransfers(currentTripId);
      })
      .catch((err) => {
        this.setLoadingState(false);
        this.setTripsLoadingState(false);

        if (!(err instanceof MissingTripError)) {
          logError('Get trips error', err);
        }

        throw err;
      });
  };

  getTripsActiveNumber = () => {
    this.setLoadingState(true);

    return TravelerApi.getTrips()
      .then((trips) => {
        return getActiveTrips(trips).length;
      })
      .catch((err) => {
        this.setLoadingState(false);
        logError('Get trips active number error', err);
        throw err;
      });
  };

  setTimelineData = (transferId, timelineData) => {
    if (this.chosenTransfer.id === transferId) {
      this.chosenTransfer.timelineData = timelineData;
    }
    const transfer = this.transfers.find((transfer) => transfer.id === transferId);
    if (transfer) {
      transfer.timelineData = timelineData;
    }
  };

  fetchProfileInformation = async () => {
    // TODO: Add loading state
    this.setProfileInfoLoading(true);
    return TravelerApi.getPersonalInfo()
      .then((data) => {
        this.setProfileInformation(data);
      })
      .catch((error) => {
        logError('Fetch profile information error', error);
        throw error;
      })
      .then(() => {
        this.setProfileInfoLoading(false);
      });
  };

  updateProfileDataAction = (profileData) => {
    // TODO: Add loading state
    this.setProfileInfoLoading(true);
    return TravelerApi.updatePersonalInfo(profileData)
      .then((travelerProfileInfo) => {
        this.setProfileInfoLoading(false);
        this.setProfileInformation(travelerProfileInfo);

        trackEvent('traveler app', 'profile', 'update success');
      })
      .catch((error) => {
        this.setProfileInfoLoading(false);
        trackEvent('traveler app', 'profile', 'update error');
        logError('Update profile data error', error);
        throw error;
      });
  };

  updateProfilePictureAction = async (picture) => {
    this.setProfileInfoLoading(true);
    return TravelerApi.updateProfilePicture(picture)
      .then((data) => {
        const { photoUrl } = data;

        if (photoUrl) {
          this.setProfileInformation({ ...this.profileInformation, photoUrl });
        }
      })
      .catch((error) => {
        trackEvent('traveler app', 'profile', 'update error');
        logError('Update profile picture error', error);
        throw error;
      })
      .finally(() => this.setProfileInfoLoading(false));
  };

  deleteAccount = () => {
    this.setAccountOperationProcessingStatus(true);
    return TravelerApi.deleteAccount()
      .then((result) => {
        this.setAccountOperationProcessingStatus(false);
        this.deletingAccountResponse = { success: result };
        trackEvent('traveler app', 'delete account', 'success');
      })
      .catch((err) => {
        this.setAccountOperationProcessingStatus(false);
        this.deletingAccountResponse = { error: err.response.data.error };
        trackEvent('traveler app', 'delete account', 'error');
      });
  };

  downloadAccountData = () => {
    this.setAccountOperationProcessingStatus(true);
    TravelerApi.downloadAccountData()
      .then((result) => {
        this.setAccountOperationProcessingStatus(false);
        this.setAccountDownloadDataResponse(result.success);
        trackEvent('traveler app', 'download account data', 'success');
      })
      .catch((err) => {
        this.setAccountDownloadDataResponse({ error: err.response.data.error });
        this.setAccountOperationProcessingStatus(false);
        trackEvent('traveler app', 'download account data', 'error');
        logError('Download account data error', err);
        throw err;
      });
  };

  codeRequest = async (emailOrPhone) => {
    this.setAuthEndpointProcessing(true);
    return TravelerApi.codeRequest(emailOrPhone)
      .then(({ hasValidPhone, phoneNumber, timeToNextAttempt }) => {
        this.setCodeRequestError({});
        this.setAuthEndpointProcessing(false);

        if (timeToNextAttempt) {
          this.setApiTimeUntilSmsResend(timeToNextAttempt);
          setStorage('timeUntilSmsResend', timeToNextAttempt); // set it in local storage since we need to be sure that this value won't be lost even if the user refreshes the page.
        }

        return {
          hasValidPhone,
          phoneNumber
        };
      })
      .catch((error) => {
        const timeToNextAttempt = error.response.data.time_to_next_attempt;

        this.setCodeRequestError({ error: error.response.data.error });
        this.setAuthEndpointProcessing(false);

        if (timeToNextAttempt) {
          this.setApiTimeUntilSmsResend(timeToNextAttempt);
          setStorage('timeUntilSmsResend', timeToNextAttempt); // set it in local storage since we need to be sure that this value won't be lost even if the user refreshes the page.
        }

        if (error.response?.status !== 401) {
          logError('Error requesting code', error);
        }

        throw error;
      });
  };

  logout = () => {
    return TravelerApi.logout()
      .then((data) => {
        return data;
      })
      .catch((error) => {
        throw error;
      });
  };

  login = (loginData) => {
    return TravelerApi.login(loginData)
      .then((data) => {
        this.clearTimer();
        this.setLoginError({});
        return data;
      })
      .catch((error) => {
        this.setLoginError({ error: error.response.data.error });

        if (error.response?.status !== 401) {
          logError('Login error', error);
        }

        throw error;
      });
  };

  createChatForTransfer = async (transfer) => {
    let getChatUser = null;
    if (this.travelerInfo.chatUser) {
      getChatUser = Promise.resolve(this.travelerInfo.chatUser);
    } else {
      getChatUser = TravelerApi.createChatUser()
        .then(async (chatUser) => {
          this.travelerInfo.chatUser = chatUser;
          await messagesStore.connect(chatUser);

          return chatUser;
        })
        .catch((err) => {
          logError('Create chat user error', err);
        });
    }

    return getChatUser.then((chatUser) => {
      return TravelerApi.createChat(transfer)
        .then((chat) => {
          const foundTransfer = this.transfers.find((trans) => trans.id === transfer.id);
          if (foundTransfer) {
            foundTransfer.channelToken = chat.channelToken;
          }
          if (this.chosenTransfer.id === transfer.id) {
            this.chosenTransfer.channelToken = chat.channelToken;
          }
          return chat;
        })
        .catch((err) => {
          logError('Create chat error', err);
        });
    });
  };

  keepTrackOfCountDown = (seconds) => {
    this.clearTimer();
    this.timerCompleted = false;
    this.secondsUntilResend.value = seconds;
    this.secondsUntilResend.timer = setInterval(() => {
      if (this.secondsUntilResend.value > 0) {
        this.secondsUntilResend.value = this.secondsUntilResend.value - 1;
      } else {
        this.clearAndCompleteTimer();
      }
    }, 1000);
  };

  clearTimer = () => {
    clearInterval(this.secondsUntilResend.timer);
    this.secondsUntilResend.value = 0;
  };

  clearAndCompleteTimer = () => {
    this.clearTimer();
    this.timerCompleted = true;
  };

  setRollbar = (rollbar) => {
    this.rollbar = rollbar;
  };

  setTravelerInfoToRollbar = (traveler) => {
    if (this.rollbar) {
      this.rollbar.configure({
        payload: {
          person: {
            id: traveler.id
          }
        }
      });
    }
  };

  resetTravelerInfoToRollbar = (traveler) => {
    if (this.rollbar) {
      this.rollbar.configure({
        payload: {
          person: {
            id: null
          }
        }
      });
    }
  };

  setChosenTransferInvitations = (invitations) => {
    this.chosenTransfer.invitations = [...invitations];

    const transfers = [...this.transfers];
    for (let transfer of transfers) {
      if (transfer.id === this.chosenTransfer.id) {
        transfer.invitations = [...invitations];
        this.transfers.replace(transfers);
        break;
      }
    }
  };

  getChatUser = async () => {
    this.setLoadingState(true);

    return TravelerApi.getChatUser()
      .then((chatUser) => {
        this.travelerInfo.chatUser = chatUser;
        return chatUser;
      })
      .catch((err) => {
        this.setLoadingState(false);

        if (err.response?.data?.errors[0]?.code !== 'not_found' && err.response?.data?.errors[0]?.status !== '404') {
          logError('Get chat user error', err);
        }

        throw err;
      });
  };

  setHideTabs = (state) => {
    this.hideTabs = state;
  };

  setLoginWithTokenInProgress = (state) => {
    this.loginWithTokenInProgress = state;
  };

  get fullName() {
    return `${this.profileInformation.firstName} ${this.profileInformation.lastName}`;
  }

  get shouldHideTabs() {
    return this.hideTabs;
  }

  get isAllowedToMergeAccounts() {
    return this.travelerInfo?.hasDuplicateAccounts && this.travelerInfo?.canMergeAccounts;
  }
}

const travelerAppStore = new TravelerAppStore();
export default travelerAppStore;
