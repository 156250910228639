import React, { Fragment, useEffect } from 'react';
import { StyledCard, StyledContent, StyledPage } from '../../../components/styled/IonElements';
import StickyHeader from '../../../components/common/StickyHeader';
import { observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import Balance from '../../../components/common/TalonOne/Balance';
import { Text2 } from '../../../components/styled/Typography';
import ReferralCodeForm from '../../../components/common/TalonOne/ReferralCodeForm';
import ReferralInfoSection from '../../../components/common/TalonOne/ReferralInfoSection';
import talonOne from '../../../services/talonOne';
// import PromoCode from "../../../components/common/TalonOne/PromoCode";
import { useStores } from '../../../stores/useStores';
import ReferralBanner from '../../../components/common/TalonOne/ReferralBanner';
import Transactions from '../../../components/common/TalonOne/Transactions';
import { Link } from 'react-router-dom';
import theme from '../../../theme';
import EllipsisIcon from '../../../assets/icons/ellipsis_horizontal_grey_icon.svg';
import {
  DesktopFlexContainer,
  DesktopList,
  DesktopRightContainer,
  InlineFlexContainer
} from '../../../components/styled/Wrappers';
import SkeletonWallet from '../../../components/common/ScarySkeletons/SkeletonWallet';
import FAQs from '../../../components/common/TalonOne/FAQs';
import InvitationInfo from '../../../components/common/TalonOne/InvitationInfo';
import LoggedInUser from '../LoggedInUser';
import mixpanel from '../../../services/mixpanel';
import Refresher from '@/src/components/common/Refresher';
import DuplicateTravelersNotification from '@/src/components/common/DuplicateTravelers/DuplicateTravelersNotification';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const transactionsLimit = 4;

const Wallet = (props) => {
  const { history, t } = props;

  const {
    talonOneStore: { transactions, balance },
    travelerAppStore: { isAllowedToMergeAccounts },
    duplicateTravelersStore: { getDuplicateTravelersLoading }
  } = useStores();

  const [loading, setLoading] = React.useState(true);
  const [isDataReady, setIsDataReady] = React.useState(false);

  const renderShowFullHistoryLink = () => {
    return (
      <div className="text-center margin-y-xl">
        <Link to="/profile/rewards_and_wallet/transaction_history">
          <InlineFlexContainer gap="8px">
            <img src={EllipsisIcon} alt="ellipsis icon" />
            <Text2 display="inline-block" weight="600" color={theme.colors.base.graphiteLighter}>
              {t('talon_one.show_full_history')}
            </Text2>
          </InlineFlexContainer>
        </Link>
      </div>
    );
  };

  // Notifications displayed when the user has no transaction
  const renderNoTransactionsNotifications = () => {
    if (isAllowedToMergeAccounts && getDuplicateTravelersLoading) {
      return null;
    }

    if (isAllowedToMergeAccounts) {
      const DuplicateTravelersNotificationSubtitle = (
        <Fragment>
          {t('duplicate_travelers.missing_credits')}
          <br />
          {t('duplicate_travelers.merge_accounts_to_make_full_use_of_wallet')}
        </Fragment>
      );

      return (
        <DuplicateTravelersNotification
          title={t('duplicate_travelers.action_required')}
          subtitle={DuplicateTravelersNotificationSubtitle}
          margin="16px 0 0"
        />
      );
    }

    return null;
  };

  // Notifications displayed when the user has transactions
  const renderTransactionsNotifications = () => {
    // Avoid flickering when user has duplicate accounts but we haven't fetched the info yet
    if (isAllowedToMergeAccounts && getDuplicateTravelersLoading) {
      return null;
    }

    if (isAllowedToMergeAccounts) {
      const DuplicateTravelersNotificationSubtitle = (
        <Fragment>
          {t('duplicate_travelers.not_sure')}
          <br />
          {t('duplicate_travelers.merge_your_accounts')}
        </Fragment>
      );

      return (
        <DuplicateTravelersNotification
          title={t('duplicate_travelers.action_required')}
          subtitle={DuplicateTravelersNotificationSubtitle}
          margin="0 0 24px"
        />
      );
    }

    return <ReferralBanner />;
  };

  // Page content when there are no transactions
  const renderNoTransactionsContent = () => {
    return (
      <Fragment>
        <Balance />
        <InvitationInfo />
        <ReferralCodeForm />
        {renderNoTransactionsNotifications()}
        <ReferralInfoSection />
        <FAQs />
      </Fragment>
    );
  };

  // Page content when there are no transactions
  const renderTransactionsContent = () => {
    return (
      <Fragment>
        <Balance />
        <Transactions limit={transactionsLimit} />
        {transactions.data?.length > transactionsLimit && renderShowFullHistoryLink()}
        {renderTransactionsNotifications()}
        <FAQs />
      </Fragment>
    );
  };

  const fetchTalonOneTransactions = async () => {
    const transactionsRes = await talonOne.getTransactionsAndInvitations();
    if (transactionsRes.status === 404) {
      await talonOne.updateOrCreateCustomerProfile();
      await talonOne.getTransactionsAndInvitations();
    }
  };

  const doRefresh = async (event) => {
    setLoading(true);
    Promise.all([fetchTalonOneTransactions(), talonOne.getLoyaltyBalances()]);
    setLoading(false);

    event.detail.complete();
  };

  const trackPageView = () => {
    mixpanel.trackWalletPageView({
      Wallet_Amount: balance.activePoints || 0,
      Referral_Banner_Visible: !isAllowedToMergeAccounts, // TODO: Change this rule if we change when we show the referral banner
      merge_accounts_visible: isAllowedToMergeAccounts,
      Transactions_Visible: !!transactions.data?.length
    });
  };

  useEffect(() => {
    if (isDataReady) {
      trackPageView();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDataReady]);

  useEffect(() => {
    if (!transactions.data) {
      fetchTalonOneTransactions().then(() => {
        setLoading(false);
        setIsDataReady(true);
      });
    } else {
      setLoading(false);
      setIsDataReady(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StyledPage>
      {window.innerWidth < 768 && (
        <StickyHeader
          goBack={() => history.push('/profile')}
          title={t('profile.rewards_and_wallet')}
          subtitle=""
          noBorder
        />
      )}
      <StyledContent>
        <Refresher onRefresh={doRefresh} />

        <DesktopFlexContainer align="flex-start">
          <DesktopList padding="0">
            <LoggedInUser isSide />
          </DesktopList>

          <DesktopRightContainer>
            <StyledCard margin="10px" mobileMarginTop="50px" padding="38px 40px" mobilePadding="20px">
              {loading ? (
                <SkeletonWallet />
              ) : transactions.data?.length ? (
                renderTransactionsContent()
              ) : (
                renderNoTransactionsContent()
              )}
            </StyledCard>
          </DesktopRightContainer>
        </DesktopFlexContainer>
      </StyledContent>
    </StyledPage>
  );
};

Wallet.propTypes = {
  history: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired
};

export default withTranslation()(withRouter(observer(Wallet)));
