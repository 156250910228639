import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useStores } from '../../../stores/useStores';
import ContentCard from './ContentCard';

const MobileContentCards = (props) => {
  const { filteredCards } = props;

  const {
    contentCardsStore: { contentCards, dismissedContentCards, removeContentCard }
  } = useStores();

  const [cards, setCards] = useState([]);

  useEffect(() => {
    let _cards = filteredCards ? [...filteredCards] : [...contentCards];

    let normalCards = [];
    let pinnedCards = [];

    if (!_cards.length) {
      setCards([]);
      return;
    }

    _cards.sort((a, b) => {
      let timestampA = Number(a.created);
      let timestampB = Number(b.created);

      return timestampB - timestampA;
    });

    _cards.forEach((card) => {
      if (card.pinned) {
        pinnedCards.push(card);
      } else {
        normalCards.push(card);
      }
    });

    setCards([...pinnedCards, ...normalCards]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(contentCards), filteredCards]);

  return cards.map((card) => {
    if (!dismissedContentCards.find((item) => item.id === card.id)) {
      return <ContentCard key={card.id} {...card} onDismiss={removeContentCard} />;
    }
    return null;
  });
};

export default observer(MobileContentCards);
