import React from 'react';
import PropTypes from 'prop-types';
import { StyledCard } from '@/src/components/styled/IonElements';
import { BannerIllustration, BannerText, BannerTitle, CloseIconImg } from './banner.styled';
import { FlexContainer } from '@/src/components/styled/Wrappers';
import { ExternalLinkButtonPrimary } from '@/src/components/styled/ButtonsV2';
import CloseIcon from '@/src/assets/icons/close-delete-grey-icon.svg';

const Banner = (props) => {
  const { title, text, image, cta, ctaUrl, onClose = () => {}, onCtaClick = () => {} } = props;

  return ctaUrl ? (
    <StyledCard margin="20px">
      <FlexContainer justify="space-between" align="center" margin="0 0 4px" gap="16px">
        <BannerTitle>{title}</BannerTitle>
        <CloseIconImg src={CloseIcon} alt="close_icon" onClick={onClose} />
      </FlexContainer>

      <FlexContainer justify="space-between" align="center" gap="16px" margin="0 0 8px">
        <BannerText dangerouslySetInnerHTML={{ __html: text }} />
        <BannerIllustration src={image} alt="banner_image" />
      </FlexContainer>

      <ExternalLinkButtonPrimary block href={ctaUrl} target="_blank" rel="noreferrer" onClick={onCtaClick}>
        {cta}
      </ExternalLinkButtonPrimary>
    </StyledCard>
  ) : null;
};

Banner.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  cta: PropTypes.string.isRequired,
  ctaUrl: PropTypes.string,
  onClose: PropTypes.func,
  onCtaClick: PropTypes.func
};

export default Banner;
