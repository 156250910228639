import { IonHeader, IonText } from '@ionic/react';
import React from 'react';
import styled from 'styled-components';
import arrowLeft from '../../assets/icons/leftGraphiteArrow.svg';
import mixpanel from '../../services/mixpanel';
import theme from '../../theme';

const StickyHeader = ({ title, subtitle, goBack, noBorder, isFixed = true }) => (
  <StickyHeaderContainer className={`sticky-header ion-no-border`} id="sticky-header" isFixed={isFixed}>
    <div
      className="back-arrow-icon"
      onClick={() => {
        mixpanel.trackBackButtonClick();
        goBack();
      }}
      // ionBackButton={goBack}
    />
    <div className="titles-wrapper">
      <IonText>
        <h3 className="title" id="sticky-header-title">
          {title}
        </h3>
        <h3 className="subtitle">{subtitle}</h3>
      </IonText>
    </div>
  </StickyHeaderContainer>
);

export default StickyHeader;

const StickyHeaderContainer = styled(IonHeader)`
  .back-arrow-icon {
    background-image: url(${arrowLeft});
    background-size: 20px 20px;
    width: 40px;
    height: 40px;
    background-position: center;
		background-repeat: no-repeat;
    margin-left: 10px;
  }
  .titles-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    .title {
      font-size: 16px;
      color: ${theme.colors.base.graphite};
      font-weight: 700;
      line-height: 20px;
      margin: 0;
    }

    .subtitle {
      color:${theme.colors.greys.grey5};
      font-size: 12px;
      line-height: 15px;
      font-weight: 500;
      margin: 0;
    }
  }

  display: flex !important;
  justify-content: flex-start;
  align-items: center;
  height: 60px;
  width: 100%;
  z-index: 11;
  background-color: #fff;
  transition: 0.5s;
  // box-shadow: 0 2px 4px 0 ${theme.colors.greys.grey06};
  position: ${(props) => (props.isFixed ? 'fixed' : 'relative')};
  padding-top: 10px;
  padding-bottom: 10px;

  &::after{
    display: ${(props) => (props.isFixed ? 'block' : 'none')};
  }
}
`;
