import { IonCard } from '@ionic/react';
import styled from 'styled-components';
import theme from '../../../../theme';

const imageBorderRadious = '12px';

export const ImageCardStyled = styled(IonCard)`
  border-radius: 12px;
  margin: 0;
`;

export const ImageContainer = styled.div`
  background-image: url(${({ src }) => src});
  background-size: cover;
  background-position: center;
  height: ${({ mobileHeight }) => mobileHeight};
  width: 100%;
  position: relative;
  border-radius: ${imageBorderRadious} ${imageBorderRadious} 0 0;

  * {
    z-index: 1; // Neended to make sure the content is on top of the dimmed overlay
  }

  ${({ dimmed }) =>
    dimmed &&
    `
      &:before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 100%;
        background: linear-gradient(180deg,rgba(0,0,0,0.3),rgba(0,0,0,0.6));
        border-radius: ${imageBorderRadious} ${imageBorderRadious} 0 0;
      }
    `}

  @media (min-width: ${theme.breakpoints[1]}px) {
    height: ${({ tabletHeight }) => tabletHeight} !;
  }

  @media (min-width: ${theme.breakpoints[2]}px) {
    height: ${({ desktopHeight }) => desktopHeight};
  }
`;

export const MainContent = styled.div`
  padding: 20px;
`;
