import React from 'react';
import PropTypes from 'prop-types';
import { StyledContent, StyledPage } from '../../styled/IonElements';
import Refresher from '../../common/Refresher';
import { Wrapper } from '@/src/theme/components/Wrapper';

// Layout for pages that have a single column of content.
// The content is centered and has a max width of 600px.
const SingleLayout = (props) => {
  const {
    children,
    onRefresh = (e) => {
      e.detail.complete();
    },
    header
  } = props;

  return (
    <StyledPage>
      <StyledContent>
        <Refresher onRefresh={onRefresh} />
        {header}
        <Wrapper styles={{ marginBottom: '20px', sm: { marginTop: '20px' } }}>{children}</Wrapper>
      </StyledContent>
    </StyledPage>
  );
};

SingleLayout.propTypes = {
  children: PropTypes.node,
  onRefresh: PropTypes.func,
  header: PropTypes.node
};

export default SingleLayout;
