import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Header from '../Modals/Header';
import theme from '../../../theme';
import InvitePassengerForm from './InvitePassengerForm';
import InvitedPassengersList from './InvitedPassengersList';
import { Text2 } from '../../styled/Typography';
import InvitePassengersActions from './InvitePassengersActions';
import { StyledModal } from '../../styled/IonElements';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';
import mixpanel from '../../../services/mixpanel';
import { useStores } from '../../../stores/useStores';
import { observer } from 'mobx-react';

function InvitePassengersModal(props) {
  const { isOpen, onClose, t } = props;
  const {
    travelerAppStore: {
      chosenTransfer: { invitations = [], numberOfPassengers = 1 }
    }
  } = useStores();

  const modalRef = useRef(null);

  const handleClose = () => {
    modalRef.current?.dismiss();
    onClose();
  };

  useEffect(() => {
    if (isOpen) {
      mixpanel.trackTravelerInvitePeopleModalView({
        Invited_Passengers_Count: invitations.length,
        Potential_Invitations_Count: numberOfPassengers - 1
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <CustomStyledModal ref={modalRef} isOpen={isOpen} onClose={handleClose} onDidDismiss={handleClose}>
      <div className="padding-all-lg">
        <Header title={t('invite_passengers.invite_people')} onClose={handleClose} />

        <Text2 className="margin-bottom-lg" color={theme.colors.base.graphiteLight} style={{ paddingRight: '80px' }}>
          {t('invite_passengers.modal_description')}
        </Text2>

        <div className="margin-bottom-xl">
          <InvitePassengerForm />
        </div>

        <InvitedPassengersList />
      </div>
      <InvitePassengersActions />
    </CustomStyledModal>
  );
}

const CustomStyledModal = styled(StyledModal)`
  --height: auto;
  --max-height: 95%;
  --overflow: auto;

  @media (max-width: 768px) {
    align-items: end;
    --border-radius: 4px 4px 0 0;
  }
`;

InvitePassengersModal.propTypes = {
  isOpen: PropTypes.bool.isRequired
};

export default withTranslation()(observer(InvitePassengersModal));
