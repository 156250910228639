import React, { Fragment } from 'react';
import { Heading2 } from '../../styled/Typography';
import styled from 'styled-components';
import { FlexContainer } from '../../styled/Wrappers';
import { IonText } from '@ionic/react';
import theme from '../../../theme';
import DefaultAvatar from '../../../assets/icons/avatar.svg';
import { useStores } from '../../../stores/useStores';
import { withTranslation } from 'react-i18next';
import { observer } from 'mobx-react';

function InvitedPassengersList(props) {
  const { t } = props;
  const {
    travelerAppStore: {
      chosenTransfer: { invitations = [], numberOfPassengers = 1 }
    }
  } = useStores();

  const renderPassenger = (passenger, index) => {
    if (!passenger.email) {
      return null;
    }

    return (
      <PassengerInformation key={index}>
        <PassengerInfoWrapper passengerAvatar={passenger.joinedTraveler?.photoUrl}>
          <div className="passenger-info-avatar" />
          <FlexContainer align="center">
            <WrapIonText>
              {passenger.status === 'joined' ? (
                <Fragment>
                  <p>{passenger.joinedTraveler?.fullName}</p>
                  <p className="passenger-info-additional success">{t('invite_passengers.accepted_invitation')}</p>
                </Fragment>
              ) : (
                <Fragment>
                  <p>{passenger.email}</p>
                  <p className="passenger-info-additional">{t('invite_passengers.pending_invitation')}</p>
                </Fragment>
              )}
            </WrapIonText>
          </FlexContainer>
        </PassengerInfoWrapper>
      </PassengerInformation>
    );
  };

  const renderPassengers = () => {
    return invitations.map((passenger, index) => renderPassenger(passenger, index));
  };

  return (
    <Fragment>
      <div>
        <IonText>
          <Heading2Styled color={theme.colors.base.graphiteLight}>
            {t('invite_passengers.number_of_passengers_added', {
              added_number: invitations.length,
              max_number: numberOfPassengers - 1
            })}
          </Heading2Styled>
        </IonText>

        {renderPassengers()}
      </div>
    </Fragment>
  );
}

const Heading2Styled = styled(Heading2)`
  font-size: 12px;
  margin-bottom: 16px;
  text-transform: uppercase;
`;

const PassengerInformation = styled(FlexContainer)`
  margin-top: 16px;
`;

const PassengerInfoWrapper = styled(FlexContainer)`
  .passenger-info-avatar {
    border-radius: 100%;
    width: 36px;
    height: 36px;
    margin-right: 12px;
    background-repeat: no-repeat;
    background-image: ${(props) => (props.passengerAvatar ? `url(${props.passengerAvatar})` : `url(${DefaultAvatar})`)};
    background-size: 36px 36px;
    background-size: cover;
  }

  .passenger-info-additional {
    color: ${theme.colors.greys.grey5};
    font-size: 12px;
    line-height: 18px;

    &.success {
      color: ${theme.colors.cta.greenDark};
    }
  }
`;

const WrapIonText = styled(IonText)`
  max-width: calc(600px - 112px); // Max page width - paddings/margins - avatar width

  @media (max-width: 600px) {
    max-width: calc(
      100vw - 112px
    ); // 112px is the width of paddings/margins + the avatar in order to find the max width of the text
  }
`;

export default withTranslation()(observer(InvitedPassengersList));
