import React, { PureComponent } from 'react';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { StyledContent, StyledPage } from '../../../../../components/styled/IonElements';
import TransfersList from '../../List/TransfersList';
import { DesktopFlexContainer, DesktopList } from '../../../../../components/styled/Wrappers';
import StickyHeader from '../../../../../components/common/StickyHeader';
import { withTranslation } from 'react-i18next';
import { onExternalLinkClick } from '../../../../../components/common/ExternalLink/ExternalLink';
import { externalLinkRedirection } from '../../helpers';
import TrackTravelerAppEventsService from '../../../../../services/trackTravelerAppEvents.js';
import CommonDetails from '../CommonDetails';
import debounce from '../../../../../helpers/debounce';
import TransferListNav from '../../List/TransferListNav';
import styled from 'styled-components';
import theme from '../../../../../theme';
import { isTruthy } from '../../../../../components/common/helpers.js';
import Refresher from '@/src/components/common/Refresher';

const isSightSeeingRide = ({ transferType }) => typeof transferType === 'undefined';
class StaticDetails extends PureComponent {
  constructor() {
    super();

    this.trackTransfePageVisitedEventDebounced = debounce(
      TrackTravelerAppEventsService.trackTransfePageVisitedEvent,
      3000
    );
  }

  transferDetailsContainer = React.createRef();

  state = {
    driverInfoModalVisibility: false,
    meetingPointModalVisibility: false,
    showHeader: false
  };

  onTransferAction = async (makeRedirection, link, event) => {
    event.preventDefault();
    if (makeRedirection) {
      // trackEvent("traveler app", "grey footer link", link);
      const isWebLink = await onExternalLinkClick(event, link);
      if (isWebLink) {
        externalLinkRedirection(link);
      }
    }
  };

  onChangeModalVisibility = (modalStateName) => {
    this.setState((state) => {
      return {
        [modalStateName]: !state[modalStateName]
      };
    });
  };

  componentWillUnmount() {
    this.trackTransfePageVisitedEventDebounced.stop();
  }

  componentDidMount = () => {
    const {
      travelerAppStore: { currentTrip }
    } = this.props;
    if (currentTrip?.id) {
      this.trackTransfePageVisitedEventDebounced.init(currentTrip);
    }
  };

  doRefresh = (event) => {
    this.trackTransfePageVisitedEventDebounced.stop();

    this.componentDidMount();

    setTimeout(() => {
      event.detail.complete();
    }, 1000);
  };

  render() {
    const {
      travelerAppStore: {
        chosenTransfer,
        currentTrip: { id: tripId }
      },
      history,
      t
    } = this.props;
    const isSRide = isSightSeeingRide(chosenTransfer);
    const stickyHeaderTitle = isSRide ? t('tour_details.sightseeing_ride') : t('trip_details.split_transfer_one');

    return (
      <StyledPage>
        <StyledContent>
          <Refresher onRefresh={this.doRefresh} />
          {window.innerWidth <= theme.breakpoints[0] && (
            <StickyTopbar>
              <StickyHeader
                goBack={() => history.push(`/trip/${tripId}`)}
                title={stickyHeaderTitle}
                subtitle={chosenTransfer.title || `${t('my_trip.from')} ${chosenTransfer.fromTitle}`}
                isFixed={false}
              />
              {isTruthy(chosenTransfer) && <TransferListNav />}
            </StickyTopbar>
          )}
          <DesktopFlexContainer align="flex-start">
            <DesktopList padding="0" top="20px">
              <TransfersList errors isTransferDetailsPage />
            </DesktopList>
            <CommonDetails />
          </DesktopFlexContainer>
        </StyledContent>
      </StyledPage>
    );
  }
}

export default withTranslation()(withRouter(inject('travelerAppStore')(observer(StaticDetails))));

const StickyTopbar = styled.div`
  position: sticky;
  top: 0;
  z-index: 11;
  box-shadow: 0 2px 4px 0 ${theme.colors.greys.grey06};
`;
