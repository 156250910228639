import React from 'react';

// eslint-disable-next-line react/prop-types
const EmailOutlined = ({ styles, fill = '#2D3B4E' }) => (
  <svg style={styles} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.3337 5.00065C18.3337 4.08398 17.5837 3.33398 16.667 3.33398H3.33366C2.41699 3.33398 1.66699 4.08398 1.66699 5.00065V15.0007C1.66699 15.9173 2.41699 16.6673 3.33366 16.6673H16.667C17.5837 16.6673 18.3337 15.9173 18.3337 15.0007V5.00065ZM16.667 5.00065L10.0003 9.16732L3.33366 5.00065H16.667ZM16.667 15.0007H3.33366V6.66732L10.0003 10.834L16.667 6.66732V15.0007Z"
      fill={fill}
    />
  </svg>
);

export default EmailOutlined;
