import { useStores } from '../../../stores/useStores';
import { CustomRadioInput, RadioInputWrapper } from './StyledRadioInput';

function RadioInput({ name, value, label, checked, onChange = () => {} }) {
  const uniqId = `${name}-${Math.floor(Math.random() * 1000000)}`;
  const {
    brandedSettingsStore: {
      brandedSettings: {
        coBrandingThemeProperties: { mainColor }
      }
    }
  } = useStores();

  return (
    <CustomRadioInput htmlFor={uniqId}>
      <RadioInputWrapper color={mainColor}>
        <input type="radio" name={name} id={uniqId} value={value} checked={checked} onChange={onChange} />
        <span></span>
      </RadioInputWrapper>
      <span>{label}</span>
    </CustomRadioInput>
  );
}

export default RadioInput;
