import React, { Component } from 'react';
import { withIonLifeCycle, IonSegment, IonSegmentButton, IonLabel } from '@ionic/react';
import { withRouter } from 'react-router-dom';
import { GreyWrapper, PoweredByLogoWrapper } from '../../components/styled/Wrappers';
import styled from 'styled-components';
import theme from '../../theme';
import WelcomeLogo from '../../components/svgs/WelcomeLogo';
import { Heading2, Paragraph } from '../../components/styled/Typography';
import EmailInput from './EmailInput';
import PhoneInputContainer from './PhoneInput';
import { StyledPage } from '../../components/styled/IonElements';
import { BackButton } from '../../components/styled/Buttons';
import { withTranslation } from 'react-i18next';
import { inject, observer } from 'mobx-react';
import PoweredBy from '../../components/svgs/PoweredBy';
import PropTypes from 'prop-types';

const welcomeImageStyles = {
  width: '40px',
  height: '40px',
  borderRadius: '100%'
};

class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showEmail: false
    };
  }

  goBack = () => {
    this.props.history.goBack();
  };

  render() {
    const { showEmail } = this.state;
    const {
      t,
      brandedSettingsStore: {
        isPartner,
        brandedSettings: {
          brandLogoUrl,
          coBrandingThemeProperties: { mainColor }
        }
      }
    } = this.props;
    const logoUrl = !isPartner ? 'https://www.welcomepickups.com/' : brandLogoUrl;

    return (
      <StyledPage>
        <GreyWrapper>
          <StyledBlock>
            <div style={{ padding: '32px' }}>
              <BackButton onClick={() => this.goBack()} />
              <LogoWrapper
                onClick={() => {
                  window.location.href = logoUrl;
                }}
              >
                {!isPartner ? (
                  <WelcomeLogo styles={welcomeImageStyles} fill={mainColor} />
                ) : (
                  <img
                    alt="logo"
                    src={brandLogoUrl}
                    style={{
                      maxWidth: '200px',
                      width: '100%',
                      height: 'auto'
                    }}
                  />
                )}
              </LogoWrapper>
              <Heading2 style={{ textAlign: 'left' }}>{t('sign_in.sign_in')}</Heading2>
              <Paragraph align="left" maxWidth="100%" margin="0 0 10px 0">
                {showEmail ? t('sign_in.enter_email') : t('sign_in.enter_phone')}
              </Paragraph>
              {/*-- Default Segment --*/}
              <StyledSegment
                mode="md"
                onIonChange={(e) => this.setState({ showEmail: e.detail.value === 'email' })}
                value={showEmail ? 'email' : 'phone'}
              >
                <WelcomeTab value="phone" color={mainColor}>
                  <IonLabel>{t('sign_in.phone')}</IonLabel>
                </WelcomeTab>
                <WelcomeTab value="email" color={mainColor}>
                  <IonLabel>{t('personal_info.email')}</IonLabel>
                </WelcomeTab>
              </StyledSegment>
              {showEmail && <EmailInput />}
              {!showEmail && <PhoneInputContainer />}

              {isPartner && (
                <PoweredByLogoWrapper align="center" justify="flex-end">
                  <PoweredBy fill={theme.colors.base.graphite} />
                </PoweredByLogoWrapper>
              )}
            </div>
          </StyledBlock>
        </GreyWrapper>
      </StyledPage>
    );
  }
}

Login.propTypes = {
  t: PropTypes.func,
  i18n: PropTypes.object,
  history: PropTypes.object,
  brandedSettingsStore: PropTypes.object
};

const StyledSegment = styled(IonSegment)`
  justify-content: flex-start;
  width: min-content;
  margin-bottom: 16px;
`;

const WelcomeTab = styled(IonSegmentButton)`
  --background-hover: none;
  --background-focused: none;
  text-transform: initial;
  width: max-content;
  box-sizing: border-box;

  ion-label {
    margin: 0;
  }

  &.segment-button-checked {
    color: ${(props) => props.color};
    --color-checked: ${(props) => props.color};
    --indicator-color: ${(props) => props.color};
    --indicator-transition: none;
  }
`;

const LogoWrapper = styled.div`
  cursor: pointer;

  @media (max-height: 394px) and (max-width: ${theme.breakpoints[0]}px) {
    display: none;
  }
`;

const StyledBlock = styled.div`
  width: 420px;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 ${theme.colors.greys.grey06};
  padding: 32px 40px 48px 40px;
  display: flex;
  flex-direction: column;

  .error {
    color: rgba(242, 116, 112, 1);
    font-size: 12px;
    line-height: 15px;
    font-weight: 500;
    margin-top: 8px;
  }

  @media (max-width: ${theme.breakpoints[0]}px) {
    height: 100%;
    width: 100%;
    border-radius: unset;
    padding: 0;
  }
`;

Login.propTypes = {
  t: PropTypes.func,
  i18n: PropTypes.object,
  history: PropTypes.object,
  location: PropTypes.object,
  brandedSettingsStore: PropTypes.object
};

export default withTranslation()(withRouter(withIonLifeCycle(inject('brandedSettingsStore')(observer(Login)))));
