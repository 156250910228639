import React, { useEffect } from 'react';
import LoggedInUser from '../../LoggedInUser';
import DoubleLayout from '@/src/components/layouts/DoubleLayout';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Wrapper } from '@/src/theme/components/Wrapper';
import PuzzleBulb from '@/src/assets/illustrations/puzzle-bulb.gif';
import { Text } from '@/src/theme/components/Typography';
import { InternalLinkButtonSecondary } from '@/src/components/styled/ButtonsV2';
import ChevronRight from '@/src/components/svgs/ChevronRight';
import mixpanel from '@/src/services/mixpanel';

const SuccessfullMerge = (props) => {
  const { t } = props;

  const renderSidebarContent = () => {
    return <LoggedInUser isSide />;
  };

  useEffect(() => {
    mixpanel.trackMergeAccountsSuccessPageView();
  }, []);

  const renderMainContent = () => {
    return (
      <Wrapper
        styles={{
          textAlign: 'center',
          marginTop: '80px',
          sm: {
            marginTop: '0'
          }
        }}
      >
        <Text element="h1" size="20" smSize="30" styles={{ marginBottom: '24px' }}>
          {t('duplicate_travelers.success_title')}
        </Text>

        <img src={PuzzleBulb} alt="Puzzle bulb illustration" style={{ width: '172px' }} />

        <Text
          element="h3"
          size="14"
          smSize="16"
          weight="700"
          styles={{ margin: '40px 0 16px', sm: { margin: '40px 0 2px' } }}
        >
          {t('duplicate_travelers.your_accounts_have_been_merged')}
        </Text>

        <Text size="14" smSize="16" styles={{}}>
          {t('duplicate_travelers.within_the_next_hour')}
        </Text>

        <InternalLinkButtonSecondary to="/trips" size="lg" margin="24px auto 0">
          {t('duplicate_travelers.visit_my_trips')}
          <ChevronRight fill="#fff" />
        </InternalLinkButtonSecondary>
      </Wrapper>
    );
  };

  return (
    <DoubleLayout
      hideMobileHeader
      sidebarContent={renderSidebarContent()}
      mainContent={renderMainContent()}
      mainContentPadding="32px 20px"
      customSpace="40px"
    />
  );
};

SuccessfullMerge.propTypes = {
  t: PropTypes.func.isRequired
};

export default withTranslation()(SuccessfullMerge);
