import React, { Fragment } from 'react';
import { onExternalLinkClick } from '../../ExternalLink/ExternalLink';

// In some case we want to wrap the LocationInfo component with a link so we use this component to determin if we should render a link or not
const LocationInfoWrapper = (props) => {
  const { href, children } = props;

  if (href) {
    return (
      <a href={href} onClick={(e) => onExternalLinkClick(e, href)}>
        {children}
      </a>
    );
  }

  return <Fragment>{children}</Fragment>;
};

export default LocationInfoWrapper;
