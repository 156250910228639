import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import promoImage from '../../../../assets/branding/install_app.jpg';
import promoImage2X from '../../../../assets/branding/install_app.jpg';
import { Heading4, Text2 } from '../../../../components/styled/Typography';
import { IonImg } from '@ionic/react';
import theme from '../../../../theme';

import AppsBlock from './AppsBlock';
import closeIcon from '../../../../assets/icons/close-delete-icon.svg';
import smsIcon from '../../../../assets/icons/sms-icons.svg';
import { withTranslation } from 'react-i18next';
import { isMobileWebPlatform } from '../../../../components/common/helpers';
import { observer } from 'mobx-react';
import { useStores } from '@/src/stores/useStores';
import { StyledModal } from '@/src/components/styled/IonElements';

// eslint-disable-next-line react/display-name
const PromoPopup = React.forwardRef(({ t }, _ref) => {
  const {
    travelerAppStore: { isAuthorised, promoVisiblePopupMyTrips, setPromoVisiblePopupMyTrips }
  } = useStores();

  const promoPopupRef = useRef(null);

  const renderAuthorizedContent = () => {
    return isMobileWebPlatform() ? (
      <AppsBlock />
    ) : (
      <SmsPanel>
        <SmsIcon /> {t('promo.sms_text')}
      </SmsPanel>
    );
  };

  const renderUnauthorizedContent = () => {
    return isMobileWebPlatform() ? <AppsBlock /> : null;
  };

  useEffect(() => {
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      promoPopupRef.current?.dismiss();
    };
  }, []);

  return (
    <CustomModal className="promo-modal" isOpen={promoVisiblePopupMyTrips} ref={promoPopupRef}>
      <CloseIconWrapper className="close-icon" onClick={() => setPromoVisiblePopupMyTrips(false)}>
        <CloseIcon role="button" />
      </CloseIconWrapper>
      <PromoImage srcset={`${promoImage}, ${promoImage} 2x`} src={promoImage2X} alt="Promo Image" />
      <PromoFooterBlock>
        <HeaderBlock>{t('promo.header_alt')}</HeaderBlock>
        <Text2>{t('promo.text_alt')}</Text2>
        {isAuthorised ? renderAuthorizedContent() : renderUnauthorizedContent()}
      </PromoFooterBlock>
    </CustomModal>
  );
});

export default withTranslation()(observer(PromoPopup));

const CloseIconWrapper = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  border-radius: 20px;
  background-color: ${theme.colors.base.white};
  position: absolute;
  z-index: 1000;
  right: 10px;
  top: 10px;
`;

const SmsPanel = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 14px 20px 14px 60px;
  color: #00b579;
  position: relative;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: 32px;
  border-radius: 4px;
  background-color: #d7f7e9;
`;

const SmsIcon = styled.div`
  background-image: url(${smsIcon});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
  position: absolute;
  left: 20px;
`;

const CloseIcon = styled.div`
  background-image: url(${closeIcon});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
`;

const HeaderBlock = styled(Heading4)`
  margin-top: 0px;
  margin-bottom: 16px;
  font-size: 24px;
  line-height: 32px;
`;

const PromoImage = styled(IonImg)`
  width: 100%;
  border-radius: 4px;
  box-shadow: inset 0 0 34px 0 rgba(255, 255, 255, 0.4);
  height: 380px;
  object-fit: cover;
  @media (max-width: 560px) {
    position: static;
    height: 260px;
  }
  @media (max-width: 340px) {
    position: static;
    height: 240px;
  }
`;

const PromoFooterBlock = styled.div`
  border-radius: 4px;
  width: 100%;
  background-color: ${theme.colors.base.white};

  padding: 32px 40px;
  display: flex;
  flex-direction: column;
  line-height: 24px;
  position: relative;
  bottom: 0;
  top: -6px;
  font-size: 16px;
  line-height: 24px;

  @media (max-width: 560px) {
    padding: 24px 24px 24px 24px;
  }
  @media (max-width: 340px) {
    padding: 24px 12px 24px 12px;
  }
`;

const CustomModal = styled(StyledModal)`
  --border-radius: 4px;
  ::part(content) {
    height: auto;
    max-width: 520px;
    width: 100%;
  }

  @media (max-width: 560px) {
    ::part(content) {
      max-height: 85%;
      align-self: flex-end;
      border-radius: 4px;

      .ion-page {
        justify-content: flex-start;
      }
    }
  }
`;
