import React from 'react';
import { IonSkeletonText } from '@ionic/react';

import './SkeletonTextExample.css';
import { FlexContainer } from '../../styled/Wrappers';

const SkeletonWallet = () => (
  <div>
    <IonSkeletonText animated style={{ width: '80px', height: '8px', margin: '0 auto 10px' }} />
    <IonSkeletonText animated style={{ width: '60px', height: '50px', margin: '0 auto' }} />

    <div style={{ width: '48px', margin: '40px auto 16px' }}>
      <IonSkeletonText animated style={{ display: 'block', width: '48px', height: '48px', borderRadius: '100px' }} />
    </div>

    <IonSkeletonText animated style={{ width: '90px', height: '20px', margin: '0 auto 10px' }} />
    <IonSkeletonText animated style={{ height: '30px', margin: '0 auto 24px' }} />

    <FlexContainer gap="10px" margin="0 0 24px">
      <IonSkeletonText animated style={{ width: '75%', height: '48px', margin: '0 auto' }} />
      <IonSkeletonText animated style={{ flex: '1', height: '48px', margin: '0 auto' }} />
    </FlexContainer>

    <IonSkeletonText animated style={{ height: '48px', margin: '0 auto 16px' }} />

    <IonSkeletonText animated style={{ height: '10px', margin: '0 auto' }} />

    <IonSkeletonText animated style={{ height: '300px', margin: '0 auto' }} />
  </div>
);

export default SkeletonWallet;
