import React from 'react';
import { CardStyled } from './Card.styled';
import { MainContent } from '../ImageCard/ImageCard.styled';

// A full-width card
const Card = (props) => {
  const { children } = props;
  return (
    <CardStyled>
      <MainContent>{children}</MainContent>
    </CardStyled>
  );
};

export default Card;
