import PhoneInput from 'react-phone-input-2';
import styled from 'styled-components';
import theme from '@/src/theme';

export const PhoneInputStyled = styled(PhoneInput)`
  .form-control,
  .flag-dropdown {
    border: ${({ error }) => (error ? '1px solid rgba(242,116,112,1)' : '1px solid rgba(45, 59, 78, 0.15)')};
  }

  // Focus styles for both the input and flag dropdown
  .form-control:focus,
  .flag-dropdown:focus {
    border: ${({ error }) => (error ? '1px solid rgba(242,116,112,1)' : `1px solid ${theme.colors.base.graphite}`)};
  }

  // Additional rule to ensure the flag dropdown border is also affected when the input is focused
  .form-control:focus + .flag-dropdown,
  .flag-dropdown:focus {
    border: ${({ error }) => (error ? '1px solid rgba(242,116,112,1)' : `1px solid ${theme.colors.base.graphite}`)};
  }

  .form-control {
    height: 48px;
    color: #2d3b4e;
    width: 100%;
    background: white;
  }
`;
