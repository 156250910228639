import React from 'react';
import { Heading4, Text2 } from '../../styled/Typography';
import { t } from 'i18next';
import styled from 'styled-components';
import GiftSVG from '../../svgs/Gift';
import { useStores } from '@/src/stores/useStores';
import { observer } from 'mobx-react';

const InvitationInfo = () => {
  const {
    destinationsStore: { destinationsCount }
  } = useStores();

  return (
    <div style={{ textAlign: 'center' }}>
      <GiftSVG />

      <Heading4 style={{ marginBottom: 0, marginTop: '10px' }}>Refer and</Heading4>

      <AnimationContainer>
        <AnimationContentWrapper>
          <Heading4>earn €5</Heading4>
          <Heading4>give your friend €5</Heading4>
          <Heading4>enjoy the ride</Heading4>
        </AnimationContentWrapper>
      </AnimationContainer>

      <Text2
        color="#425269"
        dangerouslySetInnerHTML={{ __html: t('talon_one.invitation_info', { count: destinationsCount }) }}
      />
    </div>
  );
};

const AnimationContainer = styled.div`
  position: relative;
  overflow: hidden;
  height: 26px;
  text-align: center;
  width: 100%;
  margin-bottom: 18px;
`;

const AnimationContentWrapper = styled.div`
  position: absolute;
  bottom: -4px;
  left: 50%;
  transform: translate(-50%, 0);
  height: 26px;
  animation: slideAnimation 4s linear(0 0%, 1 17%, 0.94 34%, 0.94 100%) infinite;
  animation-delay: 0.6s;
  width: 100%;

  h4 {
    margin: 0;
    color: #00905b;
  }

  @keyframes slideAnimation {
    33% {
      bottom: 26px;
    }
    66% {
      bottom: 52px;
    }
    100% {
      bottom: -4;
    }
  }
`;

export default observer(InvitationInfo);
