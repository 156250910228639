import TravelerApi from '../API';
import { getPlatformForTravelerAppEvent } from '../components/common/helpers';

const platform = getPlatformForTravelerAppEvent();
console.log('Platform: ', platform);

const appVersion = process.env.REACT_APP_VERSION;
console.log('App version: ', appVersion);

const EVENT_NAMES = {
  LOGIN: 'login',
  TRANSFER_PAGE_VISITED: 'transfer_page_visited',
  LIVE_TRANSFER_PAGE_VISITED: 'live_transfer_page_visited',
  STORE_REVIEW_REQUEST_SEEN: 'store_review_request_seen',
  STORE_REVIEW_REQUEST_SUBMITTED: 'store_review_request_submitted',
  STORE_REVIEW_REQUEST_SKIPPED: 'store_review_request_skipped'
};

class TrackTravelerAppEventsService {
  commonEventData = () => {
    return {
      platform: platform,
      app_version: appVersion
    };
  };

  submitEvent = (eventData) => {
    return TravelerApi.trackEvent(eventData)
      .then((data) => {
        // on success
        console.log('Tracked traveler app event');
        return true;
      })
      .catch((err) => {
        // TODO: log on rollbar
        console.error(err);
        return false;
      });
  };

  trackLoginEvent = () => {
    let eventData = this.commonEventData();
    eventData['name'] = EVENT_NAMES['LOGIN'];
    // TODO: add trip id?
    this.submitEvent(eventData);
  };

  trackTransfePageVisitedEvent = (trip) => {
    let eventData = this.commonEventData();
    eventData['name'] = EVENT_NAMES['TRANSFER_PAGE_VISITED'];
    eventData['trip_id'] = trip.id;
    this.submitEvent(eventData);
  };

  trackLiveTransfePageVisitedEvent = (trip) => {
    let eventData = this.commonEventData();
    eventData['name'] = EVENT_NAMES['LIVE_TRANSFER_PAGE_VISITED'];
    eventData['trip_id'] = trip.id;
    this.submitEvent(eventData);
  };

  trackStoreReviewRequestSeenEvent = () => {
    let eventData = this.commonEventData();
    eventData['name'] = EVENT_NAMES['STORE_REVIEW_REQUEST_SEEN'];
    this.submitEvent(eventData);
  };

  trackStoreReviewRequestSubmittedEvent = () => {
    let eventData = this.commonEventData();
    eventData['name'] = EVENT_NAMES['STORE_REVIEW_REQUEST_SUBMITTED'];
    this.submitEvent(eventData);
  };

  trackStoreReviewRequestSkippedEvent = () => {
    let eventData = this.commonEventData();
    eventData['name'] = EVENT_NAMES['STORE_REVIEW_REQUEST_SKIPPED'];
    this.submitEvent(eventData);
  };
}

export default new TrackTravelerAppEventsService();
