import travelerAppStore from './travelerAppStore';
import destinationsStore from './destinationsStore';
import brandedSettingsStore from './brandedSettingsStore';
import messagesStore from './messagesStore';
import contentCardsStore from './contentCardsStore';
import widgetStore from './widgetStore';
import talonOneStore from './talonOneStore';
import tripsStore from './tripsStore';
import duplicateTravelersStore from './duplicateTravelersStore';

const stores = {
  travelerAppStore,
  destinationsStore,
  brandedSettingsStore,
  messagesStore,
  contentCardsStore,
  widgetStore,
  talonOneStore,
  tripsStore,
  duplicateTravelersStore
};

export default stores;
