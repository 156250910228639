import React from 'react';

const Plane = ({ styles, fill = '#808994', opacity }) => (
  <svg style={styles} width="22" height="20" viewBox="0 0 22 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.16149 16H19.4534C20.0503 16 20.5342 16.4477 20.5342 17C20.5342 17.5523 20.0503 18 19.4534 18H2.16149C1.56462 18 1.08075 17.5523 1.08075 17C1.08075 16.4477 1.56462 16 2.16149 16ZM20.4862 7.40648C20.6877 8.11903 20.2368 8.84939 19.4598 9.05425L4.46834 12.7758C3.93587 12.908 3.37392 12.6921 3.09918 12.2499L1.08075 9.00081L2.47165 8.65344L4.36136 10.0251L9.1288 8.84049L5.15753 2.45425L7.00887 2L13.6276 7.72713L18.7212 6.46235C19.4886 6.2664 20.2848 6.69393 20.4862 7.40648Z"
      fill={fill}
    />
  </svg>
);

export default Plane;
