import React, { Component } from 'react';
import { Slide, Slider } from '@/src/components/common/Slider';
import styled from 'styled-components';
import { Text2, TextInfo } from '../../../../../components/styled/Typography';
import Offline from '../../../../../assets/icons/offline.svg';
import { FlexContainer } from '../../../../../components/styled/Wrappers';
import { onExternalLinkClick } from '../../../../../components/common/ExternalLink/ExternalLink';
import PropTypes from 'prop-types';

const slideOpts = {
  slidesPerView: window.innerWidth > 768 ? 3 : 2,
  pagination: false
};

export default class CarouselComponent extends Component {
  render() {
    const { destinationReturn, popularDestinationsReturn, popularDestinationsOutsideCity, callback } = this.props;
    const destinationsStype = destinationReturn ? popularDestinationsReturn : popularDestinationsOutsideCity;

    return (
      <Slider {...slideOpts}>
        {destinationsStype.map(
          ({ popularDestinationImage, bookingUrl, estimatedPrice, popularDestinationName, currency }, index) => (
            <Slide key={index}>
              <Anchor
                href={bookingUrl}
                target="_blank"
                rel="noopener noreferrer"
                onClick={(e) => {
                  if (callback) {
                    callback(popularDestinationName);
                  }
                  onExternalLinkClick(e, bookingUrl);
                }}
              >
                <CarouselImg
                  className="rounded-image"
                  onError={(ev) => {
                    ev.target.src = Offline;
                  }}
                  alt=""
                  src={popularDestinationImage}
                />
                <Description align="start">
                  <ContentText as="span">{popularDestinationName}</ContentText>
                  <ContentText as="span">
                    <Text as="span">{'from'.toUpperCase()}</Text>
                    <Price>
                      {currency.symbol}
                      {`${estimatedPrice}`}
                    </Price>
                  </ContentText>
                </Description>
              </Anchor>
            </Slide>
          )
        )}
      </Slider>
    );
  }
}

const CarouselImg = styled.img`
  height: 95px;
  display: block;
  object-fit: cover;
  object-position: inherit;
  width: 157px !important;
  height: 98px !important;
  box-sizing: inherit;
  border-radius: 4px !important;
`;

const Anchor = styled.a`
  :visited,
  :focus,
  :active,
  :hover {
    outline: 0 none !important;
  }
`;

const Description = styled(FlexContainer)`
  margin-top: 10px;
  flex-direction: column;
`;

const ContentText = styled(Text2)`
  outline: none;
  white-space: normal !important;
  height: auto;
  word-break: break-word;
`;

const Text = styled(TextInfo)`
  letter-spacing: 0.8px;
`;

const Price = styled.span`
  font-weight: 700;
  margin-left: 8px;
`;

CarouselComponent.propTypes = {
  destinationReturn: PropTypes.bool,
  popularDestinationsReturn: PropTypes.array,
  popularDestinationsOutsideCity: PropTypes.array,
  callback: PropTypes.func
};
