import React, { Fragment, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useStores } from '../../../stores/useStores';
import TravelerApi from '../../../API';

import { FlexContainer } from '../../styled/Wrappers';
import { ButtonBase } from '../../styled/Buttons';
import { StyledChips } from '../../styled/IonElements';
import { IonToast } from '@ionic/react';
import useEmailValidation from '../../../hooks/useEmailValidation';
import RedWarningIcon from '../../../assets/icons/red-warning-icon.svg';
import styled from 'styled-components';
import closeIcon from '../../../assets/icons/close-delete-icon.svg';
import RadioInput from '../../formFields/RadioInput';
import { Text2 } from '../../styled/Typography';
import mixpanel from '../../../services/mixpanel';
import { logError } from '@/src/helpers/log';
import Input from '../../formFields/Input';
import FormField from '../../formFields/FormField';

const isSightSeeingRide = ({ transferType }) => typeof transferType === 'undefined';
const relations = {
  accounting: 'accounting_dept',
  colleague: 'colleague_or_supervisor',
  otherPassenger: 'other_passenger',
  noAnswer: 'no_answer'
};

function TransfersReceiptForm(props) {
  const { t } = props;
  const [emailList, setEmailList] = useState([]);
  const [relation, setRelation] = useState('');
  const { emailError, setEmailError, validateEmail } = useEmailValidation();
  const [showSuccessToast, setShowSuccessToast] = useState();
  const [showErrorToast, setShowErrorToast] = useState();

  const {
    brandedSettingsStore: {
      brandedSettings: {
        coBrandingThemeProperties: { mainColor }
      }
    },
    travelerAppStore: { chosenTransfer }
  } = useStores();

  const relationOptions = [
    {
      value: relations.accounting,
      label: t('receipt_modal.relation.accounting')
    },
    {
      value: relations.colleague,
      label: t('receipt_modal.relation.colleague')
    },
    {
      value: relations.otherPassenger,
      label: t('receipt_modal.relation.other_passenger')
    },
    {
      value: relations.noAnswer,
      label: t('receipt_modal.relation.no_answer')
    }
  ];

  const handleSubmit = (event) => {
    event.preventDefault();
    const isSRide = isSightSeeingRide(chosenTransfer);

    TravelerApi.shareReceipt(
      emailList.join(','),
      relation,
      !isSRide ? chosenTransfer.id : '',
      isSRide ? chosenTransfer.id : ''
    )
      .then(() => {
        mixpanel.trackShareReceiptEmailSend({ Relationship: relation });
        setShowSuccessToast(true);
      })
      .catch((err) => {
        setShowErrorToast(true);
        logError('Share receipt error', err);
      })
      .finally(() => {
        setEmailList([]);
        setEmailError(null);
        setRelation('');
      });
  };

  const handleChange = (event) => {
    const value = event.target.value.replace(',', '');

    // Clear errors if the field is empty
    if (!value.length) {
      setEmailError(null);
    }

    // As long as we have error we validate the email
    // to let user  know that the email is invalid
    if (emailError) {
      validateEmail(value);
    }

    // If the user enters a comma, try to add the email
    // to the list and clear the field
    if (event.target.value.includes(',')) {
      addEmailToList(event);
    }
  };

  const handleBlur = (event) => {
    const value = event.target.value;

    // If the user leaves the field empty, we clear the error
    if (!value.length) {
      setEmailError(null);
      return;
    }

    addEmailToList(event);
  };
  const handleKeyPress = (event) => {
    if (event.key !== 'Enter') return;
    event.preventDefault();

    const value = event.target.value;

    // If the user leaves the field empty, we clear the error
    if (!value.length) {
      setEmailError(null);
      return;
    }

    addEmailToList(event);
  };

  const addEmailToList = (event) => {
    const value = event.target.value.replace(',', '');
    const isValidEmail = validateEmail(value);

    if (isValidEmail) {
      if (!emailList.includes(value)) {
        setEmailList([...emailList, value]);
      }
      event.target.value = '';
      setEmailError(null);
    }
  };

  const removeEmail = (email) => {
    return () => {
      setEmailList(emailList.filter((item) => item !== email));
    };
  };

  return (
    <Fragment>
      <form onSubmit={handleSubmit} noValidate>
        <div style={{ width: '100%', marginBottom: '16px' }}>
          <FormField
            margin="0"
            errorText={
              emailError ? (
                <FlexContainer align="center" className="form-field-error">
                  <img src={RedWarningIcon} alt="Error icon" />
                  {emailError}
                </FlexContainer>
              ) : null
            }
          >
            <FlexContainer>
              <Input
                onChange={handleChange}
                onBlur={handleBlur}
                onKeyPress={handleKeyPress}
                placeholder={t('receipt_modal.email')}
                error={!!emailError}
                type="email"
                name="email"
                padding="12px"
              />
            </FlexContainer>
          </FormField>

          {emailList.length > 0 && (
            <FlexContainer align="center" wrap="wrap" gap="4px" margin="8px 0 0">
              {emailList.map((email, index) => (
                <StyledChips key={index}>
                  {email}
                  <DeleteIconButton className="close-icon" onClick={removeEmail(email)}>
                    <CloseIcon src={closeIcon} alt="Remove icon" />
                  </DeleteIconButton>
                </StyledChips>
              ))}
            </FlexContainer>
          )}
        </div>
        <div style={{ width: '100%', marginBottom: '16px' }}>
          <Text2 weight="600" style={{ marginBottom: '12px' }}>
            {t('receipt_modal.relation.title')}*
          </Text2>
          <FlexContainer flexDirection="column" gap="8px">
            {relationOptions.map((option, index) => (
              <RadioInput
                key={index}
                name="relation"
                value={option.value}
                label={option.label}
                onChange={() => setRelation(option.value)}
                checked={relation === option.value}
              />
            ))}
          </FlexContainer>
        </div>
        <div style={{ marginBottom: '16px' }}>
          <ButtonBase
            width="100%"
            bgcolor={mainColor}
            type="submit"
            disabled={!!emailError || !emailList.length || !relation}
          >
            <span>{t('receipt_modal.send_receipt')}</span>
          </ButtonBase>
        </div>
      </form>

      <IonToast
        isOpen={showSuccessToast}
        message={t('receipt_modal.receipt_sent_successfully')}
        duration={3000}
        onDidDismiss={() => setShowSuccessToast(false)}
        cssClass="styled-toast-black"
      />
      <IonToast
        isOpen={showErrorToast}
        message={t('alert.something_went_wrong')}
        duration={3000}
        onDidDismiss={() => setShowErrorToast(false)}
        cssClass="styled-toast-error"
      />
    </Fragment>
  );
}

export default withTranslation()(TransfersReceiptForm);

const DeleteIconButton = styled(ButtonBase)`
  cursor: pointer;
  display: block;
  height: 16px;
  width: 16px;
  background: transparent;
  margin-left: 8px;
  padding: 0;
`;

const CloseIcon = styled.img`
  width: 100%;
  height: auto;
`;
