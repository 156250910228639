import GiftIcon from '../../../assets/icons/gift_icon.svg';
import { t } from 'i18next';
import Notification from '../Notification';

const ReferralBanner = (props) => {
  const { margin } = props;

  return (
    <Notification
      icon={GiftIcon}
      title={t('talon_one.referral_banner_title')}
      subtitle={t('talon_one.referral_banner_subtitle')}
      link="/profile/refer_a_friend"
      margin={margin}
    />
  );
};

export default ReferralBanner;
