import React from 'react';
import styled from 'styled-components';
import theme from '../../../theme';
import WalletIcon from '../../../assets/icons/wallet-icon.svg';
import MoneyIcon from '../../../assets/icons/money-icon.svg';
import AnnouncementIcon from '../../../assets/icons/announcement-icon.svg';
import CurvedArrowDownRight from '../../../assets/icons/curved-arrow-down-right.svg';
import CurvedArrowDownLeft from '../../../assets/icons/curved-arrow-down-left.svg';
import { Heading3, Text3 } from '../../styled/Typography';
import { t } from 'i18next';

const ReferralInfoSection = () => {
  return (
    <ReferralInfoContainer>
      <ReferralInfoItem>
        <img src={AnnouncementIcon} alt="announcement icon" />
        <div>
          <Heading3 fontSize="14px" margin="0 0 4px">
            {t('talon_one.share_your_referral_link')}
          </Heading3>
          <Text3 color={theme.colors.base.graphiteLight}>{t('talon_one.spread_the_word')}</Text3>
        </div>
      </ReferralInfoItem>

      <ReferralInfoItem margin="2px 0 2px 5px">
        <img src={CurvedArrowDownLeft} alt="curved arrow" />
      </ReferralInfoItem>

      <ReferralInfoItem>
        <img src={MoneyIcon} alt="money icon" />
        <div>
          <Heading3 fontSize="14px" margin="0 0 4px">
            {t('talon_one.you_both_earn')}
          </Heading3>
          <Text3 color={theme.colors.base.graphiteLight}>{t('talon_one.when_your_friend_completes')}</Text3>
        </div>
      </ReferralInfoItem>

      <ReferralInfoItem margin="2px 0">
        <img src={CurvedArrowDownRight} alt="curved arrow" />
      </ReferralInfoItem>

      <ReferralInfoItem>
        <img src={WalletIcon} alt="wallet icon" />
        <div>
          <Heading3 fontSize="14px" margin="0 0 4px">
            {t('talon_one.pay_for_your_trip_with_credits')}
          </Heading3>
          <Text3 color={theme.colors.base.graphiteLight}>{t('talon_one.use_your_credits_to_pay')}</Text3>
        </div>
      </ReferralInfoItem>

      <LegalInfo
        dangerouslySetInnerHTML={{
          __html: t('talon_one.legal', {
            privacy_policy_link: 'https://www.welcomepickups.com/terms-of-use/#privacy-policy',
            terms_and_conditions_link: 'https://www.welcomepickups.com/referral-program-terms-and-conditions/'
          })
        }}
      />
    </ReferralInfoContainer>
  );
};

const ReferralInfoContainer = styled.div`
  background: ${theme.colors.background.grey};
  border-radius: 8px;
  padding: 16px;
  margin-top: 24px;
`;

const ReferralInfoItem = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  margin: ${(props) => props.margin || '0'};
`;

const LegalInfo = styled.p`
  text-align: center;
  margin-top: 24px;
  color: ${theme.colors.base.graphiteLight};

  a {
    color: ${theme.colors.cta.greenMedium} !important;
  }
`;

export default ReferralInfoSection;
