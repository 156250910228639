import React from 'react';

const BookClock = ({ fill }) => (
  <svg width="40px" height="40px" viewBox="0 0 40 40" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <title>Book in advance</title>
    <desc>Created with sketchtool.</desc>
    <g id="Traveler-app" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="06.01.01.D.Book" transform="translate(-608.000000, -933.000000)">
        <g id="Main_card" transform="translate(300.000000, 84.000000)">
          <g id="Transfer.Block" transform="translate(308.000000, 849.000000)">
            <g id="Transfer.Icon-Copy-2">
              <circle id="Userpic" fill={fill} cx="20" cy="20" r="20" fillOpacity="0.2" />
              <g id="Icon-Icon-/-Small-/-Time" transform="translate(10.000000, 10.000000)">
                <g id="Time_Icon.20">
                  <rect id="Bounds" x="0" y="0" width="20" height="20" />
                  <path
                    d="M10,17 C13.8659932,17 17,13.8659932 17,10 C17,6.13400675 13.8659932,3 10,3 C6.13400675,3 3,6.13400675 3,10 C3,13.8659932 6.13400675,17 10,17 Z M10,19 C5.02943725,19 1,14.9705627 1,10 C1,5.02943725 5.02943725,1 10,1 C14.9705627,1 19,5.02943725 19,10 C19,14.9705627 14.9705627,19 10,19 Z M11,10.0824382 L13.4626174,12.5450556 C13.8531417,12.9355799 13.8531417,13.5687449 13.4626174,13.9592692 C13.0720931,14.3497935 12.4389281,14.3497935 12.0484038,13.9592692 L9,10.9108653 L9,5.5 C9,4.94771525 9.44771525,4.5 10,4.5 C10.5522847,4.5 11,4.94771525 11,5.5 L11,10.0824382 Z"
                    id="Fill"
                    fill={fill}
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default BookClock;
