import React from 'react';
import PropTypes from 'prop-types';
import { ContainerStyled } from './Container.styled';

// Simple centered container with a max-width
const Container = (props) => {
  const { children, className = '', styles = {} } = props;

  return (
    <ContainerStyled styles={styles} className={className}>
      {children}
    </ContainerStyled>
  );
};

Container.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  styles: PropTypes.object
};

export default Container;
