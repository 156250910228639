// eslint-disable-next-line no-unused-vars
import axios, { AxiosError } from 'axios';

/**
 * Extracts important information from an axios error
 * @param {AxiosError} error An axios error
 * @returns {object} An object containing the request and response information
 */
export const getAxiosErrorInfo = (error) => {
  const info = {};

  const { config, response } = error;

  info.request = {
    method: config?.method,
    url: config?.url,
    authorizationHeader: !!config?.headers?.Authorization
  };

  if (config?.data) {
    const data = config.data;
    // In most cases the request params are FormData so we need to convert them to objects
    if (data instanceof FormData) {
      info.request.data = Object.fromEntries(data.entries());
    } else {
      info.request.data = data;
    }
  }

  info.response = {
    status: response?.status
  };

  // status text is not always available
  if (response?.statusText) {
    info.response.statusText = response?.statusText;
  }

  return info;
};

/**
 * Returns important network info
 * @returns {object} An object containing the network info
 */
export const getConnectionInfo = () => {
  let connectionInfo = {};
  const availableConnection = navigator.connection || navigator.mozConnection || navigator.webkitConnection;

  if (availableConnection) {
    const { downlink, effectiveType } = availableConnection;
    connectionInfo = { downlink, effectiveType };
  }

  return connectionInfo;
};
