import React, { Fragment, useEffect } from 'react';
import { observer, inject } from 'mobx-react';
import { addScript } from '../../components/common/helpers';
import styled from 'styled-components';
import { TourServicesWhiteButton } from '../../components/styled/Buttons';
import { withTranslation } from 'react-i18next';
import loadTiqets from './tiqetsLoader';
import mixpanel from '../../services/mixpanel';

// const { explore: { moreAttractions } } = GonProvider.i18nTranslations.travelerApp;

const Tiqets = (props) => {
  const iFrameClickListenner = () => {
    setTimeout(() => {
      if (document.activeElement.tagName === 'IFRAME') {
        mixpanel.trackTiqetsClick();
      }
    });
  };

  useEffect(() => {
    window.focus();
    window.addEventListener('blur', iFrameClickListenner);

    return () => {
      window.removeEventListener('blur', iFrameClickListenner);
    };
  }, []);

  useEffect(() => {
    const {
      destinationsStore: { widgets }
    } = props;
    if (widgets[0].providerName === 'Tiqets' && window.location.href.includes('localhost')) {
      // The tiqets loader script adds a parameter called origin and sets its value to window.location.href.
      // When we call this script from localhost or the mobile app, the origin is set to http://localhost and prevents
      // the tiqets widget from loading.
      // To fix this, we use the loader script locally and we change the origin to process.env.REACT_APP_TRAVELER_DOMAIN
      loadTiqets();
    } else {
      const codeParts = widgets[0].code.split('<script');
      addScript(codeParts[1].split('"')[1]);
    }
  }, [props]);

  const {
    destinationsStore: { widgets },
    t
  } = props;
  return (
    <Fragment>
      <StyledContentContainer dangerouslySetInnerHTML={{ __html: widgets[0].code.split('<script')[0] }} />
      {widgets[0].viewMoreUrl && (
        <MoreRidesButton
          onClick={() => {
            mixpanel.trackMoreAttractionsClick();
            window.location.href = widgets[0].viewMoreUrl;
          }}
        >
          {t('explore.more_attractions')}
        </MoreRidesButton>
      )}
    </Fragment>
  );
};

const StyledContentContainer = styled.div`
  margin-top: 40px;
  margin-bottom: 40px;
`;

const MoreRidesButton = styled(TourServicesWhiteButton)`
  margin-top: 25px;
  max-width: 300px;
  min-height: 48px;
  margin: 0 auto;
  display: block;
`;

export default withTranslation()(inject('destinationsStore')(observer(Tiqets)));
