import { makeAutoObservable } from 'mobx';

class TalonOneStore {
  balance = {};
  profile = {};
  referrals = [];
  coupons = [];
  transactions = {};
  invitations = {};

  constructor() {
    makeAutoObservable(this);
  }

  /**
   * @param {object} newBalance
   */
  setBalance = (newBalance) => {
    this.balance = newBalance;
  };

  /**
   * @param {object} newProfile
   */
  setProfile = (newProfile) => {
    this.profile = newProfile;
  };

  /**
   * @param {array} newReferrals
   */
  setReferrals = (newReferrals) => {
    this.referrals.replace(newReferrals);
  };

  /**
   * @param {array} newReferrals
   */
  setCoupons = (newCoupons) => {
    this.coupons.replace(newCoupons);
  };

  /**
   * @param {object} newTransactions
   * @param {array} newTransactions.data
   */
  setTransactions = (newTransactions) => {
    this.transactions = newTransactions;
  };

  resetTransactions = () => {
    this.transactions = {};
  };

  /**
   * @param {object} newInvitations
   * @param {array} newInvitations.data
   */
  setInvitations = (newInvitations) => {
    this.invitations = newInvitations;
  };

  resetInvitations = () => {
    this.transactions = {};
  };

  resetValues = () => {
    this.setBalance({});
    this.setProfile({});
    this.setReferrals([]);
    this.setCoupons([]);
    this.setTransactions({});
    this.setInvitations({});
  };
}

const talonOneStore = new TalonOneStore();
export default talonOneStore;
