import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const styleParams = `
  style=element:geometry|color:0xf5f5f5&style=element:labels.icon|visibility:off&style=element:labels.text.fill|color:0x616161&style=element:labels.text.stroke|color:0xf5f5f5&style=feature:administrative.land_parcel|element:labels.text.fill|color:0xbdbdbd&style=feature:poi|element:geometry|color:0xeeeeee&style=feature:poi|element:labels.text.fill|color:0x757575&style=feature:poi.park|element:geometry|color:0xe5e5e5&style=feature:poi.park|element:labels.text.fill|color:0x9e9e9e&style=feature:road|element:geometry|color:0xffffff&style=feature:road.arterial|element:labels.text.fill|color:0x757575&style=feature:road.highway|element:geometry|color:0xdadada&style=feature:road.highway|element:geometry.fill|visibility:off&style=feature:road.highway|element:geometry.stroke|visibility:off&style=feature:road.highway|element:labels.text.fill|color:0x616161&style=feature:road.local|element:labels.text.fill|color:0x9e9e9e&style=feature:transit.line|element:geometry|color:0xe5e5e5&style=feature:transit.station|element:geometry|color:0xeeeeee&style=feature:water|element:geometry|color:0xc9c9c9&style=feature:water|element:geometry.fill|lightness:40&style=feature:water|element:labels.text.fill|color:0x9e9e9e
`;

const GmapsApiKey = process.env.REACT_APP_GMAPS_API_KEY;
const departurePin = 'https://s3.eu-west-1.amazonaws.com/welcome.mobile.production/static_assets/departure-pin.png';
const destinationPin = 'https://s3.eu-west-1.amazonaws.com/welcome.mobile.production/static_assets/destination-pin.png';

const MapV2 = (props) => {
  const {
    width = 300,
    height = 150,
    fromLatitude = '',
    fromLongitude = '',
    toLatitude = '',
    toLongitude = '',
    marginBottom = '0px'
  } = props;

  const mapSize = `${width}x${height}`;
  const toCoordinate = `${toLatitude},${toLongitude}`;
  const fromCoordinate = `${fromLatitude},${fromLongitude}`;

  return (
    <MapContainer
      width={width}
      height={height}
      backgroundImageUrl={window.encodeURI(
        `https://maps.googleapis.com/maps/api/staticmap?language=en&maptype=roadmap&size=${mapSize}&markers=icon:${departurePin}|${fromCoordinate}&markers=icon:${destinationPin}|${toCoordinate}&key=${GmapsApiKey}&style=${styleParams}`
      )}
      marginBottom={marginBottom}
    ></MapContainer>
  );
};

const MapContainer = styled.div`
  width: 100%;
  height: ${({ height }) => `${height}px`};
  background-image: url(${({ backgroundImageUrl }) => backgroundImageUrl});
  background-size: cover;
  background-position: center;
  margin-bottom: ${({ marginBottom }) => marginBottom};
`;

MapV2.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  fromLatitude: PropTypes.string,
  fromLongitude: PropTypes.string,
  toLatitude: PropTypes.string,
  toLongitude: PropTypes.string,
  marginBottom: PropTypes.string
};

export default MapV2;
