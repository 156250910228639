import React, { useEffect, useState } from 'react';
import { FlexContainer } from '../../styled/Wrappers';
import { useStores } from '../../../stores/useStores';
import ShareIconWhite from '../../../assets/icons/share_icon_white.svg';
import { canShare, copyToClipboard, nativeShare } from '../helpers';
import { IonToast } from '@ionic/react';
import { t } from 'i18next';
import talonOne from '../../../services/talonOne';
import { observer } from 'mobx-react';
import ShareIcon from '../../../assets/icons/share-icon.svg';
import mixpanel from '../../../services/mixpanel';
import braze from '../../../services/braze';
import { useLocation } from 'react-router-dom';
import theme from '@/src/theme';
import { ButtonOutlined, ButtonPrimary } from '../../styled/ButtonsV2';
import FormField from '../../formFields/FormField';
import Input from '../../formFields/Input';

const ReferralCodeForm = () => {
  const {
    brandedSettingsStore: {
      brandedSettings: {
        coBrandingThemeProperties: { mainColor }
      }
    },
    travelerAppStore: {
      travelerInfo: { id: travelerId }
    },
    talonOneStore: { referrals }
  } = useStores();

  const location = useLocation();
  const pageName = location.pathname.split('/').pop() || 'N/A';

  const [showSuccessToast, setShowSuccessToast] = useState(false);
  const [referral, setReferral] = useState('');

  const getReferralLink = () => {
    if (referral) {
      return `https://${process.env.REACT_APP_SHORT_DOMAIN}/rfr/${travelerId}/${referral.code}`;
    }
    return '';
  };

  const shareLink = async () => {
    mixpanel.trackReferralUrlShareOpened();

    const res = await nativeShare({
      // escapeValue: false is used to remove the escape characters added to the url by i18next
      text: t('talon_one.share_modal_text', { url: getReferralLink(), interpolation: { escapeValue: false } })
    });

    if (res.action === 'copy') {
      mixpanel.trackReferralUrlCopied();
      braze.logCustomEvent('referral_url_copied_or_shared', {
        PageName: pageName
      });
    } else if (res.action === 'share') {
      mixpanel.trackReferralUrlShared({ App_name: res.app });
      braze.logCustomEvent('referral_url_copied_or_shared', {
        PageName: pageName
      });
    }
  };

  const _copyToClipboard = async () => {
    const success = await copyToClipboard(getReferralLink());
    if (success) {
      setShowSuccessToast(true);
      mixpanel.trackReferralUrlCopied();
      braze.logCustomEvent('referral_url_copied_or_shared', {
        PageName: pageName
      });
    }
  };

  const renderForm = () => {
    return (
      <FormField margin="0">
        <FlexContainer>
          <Input
            value={getReferralLink()}
            type="text"
            padding="13px 12px"
            margin="0 9px 0 0"
            readOnly
            textColor={theme.colors.base.graphiteLighter}
          />
          <ButtonPrimary bgcolor={mainColor} onClick={_copyToClipboard} width="130px" fontSize="14px">
            <img src={ShareIconWhite} alt="share link" />
            {t('talon_one.copy')}
          </ButtonPrimary>
        </FlexContainer>
      </FormField>
    );
  };

  const renderShareButton = () => {
    return (
      <ButtonOutlined block margin="16px 0 0" fontSize="14px" onClick={shareLink}>
        <img src={ShareIcon} alt="share icon" />
        {t('talon_one.share_link')}
      </ButtonOutlined>
    );
  };

  const renderSuccessToast = () => {
    return (
      <IonToast
        isOpen={showSuccessToast}
        message={t('talon_one.link_copied')}
        duration={3000}
        onDidDismiss={() => setShowSuccessToast(false)}
        cssClass="styled-toast-black"
      />
    );
  };

  useEffect(() => {
    let isMounted = true;

    const loadTalonOneData = async () => {
      let data = {};
      if (!referrals.length) {
        // Retrieve referral info
        data = await talonOne.getCustomerInventory({ referrals: true });
      }

      // In case there is no user profile, we create it and then try retrieve the referral info again
      if (data.status === 404) {
        await talonOne.updateOrCreateCustomerProfile();
        data = await talonOne.getCustomerInventory({ referrals: true });
      }

      // If user has a referral code, we use it.
      // Otherwise we create a new one
      if (referrals.length) {
        isMounted && setReferral(referrals[0]);
      } else {
        talonOne.createReferralCode().then((data) => {
          if (!data.error) {
            isMounted && setReferral(data);
          }
        });
      }
    };

    loadTalonOneData();
    braze.logCustomEvent('referral_url_viewed');

    return () => {
      isMounted = false; // Mark the component as unmounted
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div style={{ margin: '24px 0 0' }}>
      {renderForm()}

      {canShare() && renderShareButton()}

      {renderSuccessToast()}
    </div>
  );
};

export default observer(ReferralCodeForm);
