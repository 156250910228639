import theme from '@/src/theme';
import { IonCheckbox } from '@ionic/react';
import styled from 'styled-components';

export const CheckboxStyled = styled(IonCheckbox)`
  --checkbox-background: transparent;
  --border-color-checked: transparent;
  --border-color: ${theme.colors.greys.grey15};
  --checkbox-background-checked: ${(props) => props.color};
  --checkmark-color: ${theme.colors.base.white};
  --transition: none;
  --border-radius: 4px;

  ${({ wrapText }) =>
    wrapText &&
    `
    .content {
      white-space: normal;
      word-wrap: break-word;
      overflow-wrap: break-word;
    }
  `}
`;
