// We had issues with the platform detection on Google Pixel deveices with android 14.
// The app was running on desktop mode instead of mobile mode so we had to patch the platform detection.
// The new methods are only applied to Google Pixels but in the future, if the old method continues to fail, we can apply the new methods to all devices.

import { Capacitor } from '@capacitor/core';
import { Device } from '@capacitor/device';

const { getPlatform, isNativePlatform } = Capacitor;

const getOs = async () => {
  const deviceInfo = await Device.getInfo();
  return deviceInfo.operatingSystem;
};

let _isAndroidOs = false;
const isAndroidOs = async () => {
  const res = (await getOs()) === 'android';
  _isAndroidOs = res;
  return res;
};
isAndroidOs();

let _isIosOs = false;
const isIosOs = async () => {
  const res = (await getOs()) === 'ios';
  _isIosOs = res;
  return;
};
isIosOs();

const isAndroidPlatformPatch = () => {
  return getPlatform() === 'android';
};

const isIosPlatformPatch = () => {
  return getPlatform() === 'ios';
};

export const isDesktopPatch = () => {
  return getPlatform() === 'web' && !(_isAndroidOs || _isIosOs);
};

export const isWebPlatformPatch = () => {
  return getPlatform() === 'web';
};

export const isMobilePlatformPatch = () => {
  return isMobileAppPlatformPatch() || isMobileWebPlatformPatch();
};

export const isMobileWebPlatformPatch = () => {
  return isWebPlatformPatch() && !isNativePlatform();
};

export const isMobileAppPlatformPatch = () => {
  return isIosPlatformPatch() || isAndroidPlatformPatch();
};

export const isIosMobileAppPatch = () => {
  return isIosPlatformPatch() && isNativePlatform();
};

export const isAndroidMobileAppPatch = () => {
  return isAndroidPlatformPatch() && isNativePlatform();
};

export const getPlatformForTravelerAppEventPatch = () => {
  if (isDesktopPatch()) {
    return 'desktop';
  } else if (isMobileWebPlatformPatch()) {
    return 'mobileweb';
  } else if (isIosMobileAppPatch()) {
    return 'ios';
  } else if (isAndroidMobileAppPatch()) {
    return 'android';
  } else {
    return null;
  }
};
