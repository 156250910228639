import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import ContinueButton from './ContinueButton';
import { withTranslation } from 'react-i18next';
import mixpanel from '../../services/mixpanel';
import Input from '@/src/components/formFields/Input';
import FormField from '@/src/components/formFields/FormField';
import PropTypes from 'prop-types';
import { urlWithReturnUrl } from '@/src/components/common/helpers';

class EmailInput extends Component {
  state = {
    email: ''
  };

  componentDidMount() {
    if (window.innerWidth > 769) {
      this.emailInput.focus();
    }
  }

  onEmailInputChange = (e) => {
    const { value } = e.target;
    const {
      travelerAppStore: { setCodeRequestError }
    } = this.props;
    this.setState({
      email: value
    });
    setCodeRequestError({});
  };

  onContinuePress = () => {
    const formData = new FormData();
    const {
      travelerAppStore: { codeRequest },
      history: { push }
    } = this.props;

    const { email } = this.state;
    formData.append('email', email);
    formData.append('message_method', 'email');
    formData.append('new_login', true);

    codeRequest(formData)
      .then(({ hasValidPhone, phoneNumber }) => {
        mixpanel.trackLoginContinue({
          Login_Method: 'email',
          $email: email,
          Status: 'success'
        });

        push(urlWithReturnUrl('/login/verification_code'), {
          email,
          hasValidPhone,
          phoneNumber
        });
      })
      .catch(() => {
        mixpanel.trackLoginContinue({
          Login_Method: 'email',
          $email: email,
          Status: 'fail'
        });
      });
  };

  onKeyDown = (e) => {
    if (e.keyCode === 13) {
      this.onContinuePress();
    }
  };

  render() {
    const {
      travelerAppStore: { codeRequestError },
      t
    } = this.props;
    const { email } = this.state;
    let error = codeRequestError.error ? codeRequestError.error : null;
    error = error === 'No bookings found with this phone number' ? null : error;
    const errorMessage = error === 'No bookings found with this email' ? t('sign_in.no_bookings') : error;

    return (
      <div style={{ position: 'relative', width: '100%' }}>
        <FormField errorText={errorMessage}>
          <Input
            ref={(ref) => (this.emailInput = ref)}
            placeholder="@"
            onChange={this.onEmailInputChange}
            value={email}
            onKeyDown={this.onKeyDown}
            error={error}
            type="email"
            padding="15px 51px 15px 16px"
          />
        </FormField>
        <ContinueButton onContinuePress={this.onContinuePress} disabled={email.length === 0} />
      </div>
    );
  }
}

EmailInput.propTypes = {
  travelerAppStore: PropTypes.object,
  t: PropTypes.func,
  history: PropTypes.object
};

export default withTranslation()(withRouter(inject('travelerAppStore')(observer(EmailInput))));
