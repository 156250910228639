import React, { useRef } from 'react';
import styled from 'styled-components';
import Header from '../../common/Modals/Header';
import { StyledModal } from '../../styled/IonElements';
import { Text2 } from '../../styled/Typography';
import theme from '../../../theme';

const DrawerModal = (props) => {
  const { isOpen, onClose, title, description, children } = props;

  const modalRef = useRef(null);

  const handleClose = (e) => {
    modalRef.current?.dismiss();
    onClose(e);
  };

  return (
    <DrawerModalStyled ref={modalRef} isOpen={isOpen} onClose={handleClose} onDidDismiss={handleClose}>
      <div className="padding-all-lg">
        <Header title={title} onClose={handleClose} />
        {description && (
          <Text2 color={theme.colors.base.graphiteLight} style={{ paddingRight: '72px' }}>
            {description}
          </Text2>
        )}
      </div>
      <div>{children}</div>
    </DrawerModalStyled>
  );
};

const DrawerModalStyled = styled(StyledModal)`
  --height: auto;
  --max-height: 90%;
  --overflow: auto;
  --width: auto;

  @media (max-width: 768px) {
    --width: 100%;
    align-items: end;
    --border-radius: 4px 4px 0 0;
  }
`;

export default DrawerModal;
