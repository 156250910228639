import JSONApiSerializer from 'json-api-serializer';

const Serializer = new JSONApiSerializer({
  convertCase: 'snake_case',
  unconvertCase: 'camelCase'
});

Serializer.register('personal_info', {
  blacklist: ['phoneOriginCode', 'type', 'imageRaw']
});

Serializer.register('trips', {
  relationships: {
    transfers: {
      type: 'transfers'
    },
    tourTransfers: {
      type: 'tour_transfers'
    },
    brandedSettings: {
      type: 'branded_settings'
    }
  }
});

Serializer.register('transfers', {
  relationships: {
    operatorConfirmedDriver: {
      type: 'drivers'
    },
    amenityItems: {
      type: 'amenity_items'
    },
    products: {
      type: 'products'
    },
    meetingPoint: {
      type: 'meeting_points'
    }
  }
});

Serializer.register('amenity_items');

Serializer.register('products');

Serializer.register('meeting_points', {
  relationships: {
    photos: {
      type: 'photos'
    }
  }
});

Serializer.register('meeting_point_photos');

Serializer.register('tour_transfers', {
  relationships: {
    confirmedDriver: {
      type: 'drivers'
    },
    meetingPoint: {
      type: 'meeting_points'
    }
  }
});

Serializer.register('welcome_cities');

Serializer.register('drivers');

Serializer.register('services');

Serializer.register('widgets');

Serializer.register('welcome_cities', {
  relationships: {
    services: {
      type: 'services'
    },
    widgets: {
      type: 'widgets'
    }
  }
});

// FIME: this line done for responses without type
Serializer.register('branded_settings');

Serializer.register('send_code_request');

Serializer.register('login_request');

Serializer.register('traveler_info_request');

Serializer.register('personal_info_photo');

Serializer.register('logout_request');

Serializer.register('download_data_request');

Serializer.register('default');

Serializer.register('erasure_data_request');

Serializer.register('update_locale');

Serializer.register('device');

Serializer.register('chat');

Serializer.register('chat_user');

Serializer.register('traveler_app_event');

Serializer.register('traveler_invitations');

Serializer.register('airbnb_host_invitation');

Serializer.register('trip_previews');

const automaticTypeInference = (data) => {
  const responseToParse = data.data || data;
  if (Array.isArray(responseToParse) && responseToParse.length > 0) {
    return responseToParse[0].type;
  }
  return responseToParse.type || 'default';
};

/**
 * @param data
 */
export function deserialize(data = {}) {
  // The data object should have the following format:
  // data: {
  //   data: {
  //     attributes: {}
  //   }
  // }
  // When that is not the case, we modify the data object to have this format.
  if (!data.data && !data.data?.attributes) {
    const _data = {
      data: { attributes: { ...data } }
    };
    const deserializedData = Serializer.deserialize(automaticTypeInference(data), _data);
    return deserializedData;
  }

  return Serializer.deserialize(automaticTypeInference(data), data);
}

/**
 * @param data
 */
export function serialize(data) {
  return Serializer.serialize(automaticTypeInference(data), data);
}
