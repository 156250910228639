/**
 * @param {number} timeInSeconds
 * @returns {string} Formatted time as MM:SS
 */
export const formatTime = (timeInSeconds) => {
  const seconds = Number(timeInSeconds);

  if (Number.isNaN(seconds) || seconds < 0) {
    return '00:00';
  }

  // Calculate remaining minutes and seconds
  const minutesRemaining = Math.floor(timeInSeconds / 60);
  const secondsRemaining = timeInSeconds % 60;

  // Format time as MM:SS
  return `${String(minutesRemaining).padStart(2, '0')}:${String(secondsRemaining).padStart(2, '0')}`;
};

export const createTimer = () => {
  let timerInterval = null;

  /**
   *  Start a timer with the given time and onUpdate callback
   * @param {string} time
   * @param {function} onUpdate
   */
  const start = (timeInSeconds = 0, onUpdate) => {
    clearInterval(timerInterval);

    let totalSeconds = timeInSeconds;

    if (totalSeconds <= 0) {
      return;
    }

    const updateTimer = () => {
      // Call the onUpdate callback with the formatted time
      if (onUpdate) {
        onUpdate(totalSeconds);
      }
    };

    // Update immediately before starting the interval
    updateTimer();

    timerInterval = setInterval(() => {
      if (totalSeconds === 0) {
        clearInterval(timerInterval);
        return;
      }

      totalSeconds -= 1; // Decrement total seconds
      updateTimer(); // Update the timer after decrementing
    }, 1000);
  };

  const stop = () => {
    clearInterval(timerInterval);
  };

  return { start, stop };
};
