import React, { Fragment, useEffect, useState } from 'react';
import { useStores } from '../../../stores/useStores.js';
import { observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import SimpleNavbar from '../SimpleNavbar/index.js';
import { MainMenuLink, MenuLabel, SmallNotificationBadge } from './MainMenu.styled.js';
import loginRoutes from '../../../pages/Login/routes';
import { IonIcon } from '@ionic/react';
import PropTypes from 'prop-types';

import transferIcoBlack from '../../../assets/menu-tabs/transfer_icon_black.svg';
import transferIco from '../../../assets/menu-tabs/transfer_icon.svg';

import luggageIco from '../../../assets/menu-tabs/luggage_icon.svg';
import luggageIcoBlack from '../../../assets/menu-tabs/luggage_icon_black.svg';

import exploreIcoBlack from '../../../assets/menu-tabs/explore_icon_black.svg';
import exploreIco from '../../../assets/menu-tabs/explore_icon.svg';

import messagesIconBlack from '../../../assets/menu-tabs/messages_icon_black.svg';
import messagesIcon from '../../../assets/menu-tabs/messages_icon.svg';
import messagesIconRed from '../../../assets/menu-tabs/messages_icon_red.svg';

import personIco from '../../../assets/menu-tabs/person_icon.svg';
import personIcoBlack from '../../../assets/menu-tabs/person_icon_black.svg';
import useWindowSize from '@/src/hooks/useWindowSize.js';
import theme from '@/src/theme/index.js';

let hideTabTimeout = null;

const MainMenu = (props) => {
  const { isMobile = false, location, t, padding = '0px 20px' } = props;
  const { pathname } = location;

  const {
    travelerAppStore: { showExploreBadge, shouldHideTabs, isAuthorised },
    messagesStore: { showNewMessagesBadge },
    brandedSettingsStore: {
      isPartner,
      brandedSettings: {
        partnerId,
        coBrandingThemeProperties: { mainColor }
      }
    }
  } = useStores();

  const [hideTabs, setHideTabs] = useState(false);

  const windowSize = useWindowSize();

  const hiddenMenuRoutes = loginRoutes.map((route) => route.path);
  const pathnameArray = pathname.split('/');
  const redirectLogoUrl = partnerId === 7580 ? 'https://layover.aegeanair.com' : undefined;
  const showPoweredByLogo = !isMobile && isPartner;

  const isLinkActive = (link) => {
    if (link === 'trips') {
      return pathnameArray[1].includes('trip');
    }

    return pathnameArray[1] === link;
  };

  const getLinks = () => {
    const newMessage = showNewMessagesBadge;

    return (
      <Fragment>
        <MainMenuLink to="/book" active={isLinkActive('book') ? 'true' : undefined} color={mainColor}>
          <IonIcon icon={isLinkActive('book') ? transferIcoBlack : transferIco} />
          <MenuLabel isActive={isLinkActive('book')}>{t('book.menu_button')}</MenuLabel>
        </MainMenuLink>

        <MainMenuLink to="/trips" active={isLinkActive('trips') ? 'true' : undefined} color={mainColor}>
          <IonIcon icon={isLinkActive('trips') ? luggageIcoBlack : luggageIco} />
          <MenuLabel isActive={isLinkActive('trips')}>{t('my_trip.trips')}</MenuLabel>
        </MainMenuLink>

        <MainMenuLink to="/explore" active={isLinkActive('explore') ? 'true' : undefined} color={mainColor}>
          <IonIcon icon={isLinkActive('explore') ? exploreIcoBlack : exploreIco} />
          {showExploreBadge && <SmallNotificationBadge />}
          <MenuLabel isActive={isLinkActive('explore')}>{t('my_trip.explore')}</MenuLabel>
        </MainMenuLink>

        {isAuthorised && (
          <MainMenuLink to="/messages" active={isLinkActive('messages') ? 'true' : undefined} color={mainColor}>
            <IonIcon
              icon={isLinkActive('messages') ? messagesIconBlack : newMessage ? messagesIconRed : messagesIcon}
            />
            {newMessage && !isLinkActive('messages') && <SmallNotificationBadge />}
            <MenuLabel isActive={isLinkActive('messages')}>{t('messages.menu_button')}</MenuLabel>
          </MainMenuLink>
        )}

        <MainMenuLink to="/profile" active={isLinkActive('profile') ? 'true' : undefined} color={mainColor}>
          <IonIcon icon={isLinkActive('profile') ? personIcoBlack : personIco} />
          <MenuLabel isActive={isLinkActive('profile')}>{t('my_trip.profile')}</MenuLabel>
        </MainMenuLink>
      </Fragment>
    );
  };

  const shouldRenderNavbar = () => {
    return !hideTabs && !hiddenMenuRoutes.includes(pathname) && pathnameArray[pathnameArray.length - 1] !== 'live';
  };

  useEffect(() => {
    if (shouldHideTabs && windowSize.width < theme.screenSizes.sm) {
      setHideTabs(true);
    } else {
      setHideTabs(false);
      clearTimeout(hideTabTimeout);
    }
  }, [shouldHideTabs, windowSize.width]);

  return shouldRenderNavbar() ? (
    <SimpleNavbar
      height="57px"
      hideLogo={isMobile}
      hideLanguageSelector={isMobile}
      hideHelp={isMobile}
      links={getLinks()}
      logoExternalLink={redirectLogoUrl}
      showPoweredByLogo={showPoweredByLogo}
      padding={padding}
      className={hideTabs ? 'hide-tabs' : ''}
    />
  ) : null;
};

MainMenu.propTypes = {
  padding: PropTypes.string,
  isMobile: PropTypes.bool,
  location: PropTypes.object
};

export default withTranslation()(withRouter(observer(MainMenu)));
