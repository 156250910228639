import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { isFalsy } from '@/src/components/common/helpers';
import { get as getStorage, set as setStorage } from '@/src/services/storage';
import Banner from './Banner';
import TravelAgentIllustration from '@/src/assets/illustrations/travel-agent-banner-illustration.svg';
import BusinessTravelllustration from '@/src/assets/illustrations/business-travel-banner-illustration.svg';
import mixpanel from '@/src/services/mixpanel';
import { withTranslation } from 'react-i18next';

const HvaB2bPromoPartner = (props) => {
  const { travelAgentUrl, businessTravelUrl, localStorageKey, t } = props;

  const [showTravelAgentPromoHva, setShowTravelAgentPromoHva] = useState(false);
  const [showBusinessPromoHva, setShowBusinessPromoHva] = useState(false);

  const handleHvaB2bPromoTravelAgent = async () => {
    if (travelAgentUrl) {
      if (isFalsy(await getStorage(localStorageKey))) {
        setShowTravelAgentPromoHva(true);
      }
    }
  };

  const handleHvaB2bPromoBusinessTravel = async () => {
    if (businessTravelUrl) {
      if (isFalsy(await getStorage(localStorageKey))) {
        setShowBusinessPromoHva(true);
      }
    }
  };

  const hideHvaB2BPromoTravelAgent = () => {
    setStorage(localStorageKey, true);
    setShowTravelAgentPromoHva(false);
  };

  const hideHvaB2BPromoBusinessTravel = () => {
    setStorage(localStorageKey, true);
    setShowBusinessPromoHva(false);
  };

  const renderHvaB2bPromoTravelAgent = () => {
    return (
      <Banner
        title={t('hva_b2b_promo_travel_agent.title')}
        text={t('hva_b2b_promo_travel_agent.textHtml')}
        image={TravelAgentIllustration}
        cta={t('hva_b2b_promo.button')}
        ctaUrl={travelAgentUrl}
        onClose={() => {
          hideHvaB2BPromoTravelAgent();
          mixpanel.trackHvaB2bPromoClosed();
        }}
        onCtaClick={() => {
          mixpanel.trackHvaB2bPromoClicked();
        }}
      />
    );
  };

  const renderHvaB2bPromoBusinessTravel = () => {
    return (
      <Banner
        title={t('hva_b2b_promo_business_travel.title')}
        text={t('hva_b2b_promo_business_travel.textHtml')}
        image={BusinessTravelllustration}
        cta={t('hva_b2b_promo.button')}
        ctaUrl={businessTravelUrl}
        onClose={() => {
          hideHvaB2BPromoBusinessTravel();
          mixpanel.trackHvaB2bPromoClosed();
        }}
        onCtaClick={() => {
          mixpanel.trackHvaB2bPromoClicked();
        }}
      />
    );
  };

  const renderHva = () => {
    if (showTravelAgentPromoHva) {
      return renderHvaB2bPromoTravelAgent();
    }

    if (showBusinessPromoHva) {
      return renderHvaB2bPromoBusinessTravel();
    }
  };

  useEffect(() => {
    handleHvaB2bPromoTravelAgent();
    handleHvaB2bPromoBusinessTravel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Fragment>{renderHva()}</Fragment>;
};

HvaB2bPromoPartner.propTypes = {
  travelAgentUrl: PropTypes.string,
  businessTravelUrl: PropTypes.string,
  localStorageKey: PropTypes.string.isRequired
};

export default withTranslation()(HvaB2bPromoPartner);
