import React from 'react';
import PropTypes from 'prop-types';
import DetectIllustration from '@/src/assets/illustrations/detect-illustration.svg';
import { ModalText, ModalTextContainer, ModalWithIconStyled } from './index.styled';
import { InternalLinkButtonSecondary } from '@/src/components/styled/ButtonsV2';
import { withTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { useStores } from '@/src/stores/useStores';

const DuplicateTravelersModal = (props) => {
  const { isOpen, onClose, t } = props;

  const {
    duplicateTravelersStore: { duplicateTravelersInfo }
  } = useStores();

  return (
    <ModalWithIconStyled
      isOpen={isOpen}
      onClose={onClose}
      icon={<img src={DetectIllustration} alt="detect illustration" />}
    >
      <div style={{ textAlign: 'center' }}>
        <ModalTextContainer>
          <ModalText>
            {duplicateTravelersInfo.duplicateAccounts?.length > 2
              ? t('duplicate_travelers.we_detected_multiple_accounts')
              : t('duplicate_travelers.we_detected_another_account')}
          </ModalText>

          <ModalText bold margin="16px 0 2px">
            1. {t('duplicate_travelers.keep_all_bookings_in_one_place')}
          </ModalText>
          <ModalText bold>2. {t('duplicate_travelers.enjoy_the_benefits')}</ModalText>
        </ModalTextContainer>

        <InternalLinkButtonSecondary
          to="/profile/merge_accounts"
          className="margin-top-md"
          block="true"
          size="lg"
          margin="31px 0 0"
        >
          {t('cta.continue')}
        </InternalLinkButtonSecondary>
      </div>
    </ModalWithIconStyled>
  );
};

DuplicateTravelersModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired
};

export default withTranslation()(observer(DuplicateTravelersModal));
