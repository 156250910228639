import React, { useEffect, useState } from 'react';
import { StyledCard, StyledContent, StyledPage } from '../../../components/styled/IonElements';
import StickyHeader from '../../../components/common/StickyHeader';
import { observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { t } from 'i18next';
import ReferralCodeForm from '../../../components/common/TalonOne/ReferralCodeForm';
import InvitationInfo from '../../../components/common/TalonOne/InvitationInfo';
import ReferralInfoSection from '../../../components/common/TalonOne/ReferralInfoSection';
import FAQs from '../../../components/common/TalonOne/FAQs';
import talonOne from '../../../services/talonOne';
import Invitations from '../../../components/common/TalonOne/Invitations';
import { Link } from 'react-router-dom';
import {
  DesktopFlexContainer,
  DesktopList,
  DesktopRightContainer,
  InlineFlexContainer
} from '../../../components/styled/Wrappers';
import EllipsisIcon from '../../../assets/icons/ellipsis_horizontal_grey_icon.svg';
import { Text2 } from '../../../components/styled/Typography';
import theme from '../../../theme';
import { useStores } from '../../../stores/useStores';
import LoggedInUser from '../LoggedInUser';
import mixpanel from '../../../services/mixpanel';
import Notification from '../../../components/common/Notification';
import Refresher from '@/src/components/common/Refresher';

const invitationsLimit = 3;

const ReferAFriend = (props) => {
  const { history } = props;
  const [invitationsLoading, setInvitationsLoading] = useState(true);
  const [isDataReady, setIsDataReady] = React.useState(false);
  const [showReferralPromotionNotification, setShowReferralPromotionNotification] = React.useState(false);

  const {
    talonOneStore: { invitations }
  } = useStores();

  const renderShowFullHistoryLink = () => {
    return (
      <div className="text-center margin-y-lg">
        <Link to="/profile/refer_a_friend/invitation_history">
          <InlineFlexContainer gap="8px">
            <img src={EllipsisIcon} alt="ellipsis icon" />
            <Text2 display="inline-block" weight="600" color={theme.colors.base.graphiteLighter}>
              {t('talon_one.show_full_history')}
            </Text2>
          </InlineFlexContainer>
        </Link>
      </div>
    );
  };

  const renderInvitationHistorySection = () => {
    return invitations.data?.length ? (
      <div className="margin-top-xl">
        <Invitations loading={invitationsLoading} limit={invitationsLimit} />
        {invitations.data?.length > invitationsLimit && renderShowFullHistoryLink()}
      </div>
    ) : null;
  };

  const fetchTalonOneInvitations = async () => {
    const invitationsRes = await talonOne.getTransactionsAndInvitations();
    if (invitationsRes.status === 404) {
      await talonOne.updateOrCreateCustomerProfile();
      await talonOne.getTransactionsAndInvitations();
    }
  };

  const doRefresh = async (event) => {
    setInvitationsLoading(true);
    Promise.all([fetchTalonOneInvitations(), talonOne.getLoyaltyBalances()]);
    setInvitationsLoading(false);

    event.detail.complete();
  };

  const trackPageView = () => {
    mixpanel.trackReferralPageView();
  };

  const closeReferralPromotionNotification = () => {
    const url = new URL(window.location.href);

    if (url.searchParams.get('nps_promoter')) {
      url.searchParams.delete('nps_promoter');
      window.history.pushState({}, document.title, url);
    }

    setShowReferralPromotionNotification(false);
  };

  useEffect(() => {
    if (isDataReady) {
      trackPageView();
    }
  }, [isDataReady]);

  useEffect(() => {
    if (!invitations.data) {
      fetchTalonOneInvitations().then(() => {
        setInvitationsLoading(false);
        setIsDataReady(true);
      });
    } else {
      setInvitationsLoading(false);
      setIsDataReady(true);
    }

    const urlSearchParams = new URLSearchParams(window.location.search);
    if (urlSearchParams.get('nps_promoter')) {
      setShowReferralPromotionNotification(true);
    }

    return () => {
      closeReferralPromotionNotification();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StyledPage>
      {window.innerWidth < 768 && (
        <StickyHeader
          goBack={() => history.push('/profile')}
          title={t('profile.refer_a_friend')}
          subtitle=""
          noBorder
        />
      )}
      <StyledContent>
        <Refresher onRefresh={doRefresh} />

        <DesktopFlexContainer align="flex-start">
          <DesktopList padding="0">
            <LoggedInUser isSide />
          </DesktopList>

          <DesktopRightContainer>
            <StyledCard margin="10px" mobileMarginTop="50px" padding="38px 40px" mobilePadding="20px">
              {showReferralPromotionNotification && (
                <Notification
                  margin="0 0 24px"
                  title={t('talon_one.thank_you_for_your_feedback')}
                  onClose={closeReferralPromotionNotification}
                />
              )}
              <InvitationInfo />
              <ReferralCodeForm />
              {renderInvitationHistorySection()}
              <ReferralInfoSection />
              <FAQs />
            </StyledCard>
          </DesktopRightContainer>
        </DesktopFlexContainer>
      </StyledContent>
    </StyledPage>
  );
};

export default withRouter(observer(ReferAFriend));
