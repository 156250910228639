import posthog from 'posthog-js';
import { PostHogProvider, usePostHog, useFeatureFlagEnabled, useFeatureFlagVariantKey } from 'posthog-js/react';
import stores from '../stores';
import { debugConsoleLog, isSubset } from '../components/common/helpers';

function getUserPropertiesConfig() {
  return {
    environment: process.env.REACT_APP_ENVIRONMENT,
    wp_locale: stores.travelerAppStore.travelerInfo.locale || stores.travelerAppStore.locale || 'en'
  };
}

function posthogInitialize() {
  const travelerId = stores.travelerAppStore.travelerInfo.id;
  if (!posthog.__loaded) {
    posthog.init(process.env.REACT_APP_PUBLIC_POSTHOG_KEY, {
      api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
      feature_flag_request_timeout_ms: 3000,
      person_profiles: 'always',
      debug: false,
      autocapture: false,
      capture_pageview: false,
      capture_pageleave: false,
      disable_session_recording: true,
      disable_surveys: true,
      enable_heatmaps: false,
      loaded: function (_posthog) {
        debugConsoleLog('Posthog: Initialized');
        debugConsoleLog('Posthog: Profile properties: ', _posthog.persistence?.props?.$stored_person_properties);

        // If there is a travelerId, we need to identify the user
        if (travelerId) {
          debugConsoleLog('Posthog: Call posthogIdentify after init');
          posthogIdentify();
        }
        // If no travelerId, we need to check if the current properties config exists and is different from the stored one.
        // If it is different, we need to update the stored properties by calling setPersonProperties
        else if (!isSubset(getUserPropertiesConfig(), posthog.persistence?.props?.$stored_person_properties)) {
          debugConsoleLog('Posthog: Set person properties after init');
          _posthog.setPersonProperties(getUserPropertiesConfig());
        }
      }
    });
  }
}

function posthogIdentify() {
  debugConsoleLog('Posthog: Trying to identify user');
  const travelerId = stores.travelerAppStore.travelerInfo.id;

  if (!posthog.__loaded || !travelerId) {
    if (!posthog.__loaded) {
      debugConsoleLog('Posthog: Posthog not loaded');
    } else {
      debugConsoleLog('Posthog: User is not authenticated');
    }
    return;
  }

  // We need to check if the identified user is the correct one.
  // If it isn't, it means that a new user has logged in (probably from a link with access token) and we need to reset
  // before we identify again.
  if (posthog._isIdentified() && posthog.get_distinct_id() !== travelerId) {
    debugConsoleLog('Posthog: New user detected. Reset old user');
    posthog.reset();
  }
  // We check if the user is identified and something changed in props
  else if (
    posthog._isIdentified() &&
    isSubset(getUserPropertiesConfig(), posthog.persistence?.props?.$stored_person_properties)
  ) {
    debugConsoleLog('Posthog: User already identified and no changes in user properties');
    return;
  }

  posthog.identify(travelerId, getUserPropertiesConfig());
  debugConsoleLog('Posthog: User identified');
}

function posthogReset() {
  if (!posthog.__loaded) {
    debugConsoleLog('Posthog: Posthog not loaded');
    return;
  }

  debugConsoleLog('Posthog: Reset user');
  posthog.reset();
  // We need to set the user properties config to the new user profile after reset
  posthog.setPersonProperties(getUserPropertiesConfig());
}

export default posthog;

export {
  // Custom functions
  posthogInitialize,
  posthogIdentify,
  posthogReset,

  // PostHog react
  PostHogProvider,
  usePostHog,
  useFeatureFlagEnabled,
  useFeatureFlagVariantKey
};
