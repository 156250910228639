import React from 'react';
import { Fragment, useState } from 'react';
import WarningIcon from '@/src/assets/icons/warning-icon.svg';
import Notification from '../../Notification';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import DuplicateTravelersModal from '../DuplicateTravelersModal';

const DuplicateTravelersNotification = (props) => {
  const { title, subtitle, margin = '0' } = props;

  const [showModal, setShowModal] = useState(false);

  return (
    <Fragment>
      <Notification
        icon={WarningIcon}
        title={title}
        subtitle={subtitle}
        onClick={() => setShowModal(true)}
        margin={margin}
        type="warning"
      />
      <DuplicateTravelersModal isOpen={showModal} onClose={() => setShowModal(false)} />
    </Fragment>
  );
};

DuplicateTravelersNotification.propTypes = {
  title: PropTypes.node.isRequired,
  subtitle: PropTypes.node.isRequired,
  margin: PropTypes.string
};

export default observer(DuplicateTravelersNotification);
