import React from 'react';

const StarSVG = ({ size = 32, fill = '#E6943F', onClick, className, style }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 32 32"
    onClick={onClick}
    className={className}
    style={style}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M30.3238 12.0557C30.1342 11.4726 29.617 11.0597 29.0074 11.0046L20.6939 10.2498L17.4083 2.55763C17.1657 1.99249 16.6138 1.62775 15.9994 1.62775C15.3851 1.62775 14.8329 1.99249 14.5918 2.55763L11.3063 10.2498L2.99137 11.0046C2.38185 11.0608 1.86572 11.4737 1.67499 12.0557C1.48537 12.6389 1.66049 13.2785 2.12148 13.6828L8.40588 19.1933L6.55292 27.3544C6.41735 27.9545 6.65026 28.575 7.14816 28.9349C7.41579 29.1294 7.73022 29.2265 8.04575 29.2265C8.31689 29.2265 8.58825 29.1544 8.83061 29.0094L15.9994 24.723L23.1669 29.0094C23.6927 29.3236 24.3538 29.2948 24.8507 28.9349C25.3486 28.575 25.5815 27.9545 25.4459 27.3544L23.5929 19.1933L29.8773 13.6828C30.3381 13.2785 30.5134 12.6402 30.3238 12.0557Z"
      fill={fill}
    />
  </svg>
);

export default StarSVG;
