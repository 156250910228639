import 'react-app-polyfill/stable';
import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import './services/i18n';
import { defineCustomElements } from '@ionic/pwa-elements/loader';

const root = createRoot(document.getElementById('root'));
root.render(<App />);

defineCustomElements(window);
