import { useStores } from '@/src/stores/useStores';
import { observer } from 'mobx-react';
import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const LoginRoute = ({ children, ...rest }) => {
  const {
    travelerAppStore: { isAuthorised }
  } = useStores();

  return (
    <Route
      {...rest}
      render={() => {
        return !isAuthorised ? children : <Redirect to="/" />;
      }}
    />
  );
};

export default observer(LoginRoute);
