import theme from '@/src/theme';
import styled from 'styled-components';

export const Wrapper = styled.div`
  cursor: pointer;

  min-height: 80px;
  width: 100%;
  margin-top: 20px;
  padding-right: 16px;
  padding-left: 16px;
  background-color: #fff;

  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-start;
  gap: 8px;

  &.active {
    border-left: 3px solid #48d9a4;
  }

  @media (max-width: ${theme.breakpoints[0]}px) {
    padding-right: 20px;
    padding-left: 20px;
    justify-content: space-evenly;
  }
`;

export const AvatarWrapper = styled.div`
  width: 64px;
  height: 64px;
  position: relative;
`;

export const Avatar = styled.div`
  display: block;
  border-radius: 100%;
  width: 64px;
  height: 64px;
  background-repeat: no-repeat;
  background-image: ${(props) => (props.driverAvatar ? `url(${props.driverAvatar})` : 'unset')};
  background-size: 64px 64px;
  background-color: ${(props) => (props.driverAvatar ? 'unset' : theme.colors.greys.grey05)};

  &.archived {
    opacity: 0.5;
  }
`;

export const DriverIconWrapper = styled.div`
  height: 24px;
  width: 24px;
  background-color: #ffffff;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15);
  position: absolute;
  right: 0px;
  bottom: 0px;
  border-radius: 50%;
`;

export const DriverIcon = styled.div`
  height: 24px;
  width: 24px;
  margin: 4px;
`;

export const Details = styled.div`
  width: 212px;

  @media (max-width: ${theme.breakpoints[0]}px) {
    width: 236px;
  }
`;

export const Info = styled.div`
  margin-bottom: 2.2px;
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 8px;
`;

export const DriverName = styled.div`
  font-size: 16px;
  letter-spacing: 0;
  line-height: 20.8px;

  &.active {
    font-weight: bold;
  }

  &.archived {
    color: rgba(45, 59, 78, 0.5);
  }
`;

export const Time = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: center;

  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const NotificationBadge = styled.div`
  height: 6px;
  width: 6px;
  border-radius: 6px;
  background-color: #f27470;
  margin-right: 3px;
  margin-bottom: 1px;
`;

export const LastMessage = styled.div`
  margin-bottom: 6px;
  width: 100%;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 18.2px;

  p {
    overflow: hidden !important;
    white-space: nowrap !important;
    text-overflow: ellipsis !important;
  }

  &.active {
    font-weight: bold;
  }

  &.archived {
    color: rgba(45, 59, 78, 0.5);
  }
`;

export const TransferDetails = styled.div`
  width: 100%;
  font-size: 12px;
  color: rgba(45, 59, 78, 0.5);

  span {
    color: rgba(45, 59, 78, 0.5);
    letter-spacing: 0;
    line-height: 15.6px;
  }
`;

export const Divider = styled.div`
  height: 1px;
  background-color: rgba(45, 59, 78, 0.08);
  margin: 20px 32px 0 32px;

  @media (max-width: ${theme.breakpoints[0]}px) {
    margin-left: 20px;
    margin-right: 20px;
  }
`;
