import { Heading3 } from '@/src/components/styled/Typography';
import theme from '@/src/theme';
import styled from 'styled-components';

export const BannerTitle = styled(Heading3)`
  color: ${theme.colors.base.graphite};
  font-size: 20px;
  line-height: 26px;
  margin: 0;
`;

export const BannerText = styled.p`
  color: ${theme.colors.base.graphiteLight};
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
`;

export const CloseIconImg = styled.img`
  cursor: pointer;
`;

export const BannerIllustration = styled.img`
  flex: 0 0 90px;

  @media (min-width: ${theme.breakpoints[1]}px) {
    flex: 0 0 140px;
  }
`;
