import React from 'react';

const EditOutline = ({ styles, fill, opacity }) => (
  <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.44734 11.9735C4.57326 11.9392 4.68804 11.8726 4.78033 11.7803L11.5303 5.0303C11.7313 4.82933 11.8907 4.59075 11.9995 4.32818C12.1082 4.0656 12.1642 3.78418 12.1642 3.49997C12.1642 3.21576 12.1082 2.93434 11.9995 2.67176C11.8907 2.40918 11.7313 2.1706 11.5303 1.96964C11.3294 1.76867 11.0908 1.60926 10.8282 1.5005C10.5656 1.39173 10.2842 1.33575 10 1.33575C9.71579 1.33575 9.43437 1.39173 9.17179 1.5005C8.90922 1.60926 8.67064 1.76867 8.46967 1.96964L1.71967 8.71964C1.62738 8.81193 1.56077 8.92671 1.52643 9.05263L0.776427 11.8026C0.705611 12.0623 0.779357 12.34 0.96967 12.5303C1.15998 12.7206 1.43768 12.7944 1.69734 12.7235L4.44734 11.9735Z"
      stroke="#2D3B4E"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default EditOutline;
