import React from 'react';
import { ArchivedTitle } from './archivedList.styled';

class ArchivedList extends React.Component {
  render() {
    const { t } = this.props;
    return (
      <div>
        <ArchivedTitle className={this.props?.children?.props?.children?.length > 0 ? 'with-channels' : ''}>
          {t('messages.archived_list_title').toUpperCase()}
        </ArchivedTitle>
        {this.props.children}
      </div>
    );
  }
}

export default ArchivedList;
