import { Text2, TextInfo } from '@/src/components/styled/Typography';
import styled from 'styled-components';

export const ContentText = styled(Text2)`
  outline: none;
  white-space: normal !important;
  height: auto;
  word-break: break-word;
`;

export const Text = styled(TextInfo)`
  letter-spacing: 0.8px;
`;

export const Price = styled.span`
  font-weight: 700;
  margin-left: 8px;
`;

export const CloseIconImg = styled.img`
  cursor: pointer;
`;
