import React from 'react';

const Sedan = ({ fill, styles }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="20" style={styles} viewBox="0 0 30 20">
    <title>Sedan</title>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h30v20H0z" />
      <path
        fill={fill}
        d="M25.22 9.12l-.226-.014A1 1 0 0 1 24 10H9a.998.998 0 0 1-.747-.335l-.532.085c-.774.126-1.547.263-2.266.407-1.1.219-1.97.432-2.495.62-.19.068-.306.125-.299.118-.14.123-.256.375-.322.752-.055.313-.067.606-.063 1.104 0 .083 0 .087-.002.146-.01-.042-.037-.083.008-.063.184.083.51.14.947.162.211.011.406.014.701.011A3.34 3.34 0 0 1 10.054 13h9.88a3.34 3.34 0 0 1 6.125.012c.338.005.562.001.803-.014.869-.056 1.138-.207 1.138-.454V9.51c-.314-.092-.772-.176-1.326-.247-.46-.06-.957-.106-1.454-.141zM18 8h5.506c-.098-.26-.198-.52-.294-.761l-.073-.181a14.997 14.997 0 0 0-.23-.543c-.121-.266-.407-.69-.703-1.025a2.757 2.757 0 0 0-.404-.385c-.039-.029-.031-.026.08-.026l-.113-.006a21.738 21.738 0 0 0-.918-.076c-.92-.06-1.88-.09-2.854-.079.002.027.003.054.003.082v3zm-2 0V4.998c-.715.051-1.429.126-2.132.229a10.9 10.9 0 0 0-2.228 1.475c-.49.415-.93.854-1.318 1.298H16zm3.72 7h-9.452a3.34 3.34 0 0 1-6.547.008c-.22 0-.397-.004-.593-.014-1.712-.087-2.85-.6-2.85-2.073l-.002-.153c-.005-.614.011-1 .094-1.467.138-.787.436-1.442.979-1.915.229-.2.529-.346.936-.492.642-.23 1.589-.462 2.78-.699a68.83 68.83 0 0 1 2.952-.517c.496-.707 1.273-1.605 2.33-2.502a12.828 12.828 0 0 1 2.794-1.82 1 1 0 0 1 .278-.085c2.536-.385 5.169-.427 7.563-.27a29.516 29.516 0 0 1 .975.08c1.042.065 2.238 1.43 2.773 2.607a16.854 16.854 0 0 1 .34.811c.08.2.161.41.29.627.534.037 1.067.087 1.569.152 2.184.28 3.071.548 3.071 1.75v3.516c0 1.652-1.218 2.334-3.01 2.45a10.14 10.14 0 0 1-.725.02A3.34 3.34 0 0 1 19.72 15zm-12.726.68a1.339 1.339 0 1 0 0-2.679 1.339 1.339 0 0 0 0 2.678zm16 0a1.339 1.339 0 1 0 0-2.679 1.339 1.339 0 0 0 0 2.678z"
      />
    </g>
  </svg>
);

export default Sedan;
