import React, { Fragment } from 'react';
import MobileMap from '../../assets/active-locations-map-mobile.png';
import DesktopMap from '../../assets/active-locations-map.png';
import { Heading2, Text2 } from '../../components/styled/Typography';
import theme from '../../theme';
import { ButtonOutlined, ButtonPrimary, LinkButton } from '../../components/styled/ButtonsV2';
import { observer } from 'mobx-react';
import { useStores } from '../../stores/useStores';
import { t } from 'i18next';
import styled from 'styled-components';
import useWindowSize from '../../hooks/useWindowSize';
import PropTypes from 'prop-types';
import { ContentCardsSimpleWrapper, FlexContainer } from '../../components/styled/Wrappers';
import { getContentCardsFilterForPage, isMobileAppPlatform, isTruthy } from '../../components/common/helpers';
import MobileContentCards from '../../components/common/MobileContentCards';
import braze from '../../services/braze';
import ReviewTransferHva from './ReviewTransferHva';

const NoTrips = (props) => {
  const { title = '', contentCardsKey = '' } = props;

  const [webContentCardsTopRendered, setWebContentCardsTopRendered] = React.useState(false);
  const [webContentCardsBottomRendered, setWebContentCardsBottomRendered] = React.useState(false);

  const {
    destinationsStore: { destinationsCount, destinationCountriesCount },
    travelerAppStore: { isAuthorised },
    contentCardsStore: { contentCards },
    tripsStore: {
      tripPreviews: { reviewTransferHva }
    }
  } = useStores();

  const windowSize = useWindowSize();

  const renderMapImage = () => {
    const windowWidth = windowSize.width || window.innerWidth;

    return windowWidth < theme.breakpoints[0] ? (
      <ActiveLocationsMap src={MobileMap} alt="active locations map" />
    ) : (
      <ActiveLocationsMap src={DesktopMap} alt="active locations map" />
    );
  };

  /**
   *
   * @param {('top'|'bottom')} position
   * @returns
   */
  const renderContentCards = (position) => {
    // We need to know the tab name to filter the content cards
    if (!contentCardsKey || !position) {
      return null;
    }

    const fullKey = `${contentCardsKey}_${position}`;
    const contentCardsFilter = getContentCardsFilterForPage(fullKey);
    const filteredCards = contentCards.filter(contentCardsFilter);

    // We check if the cards are already rendered to avoid rendering them multiple times
    if (position === 'top' && !webContentCardsTopRendered) {
      braze.renderContentCards(fullKey, contentCardsFilter);
      setWebContentCardsTopRendered(true);
    } else if (position === 'bottom' && !webContentCardsBottomRendered) {
      braze.renderContentCards(fullKey, contentCardsFilter);
      setWebContentCardsBottomRendered(true);
    }

    return (
      <ContentCardsSimpleWrapper display={filteredCards.length ? 'block' : 'none'} id={fullKey}>
        {isMobileAppPlatform() && <MobileContentCards filteredCards={filteredCards} />}
      </ContentCardsSimpleWrapper>
    );
  };

  const renderReviewTransfer = () => {
    return (
      <ReviewTransferHva
        title={reviewTransferHva?.title}
        driverAvatar={reviewTransferHva?.driverPhotoUrl}
        notMyDriverUrl={reviewTransferHva?.notMyDriverUrl}
        notMyDriverText={reviewTransferHva?.notMyDriverText}
        reviewUrls={reviewTransferHva?.reviewUrls}
      />
    );
  };

  return (
    <Fragment>
      {renderContentCards('top')}

      {isAuthorised && isTruthy(reviewTransferHva) && renderReviewTransfer()}

      <div style={{ padding: '0 32px', marginBottom: '24px' }}>
        {title && (
          <Heading2 color={theme.colors.base.graphiteLighter} align="center">
            {title}
          </Heading2>
        )}

        <Text2
          weight="400"
          color={theme.colors.greys.grey4}
          align="center"
          dangerouslySetInnerHTML={{
            __html: t('trips.available_in', {
              destinationsCount: destinationsCount,
              destinationCountriesCount: destinationCountriesCount
            })
          }}
        />
      </div>

      {renderMapImage()}

      <FlexContainer flexDirection="column" gap="20px" padding="0 20px">
        <LinkButton to="/book" block>
          <ButtonPrimary block>{t('trips.search_for_available_options')}</ButtonPrimary>
        </LinkButton>

        {!isAuthorised && (
          <Fragment>
            <Text2 color={theme.colors.greys.grey4} style={{ textAlign: 'center' }}>
              {t('trips.if_you_have_a_booking')}
            </Text2>

            <LinkButton to="/login" block>
              <ButtonOutlined block>{t('sign_in.sign_in')}</ButtonOutlined>
            </LinkButton>
          </Fragment>
        )}
      </FlexContainer>
      {renderContentCards('bottom')}
    </Fragment>
  );
};

const ActiveLocationsMap = styled.img`
  margin-bottom: 20px;
`;

NoTrips.propTypes = {
  title: PropTypes.node,
  contentCardsKey: PropTypes.string
};

export default observer(NoTrips);
