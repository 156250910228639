import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { observer } from 'mobx-react';
import TravelerApi from '../../../API';
import { withTranslation } from 'react-i18next';
import mixpanel from '../../../services/mixpanel';
import { logError } from '@/src/helpers/log';
import AlertComponent from './AlertComponent';
import PropTypes from 'prop-types';
import { useStores } from '@/src/stores/useStores';

const InvalidTravelerDataBlock = (props) => {
  const {
    t,
    alertsAreShownIn,
    errors,
    autocheckFailAlerts,
    classNameForWrapper,
    transferId,
    editUrl,
    removeError,
    showToast,
    isDetailsPage
  } = props;

  const {
    travelerAppStore: { loading }
  } = useStores();

  const isLoading = loading;
  const [loadingIdicator, initLoading] = useState({ open: false, key: null });

  const tripProfileValidateAction = (e, id, title) => {
    e.stopPropagation();
    initLoading({ open: true, key: id });
    mixpanel.trackThisInformationIsCorrectClick({ Title: title });

    TravelerApi.resolveTravelerAutochekError(id)
      .then((result) => {
        initLoading({ open: false, key: id });
        removeError(id);
        showToast({ toastMessage: t('alert.error_resolved') });
      })
      .catch((err) => {
        initLoading({ open: false, key: id });

        showToast({
          toastMessage: t('sign_in.error')
        });
        logError('Error resolving traveler autocheck error', err);
      });
  };

  const transferValidateAction = (e, id, title) => {
    e.stopPropagation();
    initLoading({ open: true, key: id });
    mixpanel.trackThisInformationIsCorrectClick({ Title: title });

    TravelerApi.resolveAutochekError(id, transferId)
      .then((result) => {
        initLoading({ open: false, key: id });

        removeError(id, transferId);
        showToast({ toastMessage: t('alert.error_resolved') });
      })
      .catch((err) => {
        initLoading({ open: false, key: id });

        showToast({
          toastMessage: t('sign_in.error')
        });

        logError('Error resolving autocheck error', err);
      });
  };

  const addCorrectInfoClicked = (e, title) => {
    e.stopPropagation();
    mixpanel.trackAddCorrectInformationClick({ Title: title });
  };

  if (!autocheckFailAlerts && isLoading && errors) {
    return null;
  }

  return autocheckFailAlerts.map((alert, index) => {
    const {
      messages: {
        forTraveler: { title, subtitle }
      },
      id
    } = alert;
    const splittedMessageAndValue = title.split(':');

    switch (alertsAreShownIn) {
      case 'Profile':
      case 'Trip':
        return (
          <AlertComponent
            isDetailsPage={isDetailsPage}
            key={index}
            order={id}
            validateText={t('alert.validate_button')}
            editText={t('alert.correction_button')}
            isLoading={loadingIdicator}
            addCorrectInfocallback={(e) => addCorrectInfoClicked(e, title)}
            onValidatePress={(e) => tripProfileValidateAction(e, id, title)}
            alertsAreShownIn={alertsAreShownIn}
            classNameForWrapper={classNameForWrapper}
            link="/profile/edit"
            points="internal"
            splittedMessageAndValue={splittedMessageAndValue}
            subtitleMessage={subtitle}
            alertName={alert.name}
          />
        );
      case 'Transfers':
        return (
          <AlertComponent
            isDetailsPage={isDetailsPage}
            key={index}
            order={id}
            validateText={t('alert.validate_button')}
            editText={t('alert.correction_button')}
            addCorrectInfocallback={(e) => addCorrectInfoClicked(e, title)}
            onValidatePress={(e) => transferValidateAction(e, id, title)}
            isLoading={loadingIdicator}
            alertsAreShownIn={alertsAreShownIn}
            classNameForWrapper={classNameForWrapper}
            link={editUrl}
            points="external"
            splittedMessageAndValue={splittedMessageAndValue}
            subtitleMessage={subtitle}
            alertName={alert.name}
          />
        );
      default:
        return null;
    }
  });
};

InvalidTravelerDataBlock.propTypes = {
  t: PropTypes.func,
  alertsAreShownIn: PropTypes.string,
  errors: PropTypes.bool,
  autocheckFailAlerts: PropTypes.array,
  classNameForWrapper: PropTypes.string,
  transferId: PropTypes.string,
  editUrl: PropTypes.string,
  removeError: PropTypes.func,
  showToast: PropTypes.func,
  isDetailsPage: PropTypes.bool,
  travelerAppStore: PropTypes.object
};

export default withTranslation()(withRouter(observer(InvalidTravelerDataBlock)));
