import React, { Fragment, useEffect, useRef } from 'react';
import { IonAccordion, IonAccordionGroup, IonItem, IonLabel } from '@ionic/react';
import { Heading2 } from '../../styled/Typography';
import styled from 'styled-components';
import CaretDownGreyIcon from '../../../assets/icons/caret-down-grey-icon.svg';
import { t } from 'i18next';
import theme from '../../../theme';
import mixpanel from '../../../services/mixpanel';

const FaqItem = ({ question, answer, onClick }) => {
  return (
    <IonAccordion value={question} toggleIcon={CaretDownGreyIcon} onClick={onClick}>
      <StyledQuestion slot="header">
        <IonLabel>{question}</IonLabel>
      </StyledQuestion>

      <StyledAnswer
        className="padding-all-md padding-left-xl"
        slot="content"
        dangerouslySetInnerHTML={{ __html: answer }}
      />
    </IonAccordion>
  );
};

const FAQs = () => {
  const [faqs, setFaqs] = React.useState([]);

  const accordionGroupRef = useRef(null);

  const handleFaqClick = (questionKey) => {
    // Track event only when a new question is opened
    if (accordionGroupRef.current.value) {
      mixpanel.trackFaqRead({ Question: questionKey });
    }
  };

  useEffect(() => {
    setFaqs(t('talon_one.faqs_list', { returnObjects: true }));
  }, []);

  return (
    <Fragment>
      <Heading2 marginTop="30px">{t('talon_one.faqs')}</Heading2>

      <IonAccordionGroup ref={accordionGroupRef}>
        {Object.keys(faqs).map((key) => (
          <FaqItem
            question={faqs[key].question}
            answer={faqs[key].answer}
            key={key}
            questionKey={key}
            onClick={() => handleFaqClick(key)}
          />
        ))}
      </IonAccordionGroup>
    </Fragment>
  );
};

const StyledQuestion = styled(IonItem)`
  border-radius: 8px;
`;

const StyledAnswer = styled.div`
  color: ${theme.colors.base.graphiteLight};
`;

export default FAQs;
