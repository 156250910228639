import React from 'react';
import { observer } from 'mobx-react';
import { useStores } from '@/src/stores/useStores';
import { withTranslation } from 'react-i18next';
import Banner from './Banner';

const HvaSRides = (props) => {
  const {
    destinationsStore: { sRides, currentSRidesCityId, destinations }
  } = useStores();

  const getDestination = () => {
    const destination = destinations.find((dest) => dest.id === currentSRidesCityId);
    return destination;
  };

  const renderHva = () => {
    return <Banner destination={getDestination()} sRides={sRides} />;
  };

  return sRides?.length && currentSRidesCityId ? renderHva() : null;
};

export default withTranslation()(observer(HvaSRides));
