import theme from '@/src/theme';
import styled from 'styled-components';
import { Swiper } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/pagination';
import '@ionic/react/css/ionic-swiper.css';

export const SwiperStyled = styled(Swiper)`
  .swiper-pagination-bullet {
    background-color: ${theme.colors.greys.grey6} !important;
    width: 5px;
    height: 5px;
  }

  .swiper-pagination-bullet-active {
    background-color: ${theme.colors.base.white75} !important;
    width: 5px;
    height: 5px;
  }
`;
