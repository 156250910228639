import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import LoggedInUser from '../LoggedInUser';
import DoubleLayout from '@/src/components/layouts/DoubleLayout';
import PropTypes from 'prop-types';
import { InternalLinkButtonSecondary } from '@/src/components/styled/ButtonsV2';
import useWindowSize from '@/src/hooks/useWindowSize';
import theme from '@/src/theme';
import styled from 'styled-components';
import Notification from '@/src/components/common/Notification';
import EmailOutlined from '@/src/components/svgs/EmailOutlined';
import { useStores } from '@/src/stores/useStores';
import UserCard from '@/src/components/common/DuplicateTravelers/UserCard';
import { IonCol, IonGrid, IonRow } from '@ionic/react';
import UserCardSkeleton from '@/src/components/common/DuplicateTravelers/UserCard/Skeleton';
import { generateArrayOfNumbers } from '@/src/helpers/arrays';
import Accordion from '@/src/components/common/Accordion/Accordion';
import { withTranslation } from 'react-i18next';
import mixpanel from '@/src/services/mixpanel';
import { Text } from '@/src/theme/components/Typography';
import { isTruthy } from '@/src/components/common/helpers';

const MergeAccounts = (props) => {
  const { history, t } = props;

  const {
    duplicateTravelersStore: {
      duplicateTravelersInfo,
      setSelectedAccount,
      selectedAccount,
      getDuplicateTravelersLoading
    },
    travelerAppStore: { profileInformation }
  } = useStores();

  const windowSize = useWindowSize();

  const renderSidebarContent = () => {
    return <LoggedInUser isSide />;
  };

  const renderUserCards = () => {
    return (
      <IonGrid style={{ padding: 0, marginTop: '16px' }}>
        <IonRow style={{ margin: '0 -4px 0', alignItems: 'stretch' }}>
          {getDuplicateTravelersLoading
            ? // Loading state
              generateArrayOfNumbers(2).map((value) => (
                <IonCol key={value} size="12" size-lg="6" style={{ padding: '4px' }}>
                  <UserCardSkeleton key={value} />
                </IonCol>
              ))
            : // Normal state
              duplicateTravelersInfo.duplicateAccounts?.map((account) => (
                <IonCol key={account.id} size="12" size-lg="6" style={{ padding: '4px' }}>
                  <UserCard
                    name={account.name}
                    phone={account.phone}
                    numberOfTrips={account.numberOfTrips}
                    avatarSrc={account.photoUrl}
                    style={{ height: '100%' }}
                    onClick={() => {
                      setSelectedAccount(account);
                    }}
                    selected={selectedAccount?.id === account.id}
                  />
                </IonCol>
              ))}
        </IonRow>
      </IonGrid>
    );
  };

  const renderSubmitButton = (margin) => {
    return (
      <InternalLinkButtonSecondary
        to="/profile/merge_accounts/verify_and_merge"
        size="lg"
        block="true"
        margin={margin}
        onClick={mixpanel.trackMergeAccountsButtonClick}
      >
        {t('duplicate_travelers.complete_merge')}
      </InternalLinkButtonSecondary>
    );
  };

  const renderMainContent = () => {
    return (
      <div>
        {windowSize.width > theme.breakpoints[0] && (
          <Text element="h2" variant="h3" styles={{ marginBottom: '28px' }}>
            {t('duplicate_travelers.merge_accounts')}
          </Text>
        )}

        <Notification
          subtitle={profileInformation.contactEmail}
          icon={<EmailOutlined />}
          padding="8px 20px 8px 12px"
          gap="8px"
          maxLines={1}
          margin="0 0 18px"
          type="mutted"
          maxWidth={windowSize.width > theme.breakpoints[1] ? 'fit-content' : '100%'}
        />

        <NormalText>{t('duplicate_travelers.tap_to_select_your_preferred_name')}</NormalText>
        <SmallText>{t('duplicate_travelers.we_wil_unify_accounts')}</SmallText>

        {renderUserCards()}

        <Accordion
          style={{ marginTop: windowSize.width > theme.breakpoints[0] ? '16px' : '24px' }}
          data={[
            {
              title: t('duplicate_travelers.what_happens_when_i_merge'),
              content: t('duplicate_travelers.by_merging_your_accounts', {
                count: duplicateTravelersInfo.duplicateAccounts?.length
              })
            }
          ]}
        />

        {windowSize.width > theme.breakpoints[0] && renderSubmitButton('24px auto 0')}
      </div>
    );
  };

  const renderFixedContent = () => {
    return <ButtonContainer>{renderSubmitButton('0 auto')}</ButtonContainer>;
  };

  useEffect(() => {
    if (isTruthy(duplicateTravelersInfo.duplicateAccounts)) {
      mixpanel.trackMergeAccountsPageView({ accounts_number: duplicateTravelersInfo.numberOfDuplicateAccounts });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [duplicateTravelersInfo.duplicateAccounts]);

  useEffect(() => {
    if (duplicateTravelersInfo.duplicateAccounts?.length > 0) {
      setSelectedAccount(duplicateTravelersInfo.duplicateAccounts[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [duplicateTravelersInfo]);

  return (
    <DoubleLayout
      onBackClick={() => history.push('/profile')}
      title={t('duplicate_travelers.merge_accounts')}
      subtitle=""
      sidebarContent={renderSidebarContent()}
      mainContent={renderMainContent()}
      mainContentPadding="32px 20px"
      fixedContent={renderFixedContent()}
      customSpace="40px"
    />
  );
};

const NormalText = styled.p`
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  color: ${theme.colors.base.graphite};
  margin-bottom: 4px;

  @media (min-width: ${theme.breakpoints[0]}px) {
    font-size: 16px;
    line-height: 20px;
  }
`;

const SmallText = styled.p`
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;

  @media (min-width: ${theme.breakpoints[0]}px) {
    font-size: 14px;
    line-height: 18px;
  }
`;

const ButtonContainer = styled.div`
  margin: 0px 20px 40px;
`;

MergeAccounts.propTypes = {
  history: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired
};

export default withTranslation()(withRouter(observer(MergeAccounts)));
