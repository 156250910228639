import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import theme from '../../../../theme';
import Google from '../../../../assets/branding/Google_Play.svg';
import Apple from '../../../../assets/branding/App_Store.svg';
import { IonImg, IonItem, IonText } from '@ionic/react';
import { FlexContainer } from '../../../../components/styled/Wrappers';
import helpIcon from '../../../../assets/icons/circle-help.svg';
import { withTranslation } from 'react-i18next';
import { useStores } from '../../../../stores/useStores';
import { isMobileWebPlatform, isTruthy } from '../../../../components/common/helpers';
import mixpanel from '../../../../services/mixpanel';
import { observer } from 'mobx-react';

const AppsBlock = ({ onClick, showText = false, t }) => {
  const [androidUrl, setAndroidUrl] = useState(process.env.REACT_APP_ANDROID_STORE_URL);
  const [iosUrl, setIosUrl] = useState(process.env.REACT_APP_IOS_STORE_URL);

  const {
    travelerAppStore: { isAuthorised, travelerInfo }
  } = useStores();

  useEffect(() => {
    if (isMobileWebPlatform() && travelerInfo && isAuthorised) {
      const appStoreLinksExist = isTruthy(travelerInfo.appStoreLinks);

      if (appStoreLinksExist) {
        const {
          appStoreLinks: { promoAndroidStoreLink, promoAppleStoreLink }
        } = travelerInfo;
        setAndroidUrl(promoAndroidStoreLink);
        setIosUrl(promoAppleStoreLink);
      }
    }
  }, [travelerInfo, isAuthorised]);

  return (
    <BadgeItem isInModal={!showText} lines="none">
      <BadgeAndTextContainer justify="space-between" align="center" isInModal={!showText}>
        {showText && (
          <PromoBlockButton onClick={onClick}>
            {t('promo.button_alt')}
            <HelpIcon alt="Help" src={helpIcon} />
          </PromoBlockButton>
        )}
        <AppsContainer className="icon-container" padding="0px" justify="space-evenly" isInModal={!showText}>
          <Redirect href={iosUrl} target="_blank" onClick={mixpanel.trackAndroidAppDownloadClick} isInModal={!showText}>
            <AppImg src={Apple} />
          </Redirect>
          <Redirect href={androidUrl} target="_blank" onClick={mixpanel.trackIosAppDownloadClick} isInModal={!showText}>
            <AppImg src={Google} />
          </Redirect>
        </AppsContainer>
      </BadgeAndTextContainer>
    </BadgeItem>
  );
};

export default withTranslation()(observer(AppsBlock));

const AppImg = styled(IonImg)`
  width: 136px;
  @media (max-width: 560px) {
    width: 100%;
    max-width: 188px;
    min-width: 136px;
  }
`;

const BadgeAndTextContainer = styled(FlexContainer)`
  width: 100%;
  margin-top: 20px;
  @media (max-width: 560px) {
    flex-direction: column;
    align-items: start;
    margin-top: ${(props) => (props.isInModal ? '0' : '16px')};
  }
`;

const AppsContainer = styled(FlexContainer)`
  width: 100%;
  padding: 0;
  justify-content: ${(props) => (props.isInModal ? 'center' : 'space-evenly')};
  gap: ${(props) => (props.isInModal ? '24px' : '8px')};

  @media (max-width: 560px) {
    margin-top: 14px;
    flex-direction: ${(props) => props.isInModal && 'row'};
    justify-content: flex-start;
    align-items: ${(props) => props.isInModal && 'center'};
  }
`;

const HelpIcon = styled.img`
  margin-left: 8px;
  height: 20px;
  top: 4px;
  position: relative;
  width: 20px;
`;

const PromoBlockButton = styled(IonText)`
  cursor: pointer;
  background-color: #f4f5f6;
  font-size: 16px;
  color: ${theme.colors.greys.grey5};
  padding-left: 0px;
  padding-right: 12px;
  outline: none;
  white-space: normal !important;
  word-break: break-word;
  display: inline-block;
  width: 100%;

  @media (max-width: 560px) {
    height: auto;
    width: auto;
    text-align: left;
    padding-right: 40px;
    margin-bottom: 10px;
  }
`;

const BadgeItem = styled(IonItem)`
  display: block;
  margin: 0 auto;
  width: 100%;
  --background: transparent;

  --padding-start: 0;
  --padding-end: 0;
  max-width: 600px;
  padding-bottom: 20px;

  @media (max-width: 560px) {
    padding-left: ${(props) => (props.isInModal ? '0' : '20px')};
    padding-bottom: 10px;
    padding-top: 20px;
  }
`;

const Redirect = styled.a`
  cursor: pointer;
  width: ${(props) => (props.isInModal ? 'initial' : '50%')};

  @media (max-width: 560px) {
    padding: ${(props) => (props.isInModal ? '0px' : '0px')};

    :last-of-type {
      padding-bottom: 0px;
    }
  }
`;
