import React, { Fragment } from 'react';
import {
  SimpleNavbarStyled,
  LogoStyled,
  MainLinks,
  PoweredByWrapper,
  LeftIonButtons,
  RightIonButtons
} from './SimpleNavbar.styled.js';
import { IonImg } from '@ionic/react';
import helpIconGraphite from '../../../assets/icons/circle-help-graphite.svg';
import mixpanel from '../../../services/mixpanel.js';
import { useStores } from '../../../stores/useStores.js';
import { observer } from 'mobx-react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import LanguageSelector from '../../common/LanguageSelector/index.js';
import { Text2 } from '../../styled/Typography.js';
import PoweredBy from '../../svgs/PoweredBy.js';
import theme from '../../../theme/index.js';
import './styles.scss';

// A simple navbar that contains the logo, the language selector and a help link
const SimpleNavbar = (props) => {
  const {
    background = '#fff',
    className = '',
    hideLogo = false,
    showHelpIcon = false,
    height = '56px',
    links,
    hideLanguageSelector = false,
    hideHelp = false,
    logoExternalLink,
    showPoweredByLogo = false,
    padding = '0 20px',
    t
  } = props;

  const {
    brandedSettingsStore: {
      getCoBrandedHelpCenterUrl,
      brandedSettings: { brandLogoUrl }
    }
  } = useStores();

  const renderHelp = () => {
    return showHelpIcon ? (
      <a onClick={mixpanel.trackSupportView} target="_blank" href={getCoBrandedHelpCenterUrl} rel="noreferrer">
        <IonImg src={helpIconGraphite} style={{ width: '20px' }} />
      </a>
    ) : (
      <Text2
        onClick={() => {
          mixpanel.trackSupportView();
        }}
      >
        <a href={getCoBrandedHelpCenterUrl} target="_blank" rel="noopener noreferrer">
          {t('my_trip.help')}
        </a>
      </Text2>
    );
  };

  const renderLogo = () => {
    if (logoExternalLink) {
      return (
        <a href={logoExternalLink} target="_blank" rel="noreferrer">
          <LogoStyled src={brandLogoUrl} alt="logo" height={height} />
        </a>
      );
    }

    return <LogoStyled src={brandLogoUrl} alt="logo" height={height} />;
  };

  const renderPoweredByLogo = () => {
    return (
      <PoweredByWrapper>
        <PoweredBy fill={theme.colors.base.graphite} width="100%" />
      </PoweredByWrapper>
    );
  };

  return (
    <Fragment>
      <SimpleNavbarStyled background={background} className={className} height={height} padding={padding}>
        <LeftIonButtons>{!hideLogo && renderLogo()}</LeftIonButtons>

        <MainLinks height={height}>{links}</MainLinks>

        <RightIonButtons>
          {!hideLanguageSelector && <LanguageSelector />}
          {!hideHelp && renderHelp()}
          {showPoweredByLogo && renderPoweredByLogo()}
        </RightIonButtons>
      </SimpleNavbarStyled>
    </Fragment>
  );
};

SimpleNavbar.propTypes = {
  background: PropTypes.string,
  className: PropTypes.string,
  hideLogo: PropTypes.bool, // If true, the logo will not be shown
  hideLanguageSelector: PropTypes.bool, // If true, the language selector will not be shown
  hideHelp: PropTypes.bool, // If true, the help link will not be shown
  showHelpIcon: PropTypes.bool, // If true, the help icon will be shown, otherwise the help text will be shown
  height: PropTypes.string,
  links: PropTypes.node, // The links to be shown in the center of the navbar
  logoExternalLink: PropTypes.string,
  showPoweredByLogo: PropTypes.bool,
  padding: PropTypes.string,
  t: PropTypes.func
};

export default withTranslation()(withRouter(observer(SimpleNavbar)));
