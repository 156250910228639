import React from 'react';
import { Paragraph } from '../../styled/Typography';
import InputErrorMessage from '../InputErrorMessage';
import PropTypes from 'prop-types';
import { FormFieldStyled } from './index.styled';

const FormField = (props) => {
  const { children, label, errorText, margin = '0 0 20px' } = props;

  return (
    <FormFieldStyled margin={margin}>
      {label && (
        <Paragraph margin="20px 0 10px" weight="700" align="left">
          {label}
        </Paragraph>
      )}

      {children}

      {errorText && <InputErrorMessage>{errorText}</InputErrorMessage>}
    </FormFieldStyled>
  );
};

FormField.propTypes = {
  children: PropTypes.node.isRequired,
  label: PropTypes.string,
  errorText: PropTypes.string,
  margin: PropTypes.string
};

export default FormField;
