import styled from 'styled-components';
import { IonText, IonToolbar } from '@ionic/react';
import theme from '@/src/theme';

export const StyledIonToolbar = styled(IonToolbar)`
  --background: url('${(props) => props.image}') no-repeat center/cover !important;
  --min-height: 240px;
  max-width: 600px;
  margin: 0 auto;
  border-radius: 4px;
  margin-bottom: -20px;
  z-index: 0;

  @media (max-width: 768px) {
    margin-top: 0;
    border-radius: 0;
  }
`;

export const MyTripHeader = styled(IonText)`
  width: 160px;
  h2 {
    width: 100%;
    min-width: 300px;
  }
`;

export const LogoWrapper = styled.div`
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;

  .trip-logo {
    display: none;
    max-width: ${(props) => props.maxWidth};

    @media (max-width: ${theme.breakpoints[0]}px) {
      display: block;
    }
  }
`;
