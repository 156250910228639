import theme from '@/src/theme';
import styled from 'styled-components';

export const NoMessagesContainer = styled.div`
  color: ${theme.colors.greys.grey5};
  font-size: 24px;
  line-height: 31px;
  text-align: center;
  font-weight: 700;
  margin-bottom: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 34px;
  padding-bottom: 34px;

  @media (max-width: ${theme.breakpoints[0]}px) {
    margin-top: 24px;
    margin-bottom: 24px;
  }
`;

export const NoMessagesTitle = styled.div`
  width: 360px;
  color: ${theme.colors.greys.grey5};
  font-size: 24px;
  line-height: 31px;
  text-align: center;
  word-break: break-word;
  margin-top: 20px;

  @media (max-width: ${theme.breakpoints[0]}px) {
    margin-top: 24px;
    width: 296px;
  }
`;

export const NoMessagesSubtitle = styled.div`
  margin-top: 16px;
  min-height: 63px;
  width: 360px;
  color: ${theme.colors.greys.grey5};
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  word-break: break-word;

  @media (max-width: ${theme.breakpoints[0]}px) {
    width: 296px;
  }
`;
