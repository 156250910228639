/**
 * Prevents a function to be called multiple times in the given time period.
 *
 * @param {Function} func The function you want to run.
 * @param {number} delay The amount of time before the function is allowed to run again.
 * @param {boolean} [immediate=false] - If `true`, trigger the function on the leading edge, instead of the trailing.
 * @returns {Object} An object with two methods:
 *   - `init`: Invokes the debounced function.
 *   - `stop`: Cancels any scheduled execution of the debounced function.
 * @example
 *   const debounced = debounce(() => console.log('foo'), 1000);
 *
 *   initBtn.addEventListener('click', debounced.init); // To run a function using debounce.
 *   stopBtn.addEventListener('click', debounced.stop); // To stop the debounce before it ends.
 */
export default function debounce(func, delay, immediate = false) {
  let inDebounce = null;

  return {
    init() {
      const context = this;
      const args = arguments; // eslint-disable-line prefer-rest-params
      const callNow = immediate && !inDebounce;

      clearTimeout(inDebounce);

      inDebounce = setTimeout(() => {
        inDebounce = null;
        if (!immediate) {
          func.apply(context, args);
        }
      }, delay);

      if (callNow) {
        clearTimeout(inDebounce);
        inDebounce = null;
      }
    },
    stop() {
      clearTimeout(inDebounce);
    }
  };
}
