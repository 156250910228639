import { IonItem } from '@ionic/react';
import './styles.scss';
import styled from 'styled-components';
import theme from '@/src/theme';
import { FlexContainer } from '@/src/components/styled/Wrappers';

// TODO: Use the new buttons
export const ActionButton = styled(IonItem)`
  background: ${(props) => (props.kind === 'primary' ? theme.colors.cta.primary : theme.colors.base.white)};
  cursor: pointer;
  font-weight: bold;
  opacity: 1;
  padding: 0px 8px;
  text-align: center;
  box-sizing: border-box;
  flex: 0 0 50%;
  justify-content: center !important;
  max-width: calc(50% - 6px);
  --color-hover: #fff;
  --background: none;
  --inner-border-width: 0;
  --opacity-hover: 0.8;
  --padding-top: 0;

  border-radius: 4px;
  display: flex;
  border: 1px solid ${(props) => (props.kind === 'primary' ? theme.colors.cta.primary : theme.colors.base.graphite)};
  flex-flow: row wrap;

  @media (max-width: 767px) {
    max-width: 100%;
    flex: 0 0 100%;

    padding: 0px 4px;
  }
`;

export const ActionButtonContainer = styled(FlexContainer)`
  flex-flow: row wrap;
  margin-top: 18px;
  justify-content: space-between;
  width: 100%;
  justify-content: center;
  gap: 12px;
`;
export const IconWraper = styled.div`
  position: relative;
  left: 0;
  bottom: 0;
  top: 0;
  right: ${(props) => (props.status === true ? '15px' : 'auto')};
  margin: auto;
  max-width: 20px;
  height: auto;
  flex: 0 0 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const ItalicText = styled.em`
  display: block;
  margin-top: 10px;
  font-style: normal;
  font-size: 12px;
  color: ${theme.colors.accent.base};
`;
export const ActionText = styled.span`
  text-align: center;
  color: ${(props) => (props.kind === 'primary' ? theme.colors.base.white : theme.colors.base.graphite)};
  width: 100%;
  font-size: 14px;
  line-height: 18px;
  display: ${(props) => (props.hide ? 'none' : 'block')};
  @media (max-width: 767px) {
    opacity: 1;
    font-size: 16px;
    line-height: 18px;
  }
`;
