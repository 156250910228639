import React from 'react';
import styled from 'styled-components';
import { Text3 } from '../../styled/Typography';
import theme from '../../../theme';
import { useStores } from '../../../stores/useStores';
import Transaction from './Transaction';
import { observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import SkeletonListItem from '../ScarySkeletons/SkeletonListItem';
import { t } from 'i18next';

const Transactions = (props) => {
  const { noTitle, loading, limit } = props;
  const {
    talonOneStore: { transactions }
  } = useStores();
  const transactionsList = limit ? transactions.data?.slice(0, limit) || [] : transactions.data || [];

  return (
    <TransactionsContainer>
      {!noTitle && <Text3 className="transaction-history-title">{t('profile.transactions')}</Text3>}

      {transactionsList.map((transaction) =>
        loading ? (
          <SkeletonListItem key={transaction.id} />
        ) : (
          <Transaction transaction={transaction} key={transaction.id} />
        )
      )}
    </TransactionsContainer>
  );
};

const TransactionsContainer = styled.div`
  .transaction-history-title {
    color: ${theme.colors.base.graphiteLighter};
    text-transform: uppercase;
    margin-bottom: 20px;
  }
`;

export default withRouter(observer(Transactions));
