import axios from 'axios';
import { deserialize, serialize } from './ApiSerializer';
import { getPlatformForTravelerAppEvent } from '../components/common/helpers';

const apiUrl = process.env.REACT_APP_API_URL;

const convertToFormData = (serializedObject) => {
  const formData = new FormData();
  Object.entries(serializedObject).forEach(([key, val]) => {
    formData.append(key, val);
  });
  return formData;
};

const appVersion = process.env.REACT_APP_VERSION;
const platform = getPlatformForTravelerAppEvent();

const baseAPI = axios.create({
  baseURL: apiUrl,
  withCredentials: true,
  headers: {
    'X-Traveler-App-Version': appVersion,
    'X-Traveler-App-Platform': platform
  }
});

baseAPI.interceptors.response.use(
  // An error is triggered when data is null so we need to handle that case
  ({ data }) => {
    if (data) {
      return deserialize(data);
    }
    return {};
  }
  // manageErrors
);

class TravelerApi {
  getPersonalInfo = () => baseAPI.get('/v1/traveler/personal_info');

  updatePersonalInfo = (updatedInfo) => {
    return baseAPI.patch('/v1/traveler/personal_info', convertToFormData(serialize(updatedInfo).data.attributes), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Accept: 'application/vnd.api+json'
      }
    });
  };

  updateProfilePicture = (photo) => {
    return baseAPI.patch('/v1/traveler/personal_info/photo', convertToFormData({ photo }), {
      headers: {
        'Content-Type': 'multipart/form-data',
        Accept: 'application/vnd.api+json'
      }
    });
  };

  setAuthenticationHeaders = (token) => {
    baseAPI.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  };

  resetAuthenticationHeaders = () => {
    baseAPI.defaults.headers.common['Authorization'] = null;
  };

  getTripPreviews = () => baseAPI.get('/v1/traveler/trip_previews');

  getTrips = () => baseAPI.get('/v1/traveler/trips/');

  getTripById = (tripId) => baseAPI.get(`/v1/traveler/trips/${tripId}`);

  getTransferById = (transferId) => baseAPI.get(`/v1/traveler/transfers/${transferId}`);

  getTourById = (tourId) => baseAPI.get(`/v1/traveler/tours/${tourId}`);

  getDestinations = () => baseAPI.get('/v1/destinations');

  /**
   * @param {string} destinationId
   * @param {object} options
   * @param {number} options.services_limit
   * @param {boolean} options.include_widgets
   * @param {boolean} options.include_services
   */
  getExploreSuggestions = (destinationId, options = {}) => {
    const params = {};

    if (options.services_limit !== undefined) {
      params.services_limit = options.services_limit;
    }

    if (options.include_widgets !== undefined) {
      params.include_widgets = options.include_widgets;
    }

    if (options.include_services !== undefined) {
      params.include_services = options.include_services;
    }

    // TODO: get locale
    return baseAPI.get(`/v1/destinations/${destinationId}/explore?locale=en`, { params });
  };

  loginWithToken = (token, refreshToken) => {
    const formData = new FormData();
    formData.append('token', token);

    if (refreshToken) {
      formData.append('refresh_token', refreshToken);
    }

    return baseAPI.post('/v1/traveler/auth/traveler', formData, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
  };

  getDuplicateAccountsInfo = () => baseAPI.get('/v1/traveler/duplicate_accounts/info');

  sendDuplicateAccountsConfirmationCode = (method) => {
    const formData = new FormData();
    formData.append('confirmation_method', method);

    return baseAPI.post('/v1/traveler/duplicate_accounts/code', formData);
  };

  mergeAccounts = (travelerId, confirmationMethod, code) => {
    const formData = new FormData();
    formData.append('selected_account_id', travelerId);

    if (confirmationMethod) {
      formData.append('confirmation_method', confirmationMethod);
    }

    if (code) {
      formData.append('confirmation_code', code);
    }

    return baseAPI.post('/v1/traveler/duplicate_accounts/merge', formData);
  };

  deleteAccount = () => baseAPI.post('/v1/traveler/erase_data_request');

  downloadAccountData = () => baseAPI.post('/v1/traveler/download_data_request');

  codeRequest = (emailOrPhone) =>
    baseAPI.post('/v1/traveler/auth/code', emailOrPhone, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });

  login = (loginData) => baseAPI.post('/v1/traveler/auth/login', loginData);

  changeLocale = (locale) => {
    const formData = new FormData();
    formData.append('locale', locale);
    return baseAPI.patch('/v1/traveler/locales', formData, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
  };

  resolveTravelerAutochekError = (errorId) => {
    return baseAPI.patch(`/v1/traveler/autocheck_errors/${errorId}/resolve`);
  };

  resolveAutochekError = (errorId, transferId) => {
    const formData = new FormData();
    formData.append('autocheck_error_id', errorId);
    return baseAPI.patch(`/v1/traveler/transfers/${transferId}/resolve_autocheck_error`, formData, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
  };

  logout = () => baseAPI.post('/v1/traveler/auth/logout');

  getBrandedSettings = () => baseAPI.get('/v1/branded_settings');

  registerDevice = (uid, fcm_token, device_type) => {
    const formData = new FormData();
    if (uid) {
      formData.append('uid', uid);
    }
    if (fcm_token) {
      formData.append('fcm_token', fcm_token);
    }
    formData.append('device_type', device_type);
    return baseAPI.post('/v1/traveler/devices', formData, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
  };

  createChatUser = () => {
    return baseAPI.post('/v1/traveler/chats/users/new');
  };

  createChat = (transfer) => {
    const formData = new FormData();

    const resourceType = typeof transfer.transferType === 'undefined' ? 'TourTransfer' : 'Transfer';

    formData.append('resource_id', transfer.id);
    formData.append('resource_type', resourceType);

    return baseAPI.post('/v1/traveler/chats/new', formData, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
  };

  getChatUser = () => baseAPI.get('/v1/traveler/chats/users');

  trackEvent = (eventData) => {
    const formData = convertToFormData(serialize(eventData).data.attributes);
    return baseAPI.post('/v1/traveler/traveler_app_events', formData, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
  };

  /**
   * Creates a new invitation to a trip or transfer
   * @param {string} invitedEmail
   * @param {string} invitableId The id of the transfer or the trip you want to create the invitation
   * @param {('Trip'|'Transfer')} invitableType
   */
  createInvitation = (invitedEmail, invitableId, invitableType) => {
    const formData = new FormData();

    formData.append('invitation[email]', invitedEmail);
    formData.append('invitable_id', invitableId);
    formData.append('invitable_type', invitableType);

    return baseAPI.post('/v1/traveler/invitations', formData, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
  };

  /**
   * @param {string} tripId
   * @param {boolean} answer
   */
  saveAirbnbQuestionResponse = (tripId, answer = null) => {
    const formData = new FormData();

    formData.append('answer', answer !== null ? answer : '');

    return baseAPI.post(`/v1/traveler/trips/${tripId}/airbnb_question_response`, formData, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
  };

  /**
   * @param {string} tripId
   * @param {boolean} invitationCoppied
   */
  updateAirbnbHostInvitation = (tripId, invitationCoppied) => {
    const formData = new FormData();

    formData.append('invitation_copied', invitationCoppied);

    return baseAPI.patch(`/v1/traveler/airbnb_host_invitations/${tripId}`, formData, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
  };

  /**
   * Share a receipt with a list of emails
   * @param {string} emails Emails list separated by commas ','
   * @param {string} relation
   * @param {string} transferIds Transfer IDs list separated by commas ','
   * @param {string} tourTransferIds Tour transfer IDs list separated by commas ','
   */
  shareReceipt = (emails, relation, transferIds = '', tourTransferIds = '') => {
    const formData = new FormData();

    formData.append('emails', emails);
    formData.append('relation', relation);
    formData.append('transfer_ids', transferIds);
    formData.append('tour_transfer_ids', tourTransferIds);

    return baseAPI.post('/v1/traveler/shared_traveler_receipts', formData, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });
  };
}

export default new TravelerApi();

export const getSRides = () => {
  // return sRides;
};
