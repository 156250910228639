import React from 'react';
import styled from 'styled-components';
import theme from '../../theme';
import { Text3 } from '../../components/styled/Typography';
import TransferIcon from '../../assets/icons/transfer-icon.svg';
import { t } from 'i18next';

const MoreTransfersBanner = ({ totalTransfers }) => {
  return totalTransfers > 0 ? (
    <MoreTransfersBannerStyled>
      <img src={TransferIcon} alt="More transfers" />
      <Text3 color={theme.colors.base.graphiteLight} weight="600">
        {t('trips.more_transfers', { moreTransfersCount: totalTransfers - 1 || 0 })}
      </Text3>
    </MoreTransfersBannerStyled>
  ) : null;
};

const MoreTransfersBannerStyled = styled.div`
  background: ${theme.colors.greys.grey06};
  padding: 10px 16px;
  text-align: center;
  border-radius: 50px;
  margin: 16px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
`;

export default MoreTransfersBanner;
