import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import Header from '../Modals/Header';
import theme from '../../../theme';
import { Heading2, Text2 } from '../../styled/Typography';
import { StyledModal } from '../../styled/IonElements';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';
import PiggybankIcon from '../../../assets/icons/piggybank-icon.svg';
import { ButtonPrimary, ButtonText } from '../../styled/ButtonsV2';

function ReferralInfoModal(props) {
  const { isOpen, onClose, t } = props;
  const modalRef = useRef(null);

  const handleClose = (redirectToUrl) => {
    modalRef.current?.dismiss();
    onClose(redirectToUrl);
  };

  return (
    <CustomStyledModal ref={modalRef} isOpen={isOpen} onClose={handleClose} onDidDismiss={handleClose}>
      <div className="padding-all-lg">
        <Header title="" onClose={handleClose} />

        <figure style={{ textAlign: 'center' }}>
          <img src={PiggybankIcon} alt="piggybank icon" />
        </figure>

        <Heading2 align="center">{t('talon_one.your_reward_is_ready')}</Heading2>

        <Text2 className="margin-bottom-lg" align="center" color={theme.colors.base.graphiteLight}>
          {t('talon_one.book_your_first_transfer')}
        </Text2>

        <ButtonPrimary block onClick={handleClose}>
          {t('book.book_a_transfer')}
        </ButtonPrimary>

        <ButtonText
          block
          color={theme.colors.base.graphiteLighter}
          onClick={() => handleClose('/profile/rewards_and_wallet')}
        >
          {t('talon_one.open_my_wallet')}
        </ButtonText>
      </div>
    </CustomStyledModal>
  );
}

const CustomStyledModal = styled(StyledModal)`
  --height: auto;
  --max-height: 90%;
  --overflow: auto;
  --width: 420px;

  @media (max-width: 768px) {
    align-items: end;
    --border-radius: 4px 4px 0 0;
    --width: 100%;
  }
`;

ReferralInfoModal.propTypes = {
  isOpen: PropTypes.bool.isRequired
};

export default withTranslation()(ReferralInfoModal);
