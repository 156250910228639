import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import ContinueButton from './ContinueButton';
import { trackEvent } from '../../tracking';
import PhoneInput from '@/src/components/formFields/PhoneInput';
import 'react-phone-input-2/lib/style.css';
import { withTranslation } from 'react-i18next';
import mixpanel from '../../services/mixpanel';
import FormField from '@/src/components/formFields/FormField';
import PropTypes from 'prop-types';
import { urlWithReturnUrl } from '@/src/components/common/helpers';

const buttonStyle = {
  backgroundColor: 'transparent',
  border: 'none',
  padding: '2px 9px'
};

class PhoneInputContainer extends Component {
  state = {
    phone: ''
  };

  onPhoneInputChange = (phone) => {
    const {
      travelerAppStore: { setCodeRequestError }
    } = this.props;

    this.setState({
      phone
    });
    setCodeRequestError({});
  };

  onContinuePress = () => {
    const formData = new FormData();
    const {
      travelerAppStore: { codeRequest },
      history: { push }
    } = this.props;
    const { phone } = this.state;

    formData.append('phone', `+${phone}`);
    formData.append('message_method', 'sms');
    formData.append('new_login', true);
    trackEvent('traveler app', 'login', 'continue');
    codeRequest(formData)
      .then(() => {
        mixpanel.trackLoginContinue({
          Login_Method: 'phone',
          $email: `+${phone}`,
          Status: 'success'
        });

        push(urlWithReturnUrl('/login/verification_code_phone_only'), {
          phone: `+${phone}`,
          hasValidPhone: true,
          phoneNumber: `+${phone}`
        });
      })
      .catch(() => {
        mixpanel.trackLoginContinue({
          Login_Method: 'phone',
          $email: `+${phone}`,
          Status: 'fail'
        });
      });
  };

  onKeyDown = (e) => {
    if (e.keyCode === 13) {
      this.onContinuePress();
    }
  };

  render() {
    const { phone } = this.state;
    const {
      travelerAppStore: { codeRequestError },
      t
    } = this.props;
    let error = codeRequestError.error ? codeRequestError.error : null;
    error = error === 'No bookings found with this email' ? null : error;
    const errorMessage = error === 'No bookings found with this phone number' ? t('sign_in.no_bookings_phone') : error;

    return (
      <div style={{ position: 'relative', width: '100%' }}>
        <FormField errorText={errorMessage}>
          <PhoneInput
            placeholder="phone"
            onChange={this.onPhoneInputChange}
            value={phone}
            onKeyDown={this.onKeyDown}
            error={error}
            type="phone"
            buttonStyle={buttonStyle}
            inputStyle={{
              height: '50px'
            }}
          />
        </FormField>
        <ContinueButton onContinuePress={this.onContinuePress} error={false} disabled={phone.length === 0} />
      </div>
    );
  }
}

PhoneInputContainer.propTypes = {
  t: PropTypes.func,
  travelerAppStore: PropTypes.object,
  history: PropTypes.object
};

export default withTranslation()(withRouter(inject('travelerAppStore')(observer(PhoneInputContainer))));
