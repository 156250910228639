import React, { Fragment } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { observer } from 'mobx-react';
import { useStores } from './stores/useStores';
import Book from './pages/Book';
import Explore from './pages/Explore';
import Messages from './pages/Messages';
import Profile from './pages/Profile';
import AccountAndData from './pages/Profile/AccountAndData';
import Edit from './pages/Profile/LoggedInUser/Edit';
import TripHistory from './pages/Profile/TripHistory';
import Trip from './pages/Trips/Trip';
import Trips from './pages/Trips';
import TransferDetails from './pages/Trips/Trip/TransferDetails';
import TermsAndConditions from './pages/Profile/TermsAndConditions';
import Wallet from './pages/Profile/Wallet';
import ReferAFriend from './pages/Profile/ReferAFriend';
import TransactionHistory from './pages/Profile/Wallet/TransactionHistory';
import InvitationHistory from './pages/Profile/ReferAFriend/InvitationHistory';
import MergeAccounts from './pages/Profile/MergeAccounts';
import VerifyAndMerge from './pages/Profile/MergeAccounts/VerifyAndMerge';
import { isFalsy } from './components/common/helpers';
import SuccessfullMerge from './pages/Profile/MergeAccounts/SuccessfullMerge';

const Routes = observer(() => {
  const {
    travelerAppStore: { isAuthorised, isAllowedToMergeAccounts, loginWithTokenInProgress },
    duplicateTravelersStore: { selectedAccount, mergeAccountsLoading }
  } = useStores();

  /**
   * Redirects the user to another path when they are not logged in.
   * @param {string} redirectToPath The path you want to send the user that is not logged in
   * @param {ReactElement} Page The page that will load if the user is logged in
   */
  const redirectIfNotLoggedIn = (redirectToPath, Page) => (!isAuthorised ? <Redirect to={redirectToPath} /> : Page);

  return (
    <Fragment>
      <Route
        exact
        path="/"
        render={() => {
          return isAuthorised ? <Redirect to="/trips" /> : <Redirect to="/book" />;
        }}
      />
      <Route exact path="/book" component={Book} />

      {/* Trips Routes */}
      <Route exact path="/trips" component={Trips} />

      <Route exact path="/trip/:tripToken/transfer/:transferToken" component={TransferDetails} />
      <Route exact path="/trip/:tripToken" component={Trip} />
      <Route exact path="/trip" render={() => <Redirect to="/trips" />} />

      {/* Explore Routes */}
      <Route exact path="/explore/:destinationToken" component={Explore} />
      <Route exact path="/explore" component={Explore} />

      {/* Messages Routes */}
      <Route exact path="/messages" component={Messages} />
      <Route exact path="/messages/:channelToken" component={Messages} />

      {/* Profile Routes */}
      <Route
        exact
        path="/profile/merge_accounts"
        render={() => {
          if (!isAllowedToMergeAccounts) {
            return <Redirect to="/profile" />;
          }
          return <MergeAccounts />;
        }}
      />
      <Route
        exact
        path="/profile/merge_accounts/verify_and_merge"
        render={() => {
          // We need to know if a login is in progress to avoid flickering or double requests due to logout/login after accounts merge
          const isLoading = mergeAccountsLoading || loginWithTokenInProgress;

          if (isLoading) {
            return <VerifyAndMerge />;
          } else if (!isAllowedToMergeAccounts) {
            return <Redirect to="/profile" />;
          } else if (isFalsy(selectedAccount)) {
            return <Redirect to="/profile/merge_accounts" />;
          }

          return <VerifyAndMerge />;
        }}
      />

      <Route
        exact
        path="/profile/merge_accounts/success"
        render={() => redirectIfNotLoggedIn('/profile', <SuccessfullMerge />)}
      />

      <Route exact path="/profile/rewards_and_wallet" render={() => redirectIfNotLoggedIn('/profile', <Wallet />)} />

      <Route
        exact
        path="/profile/rewards_and_wallet/transaction_history"
        render={() => redirectIfNotLoggedIn('/profile', <TransactionHistory />)}
      />
      <Route exact path="/profile/refer_a_friend" render={() => redirectIfNotLoggedIn('/profile', <ReferAFriend />)} />
      <Route
        exact
        path="/profile/refer_a_friend/invitation_history"
        render={() => redirectIfNotLoggedIn('/profile', <InvitationHistory />)}
      />
      <Route exact path="/profile/trip_history" render={() => redirectIfNotLoggedIn('/profile', <TripHistory />)} />
      <Route
        exact
        path="/profile/account_and_data"
        render={() => redirectIfNotLoggedIn('/profile', <AccountAndData />)}
      />
      <Route exact path="/profile/edit" render={() => redirectIfNotLoggedIn('/profile', <Edit />)} />
      <Route exact path="/profile/terms_and_conditions" component={TermsAndConditions} />
      <Route exact path="/profile" component={Profile} />

      <Route exact path="/" component={Book} />
    </Fragment>
  );
});

export default Routes;
