import React, { Fragment, useEffect } from 'react';
import ImageCard from '../../components/common/Cards/ImageCard';
import PropTypes from 'prop-types';
import TripPreviewCardHeaderContent from './TripPreviewCardHeaderContent';
import { ButtonSecondary, ButtonText } from '../../components/styled/ButtonsV2';
import theme from '../../theme';
import FromToLocation from '../../components/common/FromToLocation';
import MoreTransfersBanner from './MoreTransfersBanner';
import Card from '../../components/common/Cards/Card';
import { IonCardSubtitle, IonCardTitle } from '@ionic/react';
import LocationInfo from '../../components/common/FromToLocation/LocationInfo';
import { onExternalLinkClick } from '../../components/common/ExternalLink/ExternalLink';
import MapV2 from '../../components/common/MapV2';
import useWindowSize from '../../hooks/useWindowSize';
import { t } from 'i18next';
import { withRouter } from 'react-router-dom';
import ReviewTransferHva from './ReviewTransferHva';
import { isTruthy } from '@/src/components/common/helpers';

const TripPreviewCard = (props) => {
  const { tripPreview, history, index, reviewTransferHva } = props;
  const {
    mainTripItem,
    sharingToken,
    tripItemsCount,
    imageUrl,
    mobileImageUrl,
    desktopImageUrl,
    timeZone,
    returnBannerInfo
  } = tripPreview;
  const { fromTitle, fromAddress, fromKind, fromDatetime, toTitle, toAddress, toKind, type } = mainTripItem;

  const tripUrl = `trip/${sharingToken}`;

  const [mapWidth, setMapWidth] = React.useState();

  const windowSize = useWindowSize();

  // Show a map preview of the destination
  const renderOneDestinationReturnTransfer = () => {
    return (
      <Card>
        <IonCardTitle style={{ marginBottom: '16px' }}>{t('trips.book_a_return_welcome_transfer')}</IonCardTitle>

        {mapWidth && (
          <MapV2
            width={mapWidth}
            height={120}
            fromLatitude={returnBannerInfo.fromLocation.lat}
            fromLongitude={returnBannerInfo.fromLocation.lng}
            toLatitude={returnBannerInfo.destinations[0].lat}
            toLongitude={returnBannerInfo.destinations[0].lng}
            marginBottom="20px"
          />
        )}

        <FromToLocation
          fromTitle={returnBannerInfo.fromLocation.title}
          fromAddress={returnBannerInfo.fromLocation.address}
          fromKind={returnBannerInfo.fromLocation.kind}
          toTitle={returnBannerInfo.destinations[0].title}
          toAddress={returnBannerInfo.destinations[0].address}
          toKind={returnBannerInfo.destinations[0].kind}
          type={type}
          timeZone={timeZone}
          marginBottom="20px"
        />

        <a
          href={returnBannerInfo.destinations[0].bookingUrl}
          onClick={(e) => onExternalLinkClick(e, returnBannerInfo.destinations[0].bookingUrl)}
          style={{ marginBottom: '8px', display: 'block' }}
        >
          <ButtonSecondary block>{t('trips.book_a_return_transfer')}</ButtonSecondary>
        </a>

        <a
          href={returnBannerInfo.destinations.bookDifferentRideUrl}
          onClick={(e) => onExternalLinkClick(e, returnBannerInfo.destinations.bookDifferentRideUrl)}
        >
          <ButtonText block color={theme.colors.base.graphiteLighter}>
            {t('trips.book_different_ride')}
          </ButtonText>
        </a>
      </Card>
    );
  };

  const renderMultipleOrNoDestinationsReturnTransfer = () => {
    return (
      <Card>
        <IonCardTitle>{t('trips.book_a_return_welcome_transfer')}</IonCardTitle>
        <IonCardSubtitle style={{ marginBottom: '16px' }}>
          {t('trips.from', { location: returnBannerInfo.fromLocation.title })}
        </IonCardSubtitle>

        {returnBannerInfo.destinations?.map((destination) => (
          <LocationInfo
            key={destination.title}
            title={destination.title}
            address={destination.address}
            kind={destination.kind}
            timeZone={timeZone}
            href={destination.bookingUrl}
          />
        ))}

        <a
          href={returnBannerInfo.bookDifferentRideUrl}
          onClick={(e) => onExternalLinkClick(e, returnBannerInfo.bookDifferentRideUrl)}
        >
          <ButtonSecondary block>
            {returnBannerInfo.destinations?.length > 0 ? t('trips.book_different_ride') : t('trips.book')}
          </ButtonSecondary>
        </a>
      </Card>
    );
  };

  const renderReviewTransfer = () => {
    return (
      <ReviewTransferHva
        title={reviewTransferHva?.title}
        driverAvatar={reviewTransferHva?.driverPhotoUrl}
        notMyDriverUrl={reviewTransferHva?.notMyDriverUrl}
        notMyDriverText={reviewTransferHva?.notMyDriverText}
        reviewUrls={reviewTransferHva?.reviewUrls}
      />
    );
  };

  const renderReturnBannerInfo = () => {
    return returnBannerInfo.destinations?.length === 1
      ? renderOneDestinationReturnTransfer()
      : renderMultipleOrNoDestinationsReturnTransfer();
  };

  const getImageUrl = () => {
    if (windowSize.width <= theme.mobileScreenWidths.lg) {
      return mobileImageUrl || imageUrl;
    }

    return desktopImageUrl || imageUrl;
  };

  useEffect(() => {
    if (windowSize.width < 600) {
      mapWidth !== 450 && setMapWidth(450);
    } else {
      mapWidth !== 600 && setMapWidth(600);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [windowSize.width]);

  return (
    <Fragment>
      <ImageCard
        imageUrl={getImageUrl()}
        headerContent={<TripPreviewCardHeaderContent tripPreview={tripPreview} />}
        dimmed
        onClick={() => history.push(tripUrl)}
        style={{
          cursor: 'pointer'
        }}
      >
        {tripPreview && (
          <FromToLocation
            fromTitle={fromTitle}
            fromAddress={fromAddress}
            fromKind={fromKind}
            fromDateTime={fromDatetime}
            showDate={tripItemsCount > 1}
            toTitle={toTitle}
            toAddress={toAddress}
            toKind={toKind}
            type={type}
            timeZone={timeZone}
          />
        )}

        {tripItemsCount > 1 && <MoreTransfersBanner totalTransfers={tripItemsCount} />}

        <ButtonText color={theme.colors.cta.greenMedium} center>
          {t('trips.show_trip_details')}
        </ButtonText>
      </ImageCard>

      {/* We want to show it after the first visible trip preview */}
      {isTruthy(reviewTransferHva) && index === 0 && renderReviewTransfer()}

      {returnBannerInfo && renderReturnBannerInfo()}
    </Fragment>
  );
};

TripPreviewCard.propTypes = {
  imageUrl: PropTypes.string,
  tripPreview: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  index: PropTypes.number,
  reviewTransferHva: PropTypes.object
};

export default withRouter(TripPreviewCard);
