import React from 'react';
import { IonSkeletonText } from '@ionic/react';

import './SkeletonTextExample.css';
import { FlexContainer } from '../../styled/Wrappers';

const SkeletonListItem = () => (
  <FlexContainer align="center" gap="12px" style={{ marginBottom: '20px' }}>
    <div style={{ width: '40px' }}>
      <IonSkeletonText animated style={{ display: 'block', width: '40px', height: '40px', borderRadius: '100px' }} />
    </div>
    <div style={{ width: '100%' }}>
      <IonSkeletonText animated style={{ width: '80px', height: '10px', marginBottom: '8px' }} />
      <IonSkeletonText animated style={{ width: '120px', height: '8px' }} />
    </div>
    <div style={{ width: '40px' }}>
      <IonSkeletonText animated style={{ height: '8px' }} />
    </div>
  </FlexContainer>
);

export default SkeletonListItem;
