import theme from '@/src/theme';
import styled from 'styled-components';

export const IconContainer = styled.div`
  text-align: center;

  img {
    margin-top: -38px;
    width: 70px;
    margin-bottom: 16px;

    @media (min-width: ${theme.breakpoints[0]}px) {
      width: 100px;
      margin-bottom: 24px;
    }
  }
`;
