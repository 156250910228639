import React from 'react';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import theme from '../../../../../theme';
import Header from '../../../../../components/common/Modals/Header';
import { IonImg } from '@ionic/react';
import { withTranslation } from 'react-i18next';
import { useStores } from '../../../../../stores/useStores';

const DriverDetailsModal = ({ onChangeModalVisibility, t }) => {
  const {
    travelerAppStore: { chosenTransfer }
  } = useStores();
  const driver = chosenTransfer.operatorConfirmedDriver || chosenTransfer.confirmedDriver;
  const {
    fullName,
    vehicleColor,
    vehicleBrand,
    vehicleModel,
    vehiclePlateNumber,
    phvLicenseNumber,
    professionalPhotoUrl,
    phoneNumber
  } = driver;

  return (
    <StyledContainer driverAvatar={professionalPhotoUrl}>
      <Header
        title={t('driver_dialog.my_driver')}
        onClose={() => onChangeModalVisibility('driverInfoModalVisibility')}
        ionBackButton={() => this.onModalChangeVisibilty('driverInfoModalVisibility')}
      />
      <div className="main-wrapper">
        <DriverImageWrapper>
          <DriverImage src={driver.professionalPhotoUrl} />
        </DriverImageWrapper>
        <div className="driver-info">
          <p className="driver-name">{fullName}</p>
          <p className="value car-name"> {`${vehicleColor || ''} ${vehicleBrand || ''} ${vehicleModel || ''}`}</p>
        </div>
        <div className="additional-info-wrapper">
          {!!vehiclePlateNumber && (
            <div className="additional-info-block">
              <p className="title">{t('driver_dialog.plate')}</p>
              <p className="value">{vehiclePlateNumber}</p>
            </div>
          )}
          {!!phvLicenseNumber && (
            <div className="additional-info-block">
              <p className="title">{t('driver_dialog.phv_licence')}</p>
              <p className="value">{phvLicenseNumber}</p>
            </div>
          )}
          {!!phoneNumber && (
            <div className="additional-info-block">
              <p className="title">{t('personal_info.phone_number')}</p>
              <p className="value">{phoneNumber}</p>
            </div>
          )}
        </div>
      </div>
    </StyledContainer>
  );
};

const DriverImageWrapper = styled.div`
  width: 100%;
  padding-top: 100%;
  position: relative;
  margin-bottom: 32px;
`;

const DriverImage = styled(IonImg)`
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-repeat: no-repeat;
  border-radius: 4px;
  background-image: ${(props) => (props.driverAvatar ? `url(${props.driverAvatar})` : 'unset')};
  background-size: contain;
  background-color: ${(props) => (props.driverAvatar ? 'unset' : theme.colors.greys.grey05)};
`;

const StyledContainer = styled.div`
  box-shadow: unset;
  width: 100%;
  max-width: 340px;

  .header {
    position: sticky;
    margin-top: 18px;
    margin-bottom: 18px;
    min-width: 340px;
    padding: 0 40px 0px 40px;
  }
  .main-wrapper {
    padding: 0 40px 0 40px;
    margin-bottom: 30px;
    p {
      margin: 0;
    }

    .value {
      color: ${theme.colors.base.graphite};
      font-size: 14px !important;
      line-height: 21px;

      -webkit-user-select: text;
      -moz-user-select: text;
      -ms-user-select: text;
      user-select: text;
    }
    .driver-info {
      padding-bottom: 24px;
      border-bottom: 1px solid rgba(45, 59, 78, 0.08);
      .driver-name {
        color: ${theme.colors.base.graphite};
        font-size: 18px !important;
        line-height: 24px;
        font-weight: 800;
      }
      .car-name {
        text-transform: capitalize;
      }
    }
    .additional-info-wrapper {
      display: flex;
      margin-top: 24px;
      flex-wrap: wrap;
      .additional-info-block {
        display: flex;
        flex-direction: column;
        margin-right: 50px;
        margin-bottom: 10px;
      }
      .title {
        color: ${theme.colors.greys.grey5};
        font-size: 12px;
        line-height: 18px;
      }
    }
  }
  @media (max-width: ${theme.breakpoints[0]}px) {
    min-width: unset;
    .main-wrapper {
      padding: 0 20px;
    }
    .header {
      padding: 0 20px 0 20px;
      height: 60px;
      margin: 0 0 17px 0;
      min-width: unset;
    }
  }
`;

export default withTranslation()(observer(DriverDetailsModal));
