import React from 'react';

const Close = ({ styles, fill, opacity }) => (
  <svg style={styles} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
    <title>Child seat</title>
    <g fill="none" fillRule="evenodd" opacity={opacity}>
      <path d="M0 0h20v20H0z" />
      <path
        fill={fill}
        d="M10 8.707l3.879-3.879a.914.914 0 1 1 1.293 1.293L11.293 10l3.879 3.879a.914.914 0 1 1-1.293 1.293L10 11.293l-3.879 3.879a.914.914 0 1 1-1.293-1.293L8.707 10 4.828 6.121a.914.914 0 0 1 1.293-1.293L10 8.707z"
      />
    </g>
  </svg>
);

export default Close;
