import { makeAutoObservable } from 'mobx';
import TravelerApi from '../API';
import { set as setStorage, get as getStorage } from '../services/storage';
import { logError } from '../helpers/log';

class DestinationsStore {
  // Observables
  destinationsLoading = false;
  sRidesLoading = false;
  sRides = [];
  widgets = [];
  currentSRidesCityId = null;
  destinations = [];
  destinationsCount = null;
  destinationCountriesCount = null;

  constructor() {
    makeAutoObservable(this);

    // Read destinations count from localStorage
    if (!this.destinationsCount || !this.destinationCountriesCount) {
      getStorage('destinationsCount').then((count) => this.setDestinationsCount(count));
      getStorage('destinationCountriesCount').then((count) => this.setDestinationCountriesCount(count));
    }
  }

  setSRidesLoading = (status) => (this.sRidesLoading = status);

  setCurrentSRidesCityId = (cityId) => (this.currentSRidesCityId = cityId);

  setDestinationsLoading = (status) => (this.destinationsloading = status);

  setDestinationsCount = (destinationsCount) => {
    this.destinationsCount = destinationsCount;
  };

  setDestinationCountriesCount = (destinationCountriesCount) => {
    this.destinationCountriesCount = destinationCountriesCount;
  };

  async setDestinations(destinations) {
    this.destinations = destinations;
    await setStorage('destinationsCount', destinations.length);
    this.setDestinationsCount(destinations.length);

    const uniqueCountries = new Set();
    destinations.forEach((destination) => uniqueCountries.add(destination.countryName));
    await setStorage('destinationCountriesCount', uniqueCountries.size);
    this.setDestinationCountriesCount(uniqueCountries.size);
  }

  fetchDestinations = () => {
    this.setDestinationsLoading(true);
    return TravelerApi.getDestinations()
      .then((destinationsRes) => {
        this.setDestinations(destinationsRes);
        this.setDestinationsLoading(false);
      })
      .catch((err) => {
        this.setDestinationsLoading(false);
        logError('Error fetching destinations', err);
        throw err;
      });
  };

  getSRides = async (cityId, options) => {
    this.setSRidesLoading(true);
    return TravelerApi.getExploreSuggestions(cityId, options)
      .then((result) => {
        this.sRides.replace(result.services);
        this.widgets.replace(result.widgets);
        this.setCurrentSRidesCityId(cityId);
        this.setSRidesLoading(false);
      })
      .catch((err) => {
        this.setSRidesLoading(false);
        logError('Fetching explore suggestions error', err);
        throw err;
      });
  };
}
const destinationsStore = new DestinationsStore();

export default destinationsStore;
