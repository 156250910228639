import React from 'react';

const BookPlane = ({ fill }) => (
  <svg width="40px" height="40px" viewBox="0 0 40 40" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <title>Monitor flight</title>
    <desc>Created with sketchtool.</desc>
    <g id="Traveler-app" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="06.01.01.D.Book" transform="translate(-340.000000, -1016.000000)">
        <g id="Main_card" transform="translate(300.000000, 84.000000)">
          <g id="Transfer.Block" transform="translate(40.000000, 932.000000)">
            <g id="Transfer.Icon-Copy-3">
              <circle id="Userpic" fill={fill} cx="20" cy="20" r="20" fillOpacity="0.2" />
              <g id="Icon-Icon-/-Small-/-Landing" transform="translate(10.000000, 10.000000)">
                <g id="Landing_Icon.20">
                  <rect id="Bounds" x="0" y="0" width="20" height="20" />
                  <path
                    d="M7.74207005,11.0313981 L2.56484364,9.69978901 L1.84158862,9.51407826 C1.34594028,9.38681026 1,8.9453832 1,8.44019351 L1,4.46212657 L2.41371412,4.82655053 L3.32045508,6.99450723 L7.75,8.23734239 L7.75,1.5 L9.62867449,1.98596797 L12.3147784,9.4148108 L17.9160864,10.7464199 C18.6960841,10.9473056 19.1592055,11.7135704 18.9495707,12.461144 C18.7399721,13.2087177 17.9404727,13.6525874 17.160475,13.451667 L11.9784003,12.1200579 L7.74207005,11.0313981 Z M2,16 L18,16 C18.5522847,16 19,16.4477153 19,17 C19,17.5522847 18.5522847,18 18,18 L2,18 C1.44771525,18 1,17.5522847 1,17 C1,16.4477153 1.44771525,16 2,16 Z"
                    id="Fill"
                    fill={fill}
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default BookPlane;
