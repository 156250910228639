import React from 'react';

const Ferry = ({ styles, fill = '#808994', opacity }) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 2H11V5H14C14.5523 5 15 5.44772 15 6V10.25L18 11L16 18H4L2 11L5 10.25V6C5 5.44772 5.44772 5 6 5H9V2ZM7 7H9H11H13V9.713L10.0015 9.00039L10 9.00002L9.9985 9.00038L7 9.712V7ZM9 11.311L4.491 12.438L5.509 16H9V11.311ZM11 11.311V16H14.49L15.508 12.438L11 11.311Z"
      fill={fill}
    />
  </svg>
);

export default Ferry;
