import { observable, makeAutoObservable } from 'mobx';
import TravelerApi from '../API';
import { logError } from '../helpers/log';

class TripsStore {
  tripPreviews = {};
  tripPreviewsLoading = false;

  constructor() {
    makeAutoObservable(this);
  }

  //
  // Setters
  //

  setTripPreviews = (value) => {
    this.tripPreviews = value;
  };
  setTripPreviewsLoading = (value) => {
    this.tripPreviewsLoading = value;
  };

  resetTripsStore = () => {
    this.setTripPreviews({});
  };

  //
  // Requests
  //

  fetchTripPreviews = async () => {
    this.setTripPreviewsLoading(true);
    try {
      const data = await TravelerApi.getTripPreviews();
      this.setTripPreviewsLoading(false);
      this.setTripPreviews(data);
      return data;
    } catch (err) {
      this.setTripPreviewsLoading(false);
      logError('Fetch Trip Previews Error', err);
      throw err;
    }
  };
}

const tripsStore = new TripsStore();
export default tripsStore;
