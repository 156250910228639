import React, { Fragment, useEffect } from 'react';
import { observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { StyledCard, StyledContent, StyledPage } from '../../components/styled/IonElements';
import LoggedInUser from './LoggedInUser';
import GuestUser from './GuestUser';
import { useStores } from '../../stores/useStores';
import mixpanel from '../../services/mixpanel';
import talonOne from '../../services/talonOne';
import Refresher from '@/src/components/common/Refresher';

const Profile = () => {
  const {
    travelerAppStore: { isAuthorised, travelerAutocheckErrors, isAllowedToMergeAccounts },
    talonOneStore: { resetTransactions, resetInvitations }
  } = useStores();

  const CustomProfile = isAuthorised ? LoggedInUser : GuestUser;

  const doRefresh = (event) => {
    if (isAuthorised) {
      talonOne.getLoyaltyBalances();
      // We reset the invitations and transactions to avoid sending a request in this page (since we don't use this info here)
      // but by doing this we make sure that the new data is going to be fetched when the user navigates to the other pages.
      resetTransactions();
      resetInvitations();
    }

    event.detail.complete();
  };

  useEffect(() => {
    mixpanel.trackProfileView({
      Errors_Count: (isAuthorised && travelerAutocheckErrors?.length) || 0,
      Referral_Banner_Visible: isAuthorised && !isAllowedToMergeAccounts,
      merge_accounts_visible: isAuthorised && isAllowedToMergeAccounts
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Fragment>
      <Refresher onRefresh={doRefresh} />
      <StyledPage>
        <StyledContent>
          <StyledCard margin="20px" padding="0" minHeight={window.innerWidth < 768 && '100%'}>
            <CustomProfile />
          </StyledCard>
        </StyledContent>
      </StyledPage>
    </Fragment>
  );
};

export default withRouter(observer(Profile));
