import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser';
import history from '../history';
import AuthenticationService from '../../../services/authentication.js';
import { isAndroidMobileApp, isIosMobileApp, isWebPlatform } from '../helpers';
import stores from '../../../stores';
import braze from '../../../services/braze';
import mixpanel from '@/src/services/mixpanel';
import { MIXPANEL_DISTINCT_ID_PARAM } from '@/src/constants';

// const options = "location=no,footer=no";
const androidOptions = 'location=no,footer=no,clearcache=yes,clearsessioncache=yes';
const iosOptions = 'location=no,toolbar=no,clearcache=yes,clearsessioncache=yes';
const target = '_blank';
const closeLink = '/mta/close';
const iabToAppParam = 'iab_to_app';

let browser = null;

const iabOptions = () => {
  if (isAndroidMobileApp()) {
    return androidOptions;
  }
  if (isIosMobileApp()) {
    return iosOptions;
  }
};

const mobilePlatform = () => {
  if (isAndroidMobileApp()) {
    return 'android';
  }
  if (isIosMobileApp()) {
    return 'ios';
  }
};

const urlWithMobileAppParams = (originalUrl) => {
  let url = new URL(originalUrl);
  url.searchParams.append('from_mobile_app', true);
  url.searchParams.append('mta_platform', mobilePlatform());

  // PHOENIX-7447 We pass the mixpanel distinct id to the external urls so the backend can use it to merge mixpanel user profiles if needed.
  const distinctId = mixpanel.getDistinctId();
  if (distinctId) {
    url.searchParams.append(MIXPANEL_DISTINCT_ID_PARAM, distinctId);
  }

  return url.toString();
};

const urlContainsIabToAppParam = (originalUrl) => {
  let url = new URL(originalUrl);
  return url.searchParams.has(iabToAppParam);
};

const urlToInternalUrl = (originalUrl) => {
  // extract internal url
  const slug = originalUrl.split('traveler_app').pop();
  return slug;
};

const authorizeFromParams = async (originalUrl) => {
  let url = new URL(originalUrl);
  const travelers_access_token = url.searchParams.get('travelers_access_token');
  if (travelers_access_token) {
    // console.log("Will authenticate traveler from params: ", travelers_access_token);
    await AuthenticationService.authenticateWithToken(travelers_access_token);
    // console.log("Authenticated traveler from params: ", authenticatedTraveler);
  }
};

const loadStartCallBack = async (e) => {
  const {
    tripsStore: { fetchTripPreviews }
  } = stores;

  console.log('Load start callback', e.url);
  // Handle transition from IAB back to app after first back button of IAB is clicked
  if (e.url.includes(closeLink)) {
    console.log('Closing IAB');
    browser.close();
  }
  // Handle transition from IAB back to app after post bookig flow
  if (urlContainsIabToAppParam(e.url)) {
    console.log('Closing IAB and redirecting to App');
    // Authorize traveler
    await authorizeFromParams(e.url);

    // We need to fetch the trip previews regardless of the tab we are sending the user to.
    try {
      fetchTripPreviews();
      braze.getContentCardsFromServer();
    } catch (err) {}

    // Internal redirection to original url
    browser.close();
    const internalUrl = urlToInternalUrl(e.url);
    history.push('/', null);
    history.push(internalUrl, { fetch: true });
    return;
  }
};

const onExternalLinkClick = async (event, originalUrl) => {
  console.log('onExternalLinkClick: ', originalUrl);
  if (isWebPlatform()) {
    return true;
  }

  if (event) {
    event.preventDefault();
  }
  // append params for mobile app and platform
  let url = urlWithMobileAppParams(originalUrl);
  // use inAppBroswer To open
  browser = InAppBrowser.create(url, target, iabOptions());
  browser.on('loadstart').subscribe(loadStartCallBack);
  return false;
};

export { onExternalLinkClick };
