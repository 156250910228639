import React from 'react';
import { observer } from 'mobx-react';
import OneTimePassword from '@/src/components/formFields/OneTimePassword';
import { useStores } from '@/src/stores/useStores';
import PropTypes from 'prop-types';

const VerificationCodeInput = (props) => {
  const { login } = props;

  const {
    travelerAppStore: { setLoginError }
  } = useStores();

  const handleChange = (value) => {
    setLoginError({});
  };

  const handleComplete = (value) => {
    setLoginError({});
    login(value);
  };

  return <OneTimePassword onChange={handleChange} onComplete={handleComplete} />;
};

VerificationCodeInput.propTypes = {
  login: PropTypes.func.isRequired
};

export default observer(VerificationCodeInput);
