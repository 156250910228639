// TODO: Split helpers
import React from 'react';
import styled from 'styled-components';
import { format, parseISO, compareAsc, differenceInSeconds } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { trackEvent } from '../../tracking';
import dateLocales from './dateLocales';
import { NavLink } from 'react-router-dom';
import { isPlatform } from '@ionic/react';
import { Clipboard } from '@capacitor/clipboard';
import { Device } from '@capacitor/device';
import {
  getPlatformForTravelerAppEventPatch,
  isAndroidMobileAppPatch,
  isDesktopPatch,
  isIosMobileAppPatch,
  isMobileAppPlatformPatch,
  isMobilePlatformPatch,
  isMobileWebPlatformPatch,
  isWebPlatformPatch
} from '../../patches/detectPlatformPatch';
import { Capacitor } from '@capacitor/core';
import { Share } from '@capacitor/share';
import queryString from 'query-string';
import { EXPIRED_TOKEN_QUERY_PARAM, RETURN_URL_QUERY_PARAM } from '@/src/constants';
import history from '@/src/components/common/history';
import { SafeArea } from 'capacitor-plugin-safe-area';

const { getPlatform } = Capacitor;

let _isGooglePixel = false;

const isGooglePixel = async () => {
  const deviceInfo = await Device.getInfo();
  const { operatingSystem, manufacturer, model, isVirtual } = deviceInfo;

  _isGooglePixel =
    operatingSystem === 'android' &&
    (manufacturer?.toLocaleLowerCase()?.includes('google') ||
      manufacturer?.toLocaleLowerCase()?.includes('appetize')) && // Only for appetize emulator
    (model?.toLocaleLowerCase()?.includes('pixel') ||
      model?.toLocaleLowerCase()?.includes('sdk_gphone') || // Only for emulators
      isVirtual); // Only for emulators

  return _isGooglePixel;
};
isGooglePixel(); // Set correct value on first load

/**
 * @param rawText
 * @param words
 */
function replaceTranslatedProperties(rawText, ...words) {
  const specialCharactersRegEx = /%{[_a-z0-9]*}/;

  return rawText
    ? words.reduce((text, word) => {
        return text.replace(specialCharactersRegEx, word);
      }, rawText)
    : '';
}

const noOp = () => {};

const addScript = (scriptSrc, callback = noOp, id = '', async = true, onload = noOp) => {
  const script = document.createElement('script');
  script.onload = onload;
  script.src = scriptSrc;
  script.async = async;
  script.id = id;
  document.body.appendChild(script);
  callback();
};

const removeScript = (id) => {
  const scriptList = document.querySelectorAll('script');
  const convertedNodeList = Array.from(scriptList);
  const script = convertedNodeList.find((script) => script.id === id);
  if (script) {
    script.parentNode.removeChild(script);
  }
};

const toTitleCase = (phrase = '') => {
  return phrase
    .toLowerCase()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

/**
 * @param timeZone
 * @param date
 * @param formatStr
 */
function dateLocale(date, timeZone, formatStr = 'PP', selectedLocale) {
  let locale;

  switch (selectedLocale) {
    case 'zh-hans':
      locale = 'zhCN';
      break;
    case 'pt-pt':
      locale = 'pt';
      break;
    default:
      locale = selectedLocale;
  }

  return toTitleCase(
    format(utcToZonedTime(parseISO(date), timeZone || 'Europe/Berlin'), formatStr, {
      locale: dateLocales[locale]
    })
  );
}

const getCookie = (name) => {
  const match = document.cookie.match(new RegExp(`(^| )${name}=([^;]+)`));
  return match ? match[2] : false;
};

/**
 *
 * @param {string} name
 * @param {*} value
 * @param {number} days
 */
const setCookie = (name, value, days = 365) => {
  const date = new Date();
  date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
  document.cookie = `${name}=${value}; expires=${days === 0 ? 0 : date.toUTCString()}; path=/`;
};

/**
 * @param {string} name
 */
const removeCookie = (name, domain = '') => {
  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC;${domain && ` domain=.${domain};`} path=/;`;
};

const getNavigationComponent = ({ children, callback, link, styles = null, target, as = null, ...props }) => {
  const pattern = /^https?:\/\//i;
  const isExternal = pattern.test(link);

  const NavigationComponent = styled.a`
    ${styles}
  `;

  return isExternal ? (
    <NavigationComponent
      as={as || 'a'}
      target={target}
      rel="noopener noreferrer"
      href={link}
      onClick={(e) => {
        trackEvent('traveler app', 'external link', link);
        if (callback) {
          callback(e);
        }
      }}
      {...props}
    >
      {children}
    </NavigationComponent>
  ) : (
    <NavigationComponent as={as || NavLink} to={link} onClick={callback} {...props}>
      {children}
    </NavigationComponent>
  );
};

const isDesktop = () => {
  if (_isGooglePixel) {
    return isDesktopPatch();
  }
  return isPlatform('desktop');
};

const isWebPlatform = () => {
  if (_isGooglePixel) {
    return isWebPlatformPatch();
  }
  return isPlatform('desktop') || isPlatform('pwa') || isPlatform('mobileweb');
};

const isMobilePlatform = () => {
  if (_isGooglePixel) {
    return isMobilePlatformPatch();
  }
  return isPlatform('mobile') || isPlatform('mobileweb');
};

const isMobileWebPlatform = () => {
  if (_isGooglePixel) {
    return isMobileWebPlatformPatch();
  }
  return isPlatform('mobileweb');
};

const isMobileAppPlatform = () => {
  if (_isGooglePixel) {
    return isMobileAppPlatformPatch();
  }
  return !isWebPlatform() && (isPlatform('ios') || isPlatform('android'));
};

const isIosMobileApp = () => {
  if (_isGooglePixel) {
    return isIosMobileAppPatch();
  }
  return !isWebPlatform() && isPlatform('ios');
};

const isAndroidMobileApp = () => {
  if (_isGooglePixel) {
    return isAndroidMobileAppPatch();
  }
  return !isWebPlatform() && isPlatform('android');
};

const isAndroidPlatform = () => {
  return getPlatform() === 'android';
};

const isIosPlatform = () => {
  return getPlatform() === 'ios';
};

const getPlatformForTravelerAppEvent = () => {
  if (_isGooglePixel) {
    return getPlatformForTravelerAppEventPatch();
  }

  if (isPlatform('desktop')) {
    return 'desktop';
  } else if (isPlatform('mobileweb')) {
    return 'mobileweb';
  } else if (isPlatform('ios')) {
    return 'ios';
  } else if (isPlatform('android')) {
    return 'android';
  } else {
    return null;
  }
};

const fromCoBrandedDomain = () => {
  const host = window.location.hostname;
  const traveler_domain = process.env.REACT_APP_TRAVELER_DOMAIN;
  return host !== traveler_domain && host !== 'localhost';
};

/**
 * @description sort array of objects by date
 * @param {array of objects which need to be sort by date} objects
 * @param {the key of the object which contains the date} key
 * @param {boolean flag to transform the date key to date object} transformToDate
 * @returns sorted by date array of objects
 */
const sortObjectsByDate = (objects, key, transformToDate = false) => {
  return objects.sort((objectA, objectB) => {
    const dateA = transformToDate ? parseISO(objectA[key]) : objectA[key];
    const dateB = transformToDate ? parseISO(objectB[key]) : objectB[key];
    const result = compareAsc(dateA, dateB);
    return result;
  });
};

const getDiffInSecondsFromTimestamps = (firstTimestamp, lastTimestamp) => {
  const lastDate = new Date(lastTimestamp);
  const fistDate = new Date(firstTimestamp);
  const diff = differenceInSeconds(lastDate, fistDate);
  return diff;
};

const stringWithEllipsis = (str, n) => {
  return str.length > n ? str.substr(0, n - 1) + String.fromCharCode(8230) : str;
};

const currentPageIs = (pathname) => {
  return window.location.pathname === pathname;
};

const getContentCardsFilterForPage = (pageName) => {
  return (card) =>
    card.extras?.placement === pageName || card.extras?.placement === 'everywhere' || !card.extras?.placement;
};

const isObject = (value) => {
  return typeof value === 'object' && value !== null;
};

const isEmptyObject = (value) => {
  return isObject(value) && Object.keys(value).length === 0;
};

const isEmptyArray = (value) => {
  return Array.isArray(value) && value.length === 0;
};

const isFalsy = (value) => {
  return (
    value === undefined ||
    value === null ||
    value == 0 ||
    value === 0n ||
    value < 0 ||
    String(value).trim() === '' ||
    value === false ||
    value === 'false' ||
    (typeof value === 'number' && Number.isNaN(value)) ||
    isEmptyObject(value) ||
    isEmptyArray(value)
  );
};

const isTruthy = (value) => !isFalsy(value);

const debugConsoleLog = (...args) => {
  if (process.env.REACT_APP_ENVIRONMENT !== 'production') {
    console.log(...args);
  }
};

const debugConsoleError = (...args) => {
  if (process.env.REACT_APP_ENVIRONMENT !== 'production') {
    console.error(...args);
  }
};

const copyToClipboard = async (text) => {
  try {
    // navigator.clipboard.write does not work on android so we use the capacitor plugin
    if (isAndroidMobileApp() || !(navigator.clipboard && navigator.clipboard.writeText)) {
      await Clipboard.write({
        string: text || ''
      });
    } else {
      await navigator.clipboard.writeText(text || '');
    }
  } catch (error) {
    console.error(error);
    return false;
  }

  return true;
};

/**
 * Indicates whether we can use the native share functionality or not.
 */
const canShare = () => {
  return !!(isMobileAppPlatform() || navigator?.canShare);
};

/**
 * Opens the native share modal.
 * @param {object} opts
 * @param {string} opts.title // Only on android, it will show a title with the logo of the app on the side (it doesn't work with the capacitor plugin)
 * @param {string} opts.text // An informative text to show on the share modal
 * @param {string} opts.url // The url to share. It is added right after the "text"
 */
const nativeShare = async (opts) => {
  let res;

  try {
    // On mobile apps we use the capacitor plugin
    if (isMobileAppPlatform()) {
      res = await Share.share(opts);
    }
    // On web mobile we use the Web Share API
    else if (navigator?.canShare) {
      // navigator.share does not show any info about the action taken by the user, so we can not know which app was selected or if the link was copied
      await navigator?.share(opts);
    }
    // eslint-disable-next-line no-unused-vars
  } catch (error) {}

  if (res) {
    // Means the user copied the link from inside the share modal
    const linkCopied = (res.activityType === '' || res.activityType) === 'com.apple.UIKit.activity.CopyToPasteboard';

    if (linkCopied) {
      return { action: 'copy' };
    } else if (res.activityType) {
      return { action: 'share', app: res.activityType };
    }
  }

  return {};
};

/**
 * Checks if obj1 is subset of obj2
 * @param {object} obj1
 * @param {object} obj2
 * @returns {boolean} true if obj1 is subset of obj2
 * @example
 * isSubset({a: 1}, {a: 1, b: 2}) // true
 * isSubset({a: 1}, {a: 2, b: 2}) // false
 * isSubset({a: 1}, {b: 2}) // false
 * isSubset({}, {a: 1}) // true
 * isSubset({a: undefined}, {a: undefined}) // true
 * isSubset({a: undefined}, {a: 1}) // false
 */
const isSubset = (obj1 = {}, obj2 = {}) => {
  // If obj1 has more keys, it is not a subset of obj2
  if (Object.keys(obj1).length > Object.keys(obj2).length) {
    return false;
  }

  for (let key in obj1) {
    if (!Object.prototype.hasOwnProperty.call(obj2, key) || obj1[key] !== obj2[key]) {
      return false;
    }
  }

  return true;
};

/**
 * Appends the given params to the url as search params
 * @param {string} url
 * @param {object} params
 * @returns
 */
const addParamsToUrl = (url, params) => {
  const urlObj = new URL(url);
  Object.entries(params).forEach(([key, value]) => {
    urlObj.searchParams.append(key, value);
  });
  return urlObj.href;
};

const getQueryParam = (paramName) => {
  const urlParams = queryString.parse(window.location.search);
  return urlParams[paramName];
};

/**
 * Returns the url with the post login destination param appended if needed
 * @param {string} url
 * @param {string} returnUrl
 */
const urlWithReturnUrl = (url) => {
  const returnUrl = getQueryParam(RETURN_URL_QUERY_PARAM);

  return returnUrl && !returnUrl.includes('login') ? `${url}?${RETURN_URL_QUERY_PARAM}=${returnUrl}` : url;
};

/**
 * Redirects to login page with ?expired_token=true&return_url=... query params
 */
const handleExpiredToken = () => {
  // Redirect to login page
  let returnUrl = getQueryParam(RETURN_URL_QUERY_PARAM) || window.location.pathname;
  if (returnUrl.includes('traveler_app')) {
    returnUrl = returnUrl.split('traveler_app').pop();
  }

  history.push(`/profile?${EXPIRED_TOKEN_QUERY_PARAM}=true&${RETURN_URL_QUERY_PARAM}=${returnUrl}`);
};

// Some devices have a notch or a rounded corner that can be covered by the app.
// This function sets the CSS variables to adjust the app layout to avoid covering the safe area.
const setSafeAreaVars = async () => {
  const safeAreaData = await SafeArea.getSafeAreaInsets();
  const { insets } = safeAreaData;
  for (const [key, value] of Object.entries(insets)) {
    if (key === 'bottom') {
      // Since we have added the safe area top padding in the body to cover all cases (ex. widget modals),
      // we need to add the top space value to the original bottom value to show the content correctly.
      document.documentElement.style.setProperty(`--ion-safe-area-bottom`, `${value + insets.top}px`);
    } else {
      document.documentElement.style.setProperty(`--ion-safe-area-${key}`, `${value}px`);
    }
  }
};

export {
  isGooglePixel,
  replaceTranslatedProperties,
  addScript,
  removeScript,
  dateLocale,
  getCookie,
  setCookie,
  removeCookie,
  getNavigationComponent,
  isDesktop,
  isWebPlatform,
  isMobilePlatform,
  isMobileWebPlatform,
  isMobileAppPlatform,
  isIosMobileApp,
  isAndroidMobileApp,
  isAndroidPlatform,
  isIosPlatform,
  getPlatformForTravelerAppEvent,
  fromCoBrandedDomain,
  sortObjectsByDate,
  getDiffInSecondsFromTimestamps,
  stringWithEllipsis,
  currentPageIs,
  getContentCardsFilterForPage,
  isObject,
  isEmptyObject,
  isEmptyArray,
  isFalsy,
  isTruthy,
  debugConsoleLog,
  debugConsoleError,
  copyToClipboard,
  canShare,
  nativeShare,
  isSubset,
  addParamsToUrl,
  getQueryParam,
  urlWithReturnUrl,
  handleExpiredToken,
  setSafeAreaVars
};
