import React from 'react';
import { ImageCardStyled, ImageContainer, MainContent } from './ImageCard.styled.js';
import PropTypes from 'prop-types';

// A full-width card with an image header
const ImageCard = (props) => {
  const {
    children,
    imageUrl,
    headerContent,
    mobileImageHeight = '120px',
    tabletImageHeight = '120px',
    desktopImageHeight = '140px',
    dimmed = false,
    onClick = () => {},
    style = {}
  } = props;

  return (
    <ImageCardStyled onClick={onClick} style={style}>
      <ImageContainer
        src={imageUrl}
        mobileHeight={mobileImageHeight}
        tabletHeight={tabletImageHeight}
        desktopHeight={desktopImageHeight}
        dimmed={dimmed}
      >
        {headerContent}
      </ImageContainer>

      <MainContent>{children}</MainContent>
    </ImageCardStyled>
  );
};

ImageCard.propTypes = {
  imageUrl: PropTypes.string,
  mobileImageHeight: PropTypes.string,
  tabletImageHeight: PropTypes.string,
  desktopImageHeight: PropTypes.string,
  headerContent: PropTypes.node,
  children: PropTypes.node,
  dimmed: PropTypes.bool,
  onClick: PropTypes.func,
  style: PropTypes.instanceOf(Object)
};

export default ImageCard;
