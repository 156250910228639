import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import LoggedInUser from '../../LoggedInUser';
import DoubleLayout from '@/src/components/layouts/DoubleLayout';
import PropTypes from 'prop-types';
import useWindowSize from '@/src/hooks/useWindowSize';
import theme from '@/src/theme';
import { useStores } from '@/src/stores/useStores';
import { Heading2 } from '@/src/components/styled/Typography';
import { withTranslation } from 'react-i18next';
import VerificationScreen from './VerificationScreen';
import { Wrapper } from '@/src/theme/components/Wrapper';

const VerifyAndMerge = (props) => {
  const { history, t } = props;

  const {
    duplicateTravelersStore: { mergeAccountsLoading },
    travelerAppStore: { setHideTabs }
  } = useStores();

  const windowSize = useWindowSize();

  const renderSidebarContent = () => {
    return <LoggedInUser isSide />;
  };

  const renderMainContent = () => {
    return (
      <div>
        {windowSize.width > theme.breakpoints[0] && (
          <Heading2 marginTop="0" marginBottom="28px">
            {t('duplicate_travelers.merge_accounts')}
          </Heading2>
        )}

        <Wrapper
          styles={{
            textAlign: 'center',
            marginTop: '80px',
            md: {
              marginTop: '70px',
              padding: '0 100px'
            }
          }}
        >
          <VerificationScreen />
        </Wrapper>
      </div>
    );
  };

  useEffect(() => {
    // We hide the navigation tabs while the accounts are being merged
    if (mergeAccountsLoading) {
      setHideTabs(true);
    } else {
      setHideTabs(false);
    }

    return () => {
      setHideTabs(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mergeAccountsLoading]);

  return (
    <DoubleLayout
      onBackClick={() => history.push('/profile/merge_accounts')}
      title={t('duplicate_travelers.merge_accounts')}
      subtitle=""
      sidebarContent={renderSidebarContent()}
      mainContent={renderMainContent()}
      mainContentPadding="32px 20px"
      customSpace="40px"
    />
  );
};

VerifyAndMerge.propTypes = {
  history: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired
};

export default withTranslation()(withRouter(observer(VerifyAndMerge)));
