import React, { Fragment } from 'react';
import styled from 'styled-components';
import { IonLabel, IonSkeletonText, IonThumbnail } from '@ionic/react';
import { FlexContainer } from '../../styled/Wrappers';

import './SkeletonTextExample.css';
import theme from '../../../theme';

const SkeletonGrid = () => (
  <Fragment>
    <IonSkeletonText animated style={{ width: '200px', marginTop: '50px', marginBottom: '10px', height: '18px' }} />
    <IonSkeletonText animated style={{ width: '100px' }} />
    <SkeletonGridContainer>
      {[0, 1].map((i) => (
        <div key={i}>
          <IonThumbnail style={{ width: '100%', height: '154px', margin: 0 }} slot="start">
            <IonSkeletonText animated />
          </IonThumbnail>

          <IonLabel>
            <IonSkeletonText animated style={{ width: '100%' }} />
            <IonSkeletonText animated style={{ width: '60%' }} />

            <FlexContainer justify="space-between" align="start">
              <IonSkeletonText animated style={{ width: '50px' }} />
              <IonSkeletonText animated style={{ width: '10%' }} />
            </FlexContainer>
            <FlexContainer justify="space-between" align="start">
              <IonSkeletonText animated style={{ width: '90px' }} />
              <IonSkeletonText animated style={{ width: '10%' }} />
            </FlexContainer>
          </IonLabel>
        </div>
      ))}
    </SkeletonGridContainer>
    <SkeletonGridContainer>
      {[0, 1].map((i) => (
        <div key={i}>
          <IonThumbnail style={{ width: '100%', height: '134px', margin: 0 }} slot="start">
            <IonSkeletonText animated />
          </IonThumbnail>

          <IonLabel>
            <IonSkeletonText animated style={{ width: '100%' }} />
            <IonSkeletonText animated style={{ width: '60%' }} />

            <FlexContainer justify="space-between" align="start">
              <IonSkeletonText animated style={{ width: '50px' }} />
              <IonSkeletonText animated style={{ width: '10%' }} />
            </FlexContainer>
            <FlexContainer justify="space-between" align="start">
              <IonSkeletonText animated style={{ width: '90px' }} />
              <IonSkeletonText animated style={{ width: '10%' }} />
            </FlexContainer>
          </IonLabel>
        </div>
      ))}
    </SkeletonGridContainer>
  </Fragment>
);

const SkeletonGridContainer = styled.div`
  margin: 40px 0 40px 0;
  display: grid;
  grid-auto-rows: auto;
  grid-template-columns: 45% 45%;
  grid-gap: 32px;
  justify-content: center;

  @media (max-width: ${theme.breakpoints[0]}px) {
    grid-template-columns: 95%;
  }
`;

export default SkeletonGrid;
