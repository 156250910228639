import styled from 'styled-components';
import theme from '../../theme';
import { StyledCard } from './IonElements';
import { IonTabs } from '@ionic/react';

const GreyWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f4f5f6;
  height: 100%;

  @media (max-width: 768px) {
    width: 100%;
    border-radius: unset;
  }
`;

const GradientOverlay = styled.div`
  z-index: 10;
  display: flex;
  align-items: center;
  padding-left: 30px;
  position: absolute;
  top: 0;
  width: 100%;
  height: 96px;
  background: linear-gradient(
    180deg,
    rgba(246, 246, 247, 1) 0%,
    rgba(246, 246, 247, 0.7) 50%,
    rgba(246, 246, 247, 0) 90%
  );
  border-radius: 4px;
  background: none;

  @media (max-width: ${theme.breakpoints[0]}px) {
    background: linear-gradient(180deg, rgba(22, 35, 54, 0.4) 0%, rgba(22, 35, 54, 0) 100%);
    padding-left: 10px;
  }
`;

const FullHeightOverlay = styled.div`
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  background: linear-gradient(180deg, rgba(22, 35, 54, 0.3) 0%, rgba(22, 35, 54, 0.4) 100%);
  height: 240px;
  max-width: 600px;
  width: 100%;
`;

const FlexContainer = styled('div')(
  ({
    align = 'normal',
    justify = 'initial',
    margin,
    gap = '0',
    flexDirection = 'row',
    textAlign = 'left',
    flex,
    height,
    maxWidth,
    padding,
    wrap = 'inherit'
  }) => ({
    display: 'flex',
    alignItems: align,
    justifyContent: justify,
    margin,
    gap,
    flexDirection,
    textAlign,
    flex,
    height,
    maxWidth,
    padding,
    flexWrap: wrap
  })
);

const InlineFlexContainer = styled('div')(({ align = 'normal', justify = 'initial', margin, gap = 0 }) => ({
  display: 'inline-flex',
  alignItems: align,
  justifyContent: justify,
  margin,
  gap
}));

const DesktopFlexContainer = styled(FlexContainer)`
  display: inline-flex;
  -webkit-box-pack: center;
  justify-content: center;
  padding-bottom: 40px;
  width: 100%;
  padding-top: 20px;
  min-height: 100%;

  @media (max-width: ${theme.breakpoints[0]}px) {
    padding-top: 0px;
  }
`;

const DesktopList = styled(StyledCard)`
  position: sticky;
  top: ${(props) => props.top || '40px'};
  vertical-align: top;
  max-width: 360px;
  display: inline-block;
  width: 100%;
  margin-right: 16px;
  margin-left: 0;

  @media (max-width: ${theme.breakpoints[0]}px) {
    display: none;
  }
`;

const StickyList = styled(StyledCard)`
  position: sticky;
  top: 40px;
  vertical-align: top;
  max-width: 360px;
  display: inline-block;
  width: 100%;
  margin-right: 16px;
  margin-left: 0;
`;

const DesktopRightContainer = styled.div`
  margin-top: 10px;
  width: 100%;
  max-width: 600px;
  height: 100%;
`;

const PoweredByLogoWrapper = styled(FlexContainer)`
  margin-top: 60px;
  flex-grow: 1;
  flex-direction: column;

  @media (max-height: 394px) and (max-width: ${theme.breakpoints[0]}px) {
    display: none;
  }
`;

const HeaderBottomFlexContainer = styled(FlexContainer)`
  position: absolute;
  bottom: 30px;
  width: 100%;
  padding: 0 20px;
`;

const ContentCardsWrapper = styled.div`
  padding: 0;
  background: rgba(0, 0, 0, 0);
  display: ${(props) => props.display || 'block'};
  box-shadow: none;
`;

// A simple div wrapper for content cards.
const ContentCardsSimpleWrapper = styled.div`
  display: ${(props) => props.display || 'block'};
  box-shadow: none;
`;

const CustomTabs = styled(IonTabs)`
  padding: 5px 0;
  ion-tab-button {
    border-bottom: 3px solid transparent;
    &.tab-selected {
      color: ${theme.colors.greys.grey5};
      border-bottom: 3px solid ${(props) => props.bordercolor};
    }
    --color-selected: ${theme.colors.base.graphite};
  }
`;

export {
  GreyWrapper,
  GradientOverlay,
  FullHeightOverlay,
  FlexContainer,
  InlineFlexContainer,
  DesktopFlexContainer,
  DesktopList,
  StickyList,
  DesktopRightContainer,
  PoweredByLogoWrapper,
  HeaderBottomFlexContainer,
  ContentCardsWrapper,
  ContentCardsSimpleWrapper,
  CustomTabs
};
