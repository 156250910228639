import React from 'react';
import { StyledContent, StyledPage } from '@/src/components/styled/IonElements';
import StickyHeader from '@/src/components/common/StickyHeader';
import { observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import useWindowSize from '@/src/hooks/useWindowSize';
import theme from '@/src/theme';
import PropTypes from 'prop-types';
import { ExtraContent, MainContent, MainWrapper, Sidebar } from './index.styled';
import Loading from '../../common/Loading';

const DoubleLayout = (props) => {
  const {
    title = '',
    subtitle = '',
    sidebarContent,
    mainContent,
    mainContentLoading = false,
    mainContentPadding = '40px',
    extraContent,
    fixedContent = null,
    onBackClick = () => {},
    hideMobileHeader = false,
    customSpace
  } = props;

  const windowSize = useWindowSize();

  return (
    <StyledPage>
      {!hideMobileHeader && windowSize.width < theme.breakpoints[0] && (
        <StickyHeader goBack={onBackClick} title={title} subtitle={subtitle} noBorder />
      )}

      <StyledContent>
        <MainWrapper hide_mobile_header={hideMobileHeader} custom_space={customSpace}>
          <Sidebar custom_space={customSpace}>{sidebarContent}</Sidebar>
          <MainContent padding={mainContentPadding}>{mainContentLoading ? <Loading /> : mainContent}</MainContent>
        </MainWrapper>

        {extraContent && (
          <MainWrapper hide_mobile_header={true}>
            <Sidebar></Sidebar>
            <ExtraContent>{extraContent}</ExtraContent>
          </MainWrapper>
        )}
      </StyledContent>

      {fixedContent && windowSize.width < theme.breakpoints[0] && <div className="ion-no-border">{fixedContent}</div>}
    </StyledPage>
  );
};

DoubleLayout.propTypes = {
  history: PropTypes.object.isRequired,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  sidebarContent: PropTypes.node.isRequired,
  mainContent: PropTypes.node.isRequired,
  mainContentLoading: PropTypes.bool,
  mainContentPadding: PropTypes.string,
  extraContent: PropTypes.node,
  fixedContent: PropTypes.node,
  onBackClick: PropTypes.func,
  hideMobileHeader: PropTypes.bool,
  customSpace: PropTypes.string // The space in the top and bottom of the main content
};

export default withRouter(observer(DoubleLayout));
