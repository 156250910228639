import React from 'react';

// eslint-disable-next-line react/prop-types
const ChevronRight = ({ styles, fill = '#2D3B4E', size = '20px', className }) => (
  <svg
    className={className}
    style={styles}
    height={size}
    width={size}
    viewBox={`0 0 20 20`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.1621 10.0111L7.75165 6.1633C7.38532 5.74999 7.42341 5.11797 7.83672 4.75165C8.25003 4.38532 8.88205 4.42341 9.24837 4.83672L13.2484 9.34976C13.5846 9.72913 13.5838 10.3001 13.2465 10.6785L9.24646 15.1655C8.87895 15.5777 8.24682 15.614 7.83457 15.2465C7.42232 14.8789 7.38605 14.2468 7.75356 13.8346L11.1621 10.0111Z"
      fill={fill}
    />
  </svg>
);

export default ChevronRight;
