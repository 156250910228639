/**
 * Creates a sequence of numbers starting from the given start value
 * @param {number} count The number of elements in the sequence
 * @param {number} start The starting value of the sequence
 * @returns {Array} An array of numbers
 */
export function generateArrayOfNumbers(count, start = 1) {
  if (count <= 0) return []; // Return an empty array for non-positive counts
  return Array.from({ length: count }, (_, i) => start + i);
}
