import React from 'react';

const LoaderSpinner = ({ fill, styles }) => (
  <svg style={styles} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 54 54">
    <title>Spinner</title>
    <path
      fill={fill}
      fillRule="evenodd"
      d="M27.43 1c.826 0 1.496.659 1.496 1.472 0 .812-.67 1.471-1.496 1.471C14.486 3.943 3.992 14.266 3.992 27c0 12.734 10.494 23.057 23.438 23.057.826 0 1.496.659 1.496 1.471 0 .813-.67 1.472-1.496 1.472C12.833 53 1 41.36 1 27S12.833 1 27.43 1z"
    />
  </svg>
);

export default LoaderSpinner;
