import React, { Fragment, useState } from 'react';
import { useStores } from '../../../stores/useStores';
import styled from 'styled-components';
import { Text1 } from '../../styled/Typography';
import theme from '../../../theme';
import { IonToast } from '@ionic/react';
import { withTranslation } from 'react-i18next';
import { copyToClipboard, isMobileAppPlatform } from '../helpers';
import Divider from '../../styled/Divider';
import { Share } from '@capacitor/share';
import CopyIcon from '../../../assets/icons/copy-icon-grey.svg';
import ShareIcon from '../../../assets/icons/share-icon-grey.svg';
import mixpanel from '../../../services/mixpanel';
import { observer } from 'mobx-react';

function InvitePassengersActions(props) {
  const { t } = props;
  const {
    travelerAppStore: {
      chosenTransfer: { sharingLink }
    }
  } = useStores();
  const [showSuccessToast, setShowSuccessToast] = useState(false);

  const _copyToClipboard = async () => {
    const success = await copyToClipboard(sharingLink);
    if (success) {
      setShowSuccessToast(true);
      mixpanel.trackTravelerSharingLinkCopied();
    }
  };

  const renderCopySharingLink = () => {
    return (
      <StyledAction onClick={_copyToClipboard}>
        <img src={CopyIcon} alt="copy icon" />
        <Text1 color={theme.colors.base.graphiteLighter}>{t('invite_passengers.copy_sharing_link')}</Text1>
      </StyledAction>
    );
  };

  const shareInvitation = async () => {
    const text = sharingLink || '';

    mixpanel.trackTravelerInvitationShareOpened();

    let res;
    try {
      res = await Share.share({
        title: t('invite_passengers.invite_people_to_transfer'),
        text: text,
        dialogTitle: t('invite_passengers.invite_people_to_transfer')
      });
    } catch (error) {}

    if (res) {
      // Means the user copied the link from inside the share modal
      const linkCopied = res.activityType === '' || res.activityType === 'com.apple.UIKit.activity.CopyToPasteboard';

      if (linkCopied) {
        mixpanel.trackTravelerInvitationSharableLinkCopied();
      } else if (res.activityType) {
        mixpanel.trackTravelerInvitationShared({ App_name: res.activityType });
      }
    }
  };

  const renderShareButton = () => {
    if (isMobileAppPlatform()) {
      return (
        <StyledAction onClick={shareInvitation}>
          <img src={ShareIcon} alt="Invite passenger" />
          <Text1 color={theme.colors.base.graphiteLighter}>{t('invite_passengers.share_invitation_link')}</Text1>
        </StyledAction>
      );
    }
    return null;
  };

  return (
    <div>
      <ActionsWrapper>
        {sharingLink && (
          <Fragment>
            {renderCopySharingLink()}
            <Divider color={theme.colors.greys.grey1} />
            {renderShareButton()}
          </Fragment>
        )}
      </ActionsWrapper>

      <IonToast
        isOpen={showSuccessToast}
        message={t('invite_passengers.link_copied')}
        duration={3000}
        onDidDismiss={() => setShowSuccessToast(false)}
        cssClass="styled-toast-black"
      />
    </div>
  );
}

const ActionsWrapper = styled.div`
  margin-top: 32px;
  margin-bottom: var(--ion-safe-area-bottom);
`;

const StyledAction = styled.div`
  display: flex;
  align-items: center;
  padding: 18px 20px;
  font-weight: 600;
  color: red;
  cursor: pointer;

  &:active {
    background-color: ${theme.colors.greys.grey1};
  }

  @media (min-width: 768px) {
    &:hover {
      background-color: ${theme.colors.greys.grey06};
    }
  }

  img {
    margin-right: 20px;
  }
`;

export default withTranslation()(observer(InvitePassengersActions));
