import { BrowserAgent } from '@newrelic/browser-agent/loaders/browser-agent';
import { debugConsoleLog, isTruthy } from '../components/common/helpers';

const {
  REACT_APP_NEW_RELIC_INIT,
  REACT_APP_NEW_RELIC_INFO,
  REACT_APP_NEW_RELIC_LOADER_CONFIG,
  REACT_APP_NEW_RELIC_ENABLED
} = process.env;

const options = {
  init: JSON.parse(REACT_APP_NEW_RELIC_INIT), // NREUM.init
  info: JSON.parse(REACT_APP_NEW_RELIC_INFO), // NREUM.info
  loader_config: JSON.parse(REACT_APP_NEW_RELIC_LOADER_CONFIG) // NREUM.loader_config
};

class NewRelic {
  init() {
    if (isTruthy(REACT_APP_NEW_RELIC_ENABLED)) {
      new BrowserAgent(options);
      debugConsoleLog('New Relic initialized');
    }
  }
}

export default new NewRelic();
