import React, { useState } from 'react';
import { FlexContainer } from '../../styled/Wrappers';
import StarSVG from '../../svgs/Star';
import PropTypes from 'prop-types';
import theme from '../../../theme';

const Rating = (props) => {
  const { onRate, size = '32px', noHighlight = false } = props;

  const [rating, setRating] = useState(0);

  const handleRating = (value) => {
    setRating(value);

    if (onRate) {
      onRate(value);
    }
  };

  return (
    <FlexContainer alignItems="center" justifyContent="center" gap="16px">
      {Array.from({ length: 5 }, (_, i) => {
        return (
          <StarSVG
            key={i}
            fill={i < rating && !noHighlight ? theme.colors.cta.greenMedium : '#EAEBED'}
            width={size}
            height={size}
            onClick={() => handleRating(i + 1)}
            style={{ cursor: 'pointer' }}
          />
        );
      })}
    </FlexContainer>
  );
};

Rating.propTypes = {
  onRate: PropTypes.func,
  size: PropTypes.string,
  fill: PropTypes.string,
  noHighlight: PropTypes.bool // If true, the stars will not be highlighted
};

export default Rating;
