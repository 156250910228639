import React from 'react';
import { FlexContainer } from '../../styled/Wrappers';
import styled from 'styled-components';
import { Text2, Text3 } from '../../styled/Typography';
import CardIcon from '../../../assets/icons/talon-one-card-icon.svg';
import CoinsIcon from '../../../assets/icons/talon-one-coins-icon.svg';
import theme from '../../../theme';
import GiftIcon from '../../../assets/icons/talon-one-gift-icon.svg';
// import PersonIcon from "../../../assets/icons/talon-one-person-icon.svg";
import { t } from 'i18next';
import { format } from 'date-fns';
import { useStores } from '../../../stores/useStores';
import dateLocales from '../../../components/common/dateLocales';
import { observer } from 'mobx-react';
import { FRIEND_SIGN_UP_SESSION_PREFIX, PENDING_TRANSACTION_SUBLEDGER_ID } from '../../../services/talonOne';

const Transaction = ({ transaction }) => {
  const { type, amount, name, expiryDate, ruleName, customerSessionId, subledgerId } = transaction;
  const {
    travelerAppStore: { locale }
  } = useStores();

  const getTransactionIcon = () => {
    if (isTransactionPending()) {
      return CoinsIcon;
    }

    if (type === 'addition') {
      return GiftIcon;
    }

    // if type === "subtraction" or any other case that was not covered
    return CardIcon;
  };

  const getTransactionColor = () => {
    if (type === 'addition') {
      if (isTransactionPending()) {
        return '#E6943F';
      } else {
        return theme.colors.cta.greenDark;
      }
    } else {
      return '#F05B56';
    }
  };

  const isTransactionPending = () => {
    if (subledgerId === PENDING_TRANSACTION_SUBLEDGER_ID) {
      return true;
    }

    return false;
  };

  const renderPrice = () => {
    let prefix = '';

    if (!isTransactionPending()) {
      prefix = type === 'addition' ? '+' : '-';
    }

    return `${prefix}${amount.toFixed(2)}€`;
  };

  return (
    <TransactionContainer align="center" gap="12px" justify="space-between">
      <img src={getTransactionIcon()} alt="transaction icon" />

      <div style={{ flex: 1 }}>
        <Text2 weight="600">{ruleName || name}</Text2>

        {name && <Text3 color={theme.colors.base.graphiteLighter}>{name}</Text3>}
      </div>

      <div className="amount">
        <Text2 weight="600" color={getTransactionColor()}>
          {renderPrice()}
        </Text2>

        {isTransactionPending() && <Text3 color={getTransactionColor()}>{t('talon_one.pending')}</Text3>}

        {
          // Show expiration date only when transaction is not pending
          expiryDate !== 'unlimited' && (
            <Text3 color={theme.colors.base.graphiteLighter}>
              {
                // When the year of the expiration date is bigger than the current year, we also show the year
                t('talon_one.exp', {
                  date: format(
                    new Date(expiryDate),
                    new Date(expiryDate).getFullYear() > new Date().getFullYear() ? 'd MMM yy' : 'd MMM',
                    { locale: dateLocales[locale] }
                  )
                })
              }
            </Text3>
          )
        }
      </div>
    </TransactionContainer>
  );
};

const TransactionContainer = styled(FlexContainer)`
  margin-bottom: 25px;

  .amount {
    min-width: 50px;
    text-align: right;
  }
`;

export default observer(Transaction);
