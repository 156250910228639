import React, { Fragment } from 'react';
import { IonSpinner } from '@ionic/react';
import './styles.scss';
import Tick from '@/src/components/svgs/Tick';
import EditOutline from '@/src/components/svgs/EditOutline';
import { onExternalLinkClick } from '../../ExternalLink/ExternalLink';
import history from '../../history';
import { ActionButton, ActionButtonContainer, ActionText, IconWraper, ItalicText } from './styled';
import PropTypes from 'prop-types';
import { externalLinkRedirection } from '@/src/pages/Trips/Trip/helpers';

const AlertComponent = ({
  editText,
  link,
  validateText,
  splittedMessageAndValue,
  addCorrectInfocallback,
  onValidatePress,
  isLoading,
  order,
  isDetailsPage,
  points,
  subtitleMessage,
  alertName
}) => {
  if (link) {
    return (
      <div color="danger" className="message">
        <p className="error-message">
          {splittedMessageAndValue[0]}
          {splittedMessageAndValue[1] && <span className="wrong-data">{`: ${splittedMessageAndValue[1]}`}</span>}
          {subtitleMessage && <ItalicText>{subtitleMessage}</ItalicText>}
        </p>

        <ActionButtonContainer>
          {/* We hide the resolve button when the error has to do with the flight number field */}
          {alertName !== 'pre_transfer_name_blank' && (
            <ActionButton
              className="ion-justify-content-center"
              onClick={onValidatePress}
              kind="primary"
              position="fixed"
            >
              {isLoading.open === true && isLoading.key === order ? (
                <IonSpinner className="white-spinner" name="crescent" />
              ) : (
                <Fragment>
                  <IconWraper status={isDetailsPage}>
                    <Tick fill="#fff" />
                  </IconWraper>

                  <ActionText kind="primary" hide={isDetailsPage}>
                    {validateText}
                  </ActionText>
                </Fragment>
              )}
            </ActionButton>
          )}

          <ActionButton
            className="ion-justify-content-center"
            onClick={async (e) => {
              addCorrectInfocallback(e);

              if (points === 'internal') {
                history.push('/profile/edit');
              } else {
                const isWebLink = await onExternalLinkClick(e, link);
                if (isWebLink) {
                  externalLinkRedirection(link);
                }
              }
            }}
            kind="secondary"
            detail="false"
          >
            <IconWraper status={isDetailsPage}>
              <EditOutline fill="#2d3b4e" />
            </IconWraper>
            <ActionText hide={isDetailsPage}>{editText}</ActionText>
          </ActionButton>
        </ActionButtonContainer>
      </div>
    );
  }

  return (
    <button
      onClick={(e) => {
        e.preventDefault();
      }}
      className="message"
    >
      <p className="error-message">
        {splittedMessageAndValue[0]}
        {splittedMessageAndValue[1] && <span className="wrong-data">{`: ${splittedMessageAndValue[1]}`}</span>}
      </p>
    </button>
  );
};

AlertComponent.defaultProps = {
  editText: '',
  link: '',
  validateText: '',
  splittedMessageAndValue: [],
  addCorrectInfocallback: () => {},
  onValidatePress: () => {},
  isLoading: {},
  order: 0,
  isDetailsPage: false,
  points: '',
  subtitleMessage: '',
  alertName: ''
};

AlertComponent.propTypes = {
  editText: PropTypes.string,
  link: PropTypes.string,
  validateText: PropTypes.string,
  splittedMessageAndValue: PropTypes.array,
  addCorrectInfocallback: PropTypes.func,
  onValidatePress: PropTypes.func,
  isLoading: PropTypes.object,
  order: PropTypes.number,
  isDetailsPage: PropTypes.bool,
  points: PropTypes.string,
  subtitleMessage: PropTypes.string,
  alertName: PropTypes.string
};

export default AlertComponent;
