import React from 'react';
import styled from 'styled-components';
import { Text2 } from '../../styled/Typography';
import theme from '../../../theme';
import { useStores } from '../../../stores/useStores';
import { observer } from 'mobx-react';
import { t } from 'i18next';

const Balance = () => {
  const {
    talonOneStore: { balance }
  } = useStores();

  return (
    <BalanceContainer>
      <Text2>{t('talon_one.on_your_balance')}</Text2>

      <p className="balance-value">€{balance.activePoints ? balance.activePoints.toFixed(2) : '0.00'}</p>

      {/* {
        balance.activePoints > 0 && (
          <p className="expiration">
            {t("talon_one.no_expiration_date")}
          </p>
        )
      } */}
    </BalanceContainer>
  );
};

const BalanceContainer = styled.div`
  text-align: center;
  margin-bottom: 40px;

  .balance-value {
    font-size: 36px;
    font-weight: 600;
  }

  .expiration {
    font-size: 11px;
    color: ${theme.colors.base.graphiteLighter};
    margin-top: 4px;
  }
`;

export default observer(Balance);
