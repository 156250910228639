import React, { useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import mixpanel from '../../../services/mixpanel';
import TermsAndConditionsEnglish from './TermsAndConditionsEnglish';
import TermsConditionsGreek from './TermsAndConditionsGreek';

const TermsAndConditions = ({ i18n }) => {
  useEffect(() => {
    mixpanel.trackTermsAndConditionsView();
  }, []);

  // TODO: add partner id for greek translations
  return i18n.language === 'el' ? <TermsConditionsGreek /> : <TermsAndConditionsEnglish />;
};

export default withTranslation()(TermsAndConditions);
