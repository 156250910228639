import styled from 'styled-components';
import theme from '../../../theme';
import { NOTIFICATION_TYPES } from '@/src/constants';
import truncateLines from '@/src/helpers/truncateLines';

const getNotificationBackgroundColor = (type) => {
  switch (type) {
    case NOTIFICATION_TYPES.SUCCESS:
      return theme.colors.background.greenLight;
    case NOTIFICATION_TYPES.WARNING:
      return theme.colors.background.orangeLight;
    case NOTIFICATION_TYPES.MUTTED:
      return theme.colors.background.grey;
    default:
      return theme.colors.background.greenLight;
  }
};

const getNotificationTitleColor = (type) => {
  switch (type) {
    case NOTIFICATION_TYPES.SUCCESS:
      return theme.colors.base.graphite;
    case NOTIFICATION_TYPES.WARNING:
      return '#E6943F';
    case NOTIFICATION_TYPES.MUTTED:
      return theme.colors.base.graphite;
    default:
      return theme.colors.base.graphite;
  }
};

export const NotificationStyled = styled.div`
  margin: ${({ margin }) => (margin ? margin : '0')};
  background-color: ${({ type }) => getNotificationBackgroundColor(type)};
  padding: ${({ padding }) => padding};
  display: flex;
  align-items: center;
  gap: ${({ gap }) => gap};
  border-radius: 8px;
  cursor: ${({ link, onClick }) => (link || onClick ? 'pointer' : 'default')};
  max-width: ${({ maxWidth }) => maxWidth};

  .text-container {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    text-wrap: wrap;

    ${({ maxLines }) => maxLines && truncateLines(maxLines, '18px')};

    .notification-title {
      color: ${({ type }) => getNotificationTitleColor(type)};
      margin-bottom: 4px;
      line-height: 18px;
    }

    .notification-subtitle {
      line-height: 18px;
    }
  }

  .close-icon {
    cursor: pointer;
  }

  .notification-icon {
    width: 20px;
    height: 20px;
  }
`;
