export const HVA_SRIDES_LIMIT = 3;

export const NOTIFICATION_TYPES = {
  SUCCESS: 'success',
  WARNING: 'warning',
  MUTTED: 'mutted'
};

//
// Mixpanel tracking
//
export const WELCOME_MIXPANEL_INSTANCE_NAME = '__welcome_mixpanel';
export const MIXPANEL_DISTINCT_ID_PARAM = 'mp_distinct_id';

//
// Local storage keys
//
export const MERGE_ACCOUNTS_CONFIRMATION_TIMER = 'merge_accounts_confirmation_timer';

//
// Query Params
//

// The url that the user should be redirected after login
export const RETURN_URL_QUERY_PARAM = 'return_url';
export const EXPIRED_TOKEN_QUERY_PARAM = 'expired_token';
