import styled from 'styled-components';
import theme from '../../theme';
import { Link } from 'react-router-dom';

const sizes = {
  xl: {
    padding: '19px 28px',
    fontSize: '16px',
    lineHeight: '18px'
  },
  lg: {
    padding: '15px 24px',
    fontSize: '16px',
    lineHeight: '18px'
  },
  md: {
    padding: '12px 20px',
    fontSize: '16px',
    lineHeight: '18px'
  },
  sm: {
    padding: '10px 15px',
    fontSize: '12px',
    lineHeight: '16px'
  }
};

const buttonStyling = (props) => ({
  borderRadius: props.round ? '50px' : '4px',
  border: 0,
  minWidth: props.width ? props.width : props.block ? '100%' : '180px',
  maxWidth: props.block ? '100%' : 'max-content',
  padding: sizes[props.size] ? sizes[props.size].padding : sizes.md.padding,
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '8px',
  margin: props.margin ? props.margin : props.center ? '0 auto' : 'initial',

  fontSize: props.fontSize ? props.fontSize : sizes[props.size] ? sizes[props.size].fontSize : sizes.md.fontSize,
  fontWeight: 600,
  lineHeight: sizes[props.size] ? sizes[props.size].lineHeight : sizes.md.lineHeight,
  letterSpacing: 'normal',
  textAlign: 'center',

  '&:active': {
    filter: 'brightness(1) !important'
  },

  '&:hover': {
    filter: 'brightness(1.05)'
  }
});

// Button with the basic styling that all buttons have
const _StyledButton = styled('button')(buttonStyling);
const _StyledExternalLinkButton = styled('a')(buttonStyling);
const _StyledInternalLinkButton = styled(Link)(buttonStyling);

const StyledLinkWrapper = styled.div`
  margin: ${(props) => props.margin || '0'};
  text-align: ${(props) => (props.center ? 'center' : 'initial')};
`;

const StyledLink = styled(Link)`
  display: ${(props) => (props.block === 'true' ? 'block' : 'inline-block')};
`;

// Primary Button
const buttonPrimaryStyling = (props) => ({
  backgroundColor: props.bgcolor || theme.colors.cta.greenMedium,
  color: `${props.color || '#fff'} !important`
});
const ButtonPrimary = styled(_StyledButton)(buttonPrimaryStyling);
const ExternalLinkButtonPrimary = styled(_StyledExternalLinkButton)(buttonPrimaryStyling);
const InternalLinkButtonPrimary = styled(_StyledInternalLinkButton)(buttonPrimaryStyling);

// Secondary Button
const buttonSecondaryStyling = (props) => ({
  backgroundColor: props.bgcolor || theme.colors.base.graphite,
  color: `${props.color || '#fff'} !important`,
  '&:hover': {
    filter: 'brightness(1.2)'
  }
});
const ButtonSecondary = styled(_StyledButton)(buttonSecondaryStyling);
const ExternalLinkButtonSecondary = styled(_StyledExternalLinkButton)(buttonSecondaryStyling);
const InternalLinkButtonSecondary = styled(_StyledInternalLinkButton)(buttonSecondaryStyling);

// Outlined Button
const buttonOutlinedStyling = (props) => ({
  backgroundColor: 'transparent',
  outline: `1px solid ${props.borderColor || theme.colors.base.graphite} !important`,
  color: `${props.color || theme.colors.base.graphite} !important`
});
const ButtonOutlined = styled(_StyledButton)(buttonOutlinedStyling);
const ExternalLinkButtonOutlined = styled(_StyledExternalLinkButton)(buttonOutlinedStyling);
const InternalLinkButtonOutlined = styled(_StyledInternalLinkButton)(buttonOutlinedStyling);

// Grey Button
const buttonGreyStyling = (props) => ({
  backgroundColor: theme.colors.cta.grey,
  color: `${props.color || theme.colors.base.graphiteLight} !important`,
  '&:hover': {
    filter: 'brightness(1.02)'
  }
});
const ButtonGrey = styled(_StyledButton)(buttonGreyStyling);
const ExternalLinkButtonGrey = styled(_StyledExternalLinkButton)(buttonGreyStyling);
const InternalLinkButtonGrey = styled(_StyledInternalLinkButton)(buttonGreyStyling);

// Text Button
const buttonTextStyling = (props) => ({
  backgroundColor: 'transparent',
  border: 'none',
  color: `${props.color || theme.colors.cta.greenMedium} !important`
});
const ButtonText = styled(_StyledButton)(buttonTextStyling);
const ExternalLinkButtonText = styled(_StyledExternalLinkButton)(buttonTextStyling);
const InternalLinkButtonText = styled(_StyledInternalLinkButton)(buttonTextStyling);

/**
 * A button that is wrapped in a Link component.
 * It can be used with all the different button types.
 *
 * @deprecated Use InternalLinkButton[VARIANT] instead
 * @param {*} props
 * @example
 * <LinkButton to="/">
 *   <ButtonPrimary>
 *     Home
 *   </ButtonPrimary>
 * </LinkButton>
 * @returns
 */
const LinkButton = (props) => {
  const { children, center, margin, block, to, onClick = () => {} } = props;

  return (
    <StyledLinkWrapper center={center} margin={margin}>
      <StyledLink to={to} block={block && 'true'} onClick={onClick}>
        {children}
      </StyledLink>
    </StyledLinkWrapper>
  );
};

export {
  ButtonPrimary,
  ExternalLinkButtonPrimary,
  InternalLinkButtonPrimary,
  ButtonSecondary,
  ExternalLinkButtonSecondary,
  InternalLinkButtonSecondary,
  ButtonOutlined,
  ExternalLinkButtonOutlined,
  InternalLinkButtonOutlined,
  ButtonGrey,
  ExternalLinkButtonGrey,
  InternalLinkButtonGrey,
  ButtonText,
  ExternalLinkButtonText,
  InternalLinkButtonText,
  LinkButton
};
