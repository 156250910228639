import { IonImg, IonToolbar } from '@ionic/react';
import styled from 'styled-components';
import theme from '../../../theme';

export const SimpleNavbarStyled = styled(IonToolbar)`
  padding: ${({ padding }) => padding || '0px 20px'} !important;
  --background: ${({ background }) => background || '#fff'};
  --min-height: ${({ height }) => height};
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const LeftIonButtons = styled.div`
  gap: 16px;
  position: absolute;
  left: 0;
  display: flex;
  align-items: center;
`;

export const RightIonButtons = styled.div`
  gap: 8px;
  position: absolute;
  right: 0;
  top: 0;
  height: 57px;
  padding: 8px 0;
  display: flex;
  align-items: center;
`;

export const MainLinks = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${({ height }) => height};
  text-align: center;

  @media (min-width: ${theme.breakpoints[1]}px) {
    justify-content: center;
  }
`;

export const LogoStyled = styled(IonImg)`
  max-width: 147px;
  height: ${({ height }) => height};
  padding: 8px 0;

  @media (min-width: ${theme.breakpoints[0]}px) {
    max-width: 175px;
  }
`;

export const PoweredByWrapper = styled.div`
  svg {
    max-width: 120px;
    height: 30px;

    @media (min-width: ${theme.breakpoints[1]}px) {
      max-width: 130px;
    }

    @media (min-width: ${theme.breakpoints[2]}px) {
      max-width: 173px;
    }
  }
`;
