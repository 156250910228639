import React, { Component } from 'react';
import styled from 'styled-components';
import { ButtonBase } from '../../../../components/styled/Buttons';
import noUpcomingTripsImage from '../../../../assets/branding/no_upcoming_trips.jpg';
import { StyledCard, StyledIonToolbar, StyledItem } from '../../../../components/styled/IonElements';
import { inject, observer } from 'mobx-react';
import { HeaderLogo } from '../../../../components/styled/Images';
import { withTranslation } from 'react-i18next';
import { FullHeightOverlay } from '../../../../components/styled/Wrappers';
import { Paragraph } from '../../../../components/styled/Typography';
import HeaderRightContainer from '../../../../components/common/HeaderRightContainer';
import mixpanel from '../../../../services/mixpanel';

class NoUpcomingTrips extends Component {
  constructor(props) {
    super(props);
    this.state = {
      destinationModalVisibility: false
    };
  }

  onModalChangeVisibilty = (modalName) => {
    this.setState((state) => ({ [modalName]: !state[modalName] }));
  };

  render() {
    const {
      children,
      travelerAppStore: { isAuthorised },
      t,
      brandedSettingsStore: {
        isPartner,
        brandedSettings: {
          brandLogoForTravelerAppUrl,
          coBrandingThemeProperties: { mainColor }
        }
      }
    } = this.props;

    return (
      <>
        <StyledIonToolbar image={noUpcomingTripsImage} marginBottom="auto">
          <FullHeightOverlay />
          <HeaderLogo src={brandLogoForTravelerAppUrl} maxWidth={isPartner ? '70px' : '100%'} />
          <HeaderRightContainer />
        </StyledIonToolbar>
        <StyledCard height="270px" margin="0" padding="60px 20px">
          <Title weight="700" maxWidth="100%">
            {t('my_trip.no_upcoming_trips')}
          </Title>
          <Paragraph margin=" 20px" maxWidth="100%">
            {isAuthorised ? t('my_trip.its_a_time') : t('trips.if_you_have_a_booking')}
          </Paragraph>
          {isAuthorised ? (
            <StyledItem routerLink="/book" onClick={mixpanel.trackBookATransferClick} lines="none" detail={false}>
              <ButtonBase bgcolor={mainColor} style={{ margin: '0 auto' }}>
                <span>{t('book.book_a_transfer')}</span>
              </ButtonBase>
            </StyledItem>
          ) : (
            <StyledItem
              routerLink="/login"
              onClick={mixpanel.trackSignInClick}
              lines="none"
              detail={false}
              detailIcon="none"
            >
              <ButtonBase bgcolor={mainColor} style={{ margin: '0 auto' }}>
                <span>{t('sign_in.sign_in')}</span>
              </ButtonBase>
            </StyledItem>
          )}
        </StyledCard>
        {children}
        <div className="mainView-lifter" />
      </>
    );
  }
}

export default withTranslation()(inject('travelerAppStore', 'brandedSettingsStore')(observer(NoUpcomingTrips)));

const Title = styled(Paragraph)`
  font-size: 24px;
  margin-bottom: 13px;
`;
