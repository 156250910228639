import React, { Fragment, useEffect } from 'react';
import { getDriver, scrollToLastMessage } from '../helpers';
import mixpanel from '@/src/services/mixpanel';
import { Text2 } from '@/src/components/styled/Typography';
import { Channel, Thread, MessageInput, MessageList, Window, withChatContext } from 'stream-chat-react';
import { withTranslation } from 'react-i18next';
import NoMessages from '../NoMessages';
import carIcon from '@/src/assets/icons/car_icon.svg';
import PropTypes from 'prop-types';
import { useStores } from '@/src/stores/useStores';
import { observer } from 'mobx-react';
import { ChannelHeading, ChannelOuter, ChannelTitle, LinkToTransfer } from './desktopChannel.styled';
import debounce from '@/src/helpers/debounce';

const scrollToLastMessageDeboucned = () => debounce(scrollToLastMessage, 100);

function DesktopChannel(props) {
  const { channel, t, onViewTransferClick } = props;
  let channelTitle = null;

  const { messagesStore } = useStores();

  if (channel) {
    const driver = getDriver(channel);
    const driverFirstName = driver?.first_name || driver?.name; // change to first_name
    channelTitle = `Driver ${driverFirstName}`;

    mixpanel.trackChatOpenDebounced.init({
      Chat_Id: channel.id,
      Order_Name: channel.data.name,
      Order_Id: channel.data.service.id,
      Is_Chat_Active: !channel.data.frozen,
      Created_By: channel.data.created_by.member_type
    });
  }

  // Scroll to bottom when messages are ready
  useEffect(() => {
    scrollToLastMessageDeboucned().init();
  }, [channel]);

  return (
    <Fragment>
      {channel ? (
        <ChannelHeading key="channel-heading">
          <ChannelTitle>{channelTitle}</ChannelTitle>
          <LinkToTransfer
            onClick={(e) => {
              onViewTransferClick(e, channel);
            }}
          >
            <img style={{ marginRight: '8px' }} alt={t('messages.cta_to_transfer')} src={carIcon} />
            <Text2>{t('messages.cta_to_transfer')}</Text2>
          </LinkToTransfer>
        </ChannelHeading>
      ) : null}

      <ChannelOuter>
        <Channel EmptyPlaceholder={<NoMessages messagesStore={messagesStore} />} acceptedFiles={['image/*']}>
          <Window>
            <MessageList messageActions={[]} />
            {channel && channel.data.frozen ? null : <MessageInput focus grow />}
          </Window>
          <Thread />
        </Channel>
      </ChannelOuter>
    </Fragment>
  );
}

DesktopChannel.propTypes = {
  channel: PropTypes.object,
  messagesStore: PropTypes.object,
  onViewTransferClick: PropTypes.func,
  t: PropTypes.func
};

export default withTranslation()(withChatContext(observer(DesktopChannel)));
