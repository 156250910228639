import React, { Fragment, useState } from 'react';
import { FlexContainer } from '../../styled/Wrappers';
import { ButtonBase } from '../../styled/Buttons';
import { useStores } from '../../../stores/useStores';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { IonToast } from '@ionic/react';
import { withTranslation } from 'react-i18next';
import TravelerApi from '../../../API';
import RedWarningIcon from '../../../assets/icons/red-warning-icon.svg';
import mixpanel from '../../../services/mixpanel';
import { logError } from '@/src/helpers/log';
import Input from '../../formFields/Input';
import FormField from '../../formFields/FormField';

function InvitePassengerForm(props) {
  const { t, onSuccess } = props;

  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState();
  const [showSuccessToast, setShowSuccessToast] = useState();
  const [showErrorToast, setShowErrorToast] = useState();

  const {
    brandedSettingsStore: {
      brandedSettings: {
        coBrandingThemeProperties: { mainColor }
      }
    },
    travelerAppStore: {
      chosenTransfer: { id, invitations, numberOfPassengers },
      setChosenTransferInvitations
    }
  } = useStores();

  const validateEmail = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleChange = (e) => {
    setEmail(e.target.value);
    setEmailError(null);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const isValidEmail = validateEmail();

    if (isValidEmail) {
      setEmailError(null);
    } else {
      setEmailError(t('invite_passengers.invalid_email_correct_errors'));
      return;
    }

    TravelerApi.createInvitation(email, id, 'Transfer')
      .then((data) => {
        // Keep only invitations with email
        setChosenTransferInvitations(data.filter((invitation) => invitation.email));
        setShowSuccessToast(true);
        setEmail('');

        mixpanel.trackTravelerInvitationEmailShared();

        onSuccess && onSuccess();
      })
      .catch((error) => {
        if (error.response?.data?.email?.message?.includes('already invited')) {
          setEmailError(t('invite_passengers.person_already_invited'));
        } else {
          setShowErrorToast(true);
          logError('Create passenger invitation error', error);
        }
      });
  };

  return (
    <Fragment>
      <form onSubmit={handleSubmit} noValidate>
        <div style={{ width: '100%', marginBottom: '16px' }}>
          <FormField
            margin
            errorText={
              emailError ? (
                <FlexContainer align="center" className="form-field-error">
                  <img src={RedWarningIcon} alt="Error icon" />
                  {emailError}
                </FlexContainer>
              ) : null
            }
          >
            <FlexContainer>
              <Input
                onChange={handleChange}
                placeholder={t('invite_passengers.enter_passenger_email')}
                value={email}
                error={!!emailError}
                type="email"
                padding="12px"
                margin="0 15px 0 0"
              />
              <ButtonBaseStyled
                bgcolor={mainColor}
                height="100%"
                type="submit"
                disabled={!email || !!emailError || invitations.length >= numberOfPassengers - 1}
              >
                <span>{t('invite_passengers.invite')}</span>
              </ButtonBaseStyled>
            </FlexContainer>
          </FormField>
        </div>
      </form>

      <IonToast
        isOpen={showSuccessToast}
        message={t('invite_passengers.invitation_sent_successfully')}
        duration={3000}
        onDidDismiss={() => setShowSuccessToast(false)}
        cssClass="styled-toast-black"
      />
      <IonToast
        isOpen={showErrorToast}
        message={t('alert.something_went_wrong')}
        duration={3000}
        onDidDismiss={() => setShowErrorToast(false)}
        cssClass="styled-toast-error"
      />
    </Fragment>
  );
}

const ButtonBaseStyled = styled(ButtonBase)`
  height: 50px;
`;

InvitePassengerForm.propTypes = {
  onSuccess: PropTypes.func
};

export default withTranslation()(InvitePassengerForm);
