import styled from 'styled-components';

const CustomRadioInput = styled.label`
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  padding: 4px 0;
  font-size: 14px;
  line-height: 20px;
`;

const RadioInputWrapper = styled.div`
  width: 20px;
  height: 20px;
  position: relative;
  flex-shrink: 0;
  flex-grow: 0;

  span {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 1px solid rgba(45, 59, 78, 0.15);
    background-color: #ffffff;
  }

  input {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 1;

    &:checked + span {
      background-color: ${(props) => props.color};
      border-color: ${(props) => props.color};

      &::after {
        content: '';
        width: 8px;
        height: 8px;
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        border-radius: 50%;
        background-color: #ffffff;
        transform: translate(-50%, -50%);
      }
    }
  }
`;

export { CustomRadioInput, RadioInputWrapper };
