import React from 'react';
import defaultAvatar from '../../../assets/icons/avatar.svg';
import { IonAvatar } from '@ionic/react';
import PropTypes from 'prop-types';

function Avatar(props) {
  const { src = defaultAvatar, size = '64px', alt = '', onClick = () => {}, margin = '0px' } = props;

  const avatarStyle = {
    margin: margin,
    width: size,
    height: size,
    cursor: props.onClick ? 'pointer' : 'default', // Set cursor pointer if user set an onClick listenner
    background: '#fff',
    flexShrink: 0,
    cursor: 'inherit'
  };

  return (
    <>
      <IonAvatar onClick={onClick} style={avatarStyle}>
        <img
          onError={(ev) => {
            ev.target.src = defaultAvatar;
          }}
          src={src}
          alt={alt}
        />
      </IonAvatar>
    </>
  );
}

Avatar.propTypes = {
  src: PropTypes.string,
  size: PropTypes.string,
  alt: PropTypes.string,
  onClick: PropTypes.func,
  margin: PropTypes.string
};

export default Avatar;
