import styled from 'styled-components';
import theme from '../../../theme';
import { Text1 } from '../../styled/Typography';
import { ReactComponent as DownloadIcon } from '../../../assets/icons/download-icon.svg';
import { useStores } from '../../../stores/useStores';
import { withTranslation } from 'react-i18next';
import mixpanel from '../../../services/mixpanel';

function ReceiptModalFooter(props) {
  const { t } = props;

  const {
    travelerAppStore: {
      chosenTransfer: { receiptUrl }
    }
  } = useStores();

  return (
    <div>
      <ActionsWrapper>
        <StyledAction href={receiptUrl} target="_blank" onClick={() => mixpanel.trackDownloadReceiptClick()}>
          <DownloadIcon />
          <Text1 color={theme.colors.base.graphiteLighter}>{t('trip_details.download_receipt')}</Text1>
        </StyledAction>
      </ActionsWrapper>
    </div>
  );
}

export default withTranslation()(ReceiptModalFooter);

const ActionsWrapper = styled.div`
  margin-top: 12px;
  margin-bottom: var(--ion-safe-area-bottom);
`;

const StyledAction = styled.a`
  display: flex;
  align-items: center;
  padding: 18px 0;
  font-weight: 600;
  color: red;
  cursor: pointer;
  gap: 20px;
  justify-content: center;

  &:active {
    background-color: ${theme.colors.greys.grey1};
  }

  img {
    margin-right: 20px;
  }
`;
