import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import styled from 'styled-components';
import { dateLocale } from '../../../../components/common/helpers';
import { IonSegment, IonSegmentButton } from '@ionic/react';
import i18n from '../../../../services/i18n';
import { Fragment, useEffect, useState } from 'react';
import useWindowSize from '../../../../hooks/useWindowSize';
import mixpanel from '../../../../services/mixpanel';
import theme from '../../../../theme';

const TransferListNav = (props) => {
  const {
    travelerAppStore: { transfers, chosenTransfer, setChosenTransfer, currentTrip },
    brandedSettingsStore: {
      brandedSettings: {
        coBrandingThemeProperties: { mainMediumColor }
      }
    },
    history
  } = props;

  const windowSize = useWindowSize();
  const [isSmoothScroll, setIsSmoothScroll] = useState(false);

  const handleClick = (transfer) => {
    mixpanel.trackTransferDetailsClick({ Clicked_Item: 'header picker' });
    setChosenTransfer(transfer);
    history.push(`/trip/${currentTrip.id}/transfer/${transfer.id}`, { tripListItem: true });
    handleItemViewport(transfer.id);
  };

  // Scrollable IonSegment has a bug that scroll the hole window to the top
  const handleItemViewport = (transferId) => {
    const element = document.getElementById(`transfer-nav-${transferId}`);
    const rect = element?.getBoundingClientRect();
    const isInView = rect?.left >= 0 && rect?.right <= (window.innerWidth || document.documentElement.clientWidth);
    if (element && !isInView) {
      element.scrollIntoView({
        behavior: isSmoothScroll ? 'smooth' : 'instant',
        block: 'nearest',
        inline: 'start'
      });
    }
    if (!isSmoothScroll) setIsSmoothScroll(true);
  };

  useEffect(() => {
    setTimeout(() => {
      handleItemViewport(chosenTransfer.id);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Fragment>
      {transfers.length > 1 && (
        <TransferNavListSegment
          value={chosenTransfer.id}
          style={{ display: windowSize.width > theme.breakpoints[0] && 'none' }}
          swipeGesture={false}
        >
          {transfers.map((transfer) => (
            <TranferNavItem
              id={`transfer-nav-${transfer.id}`}
              key={transfer.id}
              value={transfer.id}
              indicatorColor={mainMediumColor}
              onClick={() => handleClick(transfer)}
            >
              {transfer.fromDatetime && dateLocale(transfer.fromDatetime, transfer.timeZone, 'd MMM', i18n.language)}
            </TranferNavItem>
          ))}
        </TransferNavListSegment>
      )}
    </Fragment>
  );
};

export default withRouter(inject('travelerAppStore', 'brandedSettingsStore')(observer(TransferListNav)));

const TransferNavListSegment = styled(IonSegment)`
  width: 100%;
  justify-content: flex-start;
  --min-height: 38px;
  background-color: #ffffff;
  overflow-x: auto;
`;

const TranferNavItem = styled(IonSegmentButton)`
  width: auto;
  min-height: 38px;
  font-weight: 600;
  letter-spacing: 0;
  text-transform: capitalize;
  min-width: 105px;
  --indicator-color: ${(props) => props.indicatorColor};
`;
