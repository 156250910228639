import { SwiperSlide as Slide } from 'swiper/react';
import { SwiperStyled } from './index.styled';

import { Pagination } from 'swiper/modules';

export const Slider = ({ children, ...props }) => {
  return (
    <SwiperStyled modules={[Pagination]} {...props}>
      {children}
    </SwiperStyled>
  );
};

export { Slide };
