import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { observer, inject } from 'mobx-react';
import styled from 'styled-components';
import theme from '../../../theme';
import GreenArrow from '../GreenArrow';
import { IonCardContent } from '@ionic/react';
import { Text1, Text3 } from '../../../components/styled/Typography';
import SkeletonProfile from '../../../components/common/ScarySkeletons/SkeletonProfile';
import { StyledItem } from '../../../components/styled/IonElements';
import ShortProfileInfo from '../ShortProfileInfo';
import LogoutModal from './LogoutModal';
import AuthenticationService from '../../../services/authentication.js';
import { withTranslation } from 'react-i18next';
import mixpanel from '../../../services/mixpanel';
import ReferralBanner from '../../../components/common/TalonOne/ReferralBanner';
import talonOne from '../../../services/talonOne';
import DuplicateTravelersNotification from '@/src/components/common/DuplicateTravelers/DuplicateTravelersNotification';
import PropTypes from 'prop-types';

class LoggedInUser extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showLogoutPopup: false
    };
  }

  async componentDidMount() {
    const {
      travelerAppStore: {
        fetchProfileInformation,
        profileInformation,
        travelerInfo: { id: currentTravelerId }
      },
      talonOneStore: { balance }
    } = this.props;

    if (profileInformation.id === undefined || profileInformation.id !== currentTravelerId) {
      await fetchProfileInformation();
    }

    if (balance.activePoints === undefined) {
      // Fetch balances
      talonOne.getLoyaltyBalances();
    }
  }

  openLogoutModal = () => {
    mixpanel.trackLogoutClick();
    this.setState({ showLogoutPopup: true });
  };

  onLogout = () => {
    const {
      travelerAppStore: { logout }
    } = this.props;

    this.togglePopups();

    logout()
      .then((data) => {
        this.onLogoutSuccess(data);
      })
      .catch(() => {});
  };

  onLogoutSuccess = async (data) => {
    await AuthenticationService.onLogoutSuccess();
    this.props.history.push('/book');
  };

  togglePopups = () => {
    const { showLogoutPopup } = this.state;
    this.setState({
      showLogoutPopup: !showLogoutPopup
    });
  };

  renderBanner = () => {
    const {
      t,
      travelerAppStore: { isAllowedToMergeAccounts },
      duplicateTravelersStore: { duplicateTravelersInfo, getDuplicateTravelersLoading }
    } = this.props;

    // Avoid flickering when user has duplicate accounts but we haven't fetched the info yet
    if (isAllowedToMergeAccounts && getDuplicateTravelersLoading) {
      return null;
    }

    return isAllowedToMergeAccounts ? (
      <DuplicateTravelersNotification
        margin="16px 20px"
        title={t('Action required')}
        subtitle={t('duplicate_travelers.merge_x_accounts_detected', {
          count: duplicateTravelersInfo.duplicateAccounts?.length
        })}
      />
    ) : (
      <ReferralBanner margin="16px 20px" />
    );
  };

  render() {
    const {
      isExpanded,
      travelerAppStore: { profileInfoLoading },
      history,
      isSide,
      location,
      t,
      brandedSettingsStore: {
        brandedSettings: {
          coBrandingThemeProperties: { mainColor }
        }
      },
      talonOneStore: { balance }
    } = this.props;
    const { showLogoutPopup } = this.state;

    return profileInfoLoading ? (
      <SkeletonProfile />
    ) : (
      <ProfileContainer isExpanded={isExpanded}>
        <LogoutModal isOpen={showLogoutPopup} onClose={this.togglePopups} logout={() => this.onLogout()} />

        <ShortProfileInfo isExpanded={isExpanded} />

        {!isSide && this.renderBanner()}

        <SubpagesContainer>
          <CustomItem
            color={mainColor}
            lines="none"
            showBorder
            padding="0"
            margin="0"
            onClick={() => history.push('/profile/rewards_and_wallet')}
            isSide={isSide}
            isCurrent={location.pathname === '/profile/rewards_and_wallet'}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                flexDirection: 'row',
                width: '100%',
                marginRight: '12px'
              }}
            >
              <Text1 weight="500">{t('profile.rewards_and_wallet')}</Text1>
              <div className="wallet-bubble">
                <Text3>€{balance.activePoints ? balance.activePoints.toFixed(2) : '0.00'}</Text3>
              </div>
            </div>

            <GreenArrow />
          </CustomItem>

          <CustomItem
            color={mainColor}
            lines="none"
            showBorder
            padding="0"
            margin="0"
            onClick={() => history.push('/profile/refer_a_friend')}
            isSide={isSide}
            isCurrent={location.pathname === '/profile/refer_a_friend'}
          >
            <Text1 weight="500">{t('profile.refer_a_friend')}</Text1>
            <GreenArrow />
          </CustomItem>

          <CustomItem
            color={mainColor}
            lines="none"
            showBorder
            padding="0"
            margin="0"
            onClick={() => history.push('/profile/trip_history')}
            isSide={isSide}
            isCurrent={location.pathname === '/profile/trip_history'}
          >
            <Text1 weight="500">{t('profile.trip_history')}</Text1>
            <GreenArrow />
          </CustomItem>

          <CustomItem
            color={mainColor}
            lines="none"
            showBorder
            padding="0"
            margin="0"
            onClick={() => history.push('/profile/terms_and_conditions')}
            isSide={isSide}
            isCurrent={location.pathname === '/profile/terms_and_conditions'}
          >
            <Text1 weight="500">{t('profile.legal_information')}</Text1>
            <GreenArrow />
          </CustomItem>

          <CustomItem
            color={mainColor}
            lines="none"
            showBorder
            padding="0"
            margin="0"
            onClick={() => history.push('/profile/account_and_data')}
            isSide={isSide}
            isCurrent={location.pathname === '/profile/account_and_data'}
          >
            <Text1 weight="500">{t('profile.account_and_data')}</Text1>
            <GreenArrow />
          </CustomItem>
        </SubpagesContainer>
        <Text1
          weight="500"
          color={theme.colors.greys.grey6}
          onClick={this.openLogoutModal}
          style={{ paddingLeft: '20px', paddingBottom: '32px', cursor: 'pointer', width: 'fit-content' }}
        >
          {t('profile.logout')}
        </Text1>
      </ProfileContainer>
    );
  }
}

const ProfileContainer = styled(IonCardContent)`
  padding: 0;
`;

const SubpagesContainer = styled.div`
  margin: 32px auto;
`;

const CustomItem = styled(StyledItem)`
  border-left: ${(props) => props.isSide && props.isCurrent && `3px solid ${(props) => props.color}`};
  padding: 0 20px;
  cursor: pointer;

  .wallet-bubble {
    background: ${theme.colors.background.grey};
    border-radius: 40px;
    padding: 6px 8px;
  }
`;

LoggedInUser.propTypes = {
  isExpanded: PropTypes.bool,
  isSide: PropTypes.bool,
  location: PropTypes.object,
  history: PropTypes.object,
  t: PropTypes.func,
  travelerAppStore: PropTypes.object,
  brandedSettingsStore: PropTypes.object,
  talonOneStore: PropTypes.object,
  duplicateTravelersStore: PropTypes.object
};

export default withTranslation()(
  withRouter(
    inject(
      'travelerAppStore',
      'brandedSettingsStore',
      'talonOneStore',
      'duplicateTravelersStore'
    )(observer(LoggedInUser))
  )
);
