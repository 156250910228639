import { Slide, Slider } from '@/src/components/common/Slider';
import React, { Fragment } from 'react';
import styled from 'styled-components';
import { Text1, Text3 } from '../../../components/styled/Typography';
import { FlexContainer } from '../../../components/styled/Wrappers';
import theme from '../../../theme';
import mixpanel from '../../../services/mixpanel';
import { addParamsToUrl } from '@/src/components/common/helpers';
import ExternalLinkWrapper from '@/src/components/wrappers/ExternalLinkWrapper';
import PropTypes from 'prop-types';

const slideOpts = {
  slidesPerView: 1,
  pagination: true
};

const Sride = ({
  sRide: { id, photos, title, price, passengersForPrice, duration, numberOfStops, tourLink, currency }
}) => {
  const link = addParamsToUrl(tourLink, { utm_medium: 'explore_tab', utm_source: 'traveler_app' });

  return (
    <ExternalLinkWrapper
      link={link}
      callback={() => {
        mixpanel.trackSRideView({ Title: title });
      }}
    >
      <Slider {...slideOpts}>
        {photos.map((image, index) => {
          const { large } = image;
          return (
            <Slide key={`${id}-${index}`}>
              <RidePhoto image={large} />
            </Slide>
          );
        })}
      </Slider>
      <Text1 weight="500" margin="10px auto">
        {title}
      </Text1>
      {price !== null && (
        <Fragment>
          <FlexContainer justify="space-between" align="center">
            <Text1>
              {currency.symbol}
              {`${price ? price : 0}`}
            </Text1>
            <DescriptionText className="stops">{duration}</DescriptionText>
          </FlexContainer>
          <FlexContainer justify="space-between" align="center">
            <DescriptionText className="persons-number">{passengersForPrice}</DescriptionText>
            <DescriptionText className="stops">{`${numberOfStops}`} stops</DescriptionText>
          </FlexContainer>
        </Fragment>
      )}
    </ExternalLinkWrapper>
  );
};

const DescriptionText = styled(Text3)`
  color: ${theme.colors.greys.grey5};
  font-weight: 700;
`;

const RidePhoto = styled.div`
  border-radius: 4px;
  background-image: url(${(props) => props.image});
  width: 100%;
  max-width: 244px;
  height: 142px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: ${theme.colors.greys.grey15};

  @media (max-width: ${theme.breakpoints[0]}px) {
    max-width: 100%;
    height: 184px;
  }
`;

Sride.propTypes = {
  sRide: PropTypes.shape({
    id: PropTypes.string,
    photos: PropTypes.array,
    title: PropTypes.string,
    price: PropTypes.number,
    passengersForPrice: PropTypes.string,
    duration: PropTypes.string,
    numberOfStops: PropTypes.number,
    tourLink: PropTypes.string,
    currency: PropTypes.object
  })
};

export default Sride;
