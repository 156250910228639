import React, { useState, useCallback } from 'react';
import { useStores } from '../../../../../stores/useStores';
import MessageIconWhiteSmall from '../../../../../assets/social-icons/message-icon-white-small.svg';
import CallIconWhite from '../../../../../assets/social-icons/call-icon-white.svg';
import CallIconGrey from '../../../../../assets/social-icons/call-icon-grey.svg';
import styled from 'styled-components';
import theme from '../../../../../theme';
import { Text2 } from '../../../../../components/styled/Typography';
import { IonToast, IonLoading } from '@ionic/react';
import { ButtonBase } from '../../../../../components/styled/Buttons';
import { FlexContainer } from '../../../../../components/styled/Wrappers';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import mixpanel from '../../../../../services/mixpanel';

const SocialButtons = ({ driverPhoneNumber, isGuestDriver, fromDesktop, transfer, t, history }) => {
  const {
    travelerAppStore: { chosenTransfer, createChatForTransfer }
  } = useStores();
  const [showToast, setShowToast] = useState(false);
  const [toastMessage] = useState(t('sign_in.error'));
  const [showLoading, setShowLoading] = useState(false);

  const onMessageClick = useCallback(() => {
    mixpanel.trackMessageDriverClick();

    if (chosenTransfer.channelToken) {
      history.push('/messages', { selectedChannelToken: chosenTransfer.channelToken });
    } else {
      setShowLoading(true);
      createChatForTransfer(chosenTransfer)
        .then((chat) => {
          setShowLoading(false);
          history.push('/messages', { selectedChannelToken: chat.channelToken });
        })
        .catch((error) => {
          setShowLoading(false);
          setShowToast(true);
        });
    }
  }, [chosenTransfer, createChatForTransfer, history]);

  return (
    <StyledContainer justify="center">
      {fromDesktop ? (
        <ActionButtonBase disabled kind="secondary">
          <img alt={t('trip_details.call')} src={CallIconGrey} />
          <Title disabled>{t('transfer.cta_call_desktop')}</Title>
        </ActionButtonBase>
      ) : (
        <ActionButtonBase
          kind="secondary"
          as="a"
          href={`tel:${driverPhoneNumber}`}
          onClick={mixpanel.trackCallDriverClick}
        >
          <img alt={t('trip_details.call')} src={CallIconWhite} />
          <Title>{t('trip_details.call')}</Title>
        </ActionButtonBase>
      )}
      {!isGuestDriver && !chosenTransfer.isBohBooker && !chosenTransfer.isInvitedPassenger && (
        <ActionButtonBase kind="secondary" onClick={onMessageClick}>
          <img alt={t('trip_details.message')} src={MessageIconWhiteSmall} />
          <Title>{t('trip_details.message')}</Title>
        </ActionButtonBase>
      )}
      <IonToast
        isOpen={showToast}
        onDidDismiss={() => setShowToast(false)}
        message={toastMessage}
        duration={3000}
        color={'danger'}
        cssClass="styled-toast-error"
      />
      <IonLoading
        cssClass="loading-modal"
        isOpen={showLoading}
        onDidDismiss={() => setShowLoading(false)}
        message={t('please_wait')}
      />
    </StyledContainer>
  );
};

const StyledContainer = styled(FlexContainer)`
  display: flex;
  margin-top: 24px;
`;

/*const ActionButton = styled(IonButton)`

  min-height: 66px;
  width:96px;
  margin-right:8px;
  display:flex;
  padding:12px;
  flex-flow:column;
  justify-content:flex-start;
  border:none;
  align-items: center;
  
  :last-child {
    margin-right: 0;
  }
  outline: none;
  white-space: normal !important;
  height: auto;
  word-break:break-word;
`;*/

const Title = styled(Text2)`
  font-weight: 700;
  // margin-top:8px !important;
  text-align: center;
  margin-left: 8px;
  color: ${theme.colors.base.white};
  opacity: ${(props) => (props.disabled ? '0.4' : 'inherit')};
`;

const ActionButtonBase = styled(ButtonBase)`
  height: 48px;
  border-radius: 4px;
  background-color: ${theme.colors.base.graphite};
  color: ${theme.colors.base.white};
  width: 100%;
  text-transform: none;
  font-weight: 700;
  font-size: 16px;
  max-width: 260px;
  margin-right: 16px;
  :last-child {
    margin-right: 0;
  }
  min-height: 48px;
  outline: none;
  white-space: normal !important;
  height: auto;
  word-break: break-word;

  // flex-flow:column;
  // justify-content:flex-start;
  // align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;

  cursor: ${(props) => (props.disabled ? 'unset' : 'pointer')};

  :hover {
    background-color: ${(props) => (props.disabled ? theme.colors.base.graphite : '#425269')};
  }

  :active {
    outline: none;
    background-color: ${(props) => (props.disabled ? theme.colors.base.graphite : '#1f2836')};
  }

  :focus {
    background-color: ${theme.colors.base.graphite};
  }

  @media (max-width: ${theme.breakpoints[0]}px) {
    // margin-right:20px;
    // max-width:calc(50% - 10px);
  }
`;

export default withTranslation()(withRouter(SocialButtons));
