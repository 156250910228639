import React from 'react';
import PropTypes from 'prop-types';
import { NotificationStyled } from './_Notification.styled';
import { Text2 } from '../../styled/Typography';
import CloseIcon from '../../../assets/icons/close-delete-grey-icon.svg';
import { Link } from 'react-router-dom';
import { NOTIFICATION_TYPES } from '@/src/constants';
import ArrowRight from '../../svgs/ArrowRight';
import theme from '@/src/theme';

const Notification = (props) => {
  const {
    title = '',
    subtitle = '',
    icon = '',
    link = '',
    margin = '0',
    onClose,
    type = NOTIFICATION_TYPES.SUCCESS,
    padding = '12px',
    gap = '12px',
    onClick,
    maxLines,
    maxWidth = 'auto'
  } = props;

  const handleDismiss = (e) => {
    e.preventDefault();
    onClose && onClose();
  };

  const renderIcon = () => {
    if (icon) {
      // icon is a path
      if (typeof icon === 'string') {
        return <img src={icon} className="notification-icon" alt="notification icon" />;
      }

      // icon is a component
      return icon;
    }
  };

  const renderNotification = () => {
    return (
      <NotificationStyled
        margin={margin}
        type={type}
        onClick={onClick}
        padding={padding}
        gap={gap}
        maxLines={maxLines}
        maxWidth={maxWidth}
        link={link}
      >
        {renderIcon()}

        <div className="text-container">
          {title && (
            <Text2 weight="600" className="notification-title" style={{ marginBottom: subtitle ? '4px' : 0 }}>
              {title}
            </Text2>
          )}
          {subtitle && <Text2 className="notification-subtitle"> {subtitle} </Text2>}
        </div>

        {(link || onClick) && <ArrowRight fill={theme.colors.base.graphiteLight} />}
        {onClose && <img src={CloseIcon} alt="close icon" onClick={handleDismiss} className="close-icon" />}
      </NotificationStyled>
    );
  };

  return link ? <Link to={link}>{renderNotification()}</Link> : renderNotification();
};

Notification.propTypes = {
  title: PropTypes.node,
  subtitle: PropTypes.node,
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  link: PropTypes.string,
  margin: PropTypes.string,
  onClose: PropTypes.func,
  type: PropTypes.oneOf([NOTIFICATION_TYPES.SUCCESS, NOTIFICATION_TYPES.WARNING, NOTIFICATION_TYPES.MUTTED]),
  padding: PropTypes.string,
  gap: PropTypes.string,
  onClick: PropTypes.func,
  maxLines: PropTypes.number, // number of lines to show before truncating
  maxWidth: PropTypes.string
};

export default Notification;
