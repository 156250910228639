import { get, set, remove } from './storage';
import queryString from 'query-string';
import TravelerApi from '../API';
import stores from '../stores';
import tripsStore from '../stores/tripsStore';
import { getCookie, isWebPlatform, handleExpiredToken, removeCookie } from '../components/common/helpers';
import PushNotificationsService from './pushNotifications.js';
import TrackTravelerAppEventsService from './trackTravelerAppEvents.js';
import { isMobileAppPlatform } from '../components/common/helpers';
import braze from './braze';
import mixpanel from './mixpanel';
import { posthogReset } from '@/src/services/posthog';
import { logError } from '../helpers/log';

const { travelerAppStore, brandedSettingsStore, messagesStore, duplicateTravelersStore, talonOneStore } = stores;

class AuthenticationService {
  authenticateTraveler = async () => {
    // read token from param > storage > cookies
    // get token from param
    const values = queryString.parse(window.location.search);
    let { travelers_access_token } = values;
    let refreshToken;

    if (!travelers_access_token) {
      // get token from storage
      const travelerInfo = await get('travelerInfo');
      const travelers_access_token_from_storage = travelerInfo?.accessToken;
      // We only want to use the refresh token if the access token we send is the stored one.
      refreshToken = travelerInfo?.refreshToken;

      travelers_access_token = travelers_access_token_from_storage;
    }

    // Ignore cookies
    // get token from cookies if not mobile
    if (!travelers_access_token) {
      if (isWebPlatform()) {
        // read cokies
        travelers_access_token = getCookie('travelers_access_token');
      }
    }

    // if token exists already in storage authenticate from storage and refresh traveler info asynchronously
    if (travelers_access_token) {
      // refresh Token synchronously
      return this.loginWithToken(travelers_access_token, false, refreshToken);
    } else {
      return Promise.resolve({ authenticated: false });
    }
  };

  authenticateWithToken = async (token) => {
    if (travelerAppStore.isAuthorised && travelerAppStore.travelerInfo.accessToken === token) {
      return Promise.resolve({ authenticated: true });
    } else {
      // login to API
      return this.loginWithToken(token);
    }
  };

  /**
   * @param {string} token
   * @param {bool} forceLogout Forces logout before login
   * @param {string} refreshToken
   */
  loginWithToken = async (token, forceLogout = false, refreshToken) => {
    travelerAppStore.setLoginWithTokenInProgress(true);
    // call api to to login traveler and get his info
    return TravelerApi.loginWithToken(token, refreshToken)
      .then(async (travelerInfo) => {
        // on success
        // save traveler info to storage
        // save traveler info to store
        if (travelerInfo.accessToken !== token || forceLogout) {
          await this.onLogoutSuccess();
        }
        await this.onLoginSuccess(travelerInfo);

        mixpanel.trackLoginCompleted('auto');

        travelerAppStore.setLoginWithTokenInProgress(false);

        return { authenticated: true };
      })
      .catch(async (err) => {
        travelerAppStore.setLoginWithTokenInProgress(false);
        await this.onLoginFailure();

        // Remove token from storage if token is not valid anymore
        if (err.response?.status === 401) {
          // Mobile redirection is handled after the loginWithToken call
          if (isWebPlatform()) {
            mixpanel.trackTravelerTokenExpired(err);
            handleExpiredToken();
          }
        } else {
          logError('Error logging in with token', err);
        }

        return { authenticated: false, error: err };
      });
  };

  onLoginSuccess = async (travelerInfo) => {
    this.saveTravelerInfoToStorage(travelerInfo);
    this.saveTravelerInfoToStore(travelerInfo);
    if (isMobileAppPlatform()) {
      PushNotificationsService.register();
    }
    if (travelerInfo.chatUser) {
      messagesStore.connect(travelerInfo.chatUser);
    }
    if (travelerAppStore.isAllowedToMergeAccounts) {
      duplicateTravelersStore.getInfo();
    }
    TrackTravelerAppEventsService.trackLoginEvent();
    braze.changeUser(travelerInfo.id);
    braze.subscribeToContentCardsUpdates();
    setTimeout(() => {
      braze.getContentCardsFromServer();
    }, 500);
  };

  resetData = async () => {
    messagesStore.disconnect(travelerAppStore.firebaseToken);
    if (isMobileAppPlatform()) {
      await PushNotificationsService.unregister();
    }
    this.removeTravelerInfoFromStorage();
    this.removeTravelerInfoFromStore();

    duplicateTravelersStore.resetDuplicateTravelersStore();
    tripsStore.resetTripsStore();
    talonOneStore.resetValues();

    mixpanel.reset();
    braze.reset();
    posthogReset();
  };

  onLoginFailure = async () => {
    const cookieDomain = process.env.REACT_APP_ENVIRONMENT === 'production' ? 'welcomepickups.com' : 'welcomd.com';
    removeCookie('travelers_access_token', cookieDomain);
    await this.resetData();
  };

  onLogoutSuccess = async () => {
    await this.resetData();
    return brandedSettingsStore.fetchBrandedSettings();
  };

  saveTravelerInfoToStorage = async (travelerInfo) => {
    const saved = await set('travelerInfo', travelerInfo);
    return saved;
  };

  saveTravelerInfoToStore = (travelerInfo) => {
    travelerAppStore.setTravelerInfo(travelerInfo);
  };

  getTravelerInfoFromStorage = async () => {
    return await get('travelerInfo');
  };

  removeTravelerInfoFromStorage = async () => {
    await remove('travelerInfo');
  };

  removeTravelerInfoFromStore = () => {
    return travelerAppStore.resetTravelerInfo();
  };

  getCookie = (name) => {
    const match = document.cookie.match(new RegExp(`(^| )${name}=([^;]+)`));
    return match ? match[2] : false;
  };
}
export default new AuthenticationService();
