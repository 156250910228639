const theme = {
  colors: {
    background: {
      white: '#ffffff',
      grey: '#f5f5f5',
      greenLight: '#D7F7E9',
      orangeLight: '#E6943F1A'
    },
    base: {
      graphite: '#2d3b4e',
      graphiteLight: '#616C7A',
      graphiteLighter: '#969DA6',
      graphiteDark: '#0f161e',
      graphiteDarker: '#27282E',
      white: '#ffffff',
      white75: 'rgba(255, 255, 255, .75)',
      white5: 'rgba(255, 255, 255, .5)'
    },
    greys: {
      grey6: 'rgba(45, 59, 78, .6)',
      grey5: 'rgba(45, 59, 78, .5)',
      grey4: 'rgba(45, 59, 78, .4)',
      grey3: 'rgba(45, 59, 78, .3)',
      grey2: 'rgba(45, 59, 78, .2)',
      grey15: 'rgba(45, 59, 78, .15)',
      grey1: 'rgba(45, 59, 78, .1)',
      grey05: 'rgba(45, 59, 78, .05)',
      grey06: 'rgba(45, 59, 78, .06)'
    },
    cta: {
      primary: '#48D9A4',
      green: '#48D9A4',
      greenMedium: '#00CF95',
      greenDark: '#00C58A',
      greenHover: '#8AE3BE',
      greenDisabled: '#BAEED7',
      greenModern: '#50D8A5',
      mustard: '#F3A93A',
      grey: '#F4F5F6'
    },
    accent: {
      red: '#f27470',
      yellow: '#fbd97e',
      blue: '#f2f2f2'
    }
  },
  space: [0, 8, 12, 18, 24, 32, 40],
  typography: {
    11: {
      fontSize: '11px',
      lineHeight: '14px'
    },
    12: {
      fontSize: '12px',
      lineHeight: '16px'
    },
    14: {
      fontSize: '14px',
      lineHeight: '18px'
    },
    16: {
      fontSize: '16px',
      lineHeight: '20px'
    },
    18: {
      fontSize: '18px',
      lineHeight: '24px'
    },
    20: {
      fontSize: '20px',
      lineHeight: '24px'
    },
    24: {
      fontSize: '24px',
      lineHeight: '31.2px'
    },
    30: {
      fontSize: '30px',
      lineHeight: '39px'
    },
    36: {
      fontSize: '36px',
      lineHeight: '46.8px'
    }
    // TODO: Delete if not used
    // h1: {
    //   fontSize: '36px'
    // },
    // h2: {
    //   fontSize: '30px'
    // },
    // h3: {
    //   fontSize: '24px'
    // },
    // h4: {
    //   fontSize: '20px'
    // },
    // '.subheading': {
    //   fontSize: 14,
    //   fontWeight: 700,
    //   letterSpacing: 1.2,
    //   color: 'rgba(45, 59, 78, .5)'
    // },
    // '.subheading--sm': {
    //   fontSize: 12,
    //   fontWeight: 700,
    //   color: 'rgba(45, 59, 78, .5)'
    // },
    // 'p, .text, .text--sm, .text--xs': {
    //   fontSize: 16,
    //   lineHeight: 1.5,
    //   color: '#2d3b4e'
    // },
    // '.text--sm': {
    //   fontSize: 14
    // },
    // '.text--xs': {
    //   fontSize: 12
    // }
  },
  breakpoints: [768, 992, 1200],
  screenSizes: {
    xs: 0,
    sm: 768,
    md: 992,
    lg: 1200
  },
  mobileScreenWidths: {
    lg: 435 // Largest current phone viewport width
  },
  borderRadius: {
    radius4: '4px'
  },
  tooltip: {
    backgroundColor: 'rgba(15, 22, 30, 0.92) !important',
    padding: 16,
    fontSize: 14,
    fontWeight: 500,
    lineHeight: 1.29
  }
};

export default theme;
